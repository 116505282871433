import React from 'react'
import { useState } from 'react'
import { FlatList } from 'react-native'
import { useFormikContext } from 'formik'

import AppText from '../Text'
import BossButton from '../screen-button/BossButton'
import { ICio } from 'types/cio'
import { defaultConfig } from '../../config/styles'

interface IFormBossChoice {
  defaultValue: null | string
  bossPractices: object
  originalFormData: ICio
}
function FormBossChoice({
  defaultValue,
  bossPractices,
  originalFormData,
}: IFormBossChoice) {
  const [highlightedBoss, setHighlightedBoss] = useState<null | string>(
    defaultValue
  )

  const { setFieldValue, values, handleSubmit } = useFormikContext<ICio>()
  const isNewBoss =
    values['boss1'] === '' &&
    values['boss2'] === '' &&
    values['boss3'] === '' &&
    values['boss4'] === ''

  return (
    <>
      <FlatList
        style={{ marginTop: 15 }}
        data={bossPractices}
        keyExtractor={(boss) => {
          return boss.id.toString()
        }}
        renderItem={({ item }) => (
          <BossButton
            bossName={item.name}
            buttonBackground={defaultConfig.colors.lightGrey}
            textColour={defaultConfig.colors.primary}
            highlightedTextColour={defaultConfig.colors.primary}
            onPress={() => {
              setHighlightedBoss(item.id)
              setFieldValue('bossId', highlightedBoss)
              console.log(item)

              // set boss1 - 4
              setFieldValue('boss1', item.boss.body)
              setFieldValue('boss2', item.boss.oxygen)
              setFieldValue('boss3', item.boss.selfEnergy)
              setFieldValue('boss4', item.boss.selfAction)

              handleSubmit()
            }}
            highlighted={highlightedBoss === item.id}
          />
        )}
        ListEmptyComponent={
          <AppText
            style={{
              marginTop: 10,
              marginBottom: 20,
            }}
          >
            There are currently no BOSS Practices
          </AppText>
        }
      />
      {!isNewBoss && (
        <BossButton
          bossName="Previously saved BOSS Practice"
          buttonBackground={defaultConfig.colors.lightGrey}
          textColour={defaultConfig.colors.primary}
          highlightedTextColour={defaultConfig.colors.primary}
          onPress={() => {
            setHighlightedBoss(null)
            setFieldValue('bossId', highlightedBoss)

            // blank boss1 - 4
            setFieldValue('boss1', originalFormData.boss1)
            setFieldValue('boss2', originalFormData.boss2)
            setFieldValue('boss3', originalFormData.boss3)
            setFieldValue('boss4', originalFormData.boss4)

            handleSubmit()
          }}
          highlighted={
            !isNewBoss &&
            (highlightedBoss === null || highlightedBoss === undefined)
          }
        />
      )}
      <BossButton
        bossName="New BOSS Practice"
        buttonBackground={defaultConfig.colors.primary}
        textColour={defaultConfig.colors.white}
        highlightedTextColour={defaultConfig.colors.white}
        onPress={() => {
          setHighlightedBoss(null)
          setFieldValue('bossId', highlightedBoss)

          // blank boss1 - 4
          setFieldValue('boss1', '')
          setFieldValue('boss2', '')
          setFieldValue('boss3', '')
          setFieldValue('boss4', '')

          handleSubmit()
        }}
        highlighted={
          isNewBoss &&
          (highlightedBoss === null || highlightedBoss === undefined)
        }
      />
    </>
  )
}

export default FormBossChoice
