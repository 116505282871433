export const removeKeys = function (
  object: { [key: string]: any },
  keys: string[]
): { [key: string]: any } {
  const ret: { [key: string]: any } = {}

  for (const key in object) {
    if (!keys.includes(key)) {
      ret[key] = object[key]
    }
  }

  return ret
}

export const joinArrayWithCommasAndAnd = function (array: string[]): string {
  if (array.length === 0) {
    return ''
  } else if (array.length === 1) {
    return array[0]
  } else {
    const allButLast = array.slice(0, -1).join(', ')
    const last = array[array.length - 1]
    return `${allButLast} and ${last}`
  }
}
