import {
  parseISO,
  differenceInCalendarDays,
  startOfDay,
  formatDistance,
} from 'date-fns'

export const today = () => {
  const date = new Date()
  return (
    date.getFullYear() +
    '-' +
    String(date.getMonth() + 1).padStart(2, '0') +
    '-' +
    String(date.getDate()).padStart(2, '0')
  )
}

export const formatDateDistance = (dateString: string) => {
  const date = startOfDay(parseISO(dateString))
  const today = startOfDay(new Date())
  const diffDays = differenceInCalendarDays(today, date)

  switch (diffDays) {
    case 0:
      return 'today'
    case 1:
      return 'yesterday'
    default:
      return formatDistance(date, today, {
        addSuffix: true,
      })
  }
}

export default { today }
