import React from 'react'
import { StyleSheet, View } from 'react-native'
import { useMutation } from 'react-query'

import AppText from '../../../../components/Text'
import colors from '../../../../config/colors'
import { defaultStyles } from '../../../../config/styles'
import Screen from '../../../../components/Screen'
import Button from '../../../../components/ButtonIcon'
import { format, parseISO } from 'date-fns'
import TitleGraphic from '../../../../components/TitleGraphic'
import MeetingCode from '../../../../components/rapid-review/MeetingCode'
import { toUUID } from '../../../../utility/resource'

import meetingQuery from '../../../../api/query/meeting'
import { useSession } from '../../../../hooks/useSession'
import ActivityIndicator from '../../../../components/ActivityIndicator'
import AppButton from '../../../../components/Button'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { AppStackParamList } from '../../../../navigation/AppNavigator'
import { useNavigation } from '@react-navigation/native'

function UpComingMeetingScreen({ route }) {
  const navigation = useNavigation<DrawerNavigationProp<AppStackParamList>>()
  const { user } = useSession()
  const meetingId = route.params.meetingId
  const { data, isLoading, isError, isSuccess, refetch } =
    meetingQuery.getMeeting(meetingId)

  const meetingOwner = user?.id === toUUID(data?.organiser)

  const joinMutation = useMutation(meetingQuery.startMeeting, {
    onSuccess: (data) => {
      navigation.navigate('RAPID_REVIEW', {
        screen: 'Meeting_View_InProgress',
        params: {
          meetingId: data.id,
        },
      })
    },
    onError: (error) => {
      alert('Failed to start the meeting')
      console.log(error)
    },
  })

  return (
    <Screen style={defaultStyles.screen}>
      <TitleGraphic
        section="Meetings"
        title="Upcoming Meeting"
        graphic={require('../../../../assets/lead-rapid-review.jpg')}
      />
      <View style={defaultStyles.mainContainer}>
        <ActivityIndicator visible={isLoading} />
        {isError && (
          <>
            <AppText>Couldn't retrieve the Meeting.</AppText>
            <AppButton title="Retry" onPress={() => refetch()} />
          </>
        )}
        {isSuccess && (
          <>
            <View style={[styles.row]}>
              <AppText style={styles.heading}>Meeting name</AppText>
              <AppText>{data.name}</AppText>
            </View>
            <View style={[styles.row]}>
              <AppText style={styles.heading}>Date</AppText>
              <AppText>{format(parseISO(data.date), 'do MMMM yyyy')}</AppText>
            </View>
            <View style={[styles.row]}>
              <AppText style={styles.heading}>Meeting intent</AppText>
              <AppText>{data.intent}</AppText>
            </View>
            {/* 
        Supposed to be here, but would need an extra api call
        <View style={[styles.row]}>
          <AppText style={styles.heading}>Invitees</AppText>
        </View>
        */}

            {meetingOwner && (
              <>
                <View style={[styles.row]}>
                  <AppText style={styles.heading}>Meeting code</AppText>
                  <MeetingCode code={data.meetingCode} />
                </View>

                <Button
                  text="Start meeting"
                  color={colors.white}
                  backgroundColor={colors.primary}
                  onPress={() => joinMutation.mutate(meetingId)}
                />
              </>
            )}
          </>
        )}
      </View>
    </Screen>
  )
}

const styles = StyleSheet.create({
  meetingCode: {
    justifyContent: 'flex-start',
    marginVertical: 100,
    width: '100%',
  },
  code: {
    fontSize: 24,
    fontWeight: 'bold',
    letterSpacing: 10,
  },
  panel: {
    backgroundColor: colors.light,
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  text: {
    fontSize: 25,
    fontWeight: '600',
    marginVertical: 20,
  },
  heading: {
    fontWeight: '700',
    minWidth: 150,
  },
  table: {},
  row: {
    paddingVertical: 20,
  },
})

export default UpComingMeetingScreen
