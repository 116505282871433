import React, { useEffect } from 'react'
import { View } from 'react-native'

import ActivityIndicator from '../../../components/ActivityIndicator'
import Screen from '../../../components/Screen'
import Button from '../../../components/Button'
import AppText from '../../../components/Text'
import { defaultStyles } from '../../../config/styles'
import TitleGraphic from '../../../components/TitleGraphic'
import sharedWithMeQuery from '../../../api/query/sharedWithMe'
import CioList from '../../../components/shared-with-me/CioList'
import appStateQuery from '../../../api/query/appState'
import { useMutation, useQueryClient } from 'react-query'
import { QUERY_KEYS } from '../../../config/queryKeys'
import { STATE_KEY } from '../../../config/constants'

function CioScreen() {
  const queryClient = useQueryClient()
  const cioApi = sharedWithMeQuery.getCriticalImpactOpportunities()

  const stateMutation = useMutation(appStateQuery.postAppState, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS.appState.all)
      queryClient.invalidateQueries(
        QUERY_KEYS.appState.item(STATE_KEY.lastSeenSharedCio)
      )
    },
    onError: (error) => {
      alert('Failed update state')
      console.error(error)
    },
  })

  useEffect(() => {
    stateMutation.mutate({
      stateKey: STATE_KEY.lastSeenSharedCio,
      stateValue: new Date().toISOString(),
    })
  }, [])

  return (
    <Screen style={defaultStyles.screen}>
      <TitleGraphic
        section="Shared With Me"
        title="Shared CIOs"
        graphic={require('../../../assets/lead-cio.jpg')}
      />

      <View style={defaultStyles.mainContainer}>
        <ActivityIndicator visible={cioApi.isLoading} />
        {cioApi.isError && (
          <>
            <AppText>
              Couldn't retrieve the shared Critical Impact Opportunities.
            </AppText>
            <Button title="Retry" onPress={() => cioApi.refetch()} />
          </>
        )}
        {cioApi.isSuccess && <CioList title="Shared CIOs" data={cioApi.data} />}
      </View>
    </Screen>
  )
}

export default CioScreen
