import React, { useContext, useEffect } from 'react'
import { StyleSheet, View, Image } from 'react-native'

import Button from '../../../components/Button'
import { defaultStyles } from '../../../config/styles'
import colors from '../../../config/colors'
import Icon from '../../../components/Icon'
import Text from '../../../components/Text'
import { ScrollToTopContext } from '../../../components/startup/components/ScrollToTopContext'

interface IWelcomeScreen {
  onClose: () => void
}

const CIWelcomeScreen: React.FC<IWelcomeScreen> = ({ onClose }) => {
  const scrollToTop = useContext(ScrollToTopContext)

  return (
    <View onLayout={scrollToTop}>
      <View style={styles.logoContainer}>
        <Image
          style={styles.logo}
          source={require('../../../assets/logo-icon.png')}
        />
      </View>
      <View style={[defaultStyles.mainContainer, styles.mainContainer]}>
        <div>
          <p style={styles.intro}>
            Welcome to HabitBuilder – and congratulations. By downloading
            HabitBuilder you’ve already taken the first small step to building
            your own more useful habits.
          </p>
          <p>
            HabitBuilder will help you make <b>better decisions</b>,{' '}
            <b>learn faster</b> and <b>achieve more</b> - thanks to a variety of
            drills and practices that you can create for yourself and by nudging
            you to keep up those practices and build them into habits.
          </p>
          <div>
            <p style={{ marginBottom: 10 }}>In here you will find ways to:</p>

            <View style={[{ flexDirection: 'row' }]}>
              <View style={{ marginRight: 5 }}>
                <Icon
                  name="check-bold"
                  size={40}
                  iconColor={colors.primary}
                  backgroundColor={colors.white}
                />
              </View>
              <Text style={styles.li}>
                build your confidence (with the Proud Past Trophy Cabinet)
              </Text>
            </View>

            <View style={[{ flexDirection: 'row' }]}>
              <View style={{ marginRight: 5 }}>
                <Icon
                  name="check-bold"
                  size={40}
                  iconColor={colors.primary}
                  backgroundColor={colors.white}
                />
              </View>
              <Text style={styles.li}>
                build your resilience under pressure (with the BOSS Practice)
              </Text>
            </View>

            <View style={[{ flexDirection: 'row' }]}>
              <View style={{ marginRight: 5 }}>
                <Icon
                  name="check-bold"
                  size={40}
                  iconColor={colors.primary}
                  backgroundColor={colors.white}
                />
              </View>
              <Text style={styles.li}>
                prepare your ideal impact for important events – like, say, an
                interview, a pitch or a competition (with the CIO Practice)
              </Text>
            </View>

            <View style={[{ flexDirection: 'row' }]}>
              <View style={{ marginRight: 5 }}>
                <Icon
                  name="check-bold"
                  size={40}
                  iconColor={colors.primary}
                  backgroundColor={colors.white}
                />
              </View>
              <Text style={styles.li}>
                learn faster (with the Rapid Review Practice)
              </Text>
            </View>

            <View style={[{ flexDirection: 'row' }]}>
              <View style={{ marginRight: 5 }}>
                <Icon
                  name="check-bold"
                  size={40}
                  iconColor={colors.primary}
                  backgroundColor={colors.white}
                />
              </View>
              <Text style={styles.li}>
                store the information that will help others get the best out of
                you (BOOM)
              </Text>
            </View>
          </div>
          <p>
            You can build your own Habitbuilder dashboard with whichever of
            those practices feel most useful. One thing that will always be
            there is your SHED - Sleep Hydration Exercise Diet – rating which is
            fundamental to making the most of all that is in the HabitBuilder
            toolkit.
          </p>
          <p>
            That’s why you will be asked to rate your SHED every day so you can
            work out what you need to do to keep well-fuelled and to give you
            the best chance of building the most useful habits.
          </p>
          <p>Head in and decide which habits you are going to build.</p>
        </div>
        <View style={styles.buttonsContainer}>
          <Button title="Start using the app" onPress={onClose} />
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  background: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  buttonsContainer: {},
  logo: {
    width: 50,
    height: 50,
  },
  logoContainer: {
    // position: "absolute",
    // top: 70,
    alignItems: 'center',
    marginTop: 20,
  },
  mainContainer: {
    padding: 20,
    maxWidth: 700,
    textAlign: 'left',
    fontFamily: 'Open Sans',
  },
  tagline: {
    fontSize: 25,
    fontWeight: '600',
    paddingVertical: 20,
  },
  intro: {
    fontSize: '125%',
    lineHeight: 1.5,
    fontWeight: '600',
  },
  li: {
    fontSize: 16,
    paddingTop: 10,
  },
  link: {
    color: '#3e4e63',
  },
})

export default CIWelcomeScreen
