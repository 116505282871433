import React, { useState } from 'react'
import { View } from 'react-native'

import AppButton from '../Button'
import { ErrorMessage } from '../forms'
import AppTextInput from '../TextInput'

const roles = {
  input: 'Input',
  other: 'Other',
  rapidReview: 'Review / Follow-up',
}

interface IRolePicker {
  role?: string
  onChange?: ((props: IRolePickerOnChange) => void) | null
}

interface IRolePickerOnChange {
  role: string
  roleString: string
}

const RolePicker = ({ role = '', onChange = null }: IRolePicker) => {
  const [selectedRole, setSelectedRole] = useState(
    role !== 'other' ? 'other' : role
  )
  const [roleString, setRoleString] = useState(role !== 'other' ? role : '')
  const [showError, setShowError] = useState(false)

  function update(role, text) {
    setSelectedRole(role)

    if (typeof onChange === 'function') {
      onChange({
        role: role,
        roleString: role === 'other' ? text : roles[role],
      })
    }
  }

  function RoleButton({ role, selectedRole, text }) {
    const enabled = selectedRole === role

    return (
      <View style={{ flex: 1, marginLeft: 5, marginRight: 5 }}>
        <AppButton
          title={text}
          borderColor={enabled ? 'primary' : 'medium'}
          backgroundColor={enabled ? 'lightPrimary' : 'white'}
          textColor={'black'}
          emphasis={enabled}
          onPress={() => {
            update(role, roleString)
          }}
        />
      </View>
    )
  }

  return (
    <>
      <View style={{ flexDirection: 'row', marginLeft: -5, marginRight: -5 }}>
        <RoleButton role="input" selectedRole={selectedRole} text="Input" />
        <RoleButton
          role="rapidReview"
          selectedRole={selectedRole}
          text="Review / Follow-up"
        />
        <RoleButton role="other" selectedRole={selectedRole} text="Other" />
      </View>
      {selectedRole === 'other' && (
        <View>
          <AppTextInput
            icon={null}
            onBlur={() => {
              setShowError(roleString === '')
              update(selectedRole, roleString)
            }}
            onChangeText={(text) => {
              setRoleString(text ?? '')
              setShowError(roleString === '')
              update(selectedRole, roleString)
            }}
            placeholder={'What is their role?'}
            value={roleString}
            style={{
              outlineStyle: 'none',
              fontSize: 16,
              fontFamily: 'Open Sans',
              width: '100%',
            }}
          />
          <ErrorMessage error={'What is their role?'} visible={showError} />
        </View>
      )}
    </>
  )
}

export default RolePicker
