import React from 'react'
import { StyleSheet, View } from 'react-native'

import AppText from '../../../../components/Text'
import colors from '../../../../config/colors'
import { defaultStyles } from '../../../../config/styles'
import Icon from '../../../../components/Icon'
import Screen from '../../../../components/Screen'
import Button from '../../../../components/ButtonIcon'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { useNavigation } from '@react-navigation/native'
import { AppStackParamList } from '../../../../navigation/AppNavigator'

function DoneScreen() {
  const navigation = useNavigation<DrawerNavigationProp<AppStackParamList>>()

  return (
    <Screen style={defaultStyles.screen}>
      <View style={[defaultStyles.mainContainer, styles.mainContainer]}>
        <Icon name="check-circle" size="80" iconColor={colors.primary} />
        <AppText style={styles.text}>
          Your rapid review has been submitted
        </AppText>
        <Button
          text="Back to dashboard"
          color={colors.white}
          backgroundColor={colors.primary}
          onPress={() => {
            navigation.navigate('DASHBOARD', {
              screen: 'Dashboard_Screen',
            })
          }}
          style={{ fontWeight: 600, marginLeft: 0 }}
        />
      </View>
    </Screen>
  )
}

const styles = StyleSheet.create({
  mainContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    fontSize: 25,
    fontWeight: '600',
    marginVertical: 20,
  },
})

export default DoneScreen
