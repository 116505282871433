import React, { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { formatISO } from 'date-fns'
import { Picker } from './Picker'

export interface IWhenOption {
  label: string
  value?: Date
}

interface IWhenPicker {
  name: string
  onValueChange?(item: string | undefined): void
  placeholder?: string
  width: any
  options: IWhenOption[]
}

function WhenPicker({
  name,
  onValueChange,
  placeholder,
  width,
  options,
}: IWhenPicker) {
  const firstOption = options?.length > 1 ? options[1] : undefined
  const [currentSelected, setCurrentSelected] = useState<string | undefined>(
    undefined
  )

  return (
    <View>
      <View style={styles.buttonRow}>
        <Picker
          onBlur={() => {
            console.log('onBlur')
            if (!currentSelected && onValueChange && firstOption) {
              const itemDate = firstOption.value
                ? formatISO(firstOption.value)
                : null
              if (itemDate) {
                onValueChange(itemDate)
              }
            }
          }}
          onTouchEnd={() => {
            console.log('onTouchEnd')
            if (onValueChange && currentSelected) {
              onValueChange(currentSelected)
            }
          }}
          onValueChange={(item: string | number) => {
            console.log('onValueChange')
            setCurrentSelected(String(item))

            if (onValueChange && item) {
              onValueChange(String(item))
            }
          }}
          placeholder={placeholder}
          selectedValue={currentSelected}
          width={width}
          style={styles.picker}
        >
          {placeholder && <Picker.Item label={placeholder} value={''} />}
          {options.map((item, index) => {
            const itemDate = item.value ? formatISO(item.value) : null
            return (
              <Picker.Item
                label={item.label}
                value={itemDate}
                key={name + '_' + itemDate}
              />
            )
          })}
        </Picker>
      </View>
    </View>
  )
}

export default WhenPicker

const styles = StyleSheet.create({
  buttonRow: {
    flexDirection: 'row',
  },
  heading: {
    fontWeight: '600',
    minWidth: 150,
    marginBottom: 10,
    marginTop: 20,
  },
  picker: {
    borderColor: '#6e6969',
    borderRadius: 10,
    borderStyle: 'solid',
    borderWidth: 1,
    fontSize: 16,
    fontFamily: 'Open Sans',
    marginTop: 10,
    marginRight: 20,
    padding: 10,
    width: '100%',
  },
})
