import { AppStateKey } from '../@types/misc'

export const STATE_KEY = {
  hasSeenWelcomeMessage: 'seen_welcome_message',
  hasSeenCiWelcomeMessage: 'seen_ci_welcome_message',
  hasSeenNudgeMessage: 'seen_nudge_message',
  lastSeenSharedBoom: 'last_seen_shared_boom',
  lastSeenSharedCio: 'last_seen_shared_cio',
  lastSeenSharedRapidReview: 'last_seen_shared_rapid_review',
  lastSeenShedActionHigh: 'last_seen_shed_action_high',
  lastSeenShedActionLow: 'last_seen_shed_action_low',
  dashboardLayout: 'dashboard_layout',
} satisfies Record<string, AppStateKey>
