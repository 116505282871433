import React, { useState } from 'react'
import { View } from 'react-native'
import * as Yup from 'yup'
import { Button, CircularProgress, Grid } from '@mui/material'
import {
  FieldArray,
  Form,
  Formik,
  FormikConfig,
  FormikValues,
  useFormikContext,
} from 'formik'
import { useMutation, useQueryClient } from 'react-query'

import {
  ErrorMessage,
  FormField,
  FormPicker as Picker,
} from '../../../../components/forms'

import Screen from '../../../../components/Screen'
import AppText from '../../../../components/Text'
import { defaultStyles } from '../../../../config/styles'
import AppFormDatePicker from '../../../../components/forms/FormDatePicker'
import rapidReviewQuery from '../../../../api/query/rapidReview'
import criticialImpactQuery from '../../../../api/query/criticalImpactOpportunity'
import meetingQuery from '../../../../api/query/meeting'

import FormSuggestionChoice from '../../../../components/forms/FormSuggestionChoice'
import { toUUID, toIRI } from '../../../../utility/resource'
import TitleGraphic from '../../../../components/TitleGraphic'
import { ratings } from './components/ratings'
import ActivityIndicator from '../../../../components/ActivityIndicator'
import AppButton from '../../../../components/Button'
import { ICio } from 'types/cio'
import { IMeetingItem } from 'types/MeetingItem'
import { IRapidReview } from 'types/rapidReview'
import FormContactPicker from '../../../../components/forms/FormContactPicker'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { AppStackParamList } from '../../../../navigation/AppNavigator'
import { useNavigation } from '@react-navigation/native'

export default function FormScreen({ route }) {
  const navigation = useNavigation<DrawerNavigationProp<AppStackParamList>>()
  const queryClient = useQueryClient()
  const [step, setStep] = useState(0)

  const criticalImpactOpportunityId =
    route.params?.criticalImpactOpportunityId ?? null
  const meetingId = route.params?.meetingId ?? null
  const isMeeting = meetingId !== null

  const cioApi = criticalImpactOpportunityId
    ? criticialImpactQuery.getCriticalImpactOpportunity(
        criticalImpactOpportunityId
      )
    : null
  const meetingApi = meetingId ? meetingQuery.getMeeting(meetingId) : null

  const rrAddMutation = useMutation(rapidReviewQuery.addRapidReview, {
    onSuccess: (data) => {
      queryClient.invalidateQueries('rapidReviews')
      queryClient.invalidateQueries(['rapidReview', { rapidReviewId: data.id }])

      navigation.navigate('RAPID_REVIEW', {
        screen: 'RapidReview_Done',
      })
    },
    onError: (error) => {
      alert('Failed to save the Rapid Review')
      console.log(error)
    },
  })

  let initialValues = defaultReview(
    meetingApi?.data ?? null,
    cioApi?.data ?? null
  )

  const handleSubmit = async (rapidReview) => {
    if (rapidReview.whoEmail) {
      rapidReview.who = null
    } else if (typeof rapidReview.who === 'object') {
      rapidReview.who = toIRI(rapidReview.who.id, 'users')
    } else if (typeof rapidReview.who === 'string') {
      rapidReview.who = toIRI(rapidReview.who, 'users')
    }

    rrAddMutation.mutate({ rapidReview: rapidReview })
  }

  return (
    <Screen style={defaultStyles.screen}>
      <TitleGraphic
        section="Rapid Reviews"
        title="Create a Rapid Review"
        graphic={require('../../../../assets/lead-rapid-review.jpg')}
      />
      <View style={defaultStyles.mainContainer}>
        <ActivityIndicator visible={cioApi?.isLoading ?? false} />
        {cioApi?.isError && meetingApi?.isError && (
          <>
            <AppText>
              Couldn't retrieve the{' '}
              {cioApi ? 'Critical Impact Opportunity' : 'Meeting'}.
            </AppText>
            <AppButton
              title="Retry"
              onPress={() => (cioApi ?? meetingApi).refetch()}
            />
          </>
        )}
        {(cioApi?.isSuccess ?? true) && (meetingApi?.isSuccess ?? true) && (
          <>
            <FormikStepper
              initialValues={initialValues}
              onSubmit={handleSubmit}
              step={step}
              setStep={setStep}
            >
              <FormikStep
                validationSchema={Yup.object({
                  meetingName: Yup.string()
                    .required()
                    .min(1)
                    .label('Meeting Name'),
                  date: Yup.date().required().label('Meeting Date'),
                })}
              >
                <AppText style={defaultStyles.questionWithoutPadding}>
                  Rapid Review of:
                </AppText>
                <FormField
                  maxLength={255}
                  name="meetingName"
                  placeholder="Meeting Name"
                  editable={!isMeeting}
                />

                <AppText
                  style={[
                    defaultStyles.questionWithoutPadding,
                    { marginTop: 20 },
                  ]}
                >
                  Date:
                </AppText>
                <AppFormDatePicker
                  name="date"
                  icon="calendar-month"
                  maxDate={new Date()}
                  dateFormat="do MMMM yyyy"
                  placeholder="Meeting date"
                  readOnly={isMeeting}
                />
              </FormikStep>
              {!isMeeting && (
                <FormikStep
                  validationSchema={Yup.object({
                    meetingAttendees: Yup.string()
                      .required()
                      .min(5)
                      .label('Who was Present?'),
                  })}
                >
                  <AppText style={defaultStyles.questionWithoutPadding}>
                    Who was Present:
                  </AppText>
                  <FormField
                    multiline
                    name="meetingAttendees"
                    numberOfLines={3}
                    placeholder=""
                  />
                </FormikStep>
              )}
              <FormikStep
                validationSchema={Yup.object({
                  meetingIntent: Yup.string()
                    .required()
                    .min(isMeeting ? 0 : 5)
                    .label('Intent'),
                  rating: Yup.number()
                    .required()
                    .min(1)
                    .max(10)
                    .label('Rating'),
                })}
              >
                <AppText style={defaultStyles.questionWithoutPadding}>
                  {!isMeeting ? 'What was the intent' : 'The intent was'}:
                </AppText>
                <FormField
                  multiline
                  name="meetingIntent"
                  numberOfLines={3}
                  placeholder="Intent info here"
                  editable={!isMeeting}
                />

                <AppText
                  style={[
                    defaultStyles.questionWithoutPadding,
                    { marginTop: 20 },
                  ]}
                >
                  How useful was it against the intent? (1-10)
                </AppText>
                <Picker
                  items={ratings}
                  name="rating"
                  placeholder="Select rating"
                  width="100%"
                  type="int"
                />
              </FormikStep>
              <FormikStep
                validationSchema={Yup.object({
                  positives: Yup.string()
                    .required()
                    .min(5)
                    .label('What was useful?'),
                })}
              >
                <AppText style={defaultStyles.questionWithoutPadding}>
                  What was useful?
                </AppText>
                <FormField
                  multiline
                  name="positives"
                  numberOfLines={3}
                  placeholder=""
                />
              </FormikStep>
              <FormikStep
                validationSchema={Yup.object().shape({
                  suggestions: Yup.array()
                    .min(1)
                    .of(Yup.string().required().min(1).label('Suggestion'))
                    .label('Suggestions'),
                })}
              >
                <AppText style={defaultStyles.questionWithoutPadding}>
                  What would make it more useful?
                </AppText>
                <FieldArray
                  name="suggestions"
                  render={(arrayHelpers) => (
                    <div>
                      {arrayHelpers.form.values.suggestions.map(
                        (suggestion, index) => {
                          return (
                            <div key={index}>
                              <button
                                type="button"
                                onClick={() => {
                                  arrayHelpers.remove(index)
                                }}
                                style={{
                                  borderWidth: 0,
                                  fontWeight: 600,
                                  position: 'absolute',
                                  marginTop: 15,
                                  right: 15,
                                  backgroundColor: '#ddd',
                                  color: '#777',
                                  borderRadius: 10,
                                  width: 20,
                                  height: 20,
                                  fontSize: 16,
                                  zIndex: 10,
                                }}
                              >
                                -
                              </button>
                              <FormField name={`suggestions.${index}`} />
                            </div>
                          )
                        }
                      )}

                      <button
                        type="button"
                        onClick={() => {
                          arrayHelpers.push('')
                        }}
                        style={{
                          backgroundColor: '#ffffff',
                          borderWidth: 0,
                          fontFamily: 'Open Sans',
                          fontSize: 16,
                          fontWeight: 600,
                          color: '#3EACBE',
                          marginTop: 20,
                          width: '100%',
                        }}
                      >
                        + Add another suggestion
                      </button>

                      <ErrorMessage
                        error={arrayHelpers.form.errors['suggestions']}
                        visible={arrayHelpers.form.touched['suggestions']}
                      />
                    </div>
                  )}
                />
              </FormikStep>
              {!isMeeting && (
                <FormikStep
                  validationSchema={Yup.object().shape({
                    suggestion: Yup.string()
                      .min(1)
                      .typeError('Which suggestions is being taken forward?')
                      .label('Improvement'),
                    who: Yup.object().shape({
                      type: Yup.string()
                        .required()
                        .min(1)
                        .typeError('Who is taking the improvement forward?'),
                      label: Yup.string(),
                    }),
                    who_email: Yup.string()
                      .when('who', {
                        is: 'email',
                        then: Yup.string()
                          .required('Must enter email address')
                          .email(),
                      })
                      .typeError('Where are we sending the improvement?')
                      .label('Email address'),
                  })}
                >
                  <AppText style={defaultStyles.questionWithoutPadding}>
                    Improvement to take forward?
                  </AppText>
                  <FormSuggestionChoice name="improvement" />

                  <AppText
                    style={[
                      defaultStyles.questionWithoutPadding,
                      { marginTop: 20 },
                    ]}
                  >
                    Who will take that forward?
                  </AppText>
                  <FormContactPicker
                    name="who"
                    group={false}
                    teams={false}
                    placeholder="Who is taking that forward?"
                    width="100%"
                    changeUpdateForm={true}
                    showOther={true}
                  />

                  <PickerEmail name="whoEmail" />
                </FormikStep>
              )}
            </FormikStepper>
          </>
        )}
      </View>
    </Screen>
  )
}

function PickerEmail({ name }) {
  const { values } = useFormikContext()

  const styles = {}

  if (!values['who'] || values['who']['type'] !== 'email') {
    styles['display'] = 'none'
  }

  return (
    <View style={styles}>
      <AppText style={{ marginTop: 20, fontWeight: 600 }}>
        What is their email address?
      </AppText>
      <FormField
        name={name}
        autoCapitalize="none"
        autoCorrect={false}
        icon="email"
        keyboardType="email-address"
        placeholder="Email"
        textContentType="emailAddress"
      />
    </View>
  )
}

function defaultReview(meeting: IMeetingItem | null, cio: ICio | null) {
  const rapidReview: IRapidReview = {
    meetingName: '',
    date: new Date(),
    meetingAttendees: '',
    meetingIntent: '',
    rating: 0,
    positives: '',
    suggestions: [''],
    improvement: '',
  } as IRapidReview

  if (rapidReview.who) {
    const uuid = toUUID(rapidReview.who)

    if (uuid !== null) {
      rapidReview.who = uuid
    }
  }

  if (cio) {
    rapidReview.criticalImpactOpportunity =
      '/critical_impact_opportunities/' + cio.id

    rapidReview.date = cio.date
    rapidReview.meetingName = cio.what
    rapidReview.meetingIntent = cio.why
  }

  if (meeting !== null) {
    if (!rapidReview.meetingName) {
      rapidReview.meetingName = meeting.name
    }
    if (!rapidReview.meetingIntent) {
      rapidReview.meetingIntent = meeting.intent
    }
    if (!rapidReview.date) {
      rapidReview.date = new Date(meeting.date)
    }

    rapidReview.who = toUUID(meeting.organiser)

    if (meeting !== null) {
      rapidReview['meeting'] = toIRI(meeting.id, 'meetings')
    }
  }

  return rapidReview
}

export type FormikStepProps = Pick<
  FormikConfig<FormikValues>,
  'children' | 'validationSchema'
>

export function FormikStep({ children }: FormikStepProps) {
  return <>{children}</>
}

export function FormikStepper({
  step,
  setStep,
  children,
  ...props
}: FormikConfig<FormikValues>) {
  const childrenArray = React.Children.toArray(
    children
  ) as React.ReactElement<FormikStepProps>[]
  const currentChild = childrenArray[step]

  function isLastStep() {
    return step === childrenArray.length - 1
  }

  return (
    <Formik
      {...props}
      validationSchema={currentChild.props.validationSchema}
      onSubmit={async (values, helpers) => {
        if (isLastStep()) {
          await props.onSubmit(values, helpers)
        } else {
          setStep((s) => s + 1)

          // the next line was not covered in the youtube video
          //
          // If you have multiple fields on the same step
          // we will see they show the validation error all at the same time after the first step!
          //
          // If you want to keep that behaviour, then, comment the next line :)
          // If you want the second/third/fourth/etc steps with the same behaviour
          //    as the first step regarding validation errors, then the next line is for you! =)
          //
          // In the example of the video, it doesn't make any difference, because we only
          //    have one field with validation in the second step :)
          helpers.setTouched({})
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form autoComplete="off">
          {currentChild}

          <Grid
            container
            spacing={2}
            style={{
              marginTop: 40,
            }}
          >
            {step > 0 ? (
              <Grid item>
                <Button
                  disabled={isSubmitting}
                  variant="contained"
                  color="primary"
                  style={{
                    backgroundColor: '#3EACBE',
                    borderRadius: 50,
                    paddingLeft: 20,
                    paddingRight: 20,
                    fontSize: 18,
                    fontFamily: 'Open Sans',
                    fontWeight: 600,
                    color: '#fff',
                    textTransform: 'none',
                    boxShadow: 'none',
                    letterSpacing: 0,
                  }}
                  onClick={() => setStep((s) => s - 1)}
                >
                  Back
                </Button>
              </Grid>
            ) : null}
            <Grid item>
              <Button
                startIcon={
                  isSubmitting ? <CircularProgress size="1rem" /> : null
                }
                disabled={isSubmitting}
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: '#3EACBE',
                  borderRadius: 50,
                  paddingLeft: 20,
                  paddingRight: 20,
                  fontSize: 18,
                  fontFamily: 'Open Sans',
                  fontWeight: 600,
                  color: '#fff',
                  textTransform: 'none',
                  boxShadow: 'none',
                  letterSpacing: 0,
                }}
                type="submit"
              >
                {isSubmitting ? 'Submitting' : isLastStep() ? 'Submit' : 'Next'}
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}
