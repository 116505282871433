import React from 'react'
import { FlatList, StyleSheet, View } from 'react-native'

import { useSession } from '../../../hooks/useSession'
import AppText from '../../../components/Text'
import colors from '../../../config/colors'
import { defaultStyles } from '../../../config/styles'
import Icon from '../../../components/Icon'
import Screen from '../../../components/Screen'
import Button from '../../../components/Button'
import Title from '../../../components/Title'
import { PlainListItem } from '../../../components/lists'

function RitualDoneScreen({ rituals, onPress, firstTime = false }) {
  const { user } = useSession()
  console.log('Done', user)
  return (
    <Screen style={defaultStyles.screen}>
      <View style={defaultStyles.mainContainer}>
        <Title>Here's your profile</Title>
        <AppText>
          Based on what you said made a positive difference to the way you
          perform, here's your profile
        </AppText>

        <View style={[styles.block]}>
          <FlatList
            data={rituals}
            keyExtractor={(ritual) => ritual}
            renderItem={({ item }) => (
              <PlainListItem
                title={item}
                IconComponent={
                  <Icon
                    name="check"
                    size={50}
                    iconColor={colors.white}
                    backgroundColor={colors.primary}
                  />
                }
                backgroundColor="transparent"
              />
            )}
          />
        </View>

        <Button title={firstTime ? 'Looks good' : 'Done'} onPress={onPress} />
      </View>
    </Screen>
  )
}

const styles = StyleSheet.create({
  block: {
    backgroundColor: colors.light,
    width: '100%',
    paddingVertical: 20,
    paddingHorizontal: 20,
    borderRadius: 10,
    marginTop: 20,
    marginBottom: 20,
  },
  text: {
    fontSize: 25,
    fontWeight: '600',
    marginVertical: 20,
  },
})

export default RitualDoneScreen
