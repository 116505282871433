import React from 'react'
import { View } from 'react-native'
import { parseISO } from 'date-fns'

import ActivityIndicator from '../../../components/ActivityIndicator'
import Screen from '../../../components/Screen'
import Button from '../../../components/Button'
import AppText from '../../../components/Text'
import colors from '../../../config/colors'
import { defaultStyles } from '../../../config/styles'
import { useSession } from '../../../hooks/useSession'
import TitleGraphic from '../../../components/TitleGraphic'
import cioQuery from '../../../api/query/criticalImpactOpportunity'
import List from '../../../components/cio/List'
import ButtonIcon from '../../../components/ButtonIcon'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { AppStackParamList } from '../../../navigation/AppNavigator'
import { useNavigation } from '@react-navigation/native'

function ListScreen() {
  const navigation = useNavigation<DrawerNavigationProp<AppStackParamList>>()
  const { user } = useSession()
  const { isLoading, isSuccess, isError, data, refetch } =
    cioQuery.getCriticalImpactOpportunities(user?.id)

  const currentCIOs: object[] = []
  const draftCIOs: object[] = []
  const pastCIOs: object[] = []
  const now = new Date()

  if (isSuccess) {
    data.map((cio) => {
      switch (cio.statusString) {
        case 'Draft': {
          draftCIOs.push(cio)
          break
        }
        case 'Active': {
          if (parseISO(cio.date) > now) {
            pastCIOs.push(cio)
          } else {
            currentCIOs.push(cio)
          }
          break
        }
      }
    })
  }

  return (
    <Screen style={defaultStyles.screen}>
      <TitleGraphic
        section="Critical Impact Opportunities"
        title="Your CIOs"
        graphic={require('../../../assets/lead-cio.jpg')}
      />

      <View style={defaultStyles.mainContainer}>
        <ActivityIndicator visible={isLoading} />
        {isError && (
          <>
            <AppText>
              Couldn't retrieve the Critical Impact Opportunities.
            </AppText>
            <Button title="Retry" onPress={() => refetch()} />
          </>
        )}
        {isSuccess && (
          <>
            <List
              navigation={navigation}
              title="Your Current CIOs"
              data={currentCIOs}
              displayWhenEmpty={data.length === 0}
            />
            <List
              navigation={navigation}
              title="Your Draft CIOs"
              data={draftCIOs}
            />
            <List
              navigation={navigation}
              title="Your Past CIOs"
              data={pastCIOs}
            />
          </>
        )}

        <ButtonIcon
          icon="briefcase-variant"
          text="View archived CIOs"
          color={colors.primary}
          onPress={() =>
            navigation.navigate('CriticalImpactOpportunity_List_Archived')
          }
        />
      </View>
    </Screen>
  )
}

export default ListScreen
