import React, { useState } from 'react'

import { defaultStyles, defaultConfig } from '../../config/styles'
import Button from '../Button'
import ActivityIndicator from '../ActivityIndicator'
import userQuery from '../../api/query/user'
import { useSession } from '../../hooks/useSession'
import AppText from '../Text'
import ToggleButton from '../ToggleButton'
import Icon from '../Icon'
import { useMutation, useQueryClient } from 'react-query'

interface NudgeOption {
  label: string
  key: string
  icon: string
}

interface INudgeView {
  onClose?: null | Function
}

const NudgeView: React.FC<INudgeView> = ({ onClose }) => {
  const options: NudgeOption[] = [
    { key: 'email', label: 'email', icon: 'email' },
    { key: 'notification', label: 'in-app notification', icon: 'bell' },
  ]
  const { user } = useSession()
  const queryClient = useQueryClient()
  const { isSuccess, isError, isLoading, data, refetch } = userQuery.getUser(
    user.id
  )
  const nudgeMutation = useMutation(userQuery.updateProfile, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['user', { userId: data.id }])

      if (onClose) {
        onClose()
      }
    },
    onError: (error) => {
      alert('Failed to update your nudge preference')
      console.log(error)
    },
  })

  const [nudges, setNudges] = useState<string[]>(data?.nudge ?? [])

  return (
    <>
      <ActivityIndicator visible={isLoading} />
      {isError && (
        <>
          <AppText>Couldn't retrieve the nudgifications.</AppText>
          <Button title="Retry" onPress={refetch()} />
        </>
      )}
      {isSuccess && (
        <>
          <AppText style={[defaultStyles.text]}>
            How would you like to be nudged?
          </AppText>
          <AppText style={{ marginTop: 10, marginBottom: 20 }}>
            (tick all methods)
          </AppText>

          {options.map((option, index) => {
            return (
              <ToggleButton
                key={'nudge_' + option.key}
                initialEnabled={data.nudge.includes(option.key)}
                onChange={(enabled) => {
                  const buttonNudges = data.nudge
                  if (enabled) {
                    buttonNudges.push(option.key)
                  } else {
                    const index = buttonNudges.indexOf(option.key)

                    if (index !== -1) {
                      buttonNudges.splice(index, 1)
                    }
                  }

                  setNudges(buttonNudges)
                }}
              >
                <AppText>
                  <Icon
                    name={option.icon}
                    size={40}
                    iconColor={defaultConfig.colors.primary}
                  />
                  {option.label}
                </AppText>
              </ToggleButton>
            )
          })}
          <Button
            title={'Save'}
            onPress={() => {
              nudgeMutation.mutate({
                userId: user.id,
                profileDetails: {
                  nudge: nudges,
                },
              })
            }}
          />
        </>
      )}
    </>
  )
}

export default NudgeView
