import React from 'react'
import { Dimensions, StyleSheet, SafeAreaView, ScrollView } from 'react-native'

function Screen({ children, style }) {
  const screen = Dimensions.get('screen')

  return (
    <SafeAreaView
      style={[{ height: screen.height * 0.7 }, styles.screen, style]}
    >
      <ScrollView
        style={[styles.view, style]}
        contentContainerStyle={{
          flexGrow: 1,
        }}
      >
        {children}
      </ScrollView>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  screen: {
    // paddingTop: Constants.statusBarHeight,
    flex: 1,
  },
  view: {
    // automaticallyAdjustContentInsets: 1,
    // flex: 1,
    // maxHeight: Constants.screen.
  },
})

export default Screen
