import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { useDispatch } from 'react-redux'
import { useFocusEffect } from '@react-navigation/native'
// import 'react-grid-layout/css/styles.css'
// import 'react-resizable/css/styles.css'
import '../../../../config/css/react-draggable.css'

import AppText from '../../../../components/Text'
import { WelcomeWidget } from './Widgets/WelcomeWidget'
import { TodayWidget } from './Widgets/TodayWidget'
import AppButton from '../../../../components/Button'
import { DefaultLayout, Widgets } from './utils'
import { WidgetGrid } from './WidgetGrid'
import { addWidget, setWidgets } from '../../../../redux/slices/dashboardSlice'
import { useSession } from '../../../../hooks/useSession'

export const WidgetLayout: React.FC = () => {
  const { userFeatures } = useSession()
  const dispatch = useDispatch()
  const [editing, setEditing] = useState<boolean>(false)

  useFocusEffect(
    React.useCallback(() => {
      return () => {
        if (editing) {
          setEditing(false)
        }
      }
    }, [editing])
  )

  return (
    <>
      {!editing && (
        <>
          <div style={{ paddingTop: '1em', paddingBottom: '1em' }}>
            <WelcomeWidget onLoaded={() => {}} />
          </div>
          <div style={{ paddingTop: '1em' }}>
            <TodayWidget editing={false} onLoaded={() => {}} />
          </div>
        </>
      )}

      <View style={editing ? { paddingRight: 50 } : {}}>
        <WidgetGrid editing={editing} />
      </View>

      {editing && (
        <View
          style={{
            width: '50%',
            marginLeft: '25%',
            borderBottomWidth: 1,
            borderBottomColor: '#ccc',
            marginBottom: 15,
            paddingBottom: 20,
          }}
        >
          <AppText style={{ fontWeight: 'bold', marginTop: 20 }}>Add:</AppText>

          {Object.entries(Widgets)
            .filter(([key, object]) =>
              object.components.some((component) =>
                userFeatures?.includes(`FEATURE_${component}`)
              )
            )
            .map(([key, object]) => (
              <AppButton
                key={key}
                title={object.label}
                onPress={() =>
                  dispatch(
                    addWidget({
                      i: object.type + '-' + new Date().getTime(),
                      w: 2,
                      x: 0,
                      h: 0,
                      y: 0,
                    })
                  )
                }
              />
            ))}
        </View>
      )}

      <AppButton
        title={editing ? 'Finish editing layout' : 'Edit layout'}
        onPress={() => setEditing(!editing)}
        style={{ width: '50%', marginLeft: '25%' }}
      />
      {editing && (
        <AppButton
          backgroundColor="danger"
          borderColor="danger"
          title={'Reset layout'}
          onPress={() => {
            dispatch(setWidgets(DefaultLayout))
          }}
          style={{ width: '50%', marginLeft: '25%' }}
        />
      )}
    </>
  )
}
