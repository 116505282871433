import React, { useState } from 'react'
import { Image, StyleSheet, View } from 'react-native'
import { useMutation, useQueryClient } from 'react-query'
import * as Yup from 'yup'
import * as ImagePicker from 'expo-image-picker'

import { Form, FormField, SubmitButton } from '../forms'

import colors from '../../config/colors'
import ButtonIcon from '../ButtonIcon'
import AppText from '../Text'
import TextHighlight from '../TextHighlight'
import boomQuery from '../../api/query/boom'
import { cacheBustUrl } from '../../utility/url'
import { IBoom } from 'types/boom'
import SharingWith from './SharingWith'

const validationSchema = Yup.object().shape({
  outsideWork: Yup.string().notRequired().label('Outside work'),
  personalAchievement: Yup.string()
    .notRequired()
    .label('Personal achievements'),
  whatMatters: Yup.string().notRequired().label('What matters'),
  superStrength: Yup.string().notRequired().label("What's your super strength"),
  driveMad: Yup.string().notRequired().label('Drives you mad'),
  helpMe1: Yup.string().notRequired().label('help me1'),
  helpMe2: Yup.string().notRequired().label('help me2'),
})

interface IBoomShareForm {
  boom: IBoom
  navigation: any
}
function BoomShareForm({ boom, navigation }: IBoomShareForm) {
  const queryClient = useQueryClient()
  const formikRef = React.createRef()

  const [initialValues, setInitialValues] = useState<IBoom>(boom)

  console.log(boom, initialValues)
  const boomShareMutation = useMutation(boomQuery.shareBoom, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['boom', { boomId: data.id }])

      setInitialValues(data)

      navigation.navigate('BOOM', {
        screen: 'Boom',
        params: {},
      })
    },
    onError: (error) => {
      alert('Failed to share your Boom')
      console.log(error)
    },
  })

  const handleSubmit = async (newBoom) => {
    console.log('handleSubmit', boom)

    boomShareMutation.mutate({
      boomId: boom.id,
      sharedWith: newBoom.sharedWith,
    })
  }

  return (
    <Form
      innerRef={formikRef}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      initialValues={initialValues}
    >
      <View style={styles.table}>
        <View style={[styles.row]}>
          <SharingWith />
        </View>

        <SubmitButton title={'Save'} />
      </View>
    </Form>
  )
}

const styles = StyleSheet.create({
  heading: {
    fontWeight: '700',
    minWidth: 150,
    marginBottom: 10,
  },
  icon: {
    fontWeight: '700',
    width: 40,
    height: 40,
    lineHeight: 40,
    borderRadius: 40,
    backgroundColor: colors.primary,
    color: '#fff',
    textAlign: 'center',
    marginRight: 10,
  },
  field: {},
  title: {
    fontWeight: '700',
    height: 40,
    lineHeight: 40,
  },
  table: {},
  row: {
    paddingVertical: 20,
  },
  rowBorder: {
    borderBottomColor: colors.light,
    borderBottomWidth: StyleSheet.hairlineWidth,
    paddingVertical: 20,
  },
  horizontalAlign: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  upload: {
    marginTop: 10,
  },
})

export default BoomShareForm
