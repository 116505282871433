import React from 'react'
import { FormikConfig, FormikValues } from 'formik'

export type FormikStepProps = Pick<
  FormikConfig<FormikValues>,
  'children' | 'validationSchema'
>

export function FormikStep({ children }: FormikStepProps) {
  return <>{children}</>
}
