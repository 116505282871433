import React from 'react'
import { View, StyleSheet, Image } from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'

import Text from '../Text'
import colors from '../../config/colors'

interface PlainListItem {
  title: string
  subTitle?: string
  image?
  IconComponent: JSX.Element
  backgroundColor: string
  showChevron?: boolean
}
function PlainListItem({
  title,
  subTitle = null,
  image = null,
  IconComponent,
  backgroundColor = colors.white,
  showChevron = false,
}) {
  return (
    <View style={[styles.container, { backgroundColor: backgroundColor }]}>
      {IconComponent}
      {image && <Image style={styles.image} source={image} />}
      <View style={styles.detailsContainer}>
        <Text style={styles.title} numberOfLines={1}>
          {title}
        </Text>
        {subTitle && (
          <Text style={styles.subTitle} numberOfLines={2}>
            {subTitle}
          </Text>
        )}
      </View>
      {showChevron && (
        <MaterialCommunityIcons
          color={colors.medium}
          name="chevron-right"
          size={25}
        />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    padding: 15,
  },
  detailsContainer: {
    flex: 1,
    marginLeft: 10,
    justifyContent: 'center',
  },
  image: {
    width: 70,
    height: 70,
    borderRadius: 35,
  },
  subTitle: {
    color: colors.medium,
  },
  title: {
    fontWeight: '500',
  },
})

export default PlainListItem
