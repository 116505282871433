import React from 'react'
import { StyleSheet, Switch, View } from 'react-native'

import Title from '../../../components/Title'
import Screen from '../../../components/Screen'
import AppText from '../../../components/Text'
import { useSession } from '../../../hooks/useSession'
import { defaultStyles } from '../../../config/styles'
import reminderQuery from '../../../api/query/reminder'
import AppButton from '../../../components/Button'
import { useMutation, useQueryClient } from 'react-query'
import colors from '../../../config/colors'
import IsNotificationDisabled from '../../../components/IsNotificationDisabled'

function FormScreen() {
  const { user } = useSession()
  const queryClient = useQueryClient()
  const getReminderApi = reminderQuery.getReminders(user?.id)
  const reminderUpdateMutation = useMutation(reminderQuery.updateReminder, {
    onSuccess: (data) => {
      queryClient.invalidateQueries('reminders')
    },
    onError: (error) => {
      alert('Failed to update your nudge')
      console.log(error)
    },
  })

  return (
    <Screen style={defaultStyles.screen}>
      <View style={defaultStyles.mainContainer}>
        <Title style={{ fontSize: 25, color: 'black' }}>Nudges</Title>
        <AppText style={styles.heading}>
          What would you like to be reminded about?
        </AppText>
        {getReminderApi.isError && (
          <>
            <AppText>Couldn't retrieve the reminders.</AppText>
            <AppButton title="Retry" onPress={getReminderApi.refetch()} />
          </>
        )}
        {getReminderApi.isSuccess && (
          <View style={styles.reminders}>
            {getReminderApi.data.map((reminder, index) => {
              return (
                <View style={styles.row} key={'reminder_row_' + index}>
                  <AppText style={styles.label}>
                    {reminder.reminder.name}
                  </AppText>
                  <Switch
                    style={styles.switch}
                    trackColor={{ false: '#767577', true: '#81b0ff' }}
                    thumbColor={reminder.enabled ? '#f5dd4b' : '#f4f3f4'}
                    ios_backgroundColor="#3e3e3e"
                    onValueChange={(enabled) => {
                      reminderUpdateMutation.mutate({
                        id: reminder.id,
                        enabled: enabled,
                      })
                    }}
                    value={reminder.enabled}
                  />
                </View>
              )
            })}
            <IsNotificationDisabled allowNavigation={true} />
          </View>
        )}
      </View>
    </Screen>
  )
}

const styles = StyleSheet.create({
  heading: {
    fontWeight: '600',
    minWidth: 150,
    marginBottom: 20,
  },
  label: {
    alignContent: 'space-between',
  },
  switch: {
    position: 'absolute',
    top: '50%',
    marginTop: -10,
    right: 20,
  },
  reminders: {
    flex: 1,
    flexWrap: 'wrap',
  },
  row: {
    borderRadius: 10,
    backgroundColor: colors.light,
    marginBottom: 20,
    padding: 20,
    paddingRight: 70,
  },
  footer: {
    paddingTop: 50,
  },
})

export default FormScreen
