import React from 'react'
import { StyleSheet, View } from 'react-native'
import { useFocusEffect } from '@react-navigation/native'
import * as Yup from 'yup'
import { FieldArray } from 'formik'

import {
  ErrorMessage,
  Form,
  FormField,
  PickerEmail,
  SubmitButton,
} from '../../../../components/forms'
import FormContactPicker from '../../../../components/forms/FormContactPicker'

import { defaultStyles } from '../../../../config/styles'
import Screen from '../../../../components/Screen'
import AppText from '../../../../components/Text'
import { useSession } from '../../../../hooks/useSession'
import colors from '../../../../config/colors'
import AppFormDatePicker from '../../../../components/forms/FormDatePicker'
import meetingApi from '../../../../api/meeting'
import TitleGraphic from '../../../../components/TitleGraphic'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { AppStackParamList } from '../../../../navigation/AppNavigator'
import { useNavigation } from '@react-navigation/native'

const days: Array<object> = []

// number of days for drop down
for (let index = 0; index < 32; index++) {
  days.push({ value: index })
}

const INVITE_TYPE_CONTACT = 'CONTACT'
const INVITE_TYPE_EMAIL = 'EMAIL'
const INVITE_TYPE_TEAM = 'TEAM'

export default function FormScreen({ route }) {
  const navigation = useNavigation<DrawerNavigationProp<AppStackParamList>>()
  const formikRef = React.createRef()
  const { user } = useSession()

  const meeting = {}
  const initialValues = defaultMeeting(meeting)

  useFocusEffect(
    React.useCallback(() => {
      if (formikRef.current) {
        console.log('form reset')
        formikRef.current?.resetForm()
        formikRef.current?.setValues(initialValues)
      }
    }, [route.params])
  )

  console.log('meeting', meeting)

  const handleSubmit = async (meeting, { resetForm }) => {
    const result = await meetingApi.addMeeting(user?.id, meeting)

    if (!result.ok) {
      return alert('Errors Occured.  Could not save the meeting')
    }

    resetForm()

    navigation.navigate('RAPID_REVIEW', {
      screen: 'Meeting_Done',
      params: {
        meeting: result.data,
      },
    })
  }

  return (
    <Screen style={defaultStyles.screen}>
      <TitleGraphic
        section="Rapid Reviews"
        title="Create a Rapid Review"
        graphic={require('../../../../assets/lead-rapid-review.jpg')}
      />
      <View style={defaultStyles.mainContainer}>
        <Form
          innerRef={formikRef}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={Yup.object({
            name: Yup.string().required().min(1).label('Meeting name'),
            date: Yup.date().required().label('Date'),
            intent: Yup.string().required().min(1).label('Intent'),
            invitees: Yup.array()
              .of(
                Yup.object().shape({
                  type: Yup.string().required(),
                  value: Yup.string()
                    .required()
                    .when('type', {
                      is: INVITE_TYPE_EMAIL,
                      then: Yup.string().email(),
                      otherwise: Yup.string()
                        .matches(
                          /^[0-9a-f]{8}-[0-9a-f]{4}-[0-6][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i,
                          {
                            name: 'uuid',
                            excludeEmptyString: false,
                          }
                        )
                        .label('Selected contact'),
                    }),
                  label: Yup.string()
                    .required()
                    .when('type', {
                      is: INVITE_TYPE_EMAIL,
                      then: Yup.string().required().label('Email address'),
                      otherwise: Yup.string().required().label('Contact'),
                    }),
                })
              )
              .label('Invitee'),
          })}
        >
          <AppText style={defaultStyles.questionWithoutPadding}>
            Rapid Review of
          </AppText>
          <FormField multiline name="name" numberOfLines={1} placeholder="" />

          <AppText
            style={[defaultStyles.questionWithoutPadding, { marginTop: 20 }]}
          >
            Date
          </AppText>
          <AppFormDatePicker
            name="date"
            icon="calendar-month"
            dateFormat="do MMMM yyyy"
            placeholder="Date picker"
          />

          <AppText style={defaultStyles.questionWithoutPadding}>Intent</AppText>
          <FormField
            multiline
            name="intent"
            numberOfLines={3}
            placeholder="What was the intended outcome?"
          />

          <AppText style={defaultStyles.questionWithoutPadding}>
            Invitees
          </AppText>
          <AppText style={{ fontSize: 16, marginTop: 10 }}>
            If the participants are already on the app, their name will be on
            the dropdown. If not, add their email below and they will receive an
            email invite.
          </AppText>
          <FieldArray
            name="invitees"
            render={(arrayHelpers) => {
              return (
                <div>
                  {arrayHelpers.form.values.invitees.map((contact, index) => {
                    const invitees = arrayHelpers.form.values.invitees
                    return (
                      <div key={index}>
                        {contact['type'] === INVITE_TYPE_CONTACT ||
                        contact['type'] === INVITE_TYPE_TEAM ? (
                          <FormContactPicker
                            name={`invitees.${index}`}
                            placeholder="Who are you inviting?"
                            group={true}
                            width="100%"
                            showAdd={false}
                            teams={true}
                            onChange={({ id, name, type }) => {
                              invitees[index] = {
                                type:
                                  type === 'team'
                                    ? INVITE_TYPE_TEAM
                                    : INVITE_TYPE_CONTACT,
                                label: name,
                                value: id,
                              }
                              console.log(invitees)
                              arrayHelpers.form.setFieldValue(
                                'invitees',
                                invitees
                              )

                              if (invitees[index] !== '') {
                                arrayHelpers.form.setFieldTouched('invitees')
                              }
                            }}
                          />
                        ) : (
                          <PickerEmail name={`invitees.${index}`} />
                        )}
                        <button
                          type="button"
                          onClick={() => {
                            arrayHelpers.remove(index)
                            arrayHelpers.form.setFieldTouched('invitees')
                          }}
                          style={{
                            borderWidth: 0,
                            fontWeight: 600,
                            position: 'absolute',
                            marginTop: -45,
                            right: 15,
                            backgroundColor: '#ddd',
                            color: '#777',
                            cursor: 'pointer',
                            borderRadius: 10,
                            width: 20,
                            height: 20,
                            fontSize: 16,
                          }}
                        >
                          -
                        </button>
                      </div>
                    )
                  })}

                  <ErrorMessage
                    error={arrayHelpers.form.errors['invitees']}
                    visible={arrayHelpers.form.touched['invitees']}
                    requireSubmission={true}
                  />

                  <View
                    style={[styles.horizontalAlign, { alignItems: 'center' }]}
                  >
                    <button
                      type="button"
                      onClick={() => {
                        arrayHelpers.push({
                          type: INVITE_TYPE_CONTACT,
                          value: '',
                          label: '',
                        })
                        arrayHelpers.form.setFieldTouched('invitees')
                      }}
                      style={{
                        backgroundColor: '#ffffff',
                        borderWidth: 0,
                        cursor: 'pointer',
                        fontFamily: 'Open Sans',
                        fontSize: 16,
                        fontWeight: 600,
                        color: '#3EACBE',
                        marginTop: 20,
                        width: 'auto',
                      }}
                    >
                      + Add contact
                    </button>
                  </View>
                  <View
                    style={[styles.horizontalAlign, { alignItems: 'center' }]}
                  >
                    <button
                      type="button"
                      onClick={() => {
                        arrayHelpers.push({
                          type: INVITE_TYPE_EMAIL,
                          value: '',
                          label: '',
                        })
                        arrayHelpers.form.setFieldTouched('invitees')
                      }}
                      style={{
                        backgroundColor: '#ffffff',
                        borderWidth: 0,
                        cursor: 'pointer',
                        fontFamily: 'Open Sans',
                        fontSize: 16,
                        fontWeight: 600,
                        color: '#3EACBE',
                        marginTop: 20,
                        width: 'auto',
                      }}
                    >
                      + Add email
                    </button>
                  </View>
                </div>
              )
            }}
          />

          <SubmitButton title="Generate Rapid Review Code" />
        </Form>
      </View>
    </Screen>
  )
}

function defaultMeeting(meeting) {
  return {
    name: meeting.name ?? '',
    date: meeting.date ?? new Date(),
    intent: meeting.intent ?? '',
    invitees: meeting.invitees ?? [],
  }
}

const styles = StyleSheet.create({
  block: {
    backgroundColor: colors.light,
    width: '100%',
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 10,
    marginTop: 20,
  },
  blockTitle: {
    fontSize: 25,
    fontWeight: '600',
  },
  line: {
    borderColor: colors.medium,
    borderWidth: 1,
    width: '100%',
    marginVertical: 10,
  },
  horizontalAlign: {
    flexDirection: 'row',
    alignItems: 'center',
  },
})
