import React from 'react'
import { FieldArray } from 'formik'
import { StyleSheet, View } from 'react-native'

import AppText from '../Text'
import Button from '../Button'

interface IUserShareList {
  field: string
  title: string
  noItemMessage: string
}

function UserShareList({ field, title, noItemMessage }: IUserShareList) {
  return (
    <View>
      <AppText style={styles.bold}>{title}</AppText>
      <FieldArray
        name={field}
        render={(arrayHelpers) => {
          console.log(arrayHelpers.form.values)
          return (
            <div>
              {arrayHelpers.form.values[field].map((contributor, index) => {
                console.log(contributor)
                return (
                  <div key={index}>
                    <hr />
                    <View>
                      <View style={styles.bold}>
                        <AppText>
                          {contributor['team'] ? 'Team: ' : ''}
                          {contributor['name'] ?? 'Unknown'}
                        </AppText>
                      </View>
                      {contributor['roleString'] && (
                        <View>
                          <AppText>Role: {contributor['roleString']}</AppText>
                        </View>
                      )}
                    </View>
                    <Button
                      onPress={() => {
                        arrayHelpers.remove(index)
                        arrayHelpers.form.setFieldTouched(field)
                      }}
                      style={{
                        borderWidth: 0,
                        fontWeight: '600',
                        position: 'absolute',
                        marginTop: -40,
                        right: 15,
                        backgroundColor: '#3eacbe',
                        color: '#fff',
                        borderRadius: 100,
                        width: 20,
                        height: 20,
                        fontSize: 16,
                        paddingTop: 13,
                        paddingBottom: 17,
                      }}
                      title="-"
                    />
                  </div>
                )
              })}
              {arrayHelpers.form.values[field].length === 0 && (
                <AppText>{noItemMessage}</AppText>
              )}
            </div>
          )
        }}
      />
    </View>
  )
}

export default UserShareList

const styles = StyleSheet.create({
  bold: {
    fontWeight: '600',
  },
})
