import { useQuery } from 'react-query'

import { get } from '../../utility/api'

const getCriticalImpactOpportunities = () => {
  return useQuery('cios_shared', ({ signal }) =>
    get('/critical_impact_opportunities/shared?order[updated]=desc', {
      signal,
    })
  )
}

const getRapidReviews = () => {
  return useQuery('rr_shared', ({ signal }) =>
    get('/rapid_reviews/shared?order[created]=desc', {
      signal,
    })
  )
}

const getBooms = () => {
  return useQuery('boom_shared', ({ signal }) =>
    get('/boom/shared?order[created]=desc', {
      signal,
    })
  )
}

export default {
  getCriticalImpactOpportunities,
  getRapidReviews,
  getBooms,
}
