import React from 'react'
import { View } from 'react-native'
import criticalImpactOpportunityQuery from '../../../api/query/criticalImpactOpportunity'
import Screen from '../../../components/Screen'
import AppText from '../../../components/Text'
import { defaultStyles } from '../../../config/styles'
import ActivityIndicator from '../../../components/ActivityIndicator'
import AppButton from '../../../components/Button'
import { ViewCio } from './components/ViewCio'

function ViewScreen({ route }) {
  const cioId: string = route.params?.criticalImpactOpportunityId
  const cioQuery =
    criticalImpactOpportunityQuery.getCriticalImpactOpportunityView(cioId)

  const { isLoading, isSuccess, isError, data, refetch } = cioQuery

  if (isSuccess) {
    return (
      <Screen style={{}}>
        <ViewCio cio={data} />
      </Screen>
    )
  }

  return (
    <Screen style={defaultStyles.screen}>
      <View style={defaultStyles.mainContainer}>
        {isLoading && <ActivityIndicator visible={isLoading} />}
        {(isError || !cioId) && (
          <>
            <AppText>Couldn't retrieve the CIO.</AppText>
            <AppButton title="Retry" onPress={() => refetch()} />
          </>
        )}
      </View>
    </Screen>
  )
}

export default ViewScreen
