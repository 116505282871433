import { StyleSheet, View } from 'react-native'
import Moment from 'moment'

import AppText from '../../../../../components/Text'
import colors from '../../../../../config/colors'
import { IRapidReview } from 'types/rapidReview'

interface IDisplayRapidReview {
  rapidReview: IRapidReview
}

export default function DisplayRapidReview({
  rapidReview,
}: IDisplayRapidReview) {
  return (
    <View style={styles.table}>
      <View style={[styles.rowBorder]}>
        <AppText style={styles.heading}>Meeting: </AppText>
        <AppText>{rapidReview.meetingName}</AppText>
      </View>
      <View style={[styles.rowBorder]}>
        <AppText style={styles.heading}>Meeting Date: </AppText>
        <AppText>{Moment(rapidReview.date).format('ddd, DD MMM YYYY')}</AppText>
      </View>
      <View style={[styles.rowBorder]}>
        <AppText style={styles.heading}>Meeting Usefulness: </AppText>
        <AppText>{rapidReview.rating} out of 10</AppText>
      </View>
      {!!rapidReview.meetingAttendees && (
        <View style={styles.rowBorder}>
          <AppText style={styles.heading}>Attendees: </AppText>
          <AppText>{rapidReview.meetingAttendees}</AppText>
        </View>
      )}
      <View style={styles.rowBorder}>
        <AppText style={styles.heading}>Intent: </AppText>
        <AppText>{rapidReview.meetingIntent}</AppText>
      </View>
      <View style={styles.rowBorder}>
        <AppText style={styles.heading}>Useful: </AppText>
        <AppText>{rapidReview.positives}</AppText>
      </View>
      <View style={styles.rowBorder}>
        <AppText style={styles.heading}>More Useful: </AppText>
        {rapidReview.suggestions.map((suggestion, index) => {
          return <AppText key={index}>{suggestion}</AppText>
        })}
      </View>
      {!!rapidReview.improvement && (
        <View style={styles.rowBorder}>
          <AppText style={styles.heading}>Improvement: </AppText>
          <AppText>{rapidReview.improvement}</AppText>
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  heading: {
    fontWeight: '700',
    minWidth: 150,
    marginBottom: 10,
  },
  table: {},
  row: {
    flexDirection: 'row',
  },
  rowBorder: {
    borderBottomColor: colors.light,
    borderBottomWidth: StyleSheet.hairlineWidth,
    paddingVertical: 20,
  },
})
