import React from 'react'
import { StyleSheet, View } from 'react-native'
import AppText from '../../../components/Text'

import { defaultConfig } from '../../../config/styles'

function BossLabel({ letter, circleColour, label, style = {} }) {
  return (
    <View style={[styles.layout, styles.horizontalAlign, style]}>
      <AppText style={[styles.icon, { backgroundColor: circleColour }]}>
        {letter}
      </AppText>
      <AppText style={styles.bold}>{label}</AppText>
    </View>
  )
}

export default BossLabel

const styles = StyleSheet.create({
  bold: {
    fontWeight: '600',
  },
  horizontalAlign: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    fontWeight: '700',
    width: 40,
    height: 40,
    lineHeight: 40,
    borderRadius: 40,
    backgroundColor: defaultConfig.colors.primary,
    color: '#fff',
    textAlign: 'center',
    marginRight: 10,
  },
  layout: {
    marginTop: 10,
    paddingTop: 10,
  },
})
