import React from 'react'
import { StyleSheet, View } from 'react-native'

import { defaultConfig } from '../../config/styles'
import AppText from '../Text'
import TrophyImage from './TrophyImage'

function TrophyShelf({ trophy }) {
  return (
    <View style={styles.shelf}>
      <TrophyImage trophy={trophy.trophy} />
      <View style={styles.line} />
      <AppText style={styles.text}>{trophy.trophyName}</AppText>
    </View>
  )
}

export default TrophyShelf

const styles = StyleSheet.create({
  shelf: {
    alignItems: 'center',
    marginTop: 20,
    height: 200,
    width: 200,
  },
  line: {
    width: '100%',
    height: StyleSheet.hairlineWidth * 8,
    backgroundColor: defaultConfig.colors.light,
  },
  text: {
    marginTop: 10,
    textAlign: 'center',
  },
})
