import React, { useState } from 'react'
import { View, StyleSheet, TouchableWithoutFeedback } from 'react-native'

import Text from './Text'
import colors from '../config/colors'
import ButtonIcon from './ButtonIcon'
import useApi from '../hooks/useApi'
import nudgificationsApi from '../api/nudgifications'
import { format } from 'date-fns'
import { useNavigation } from '@react-navigation/native'
import { AppStackParamList } from '../navigation/AppNavigator'
import { DrawerNavigationProp } from '@react-navigation/drawer'

interface INudgification {
  title: string
  subTitle: string
  nudgeDate: Date
  read: boolean
  id: string
  userId: string
  component: string
  componentId: string
}

function Nudgification({
  title,
  subTitle,
  nudgeDate,
  read,
  id,
  userId,
  component,
  componentId,
}: INudgification) {
  const navigation = useNavigation<DrawerNavigationProp<AppStackParamList>>()
  const [nudgeVisible, setNudgeVisible] = useState(true)
  const deleteNudgificationsApi = useApi(nudgificationsApi.deleteNudgification)

  return nudgeVisible ? (
    <TouchableWithoutFeedback
      onPress={() => {
        switch (component) {
          case 'critical_impact_opportunity':
            navigation.navigate('CRITICAL_IMPACT_OPPORTUNITY', {
              screen: 'CriticalImpactOpportunity_Form',
              params: {
                criticalImpactOpportunityId: componentId,
              },
            })

            break

          default:
            alert('Unknown notification type: ' + component)
        }
      }}
    >
      <View style={styles.card}>
        <View style={styles.detailsContainer}>
          <View
            style={{
              position: 'absolute',
              top: -20,
              right: -10,
            }}
          >
            <ButtonIcon
              icon="close-circle"
              color={colors.primary}
              backgroundColor={colors.light}
              size={24}
              onPress={() => {
                deleteNudgificationsApi.request(id).then(() => {
                  setNudgeVisible(false)
                })
              }}
            />
          </View>

          <Text style={styles.title} numberOfLines={1}>
            {title}
          </Text>
          <Text style={styles.subTitle} numberOfLines={2}>
            {subTitle}
          </Text>
          <Text style={styles.subTitle} numberOfLines={2}>
            {format(nudgeDate, 'do MMMM yyyy')}
          </Text>
        </View>
      </View>
    </TouchableWithoutFeedback>
  ) : null
}

const styles = StyleSheet.create({
  card: {
    borderRadius: 10,
    backgroundColor: colors.light,
    marginBottom: 10,
    overflow: 'hidden',
  },
  detailsContainer: {
    padding: 20,
  },
  image: {
    width: '100%',
    height: 200,
  },
  subTitle: {
    marginBottom: 7,
  },
  title: {
    marginBottom: 7,
    color: colors.secondary,
    fontWeight: 'bold',
    flex: 1,
  },
})

export default Nudgification
