import React from 'react'
import TickButton from './TickButton'

function RadioButton({
  title,
  enabled = false,
  onChange = null,
  children = undefined,
  style = {},
}) {
  return (
    <TickButton
      title={title}
      enabled={enabled}
      showTick={false}
      onPress={onChange}
      style={style}
    >
      {children}
    </TickButton>
  )
}

export default RadioButton
