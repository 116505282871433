import React from 'react'
import { StyleSheet, View } from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'

import { ShedRequireActionType } from '../../api/query/shed'
import { defaultConfig } from '../../config/styles'
import AppText from '../Text'
import ButtonIcon from '../ButtonIcon'
import { AppStackParamList } from '../../navigation/AppNavigator'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { useNavigation } from '@react-navigation/native'
import { joinArrayWithCommasAndAnd } from '../../utility/array'

export interface Props {
  action: ShedRequireActionType
}

const HighProgress: React.FC<Props> = ({ action }) => {
  const navigation = useNavigation<DrawerNavigationProp<AppStackParamList>>()
  const sections = joinArrayWithCommasAndAnd(action.sections)

  return (
    <>
      <View style={styles.row}>
        <MaterialCommunityIcons
          name={'star'}
          color={defaultConfig.colors.primary}
          size={30}
        />
        <AppText style={styles.text}>
          Congratulations! Your SHED for <strong>{sections}</strong> has been
          great the past {action.length} days. Why don't you create a trophy to
          document it.
        </AppText>
      </View>
      <ButtonIcon
        icon="trophy"
        backgroundColor={defaultConfig.colors.primary}
        color={defaultConfig.colors.white}
        text="Add new trophy"
        onPress={() =>
          navigation.navigate('TROPHY_CABINET', {
            screen: 'Trophy_Form',
            params: {
              trophyId: null,
              shedAction: true,
            },
          })
        }
      />
    </>
  )
}

export default HighProgress

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20,
    paddingTop: 20,
    paddingBottom: 5,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  icon: {
    marginTop: 0,
  },
  text: {
    marginLeft: 10,
  },
})
