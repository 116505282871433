import React from 'react'
import Title from '../Title'

interface ICioTitle {
  title: string
}

function CioTitle({ title }: ICioTitle) {
  return <Title style={{ fontSize: 25, color: 'black' }}>{title}</Title>
}

export default CioTitle
