import React from 'react'
import { StyleSheet } from 'react-native'
import { useFormikContext } from 'formik'

import Text from '../Text'

function ErrorMessage({
  error,
  visible,
  style = {},
  requireSubmission = false,
}) {
  const formik = useFormikContext()

  if (requireSubmission && formik && formik.submitCount === 0) {
    return null
  }

  if (Array.isArray(visible)) {
    visible = isUltimatelyAnError(visible)
  }

  if (Array.isArray(error)) {
    error = error.filter((value, index, self) => self.indexOf(value) === index)

    if (Array.isArray(error) && error.length && error[0]) {
      error = error[0]
    }
  }

  if (!visible || !error) return null

  if (typeof error === 'object') {
    error = error.label || error.value
  }

  return <Text style={[styles.error, style]}>{error}</Text>
}

const styles = StyleSheet.create({
  error: { color: 'red', display: 'block' },
})

function isUltimatelyAnError(values): boolean {
  values = values.map((value) => {
    return typeof value === 'object' ? Object.values(value) : value
  })

  return values.flat(2).find((element) => element === true) !== undefined
}

export default ErrorMessage
