import React, { useEffect, useRef, useState } from 'react'
import { View } from 'react-native'
import { Button } from '@mui/material'
import { RouteProp, useNavigation } from '@react-navigation/native'
import * as Yup from 'yup'
import { useMutation, useQueryClient } from 'react-query'

import { defaultStyles } from '../../../config/styles'
import AppText from '../../../components/Text'
import Title from '../../../components/Title'
import InfoButton from '../../../components/InfoButton'
import Screen from '../../../components/Screen'
import Panel from '../../../components/shed/Panel'
import { FormDatePicker, SubmitButton } from '../../../components/forms'
import shedQuery, { ShedRatingType } from '../../../api/query/shed'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { AppStackParamList } from '../../../navigation/AppNavigator'
import { DashboardParamList } from '../../../navigation/DashboardParamList'
import AppButton from '../../../components/Button'
import { Formik, FormikProps } from 'formik'

interface IRateYourShedScreen {
  onClose?: () => void
  route: RouteProp<DashboardParamList, 'ShedRatingScreen'>
}

const validationRatings = Yup.object().shape({
  sleep: Yup.number()
    .required('reuiqies')
    .oneOf([0, 1, 2, 3, 4, 5], 'Choose a valid rating for your sleep1')
    .label('Rate your sleep')
    .typeError('Choose a rating for your sleep2'),
  hydration: Yup.number()
    .required()
    .oneOf([0, 1, 2, 3, 4, 5], 'Choose a valid rating for your hydration')
    .label('Rate your hydration')
    .typeError('Choose a rating for your hydration'),
  exercise: Yup.number()
    .required()
    .oneOf([0, 1, 2, 3, 4, 5], 'Choose a valid rating for your exercise')
    .label('Rate your exercise')
    .typeError('Choose a rating for your exercise'),
  diet: Yup.number()
    .required()
    .oneOf([0, 1, 2, 3, 4, 5], 'Choose a valid rating for your diet')
    .label('Rate your diet')
    .typeError('Choose a rating for your diet'),
})

const RateYourShedScreen: React.FC<IRateYourShedScreen> = ({
  onClose,
  route,
}) => {
  const previous: boolean =
    typeof route.params.previous === 'string'
      ? route.params.previous === 'true'
      : route.params.previous
  const [dateQuery, setDateQuery] = useState<string | undefined>()
  const [showDatePicker, setShowDatePicker] = useState<boolean>(previous)
  const queryClient = useQueryClient()
  const formikRef = useRef<FormikProps<ShedRatingType>>()
  const today = new Date()
  const minDate = daysAgo(3)
  const formDate = formikRef.current?.values.date

  useEffect(() => {
    if (formDate !== dateQuery) {
      setDateQuery(formDate)
    }
  }, [today, formDate])

  useEffect(() => {
    if (showDatePicker !== previous) {
      setShowDatePicker(previous)
    }
  }, [previous])

  const navigation = useNavigation<DrawerNavigationProp<AppStackParamList>>()

  const mutation = useMutation(shedQuery.UpdateShedRating, {
    onSuccess: (data: ShedRatingType) => {
      formikRef.current?.resetForm()
      queryClient.invalidateQueries('shedRatings')
      queryClient.invalidateQueries(['shedRating', { shedDate: data.date }])

      if (onClose) {
        return onClose()
      }

      navigation.navigate('DASHBOARD', {
        screen: 'Dashboard_Screen',
      })
    },
    onError: (error) => {
      alert('Could not save your shed')
      console.log(error)
      queryClient.invalidateQueries('shedRating')
    },
  })

  const initialValues: ShedRatingType = {
    date: today.toISOString().split('T')[0],
    sleep: 0,
    hydration: 0,
    exercise: 0,
    diet: 0,
  }

  const handleSubmit = ({ date, sleep, hydration, exercise, diet }) => {
    mutation.mutate({
      date: date,
      sleep: sleep,
      hydration: hydration,
      exercise: exercise,
      diet: diet,
    })
  }

  return (
    <Screen style={defaultStyles.screen}>
      <Title style={{ fontSize: 25, color: 'black' }}>
        How is your SHED{showDatePicker === false && ' today'}?
      </Title>

      <View style={defaultStyles.mainContainer}>
        <View
          style={{
            marginBottom: 20,
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <View>
            <AppText style={{ marginBottom: 20 }}>
              Rate each SHED element from low 0 to high 5.
            </AppText>
            <InfoButton
              text="Learn more about SHED"
              onPress={() => {
                navigation.navigate('RESOURCE', {
                  screen: 'Resource_View',
                  params: {
                    slug: 'looking-after-your-shed',
                  },
                })
              }}
            />
          </View>

          {!showDatePicker && (
            <AppButton
              title="Choose a Past Date"
              style={{
                width: '40%',
                minWidth: 120,
              }}
              onPress={() => {
                setShowDatePicker(true)
              }}
            />
          )}
        </View>

        <Formik
          initialValues={initialValues}
          innerRef={formikRef}
          onSubmit={handleSubmit}
          validationSchema={validationRatings}
          enableReinitialize={true}
        >
          <>
            {showDatePicker && (
              <>
                <AppText
                  style={[
                    defaultStyles.questionWithoutPadding,
                    { marginTop: 20 },
                  ]}
                >
                  Date:
                </AppText>
                <FormDatePicker
                  name="date"
                  icon="calendar-month"
                  dateFormat="do MMMM yyyy"
                  enableTime={false}
                  minDate={minDate}
                  maxDate={new Date()}
                  placeholder="Date"
                />
              </>
            )}
            <Panel section="Sleep" onChange={() => {}} />
            <Panel section="Hydration" onChange={() => {}} />
            <Panel section="Exercise" onChange={() => {}} />
            <Panel section="Diet" onChange={() => {}} />

            <SubmitButton title="Save your ratings" />
            {onClose && (
              <Button
                variant="contained"
                color="primary"
                style={defaultStyles.buttonBorderless}
                onClick={onClose}
              >
                Skip
              </Button>
            )}
          </>
        </Formik>
      </View>
    </Screen>
  )
}

const daysAgo = (days) => {
  const date = new Date()
  date.setDate(date.getDate() - days)

  return date
}

export default RateYourShedScreen
