import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'

import header from '../components/part/Header'
import ListScreen from '../screens/app/Resource/ListScreen'
import ViewScreen from '../screens/app/Resource/ViewScreen'

const Stack = createStackNavigator()

const ResourceNavigator = () => (
  <Stack.Navigator initialRouteName="Resource_List">
    <Stack.Screen
      name="Resource_List"
      component={ListScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showHamburger: true,
            showBackButton: false,
            showNudgifications: true,
          }),
        }
      }}
    />
    <Stack.Screen
      name="Resource_View"
      component={ViewScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showHamburger: true,
            showBackButton: false,
            showNudgifications: false,
          }),
        }
      }}
    />
  </Stack.Navigator>
)

export default ResourceNavigator
