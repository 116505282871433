import React from 'react'
import { useState } from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import { useFormikContext } from 'formik'
import { RadioButton } from 'react-native-paper'

import AppText from '../Text'
import ErrorMessage from './ErrorMessage'
import { defaultConfig } from '../../config/styles'
import { IRapidReview } from 'types/rapidReview'

interface FormSuggestionChoice {
  name: string
  width?: string
  defaultValue?: string
  field?: string
  suggestions?: IRapidReview[]
}

function FormSuggestionChoice({
  name,
  width = '100%',
  defaultValue = '',
  field = 'suggestions',
  suggestions = [],
  ...otherProps
}: FormSuggestionChoice) {
  const [value, setValue] = useState(defaultValue)
  const { setFieldValue, errors, touched, values } = useFormikContext()

  if (values[field]) {
    suggestions = values[field]
  }

  return (
    <>
      <RadioButton.Group
        // onValueChange={(value) => {
        //   setFieldValue(name, value);
        //   setValue(value);
        // }}
        value={value}
      >
        {suggestions.map((suggestion, index) => {
          return (
            <TouchableOpacity
              onPress={() => {
                setValue(suggestion)
                setFieldValue(name, suggestion)
              }}
              key={'suggestion_' + index}
            >
              <View style={styles.card}>
                <RadioButton
                  value={suggestion}
                  onPress={() => {
                    setValue(suggestion)
                    setFieldValue(name, suggestion)
                  }}
                />
                <AppText style={styles.text}>{suggestion}</AppText>
              </View>
            </TouchableOpacity>
          )
        })}
      </RadioButton.Group>

      <ErrorMessage error={errors[name]} visible={touched[name]} />
    </>
  )
}

const styles = StyleSheet.create({
  card: {
    alignContent: 'center',
    borderRadius: 10,
    backgroundColor: defaultConfig.colors.light,
    marginTop: 10,
    overflow: 'hidden',
    flexDirection: 'row',
    padding: 10,
  },
  text: {
    fontSize: 16,
    paddingTop: 6,
  },
})

export default FormSuggestionChoice
