import React from 'react'
import { Image, StyleSheet, View } from 'react-native'

import { defaultStyles } from '../../../../config/styles'
import AppText from '../../../../components/Text'
import { IBoom } from '../../../../@types/boom'
import TextHighlight from '../../../../components/TextHighlight'
import colors from '../../../../config/colors'
import { cacheBustUrl } from '../../../../utility/url'

interface IViewBoom {
  boom: IBoom
}

export function ViewBoom({ boom }: IViewBoom) {
  return (
    <View style={styles.table}>
      <View style={[styles.row]}>
        <AppText style={styles.heading}>
          <TextHighlight>ONE</TextHighlight> thing I love doing outside of work
          and why:
        </AppText>
        <AppText>{boom.outsideWork}</AppText>
      </View>
      <View style={[styles.row]}>
        <AppText style={styles.heading}>
          <TextHighlight>ONE</TextHighlight> personal achievement:
        </AppText>
        <AppText>{boom.personalAchievement}</AppText>

        {boom.fullImagePath && (
          <View style={[styles.row, defaultStyles.lightBox]}>
            <AppText style={[styles.title]}>Personal achievement</AppText>
            <Image
              source={{
                uri: cacheBustUrl(boom.fullImagePath),
                headers: {
                  Pragma: 'no-cache',
                },
              }}
              style={{
                width: 200,
                height: 200,
                maxWidth: 200,
                maxHeight: 200,
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            />
          </View>
        )}
      </View>
      <View style={[styles.row]}>
        <AppText style={styles.heading}>
          <TextHighlight>ONE</TextHighlight> thing that really matters to me:
        </AppText>
        <AppText>{boom.whatMatters}</AppText>
      </View>
      <View style={[styles.row]}>
        <AppText style={styles.heading}>
          <TextHighlight>ONE</TextHighlight> super-strength of mine:
        </AppText>
        <AppText>{boom.superStrength}</AppText>
      </View>
      <View style={[styles.row]}>
        <AppText style={styles.heading}>
          <TextHighlight>ONE</TextHighlight> thing drives me mad:
        </AppText>
        <AppText>{boom.driveMad}</AppText>
      </View>
      <View style={[styles.row]}>
        <AppText style={styles.heading}>
          <TextHighlight>TWO</TextHighlight> ways that the team could help me do
          my best work:
        </AppText>
        <AppText>{boom.helpMe1}</AppText>
        <AppText>{boom.helpMe2}</AppText>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  heading: {
    fontWeight: '700',
    minWidth: 150,
    marginBottom: 10,
  },
  icon: {
    fontWeight: '700',
    width: 40,
    height: 40,
    lineHeight: 40,
    borderRadius: 40,
    backgroundColor: colors.primary,
    color: '#fff',
    textAlign: 'center',
    marginRight: 10,
  },
  field: {},
  title: {
    fontWeight: '700',
    height: 40,
    lineHeight: 40,
  },
  table: {},
  row: {
    paddingVertical: 20,
  },
  rowBorder: {
    borderBottomColor: colors.light,
    borderBottomWidth: StyleSheet.hairlineWidth,
    paddingVertical: 20,
  },
  horizontalAlign: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  upload: {
    marginTop: 10,
  },
})
