import React, { Dispatch, ReactNode, SetStateAction } from 'react'

import { Dimensions, View } from 'react-native'
import { CircularProgress, Grid } from '@mui/material'
import { Form, Formik, FormikConfig, FormikValues } from 'formik'

import { defaultStyles } from '../../config/styles'
import { FormikStepProps } from './FormikStep'
import AppButton from '../Button'

interface MyFormikConfig<Values> extends FormikConfig<Values> {
  step: number
  setStep: Dispatch<SetStateAction<number>>
  allowSkip?: boolean
  saveLabel?: string
}

const getStyleForWidth = () => {
  const windowWidth = Dimensions.get('window').width
  if (windowWidth > 600) {
    return {
      fontSize: defaultStyles.buttonSecondary.fontSize,
      paddingLeft: defaultStyles.buttonSecondary.paddingLeft,
      paddingRight: defaultStyles.buttonSecondary.paddingLeft,
    }
  } else {
    return {
      fontSize: 12,
      paddingLeft: 10,
      paddingRight: 10,
    }
  }
}

export function FormikStepper({
  step,
  setStep,
  allowSkip = true,
  children,
  innerRef = null,
  saveLabel = 'Save',
  ...props
}: Readonly<MyFormikConfig<FormikValues>>) {
  const childrenArray = React.Children.toArray(
    children as ReactNode[]
  ) as React.ReactElement<FormikStepProps>[]
  const currentChild = childrenArray[step]
  const validationSchema =
    currentChild && currentChild.props
      ? currentChild.props.validationSchema
      : null

  if (!innerRef) {
    innerRef = React.createRef()
  }

  function isLastStep() {
    return step === childrenArray.length - 1
  }

  const btnStyle = getStyleForWidth()

  return (
    <Formik
      {...props}
      innerRef={innerRef}
      validationSchema={validationSchema}
      onSubmit={async (values, helpers) => {
        console.log(values)
        await props.onSubmit(values, helpers)
      }}
    >
      {({ isSubmitting, handleSubmit, validateForm, errors }) => (
        <Form autoComplete="off">
          {currentChild}

          <View style={defaultStyles.mainContainer}>
            <Grid
              container
              spacing={2}
              style={{
                marginTop: 40,
                justifyContent: 'center',
                flexWrap: 'nowrap',
              }}
            >
              {step > 0 && (
                <Grid item>
                  <AppButton
                    title="< Back"
                    disabled={isSubmitting}
                    style={{
                      ...defaultStyles.buttonPrev,
                      ...btnStyle,
                    }}
                    textColor="blue"
                    backgroundColor="white"
                    onPress={() => setStep((s) => s - 1)}
                  />
                </Grid>
              )}
              {allowSkip && validationSchema && !isLastStep() && (
                <Grid item>
                  <AppButton
                    title="Skip"
                    disabled={isSubmitting}
                    style={{
                      ...defaultStyles.buttonSkip,
                      ...btnStyle,
                    }}
                    textColor="blue"
                    backgroundColor="white"
                    onPress={() => setStep((s) => s + 1)}
                  />
                </Grid>
              )}
              {!isLastStep() && (
                <Grid item>
                  <AppButton
                    title={isSubmitting ? 'Saving' : 'Save'}
                    onPress={handleSubmit}
                    disabled={isSubmitting}
                    style={{
                      ...defaultStyles.buttonNext,
                      ...btnStyle,
                    }}
                  >
                    {isSubmitting ? <CircularProgress size="1rem" /> : null}
                  </AppButton>
                </Grid>
              )}
              {validationSchema && (
                <Grid item>
                  <AppButton
                    title={'Next >'}
                    disabled={isSubmitting}
                    style={{
                      ...defaultStyles.buttonSave,
                      ...btnStyle,
                    }}
                    onPress={() => {
                      validateForm().then(() => {
                        if (Object.keys(errors).length === 0) {
                          setStep((s) => s + 1)
                        }
                      })
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </View>
        </Form>
      )}
    </Formik>
  )
}
