import React from 'react'
import { Image, StyleSheet } from 'react-native'

import settings from '../../config/settings'

function TrophyImage({ trophy }) {
  return (
    <Image
      style={styles.image}
      source={{ uri: settings.apiUrl + trophy.imagePath }}
    />
  )
}

export default TrophyImage

const styles = StyleSheet.create({
  image: {
    width: 80,
    height: 80,
  },
})
