import React, { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { useMutation, useQueryClient } from 'react-query'

import Screen from '../../../components/Screen'
import { useSession } from '../../../hooks/useSession'
import criticalImpactOpportunityQuery from '../../../api/query/criticalImpactOpportunity'

import { defaultStyles, defaultConfig } from '../../../config/styles'
import colors from '../../../config/colors'
import TitleGraphic from '../../../components/TitleGraphic'
import { ICio } from 'types/cio'
import AppText from '../../../components/Text'
import ActivityIndicator from '../../../components/ActivityIndicator'
import AppButton from '../../../components/Button'
import { FormikStep } from '../../../components/formik/FormikStep'
import CioTitle from '../../../components/cio/CioTitle'
import { FormikStepper } from '../../../components/formik/FormikStepper'
import BossLabel from '../../../components/boss/label/BossLabel'
import { Sections } from '../../../utility/cioSection'
import { useNavigation } from '@react-navigation/native'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { AppStackParamList } from '../../../navigation/AppNavigator'

export default function FormPreformScreen({ route }) {
  const navigation = useNavigation<DrawerNavigationProp<AppStackParamList>>()
  const cioId: string | null = route.params?.criticalImpactOpportunityId ?? null
  const { isLoading, isSuccess, isError, data, refetch } =
    criticalImpactOpportunityQuery.getCriticalImpactOpportunity(cioId)

  const queryClient = useQueryClient()
  const [step, setStep] = useState(0)
  const { user } = useSession()

  const cioUpdateMutation = useMutation(
    criticalImpactOpportunityQuery.updateCriticalImpactOpportunity,
    {
      onSuccess: (data: ICio) => {
        queryClient.invalidateQueries('cios')
        queryClient.invalidateQueries('cios_shared')
        queryClient.invalidateQueries([
          'cio',
          { criticalImpactOpportunityId: data.id },
        ])

        navigation.navigate('CRITICAL_IMPACT_OPPORTUNITY', {
          screen: 'CriticalImpactOpportunity_Form',
          params: {
            criticalImpactOpportunityId: data.id,
          },
        })
      },
      onError: (error) => {
        alert('Failed to update the CIO')
        console.log(error)
      },
    }
  )

  return (
    <Screen style={defaultStyles.screen}>
      <TitleGraphic
        section="Critical Impact Opportunities"
        title="Create a CIO"
        graphic={require('../../../assets/lead-cio.jpg')}
      />
      <View style={defaultStyles.mainContainer}>
        {isLoading && <ActivityIndicator visible={isLoading} />}
        {isError && (
          <>
            <AppText>Couldn't retrieve the CIO.</AppText>
            <AppButton title="Retry" onPress={() => refetch()} />
          </>
        )}
        {isSuccess && (
          <>
            <FormikStepper
              initialValues={data}
              onSubmit={(newCio) => {
                console.log('handleSubmit', newCio)
                newCio.section = Sections.perform

                cioUpdateMutation.mutate(newCio)
              }}
              step={step}
              setStep={setStep}
              saveLabel="Done"
            >
              <FormikStep>
                <CioTitle title="Perform" />
                <View style={defaultStyles.mainContainer}>
                  <View style={styles.table}>
                    <View style={[styles.rowBorder]}>
                      <AppText>
                        For those pressure moments, this is your{' '}
                        <AppText style={styles.highlight}>BOSS</AppText>{' '}
                        practice
                      </AppText>
                    </View>

                    <View style={[styles.rowBorder]}>
                      <BossLabel
                        letter="B"
                        circleColour={colors.blue}
                        label="Body posture"
                      />
                      <AppText style={styles.bossValue}>{data.boss1}</AppText>
                    </View>
                    <View style={[styles.rowBorder]}>
                      <BossLabel
                        letter="O"
                        circleColour={colors.blue}
                        label="Oxygen - Breath Practice"
                      />
                      <AppText style={styles.bossValue}>{data.boss2}</AppText>
                    </View>
                    <View style={styles.rowBorder}>
                      <BossLabel
                        letter="S"
                        circleColour={colors.red}
                        label="Self-Talk for Energy"
                      />
                      <AppText style={styles.bossValue}>{data.boss3}</AppText>
                    </View>
                    <View style={styles.rowBorder}>
                      <BossLabel
                        letter="S"
                        circleColour={colors.green}
                        label="Self-Talk for Action"
                      />
                      <AppText style={styles.bossValue}>{data.boss4}</AppText>
                    </View>
                  </View>
                </View>
              </FormikStep>
            </FormikStepper>
          </>
        )}
      </View>
    </Screen>
  )
}

const styles = StyleSheet.create({
  bold: {
    fontWeight: '600',
  },
  icon: {
    fontWeight: '700',
    width: 40,
    height: 40,
    lineHeight: 40,
    borderRadius: 40,
    backgroundColor: colors.primary,
    color: '#fff',
    textAlign: 'center',
    marginRight: 10,
  },
  highlight: {
    color: defaultConfig.colors.primary,
  },
  table: {},
  row: {
    flexDirection: 'row',
    paddingVertical: 20,
  },
  rowBorder: {
    borderBottomColor: colors.light,
    borderBottomWidth: StyleSheet.hairlineWidth,
    paddingVertical: 20,
  },
  horizontalAlign: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  bossValue: {
    marginTop: 10,
  },
})
