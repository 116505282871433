import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'

import header from '../components/part/Header'
import FormScreen from '../screens/app/CriticalImpactOpportunity/FormScreen'
import ListArchiveScreen from '../screens/app/CriticalImpactOpportunity/ListArchiveScreen'
import ListScreen from '../screens/app/CriticalImpactOpportunity/ListScreen'
import DoneScreen from '../screens/app/CriticalImpactOpportunity/DoneScreen'
import IntroScreen from '../screens/app/CriticalImpactOpportunity/IntroScreen'
import MenuScreen from '../screens/app/CriticalImpactOpportunity/MenuScreen'
import ViewScreen from '../screens/app/CriticalImpactOpportunity/ViewScreen'
import FormPauseScreen from '../screens/app/CriticalImpactOpportunity/FormPauseScreen'
import FormPreformScreen from '../screens/app/CriticalImpactOpportunity/FormPreformScreen'
import FormPrepScreen from '../screens/app/CriticalImpactOpportunity/FormPrepScreen'
import FormPickScreen from '../screens/app/CriticalImpactOpportunity/FormPickScreen'
import FormPauseReviewScreen from '../screens/app/CriticalImpactOpportunity/FormPauseReviewScreen'

const Stack = createStackNavigator()

const CriticalImpactOpportunityNavigator = () => (
  <Stack.Navigator initialRouteName="CriticalImpactOpportunity_List">
    <Stack.Screen
      name="CriticalImpactOpportunity_Form"
      component={FormScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showHamburger: false,
            showBackButton: true,
            showNudgifications: false,
          }),
        }
      }}
    />
    <Stack.Screen
      name="CriticalImpactOpportunity_View"
      component={ViewScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showHamburger: false,
            showBackButton: true,
            showNudgifications: false,
          }),
        }
      }}
    />
    <Stack.Screen
      name="CriticalImpactOpportunity_Form_Pick"
      component={FormPickScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showHamburger: false,
            showBackButton: true,
            showNudgifications: false,
          }),
        }
      }}
    />
    <Stack.Screen
      name="CriticalImpactOpportunity_Form_Prep"
      component={FormPrepScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showHamburger: false,
            showBackButton: true,
            showNudgifications: false,
          }),
        }
      }}
    />
    <Stack.Screen
      name="CriticalImpactOpportunity_Form_Perform"
      component={FormPreformScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showHamburger: false,
            showBackButton: true,
            showNudgifications: false,
          }),
        }
      }}
    />
    <Stack.Screen
      name="CriticalImpactOpportunity_Form_Pause"
      component={FormPauseScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showHamburger: false,
            showBackButton: true,
            showNudgifications: false,
          }),
        }
      }}
    />
    <Stack.Screen
      name="CriticalImpactOpportunity_Form_Pause_RapidReview"
      component={FormPauseReviewScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showHamburger: false,
            showBackButton: true,
            showNudgifications: false,
          }),
        }
      }}
    />
    <Stack.Screen
      name="CriticalImpactOpportunity_AddForm"
      component={FormScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showHamburger: false,
            showBackButton: true,
            showNudgifications: false,
          }),
        }
      }}
    />
    <Stack.Screen
      name="CriticalImpactOpportunity_AddForm_Pick"
      component={FormPickScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showHamburger: false,
            showBackButton: true,
            showNudgifications: false,
          }),
        }
      }}
    />
    <Stack.Screen
      name="CriticalImpactOpportunity_Menu"
      component={MenuScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showHamburger: false,
            showBackButton: true,
            showNudgifications: false,
          }),
        }
      }}
    />
    <Stack.Screen
      name="CriticalImpactOpportunity_Done"
      component={DoneScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showHamburger: true,
            showNudgifications: false,
          }),
        }
      }}
    />
    <Stack.Screen
      name="CriticalImpactOpportunity_List"
      component={ListScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showHamburger: true,
            showBackButton: false,
            showNudgifications: false,
          }),
        }
      }}
    />
    <Stack.Screen
      name="CriticalImpactOpportunity_List_Archived"
      component={ListArchiveScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showHamburger: false,
            showBackButton: true,
            showNudgifications: false,
          }),
        }
      }}
    />
    <Stack.Screen
      name="CriticalImpactOpportunity_Intro"
      component={IntroScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showHamburger: true,
            showBackButton: false,
            showNudgifications: true,
          }),
        }
      }}
    />
  </Stack.Navigator>
)

export default CriticalImpactOpportunityNavigator
