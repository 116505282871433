import { combineReducers, configureStore } from '@reduxjs/toolkit'
import logger from 'redux-logger'

import { dashboardReducer } from './slices/dashboardSlice'
import { sessionReducer } from './slices/sessionSlice'
import { whiteLabelReducer } from './slices/whiteLabelSlice'

const rootReducer = combineReducers({
  dashboard: dashboardReducer,
  whiteLabel: whiteLabelReducer,
  session: sessionReducer,
})

export type RootState = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  // getDefaultMiddleware({
  //   serializableCheck: false,
  // }).concat(logger),
})
