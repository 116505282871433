import axiosInstance from 'axios'

import authStorage from '../auth/storage'
import settings from '../config/settings'

const securityCheckUrl = (url) => {
  const securityCheck = /^\/(register|authentication|refresh)/i

  return url.match(securityCheck)
}

export const axios = axiosInstance.create({
  baseURL: settings.apiUrl,
  headers: {
    accept: 'application/json',
  },
})

axios.interceptors.request.use(
  async function (options) {
    if (securityCheckUrl(options.url)) {
      console.log()
      return
    }

    const authToken = await authStorage.getToken()

    if (!authToken) return

    options.headers['Authorization'] = authToken
      ? 'Bearer ' + authToken[authStorage.responseToken]
      : null

    return options
  },
  function (error) {
    console.log('Request error: ', error)
    return Promise.reject(new Error(error))
  }
)

export function handleResponse(response) {
  if (response.data) {
    return Promise.resolve(response.data)
  }

  return Promise.resolve(response)
}

export function handleError(error) {
  return Promise.reject(new Error(error))
}

export const get = async (url, { signal, config = {} }) => {
  if (!securityCheckUrl(url)) {
    await authStorage.checkAndGetToken()
  }

  // Create a new CancelToken source for this request
  const CancelToken = axiosInstance.CancelToken
  const source = CancelToken.source()

  const promise = axios
    .get(url, {
      ...config,
      cancelToken: source.token,
    })
    .then(handleResponse)
    .catch(handleError)

  // Cancel the request if React Query signals to abort
  signal?.addEventListener('abort', () => {
    source.cancel('Query was cancelled by React Query')
  })

  return promise
}

export const download = async (url, { config = {} } = {}) => {
  const response = await axios.get(url, { ...config, responseType: 'blob' })
  const hurl = window.URL.createObjectURL(new Blob([response.data])) // you can mention a type if you wish

  let filename = 'download'

  if (response.headers['content-disposition']) {
    filename = getFileName(response.headers['content-disposition']) ?? filename
  }

  const link = document.createElement('a')
  link.href = hurl
  link.setAttribute('download', filename) //this is the name with which the file will be downloaded
  link.click()

  // no need to append link as child to body.
  setTimeout(() => window.URL.revokeObjectURL(hurl), 1000) // this is important too, otherwise we will be unnecessarily spiking memory!
}

export const displayInNewTab = async (url, { config = {} } = {}) => {
  const response = await axios.get(url, { ...config, responseType: 'blob' })
  const hurl = window.URL.createObjectURL(
    new Blob([response.data], { type: 'application/pdf' })
  )
  const rtn = await window.open(hurl, '_blank')

  // Revoke the blob URL after a brief timeout for cleanup
  setTimeout(() => window.URL.revokeObjectURL(hurl), 1000)

  return rtn
}

export const post = async (resource, payload, config?) =>
  axios.post(resource, payload, config).then(handleResponse).catch(handleError)
export const patch = async (resource, payload, config?) =>
  axios
    .patch(resource, payload, {
      headers: { 'content-type': 'application/merge-patch+json' },
      ...(config ?? {}),
    })
    .then(handleResponse)
    .catch(handleError)
export const put = async (resource, payload, config?) =>
  axios.put(resource, payload, config).then(handleResponse).catch(handleError)
export const remove = async (resource) =>
  axios.delete(resource).then(handleResponse).catch(handleError)

/**
 * https://stackoverflow.com/a/67994693/157656
 */
function getFileName(disposition: string): string | null {
  const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-\.]+)(?:; ?|$)/i
  const asciiFilenameRegex = /^filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i

  let fileName: string | null = null
  if (utf8FilenameRegex.test(disposition)) {
    fileName = decodeURIComponent(
      utf8FilenameRegex.exec(disposition)?.[1] ?? ''
    )
  } else {
    // prevent ReDos attacks by anchoring the ascii regex to string start and
    //  slicing off everything before 'filename='
    const filenameStart = disposition.toLowerCase().indexOf('filename=')
    if (filenameStart >= 0) {
      const partialDisposition = disposition.slice(filenameStart)
      const matches = asciiFilenameRegex.exec(partialDisposition)
      if (matches?.[2]) {
        fileName = matches[2]
      }
    }
  }
  return fileName
}
