import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'

import header from '../components/part/Header'
import FormScreen from '../screens/app/Boss/FormScreen'
import ListScreen from '../screens/app/Boss/ListScreen'
import ViewScreen from '../screens/app/Boss/ViewScreen'

const Stack = createStackNavigator()

const BossNavigator = () => (
  <Stack.Navigator initialRouteName="Boss_List">
    <Stack.Screen
      name="Boss_Form"
      component={FormScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showHamburger: false,
            showBackButton: true,
            showNudgifications: false,
          }),
        }
      }}
    />
    <Stack.Screen
      name="Boss_AddForm"
      component={FormScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showHamburger: false,
            showBackButton: true,
            showNudgifications: false,
          }),
        }
      }}
    />
    <Stack.Screen
      name="Boss_View"
      component={ViewScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showHamburger: false,
            showBackButton: true,
            showNudgifications: false,
          }),
        }
      }}
    />
    <Stack.Screen
      name="Boss_List"
      component={ListScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showHamburger: true,
            showBackButton: false,
            showNudgifications: false,
          }),
        }
      }}
    />
  </Stack.Navigator>
)

export default BossNavigator
