import React from 'react'
import { View, StyleSheet, TouchableWithoutFeedback } from 'react-native'
import { format, parseISO } from 'date-fns'

import Text from '../Text'
import colors from '../../config/colors'

function CriticalImpactOpportunityButton({ id, contributor, onPress }) {
  let criticalImpactOpportunity = contributor.criticalImpactOpportunity
  let meetingName = criticalImpactOpportunity.what
  let date = format(parseISO(criticalImpactOpportunity.date), 'do MMMM yyyy')
  let updated = format(
    parseISO(criticalImpactOpportunity.updated),
    'do MMMM yyyy'
  )

  return (
    <TouchableWithoutFeedback onPress={onPress}>
      <View style={styles.card}>
        <View style={styles.detailsContainer}>
          <Text style={styles.title} numberOfLines={1}>
            {meetingName}
          </Text>
          <Text style={styles.date} numberOfLines={2}>
            {date}
          </Text>
          <Text style={styles.info} numberOfLines={1}>
            <b>Shared by:</b> {contributor.sharedByName ?? 'Unknown'}
          </Text>
          <Text style={styles.info} numberOfLines={1}>
            <b>Your Role:</b> {contributor.roleString}
          </Text>
          <Text style={styles.info} numberOfLines={1}>
            <b>Last Updated:</b> {updated}
          </Text>
        </View>
      </View>
    </TouchableWithoutFeedback>
  )
}

const styles = StyleSheet.create({
  card: {
    borderRadius: 10,
    backgroundColor: colors.light,
    marginBottom: 20,
    // overflow: "hidden",
  },
  detailsContainer: {
    padding: 20,
  },
  subTitle: {},
  info: {
    fontSize: 16,
    marginBottom: 5,
  },
  date: {
    // marginBottom: 7,
    fontWeight: 'normal',
    fontSize: 14,
    marginBottom: 12,
    // flex: 1,
  },
  title: {
    // marginBottom: 7,
    color: colors.secondary,
    fontWeight: 'bold',
    fontSize: 20,
    marginBottom: 3,
    // flex: 1,
  },
})

export default CriticalImpactOpportunityButton
