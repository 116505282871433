import { useDispatch } from 'react-redux'

import { AppDispatch } from '../redux/store'

// Use throughout your app instead of plain `useDispatch`
function useAppDispatch(): AppDispatch {
  return useDispatch()
}

export default useAppDispatch
