import React from 'react'
import { useFormikContext } from 'formik'
import { StyleSheet, View } from 'react-native'

import AppText from '../Text'
import Button from '../Button'

function TeamMemberList({ field, onRemove }) {
  const { values } = useFormikContext()

  return (
    <View>
      <AppText style={styles.heading}>Team Members</AppText>
      {values[field] && values[field].length === 0 && (
        <AppText>No team members</AppText>
      )}
      {values[field] &&
        values[field].length > 0 &&
        values[field].map((member, index) => (
          <View
            key={index}
            style={{
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            <AppText style={{ flex: 1 }}>{member.name}</AppText>
            <Button
              onPress={() => onRemove(member)}
              backgroundColor={'#ddd'}
              style={{
                backgroundColor: '#ddd',
                borderWidth: 0,
                borderRadius: 10,
                color: '#777',
                fontSize: 16,
                fontWeight: '600',
                height: '20',
                width: '20',
              }}
              title="-"
            />
          </View>
        ))}
    </View>
  )
}

export default TeamMemberList

const styles = StyleSheet.create({
  heading: {
    fontWeight: '600',
    minWidth: 150,
    marginBottom: 10,
    marginTop: 20,
  },
})
