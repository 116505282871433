import { UseQueryResult, useQuery } from 'react-query'

import { displayInNewTab, get, patch, post, remove } from '../../utility/api'
import { ICio, ICioView } from 'types/cio'
import { defaultCio } from '../../utility/defaultCio'

export const endpoint = '/critical_impact_opportunities'

const getCriticalImpactOpportunity = (
  cioId: string | null
): UseQueryResult<ICio> => {
  const query = useQuery<ICio>(
    ['cio', { criticalImpactOpportunityId: cioId }],
    ({ signal }) => {
      return get(endpoint + '/' + cioId, {
        signal,
        config: {
          transformResponse: [
            function (data) {
              const cio: ICio = JSON.parse(data)
              return defaultCio(cio)
            },
          ],
        },
      })
    },
    {
      refetchOnWindowFocus: false,
      enabled: cioId !== null,
    }
  )

  if (cioId === null) {
    return {
      ...query,
      isSuccess: true,
      data: defaultCio(),
    } as UseQueryResult<ICio>
  }

  return query
}

const getCriticalImpactOpportunityView = (cioId: string) => {
  let query = useQuery<ICioView>(
    ['cioView', { criticalImpactOpportunityId: cioId }],
    ({ signal }) =>
      get(endpoint + '/' + cioId + '/view', {
        signal,
        config: {
          transformResponse: [
            function (data) {
              const cio: ICioView = JSON.parse(data)
              console.log(cio)
              return cio
            },
          ],
        },
      }),
    {
      refetchOnWindowFocus: false,
    }
  )

  return query
}

const getCriticalImpactOpportunities = (userId) => {
  return useQuery('cios', ({ signal }) =>
    get('/users/' + userId + endpoint + '?order[date]=desc&status[lt]=3', {
      signal,
    })
  )
}

const getArchivedCriticalImpactOpportunities = (userId) => {
  return useQuery('cios_archived', ({ signal }) =>
    get('/users/' + userId + endpoint + '?order[date]=desc&status=3', {
      signal,
    })
  )
}

const deleteCriticalImpactOpportunity = (id) => {
  return remove(endpoint + '/' + id)
}

const addCriticalImpactOpportunity = (criticalImpactOpportunity) => {
  return post(endpoint, criticalImpactOpportunity)
}

const updateCriticalImpactOpportunity = (criticalImpactOpportunity) => {
  return patch(
    endpoint + '/' + criticalImpactOpportunity.id,
    criticalImpactOpportunity,
    {
      headers: { 'content-type': 'application/merge-patch+json' },
    }
  )
}

const archiveCriticalImpactOpportunity = (id) => {
  return patch(endpoint + '/' + id, {
    status: 3,
  })
}

const downloadPdf = async (id) => {
  return await displayInNewTab(endpoint + '/' + id + '/pdf', {})
}

export default {
  getCriticalImpactOpportunity,
  getCriticalImpactOpportunityView,
  addCriticalImpactOpportunity,
  updateCriticalImpactOpportunity,
  deleteCriticalImpactOpportunity,
  getCriticalImpactOpportunities,
  getArchivedCriticalImpactOpportunities,
  archiveCriticalImpactOpportunity,
  downloadPdf,
}
