import React, { ReactNode, useRef } from 'react'
import { View, StyleSheet, Modal, ScrollView } from 'react-native'
import { ScrollToTopContext } from './ScrollToTopContext'

interface IStartupModal {
  children: ReactNode
  modalVisible: boolean
  setModalVisible: (boolean) => void
}

function StartupModal({
  children,
  modalVisible,
  setModalVisible,
}: IStartupModal) {
  const scrollViewRef = useRef<ScrollView>(null)
  const scrollToTop = () => {
    scrollViewRef.current?.scrollTo({ x: 0, y: 0, animated: true })
  }

  return (
    <Modal
      animationType="none"
      transparent={true}
      visible={modalVisible}
      onRequestClose={() => {
        setModalVisible(!modalVisible)
      }}
    >
      <ScrollToTopContext.Provider value={scrollToTop}>
        <ScrollView ref={scrollViewRef} style={styles.centeredView}>
          <View style={styles.modalView}>{children}</View>
        </ScrollView>
      </ScrollToTopContext.Provider>
    </Modal>
  )
}

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    backgroundColor: 'rgb(200,200,200)',
    // backgroundColor: 'rgba(0, 0, 0, 0.7)', // This is your semi-transparent background
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
})

export default StartupModal
