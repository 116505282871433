import React from 'react'
import { TouchableWithoutFeedback } from 'react-native-gesture-handler'

function TrophyButton({ onPress, children }) {
  return (
    <TouchableWithoutFeedback onPress={onPress}>
      {children}
    </TouchableWithoutFeedback>
  )
}

export default TrophyButton
