import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'

import header from '../components/part/Header'
import PersonalFormScreen from '../screens/app/RapidReview/Personal/FormScreen'
import PersonalListScreen from '../screens/app/RapidReview/Personal/ListScreen'
import PersonalDoneScreen from '../screens/app/RapidReview/Personal/DoneScreen'
import PersonalViewScreen from '../screens/app/RapidReview/Personal/ViewScreen'

import ListScreen from '../screens/app/RapidReview/Team/ListScreen'
import FormScreen from '../screens/app/RapidReview/Team/FormScreen'
import JoinScreen from '../screens/app/RapidReview/Team/JoinScreen'
import CameraScreen from '../screens/app/RapidReview/Team/CameraScreen'
import DoneScreen from '../screens/app/RapidReview/Team/DoneScreen'
import UpComingMeetingScreen from '../screens/app/RapidReview/Team/UpComingMeetingScreen'
import InProgressMeetingScreen from '../screens/app/RapidReview/Team/InProgressMeetingScreen'
import SavedScreen from '../screens/app/RapidReview/Team/SavedScreen'
import SuggestionScreen from '../screens/app/RapidReview/Team/SuggestionScreen'
import PastMeetingScreen from '../screens/app/RapidReview/Team/PastMeetingScreen'
import HubScreen from '../screens/app/RapidReview/HubScreen'

const Stack = createStackNavigator()

const RapidReviewNavigator = () => (
  <Stack.Navigator initialRouteName="RapidReview_Hub">
    <Stack.Screen
      name="RapidReview_Form"
      component={PersonalFormScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showHamburger: false,
            showBackButton: true,
            showNudgifications: false,
          }),
        }
      }}
    />
    <Stack.Screen
      name="RapidReview_Form_With_CIO"
      component={PersonalFormScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showHamburger: false,
            showBackButton: true,
            showNudgifications: false,
          }),
        }
      }}
    />
    <Stack.Screen
      name="RapidReview_Form_With_Meeting"
      component={PersonalFormScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showHamburger: false,
            showBackButton: true,
            showNudgifications: false,
          }),
        }
      }}
    />
    <Stack.Screen
      name="RapidReview_View"
      component={PersonalViewScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showHamburger: false,
            showBackButton: true,
            showNudgifications: false,
          }),
        }
      }}
    />
    <Stack.Screen
      name="RapidReview_Done"
      component={PersonalDoneScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showHamburger: true,
            showNudgifications: false,
          }),
        }
      }}
    />
    <Stack.Screen
      name="RapidReview_List"
      component={PersonalListScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showHamburger: false,
            showBackButton: true,
            showNudgifications: false,
          }),
        }
      }}
    />
    <Stack.Screen
      name="Meeting_List"
      component={ListScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showHamburger: false,
            showBackButton: true,
            showNudgifications: true,
          }),
        }
      }}
    />
    <Stack.Screen
      name="RapidReview_Hub"
      component={HubScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showHamburger: true,
            showNudgifications: true,
          }),
        }
      }}
    />
    <Stack.Screen
      name="Meeting_Form"
      component={FormScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showHamburger: false,
            showBackButton: true,
            showNudgifications: false,
          }),
        }
      }}
    />
    <Stack.Screen
      name="Meeting_View_Upcoming"
      component={UpComingMeetingScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showHamburger: false,
            showBackButton: true,
            showNudgifications: false,
          }),
        }
      }}
    />
    <Stack.Screen
      name="Meeting_View_InProgress"
      component={InProgressMeetingScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showHamburger: false,
            showBackButton: true,
            showNudgifications: false,
          }),
        }
      }}
    />
    <Stack.Screen
      name="Meeting_View_Past"
      component={PastMeetingScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showHamburger: false,
            showBackButton: true,
            showNudgifications: false,
          }),
        }
      }}
    />
    <Stack.Screen
      name="Meeting_RapidReview_View"
      component={PersonalViewScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showHamburger: false,
            showBackButton: true,
            showNudgifications: false,
          }),
        }
      }}
    />
    <Stack.Screen
      name="Meeting_End"
      component={SuggestionScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showHamburger: false,
            showBackButton: true,
            showNudgifications: false,
          }),
        }
      }}
    />
    <Stack.Screen
      name="Meeting_Done"
      component={DoneScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showHamburger: true,
            showNudgifications: false,
          }),
        }
      }}
    />
    <Stack.Screen
      name="Meeting_Saved"
      component={SavedScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showHamburger: false,
            showBackButton: true,
            showNudgifications: false,
          }),
        }
      }}
    />
    <Stack.Screen
      name="Meeting_Join"
      component={JoinScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showHamburger: false,
            showBackButton: true,
            showNudgifications: false,
          }),
        }
      }}
    />
    <Stack.Screen
      name="Meeting_Camera"
      component={CameraScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showHamburger: false,
            showBackButton: true,
            showNudgifications: false,
          }),
        }
      }}
    />
  </Stack.Navigator>
)

export default RapidReviewNavigator
