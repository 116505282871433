import { CioWidgetName } from './Widgets/CioWidget'
import { RapidReviewWidgetName } from './Widgets/RapidReviewWidget'
import { SharedWithMeWidgetName } from './Widgets/SharedWithMeWidget'
import { BossPracticeWidgetName } from './Widgets/BossPracticeWidget'
import { TrophyCabinentWidgetName } from './Widgets/TrophyCabinentWidget'
import {
  IWidgetItem,
  IWidgetLayout,
} from '../../../../redux/slices/dashboardSlice'
import { COMPONENT, ComponentType } from '../../../../config/features'

export const ROW_HEIGHT = 1
export const PADDING = 0
export const MARGIN = 10

export interface WidgetArrayItem {
  i: string
  x: number
  y: number
  w: number
  h: number
}

export const DefaultLayout = [
  { i: CioWidgetName, x: 0, y: 2, w: 1, h: 110 },
  // { i: RapidReviewWidgetName, x: 0, y: 4, w: 1, h: 150 },
  { i: SharedWithMeWidgetName, x: 0, y: 6, w: 1, h: 130 },
  { i: BossPracticeWidgetName, x: 0, y: 8, w: 1, h: 130 },
  { i: TrophyCabinentWidgetName, x: 0, y: 10, w: 1, h: 120 },
]

export type Widget = {
  label: string
  type: string
  components: ComponentType[]
}

export type WidgetLine = Record<string, Widget>

export const Widgets: WidgetLine = {
  [CioWidgetName]: {
    label: 'CIO',
    type: CioWidgetName,
    components: [COMPONENT.CIO],
  },
  [RapidReviewWidgetName]: {
    label: 'Rapid Review',
    type: RapidReviewWidgetName,
    components: [COMPONENT.RAPID_REVIEW],
  },
  [SharedWithMeWidgetName]: {
    label: 'Share With Me',
    type: SharedWithMeWidgetName,
    components: [COMPONENT.CIO],
  },
  [BossPracticeWidgetName]: {
    label: 'My Boss Practices',
    type: BossPracticeWidgetName,
    components: [COMPONENT.BOSS],
  },
  [TrophyCabinentWidgetName]: {
    label: 'My Trophy Cabinet',
    type: TrophyCabinentWidgetName,
    components: [COMPONENT.TROPHY],
  },
}

export const getHeight = (id: string): number => {
  const parentElement = document.getElementById(id)
  const widgetContents = parentElement?.querySelector(
    '.widgetContents'
  ) as HTMLElement | null
  const offsetHeight = widgetContents?.offsetHeight ?? MARGIN
  // console.log(widgetContents, offsetHeight, id, parentElement, document)
  // const height = offsetHeight - MARGIN * 2 - PADDING * 2 || 1
  const height = offsetHeight / MARGIN || 1

  return height
}

export const generateLayouts = (
  layoutContents: IWidgetItem[]
): IWidgetLayout => {
  const sortedLayoutContents = [...layoutContents].sort((a, b) => a.y - b.y)

  let totalHeight = 0
  const newLayout = sortedLayoutContents.map((layoutContent) => {
    const h = getHeight(layoutContent.i)
    const y = totalHeight
    totalHeight += h
    return {
      ...layoutContent,
      h,
      y,
    }
  })

  return {
    lg: newLayout,
    md: newLayout,
    sm: newLayout,
    xs: newLayout,
    xxs: newLayout,
  }
}
