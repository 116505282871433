import React, { useEffect } from 'react'
import { StyleSheet, View } from 'react-native'

import ActivityIndicator from '../../../components/ActivityIndicator'
import Screen from '../../../components/Screen'
import Button from '../../../components/Button'
import AppText from '../../../components/Text'
import { defaultStyles } from '../../../config/styles'
import TitleGraphic from '../../../components/TitleGraphic'
import sharedWithMeQuery from '../../../api/query/sharedWithMe'
import BoomList from '../../../components/shared-with-me/BoomList'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { AppStackParamList } from '../../../navigation/AppNavigator'
import { useNavigation } from '@react-navigation/native'
import appStateQuery from '../../../api/query/appState'
import { useMutation, useQueryClient } from 'react-query'
import { QUERY_KEYS } from '../../../config/queryKeys'
import { STATE_KEY } from '../../../config/constants'

function BoomScreen() {
  const queryClient = useQueryClient()
  const navigation = useNavigation<DrawerNavigationProp<AppStackParamList>>()
  const boomApi = sharedWithMeQuery.getBooms()

  const stateMutation = useMutation(appStateQuery.postAppState, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS.appState.all)
      queryClient.invalidateQueries(
        QUERY_KEYS.appState.item(STATE_KEY.lastSeenSharedBoom)
      )
    },
    onError: (error) => {
      alert('Failed update state')
      console.error(error)
    },
  })

  useEffect(() => {
    stateMutation.mutate({
      stateKey: STATE_KEY.lastSeenSharedBoom,
      stateValue: new Date().toISOString(),
    })
  }, [])

  return (
    <Screen style={defaultStyles.screen}>
      <TitleGraphic
        section="Shared With Me"
        title="Shared Booms"
        graphic={require('../../../assets/lead-cio.jpg')}
      />

      <View style={defaultStyles.mainContainer}>
        <ActivityIndicator visible={boomApi.isLoading} />
        {boomApi.isError && (
          <>
            <AppText>Couldn't retrieve the shared Booms.</AppText>
            <Button title="Retry" onPress={() => boomApi.refetch()} />
          </>
        )}
        {boomApi.isSuccess && (
          <>
            <BoomList
              navigation={navigation}
              title="Shared Booms"
              data={boomApi.data}
            />
          </>
        )}
      </View>
    </Screen>
  )
}

const styles = StyleSheet.create({
  text: {
    fontSize: 25,
    fontWeight: '600',
    marginVertical: 20,
  },
})

export default BoomScreen
