import React, { useState } from 'react'
import { useFocusEffect, useNavigation } from '@react-navigation/native'

import ButtonIcon from './ButtonIcon'
import nudgificationsApi from '../api/nudgifications'
import { useSession } from '../hooks/useSession'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { AppStackParamList } from '../navigation/AppNavigator'

export default function Notification() {
  const { user } = useSession()
  const navigation = useNavigation<DrawerNavigationProp<AppStackParamList>>()
  const [count, setCount] = useState(0)
  let interval

  useFocusEffect(
    React.useCallback(() => {
      interval = setInterval(() => {
        checkForNudges()
      }, 30000)

      checkForNudges()
    }, [navigation])
  )

  navigation.addListener('blur', (e) => {
    clearInterval(interval)
  })

  function checkForNudges() {
    if (!user) {
      return
    }

    const nudgifications = nudgificationsApi.getNudgificationCount(user.id)
    nudgifications.then((response) => {
      if (response['status'] >= 400 || response === null) {
        console.log(response)
        // @todo

        return
      }

      if (!response['data']) {
        console.log(response)
        return
      }

      const newCount = response['data']['response']

      if (newCount != count) {
        // update count and redraw
        setCount(newCount)

        // if (this && this.forceUpdate) {
        //   this.forceUpdate()
        // }
      }
    })
  }

  return (
    <ButtonIcon
      color="#303D50"
      onPress={() => {
        console.log('Nudgification_Screen pressed')

        if (navigation) {
          console.log('Nudgification_Screen navigated')
          navigation.navigate('DASHBOARD', {
            screen: 'Nudgification_Screen',
          })
        }
      }}
      icon={count > 0 ? 'bell-alert-outline' : 'bell-outline'}
    />
  )
}
