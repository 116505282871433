import React from 'react'
import { StyleSheet, View } from 'react-native'
import * as Yup from 'yup'

import shedQuery, { ShedSections } from '../../api/query/shed'
import { defaultStyles } from '../../config/styles'
import AppText from '../Text'
import { useMutation, useQueryClient } from 'react-query'
import { Form, FormField, SubmitButton } from '../forms'
import { FormDatePicker } from '../../components/forms'
import TextHighlight from '../TextHighlight'

export interface Props {
  sections: ShedSections[]
  onSuccess: () => void
}

const validationSchema = Yup.object().shape({
  action1: Yup.string().required('What is your first action you will take?'),
  action2: Yup.string().required('What is your second action you will take?'),
  reviewDate: Yup.date()
    .required('When will review these actions?')
    .nullable(false)
    .typeError('When will review these actions?'),
})

const LowActionForm: React.FC<Props> = ({ sections, onSuccess }) => {
  const queryClient = useQueryClient()
  const shedActionMutation = useMutation(shedQuery.UpdateShedAction, {
    onSuccess: () => {
      queryClient.invalidateQueries(['shedActions'])
      onSuccess()
    },
    onError: (error) => {
      alert('Failed to create your Shed Action')
      console.log(error)
    },
  })

  const handleSubmit = async (formData) => {
    shedActionMutation.mutate({
      sections: sections,
      actions: [formData.action1, formData.action2],
      reviewDate: formData.reviewDate,
    })
  }
  const minDate = new Date()
  minDate.setDate(minDate.getDate() + 1)

  return (
    <Form
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      initialValues={{
        action1: '',
        action2: '',
        reviewDate: null,
      }}
    >
      <View style={{ marginTop: '1em' }}>
        <View>
          <AppText style={defaultStyles.questionWithPadding}>
            What <TextHighlight>TWO</TextHighlight> actions are you going to
            take?
          </AppText>
          <FormField name="action1" placeholder="action 1" />
          <FormField name="action2" placeholder="action 2" />
        </View>

        <View>
          <AppText style={defaultStyles.questionWithPadding}>
            When would you like to review this?
          </AppText>
          <FormDatePicker
            name="reviewDate"
            icon="calendar-month"
            dateFormat="do MMMM yyyy"
            minDate={minDate}
            placeholder="Select date"
          />
        </View>

        <SubmitButton title={'Save'} />
      </View>
    </Form>
  )
}

export default LowActionForm

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20,
    paddingTop: 20,
    paddingBottom: 5,
  },
  heading: {
    fontWeight: '400',
    minWidth: 150,
    marginBottom: 10,
  },
  table: {},
  row: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  icon: {
    marginTop: 0,
  },
  text: {
    marginLeft: 10,
  },
})
