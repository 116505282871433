import { AppStateKey, AppStateValue } from '../@types/misc'
import { STATE_KEY } from '../config/constants'
import { useAppStateQuery } from './useAppStateQuery'

export function useAppState() {
  const appStateQuery = useAppStateQuery()
  const states = appStateQuery.data ?? []

  return {
    hasSeenNudgeMessage: hasStateValue(STATE_KEY.hasSeenNudgeMessage, true),
    hasSeenWelcomeMessage: hasStateValue(STATE_KEY.hasSeenWelcomeMessage, true),

    lastSeenSharedBoom: stateDateValue(STATE_KEY.lastSeenSharedBoom),
    lastSeenSharedCio: stateDateValue(STATE_KEY.lastSeenSharedCio),
    lastSeenSharedRapidReview: stateDateValue(
      STATE_KEY.lastSeenSharedRapidReview
    ),
    lastSeenShedActionHigh: stateDateValue(STATE_KEY.lastSeenShedActionHigh),
    lastSeenShedActionLow: stateDateValue(STATE_KEY.lastSeenShedActionLow),

    hasDashboardLayout: hasStateValue(STATE_KEY.dashboardLayout, true),
  }

  function getByStateKey(stateKey: AppStateKey) {
    return states.find((state) => state.stateKey === stateKey)
  }

  function stateDateValue(stateKey: AppStateKey): Date | null {
    const stateValue = getByStateKey(stateKey)

    return stateValue ? new Date(stateValue.stateValue['date']) : null
  }

  function hasStateValue(
    stateKey: AppStateKey,
    stateValue: AppStateValue
  ): boolean {
    return states.some(
      (state) => state.stateKey === stateKey && state.stateValue === stateValue
    )
  }

  function normalizeAsArrayOfNumbers(stateKey: AppStateKey): number[] {
    const value = getByStateKey(stateKey)?.stateValue
    return Array.isArray(value) ? value.map(Number) : []
  }
}
