import React from 'react'
import { View, StyleSheet, TouchableOpacity } from 'react-native'

import Text from './Text'
import colors from '../config/colors'

interface IFaqButton {
  question
  onPress
}

function FaqButton({ question, onPress }: IFaqButton) {
  return (
    <TouchableOpacity onPress={onPress}>
      <View style={styles.card}>
        <View style={styles.detailsContainer}>
          <Text style={styles.title} numberOfLines={1}>
            {question}
          </Text>
        </View>
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  card: {
    borderRadius: 10,
    backgroundColor: colors.light,
    marginBottom: 20,
    overflow: 'hidden',
  },
  detailsContainer: {
    padding: 20,
  },
  subTitle: {},
  title: {
    marginBottom: 7,
    color: colors.secondary,
    fontWeight: 'bold',
    flex: 1,
  },
})

export default FaqButton
