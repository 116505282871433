import { StyleSheet } from 'react-native'

import colors from './colors'

export const defaultConfig = {
  colors,
  drawerTypeWidth: 1024,
}

const box = {
  borderRadius: 10,
  padding: 15,
  marginVertical: 10,
  borderWidth: StyleSheet.hairlineWidth,
}

export const dashboardStyles = StyleSheet.create({
  block: {
    backgroundColor: colors.lightPrimary,
    width: '100%',
    borderRadius: 10,
  },
  blockTitle: {
    fontSize: 20,
    fontWeight: '600',
    marginBottom: 12,
  },
  blockText: {
    fontSize: 16,
    fontWeight: '400',
    marginBottom: 12,
  },
  blockBody: {
    padding: 20,
  },
  line: {
    borderColor: colors.medium,
    borderWidth: 1,
    width: '100%',
    marginVertical: 10,
  },
  learnMore: {
    color: colors.primary,
    fontWeight: '600',
  },
})

const formNavButton = {
  backgroundColor: '#3EACBE',
  borderRadius: 7,
  paddingLeft: 20,
  paddingRight: 20,
  fontSize: 18,
  fontFamily: 'Open Sans',
  fontWeight: 600,
  color: '#fff',
  textTransform: 'none',
  boxShadow: 'none',
  letterSpacing: 0,
}

const formNavButtonSecondary = {
  backgroundColor: '#fff',
  borderColor: '#3EACBE',
  borderStyle: 'solid',
  borderRadius: 7,
  borderWidth: 1,
  paddingLeft: 20,
  paddingRight: 20,
  fontSize: 18,
  fontFamily: 'Open Sans',
  fontWeight: 600,
  color: '#3EACBE',
  textTransform: 'none',
  boxShadow: 'none',
  letterSpacing: 0,
}

const formNavButtonBorderless = {
  backgroundColor: '#fff',
  borderWidth: 0,
  borderRadius: 50,
  paddingLeft: 20,
  paddingRight: 20,
  fontSize: 18,
  fontFamily: 'Open Sans',
  fontWeight: 600,
  color: '#3EACBE',
  textTransform: 'none',
  boxShadow: 'none',
  letterSpacing: 0,
}

export const defaultStyles = StyleSheet.create({
  box: box,
  boxBorder: {
    ...box,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: colors.medium,
    backgroundColor: colors.white,
  },
  buttonNext: formNavButton,
  buttonPrev: formNavButtonSecondary,
  buttonSave: formNavButton,
  buttonSkip: formNavButtonSecondary,
  buttonSecondary: formNavButtonSecondary,
  buttonBorderless: formNavButtonBorderless,
  lightBox: {
    backgroundColor: colors.lightPrimary,
    borderRadius: 15,
    padding: 20,
    marginVertical: 20,
  },
  text: {
    color: colors.dark,
    fontSize: 18,
    fontFamily:
      '"Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  },
  openSans: {
    fontFamily:
      '"Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  },
  screen: {
    padding: 10,
    width: '100%',
    // maxWidth: 768,
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  mainContainer: {
    flex: 1,
    justifyContent: 'flex-start',
    padding: 10,
    width: '100%',
    maxWidth: 768,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  questionWithoutPadding: {
    fontWeight: '600',
  },
  questionWithPadding: {
    fontWeight: '600',
    marginTop: 10,
    paddingTop: 10,
  },
})
