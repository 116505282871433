const toUUID = (resource) => {
  if (typeof resource !== 'string') {
    return null
  }

  const found = resource.match(
    /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/
  )

  if (found === null) {
    return null
  }

  return found[0]
}

const toIRI = (uuid, resource) => {
  return `/${resource}/${uuid}`
}

export { toUUID, toIRI }
