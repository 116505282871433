import React from 'react'
import { StyleSheet, View } from 'react-native'

import AppText from '../../../../components/Text'
import colors from '../../../../config/colors'
import { defaultStyles } from '../../../../config/styles'
import Icon from '../../../../components/Icon'
import Screen from '../../../../components/Screen'
import Button from '../../../../components/ButtonIcon'
import MeetingCode from '../../../../components/rapid-review/MeetingCode'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { AppStackParamList } from '../../../../navigation/AppNavigator'
import { useNavigation } from '@react-navigation/native'

function DoneScreen({ route }) {
  const navigation = useNavigation<DrawerNavigationProp<AppStackParamList>>()
  console.log(route.params)
  return (
    <Screen style={defaultStyles.screen}>
      <View style={defaultStyles.mainContainer}>
        <Icon name="check-circle" size="80" iconColor={colors.primary} />
        <AppText style={styles.text}>Your meeting has been created</AppText>

        <View style={styles.meetingCode}>
          <AppText style={[styles.text]}>Meeting code</AppText>
          <MeetingCode code={route.params.meeting.meetingCode} />
        </View>

        <Button
          text="Back to meetings"
          color={colors.white}
          backgroundColor={colors.primary}
          onPress={() => {
            navigation.navigate('RAPID_REVIEW', {
              screen: 'RapidReview_Hub',
            })
          }}
          style={{ fontWeight: 600, marginLeft: 0 }}
        />
      </View>
    </Screen>
  )
}

const styles = StyleSheet.create({
  meetingCode: {
    justifyContent: 'flex-start',
    marginVertical: 100,
    width: '100%',
  },
  text: {
    fontSize: 25,
    fontWeight: '600',
    marginVertical: 20,
    textAlign: 'center',
  },
})

export default DoneScreen
