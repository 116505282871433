import React, { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'

import { ShedRequireActionType } from '../../api/query/shed'
import { defaultConfig } from '../../config/styles'
import AppText from '../Text'
import ButtonIcon from '../ButtonIcon'
import { joinArrayWithCommasAndAnd } from '../../utility/array'
import LowActionForm from './LowActionForm'
import { useFocusEffect } from '@react-navigation/native'

export interface Props {
  action: ShedRequireActionType
}

const LowProgress: React.FC<Props> = ({ action }) => {
  const sections = joinArrayWithCommasAndAnd(action.sections)
  const [showForm, setShowForm] = useState(false)

  useFocusEffect(
    React.useCallback(() => {
      return () => {
        if (showForm) {
          setShowForm(false)
        }
      }
    }, [showForm])
  )

  return (
    <>
      <View style={styles.row}>
        <MaterialCommunityIcons
          name={'alert-circle'}
          color={defaultConfig.colors.primary}
          size={30}
        />
        <AppText style={styles.text}>
          Your SHED for <strong>{sections}</strong> has been low the past{' '}
          {action.length} days. What actions can you take to fix it?
        </AppText>
      </View>
      {!showForm && (
        <ButtonIcon
          icon="shape-square-plus"
          backgroundColor={defaultConfig.colors.primary}
          color={defaultConfig.colors.white}
          text="Add actions"
          onPress={() => {
            setShowForm(true)
          }}
        />
      )}
      {showForm && (
        <LowActionForm
          sections={action.sections}
          onSuccess={() => {
            setShowForm(false)
          }}
        />
      )}
    </>
  )
}

export default LowProgress

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20,
    paddingTop: 20,
    paddingBottom: 5,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  icon: {
    marginTop: 0,
  },
  text: {
    marginLeft: 10,
  },
})
