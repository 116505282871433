import { useQuery } from 'react-query'

import { get } from '../../utility/api'
export const endpoint = '/trophies'

const getTrophyImages = () => {
  return useQuery('trophy_images', ({ signal }) =>
    get(endpoint, {
      signal,
      config: {
        transformResponse: [
          function (data) {
            const trophies: Array<object> = JSON.parse(data)

            if (!trophies) {
              return []
            }

            return trophies.filter(
              (trophy) => typeof trophy.deleted !== 'string'
            )
          },
        ],
      },
    })
  )
}

export default {
  getTrophyImages,
}
