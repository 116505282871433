import React from 'react'
import { useState } from 'react'

import TickButton from './TickButton'

interface IToggleButton {
  title?: string
  initialEnabled: boolean
  onChange: any
  children?: any
  style?: object
}

function ToggleButton({
  title,
  initialEnabled = false,
  onChange = null,
  children = undefined,
  style = {},
}: IToggleButton) {
  const [enabled, setEnabled] = useState(initialEnabled)

  function onPress() {
    if (onChange) {
      onChange(!enabled)
    }

    setEnabled(!enabled)
  }

  return (
    <TickButton
      title={title}
      enabled={enabled}
      showTick={false}
      onPress={onPress}
      style={style}
    >
      {children}
    </TickButton>
  )
}

export default ToggleButton
