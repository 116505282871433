import React, { useState } from 'react'
import { GestureResponderEvent, View } from 'react-native'
import { useFormikContext } from 'formik'

import colors from '../../config/colors'
import { defaultStyles } from '../../config/styles'
import AppText from '../Text'
import ButtonIcon from '../ButtonIcon'
import ContactPicker from '../forms/FormContactPicker'
import UserShareList from '../picker/UserShareList'
import { IBoom } from 'types/boom'

const SharingWith = () => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext<IBoom>()
  const [shareWith, setShareWith] = useState({
    name: '',
    teamId: '',
    userId: '',
  })

  return (
    <>
      <ContactPicker
        name={`contributor`}
        placeholder="Who would you like to share with?"
        width="100%"
        group={true}
        teams={true}
        onChange={({ id, name, type }) => {
          let userId = ''
          let teamId = ''

          if (type === 'team') {
            teamId = id
          } else {
            userId = id
          }

          setShareWith({
            ...shareWith,
            name: name,
            userId: userId,
            teamId: teamId,
          })
        }}
      />

      <View style={{ flexDirection: 'row' }}>
        <View style={{ flex: 1 }}>
          <ButtonIcon
            text="Add"
            icon="plus-circle"
            color={colors.primary}
            backgroundColor={colors.white}
            borderColor={colors.primary}
            onPress={(event: GestureResponderEvent) => {
              if (!shareWith.userId && !shareWith.teamId) {
                return
              }

              const sharedWith = values.sharedWith

              if (shareWith.userId) {
                sharedWith.push({
                  name: shareWith.name,
                  user: '/users/' + shareWith.userId,
                  team: null,
                })
              } else if (shareWith.teamId) {
                sharedWith.push({
                  name: shareWith.name,
                  team: '/teams/' + shareWith.teamId,
                  user: null,
                })
              }

              setFieldValue('sharedWith', sharedWith)
              setFieldTouched('sharedWith')
            }}
          />
        </View>
      </View>

      <View style={[defaultStyles.lightBox]}>
        <UserShareList
          field={'sharedWith'}
          title="Shared with:"
          noItemMessage="No shares"
        />
      </View>
      <AppText>Users will see this Boom in "Shared With Me".</AppText>
    </>
  )
}

export default SharingWith
