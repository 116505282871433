import React, { useState } from 'react'
import { View } from 'react-native'
import { useFocusEffect } from '@react-navigation/native'
import * as Yup from 'yup'

import { Form, FormField, SubmitButton } from '../../../components/forms'

import { defaultStyles } from '../../../config/styles'
import Title from '../../../components/Title'
import Screen from '../../../components/Screen'
import AppText from '../../../components/Text'
import colors from '../../../config/colors'
import helpApi from '../../../api/help'

const validationSchema = Yup.object().shape({
  subject: Yup.string().required().min(1).max(180).label('Subject'),
  message: Yup.string().required().min(5).label('Message'),
})

function MessageScreen({ route }) {
  const formikRef = React.createRef()
  const [messageSent, setMessageSent] = useState(false)

  useFocusEffect(
    React.useCallback(() => {
      if (formikRef.current) {
        console.log('form reset')
        setMessageSent(false)
      }
    }, [route.params])
  )

  const handleSubmit = async (message, { resetForm }) => {
    const result = await helpApi.sendMessage(message)

    if (!result.ok) {
      return alert('Could not save your message')
    }

    resetForm()

    setMessageSent(result.data.id)
  }

  return (
    <Screen style={defaultStyles.screen}>
      <Title style={{ fontSize: 25, color: 'black' }}>Contact Us</Title>
      {messageSent === false ? (
        <Form
          innerRef={formikRef}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          initialValues={{
            subject: '',
            message: '',
          }}
        >
          <AppText>Subject</AppText>
          <FormField
            maxLength={180}
            name="subject"
            placeholder="Summary of why you're getting in touch"
          />

          <AppText>Your message</AppText>
          <FormField
            name="message"
            placeholder=""
            multiline
            numberOfLines={3}
          />

          <SubmitButton title="Send" color={colors.secondary} />
        </Form>
      ) : (
        <View>
          <AppText>Your message has been sent.</AppText>
          <AppText>Message Id: {messageSent}</AppText>
        </View>
      )}
    </Screen>
  )
}

export default MessageScreen
