import React from 'react'
import { FlatList, View, StyleSheet } from 'react-native'

import { parseISO } from 'date-fns'
import { MeetingButton } from '../screen-button/MeetingButton'
import AppText from '../Text'
import colors from '../../config/colors'
import { useNavigation } from '@react-navigation/native'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { AppStackParamList } from '../../navigation/AppNavigator'

const IN_PROGRESS = 'in_progress'
const UPCOMING = 'upcoming'
const PAST = 'past'

type DISPLAY = typeof IN_PROGRESS | typeof UPCOMING | typeof PAST

interface IMeetingListProps {
  display: DISPLAY
  title?: string
  noDataMessage?: string | null
  data?: any
}

function MeetingList({
  display,
  title,
  noDataMessage = null,
  data,
}: Readonly<IMeetingListProps>) {
  const navigation = useNavigation<DrawerNavigationProp<AppStackParamList>>()
  let meetings = []
  let screenName = ''

  if (display === PAST) {
    meetings = data.filter(isMeetingPast)
    screenName = screenName + 'Past'
  } else if (display === IN_PROGRESS) {
    meetings = data.filter(isMeetingInProgress)
    screenName = screenName + 'InProgress'
  } else if (display === UPCOMING) {
    meetings = data.filter(isMeetingUpcomming)
    screenName = screenName + 'Upcoming'
  }

  if (null === noDataMessage && meetings.length === 0) {
    return null
  }

  return (
    <View>
      <AppText style={styles.title}>{title}</AppText>
      <FlatList
        data={meetings}
        keyExtractor={(meeting) => {
          return meeting.id.toString()
        }}
        renderItem={({ item }) => (
          <MeetingButton
            meeting={item}
            id={item.id}
            onPress={() => {
              navigation.navigate('RAPID_REVIEW', {
                screen: 'Meeting_View_' + screenName,
                params: {
                  meetingId: item.id,
                },
              })
            }}
          />
        )}
        ListEmptyComponent={
          <View style={styles.card}>
            <AppText>{noDataMessage}</AppText>
          </View>
        }
      />
    </View>
  )
}

const styles = StyleSheet.create({
  card: {
    borderRadius: 10,
    backgroundColor: colors.light,
    marginBottom: 20,
    overflow: 'hidden',
    padding: 10,
  },
  title: {
    fontWeight: '600',
    marginBottom: 10,
  },
})

function isMeetingPast(meeting): boolean {
  const date = parseISO(meeting.date)
  const dates = getDates()

  if (meeting.endDate) {
    return true
  }

  if (isMeetingInProgress(meeting)) {
    return false
  }

  return dates.now > date
}

function isMeetingUpcomming(meeting): boolean {
  const date = parseISO(meeting.date)
  const dates = getDates()

  if (meeting.startDate || meeting.endDate) {
    return false
  }

  return dates.now <= date
}

function isMeetingInProgress(meeting): boolean {
  if (meeting.startDate !== null && meeting.endDate === null) {
    return true
  }

  return false
}

function getDates() {
  const now = new Date()
  now.setHours(0)
  now.setMinutes(0)
  now.setSeconds(0)
  now.setMilliseconds(0)

  const tomorrow = new Date(now)
  tomorrow.setDate(tomorrow.getDate() + 1)

  return { now, tomorrow }
}

export { MeetingList, IN_PROGRESS, UPCOMING, PAST }
