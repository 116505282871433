import { useQuery } from 'react-query'

import { get, post, patch } from '../../utility/api'
import { IBoom, IBoomShared, IBoomSharedWith } from 'types/boom'
export const endpoint = '/users/boom'

const getSharedBoom = (sharedBoomId) => {
  return useQuery<IBoomShared>(
    ['sharedBoomId', { sharedBoomId: sharedBoomId }],
    ({ signal }) =>
      get('/boom_shares/' + sharedBoomId, {
        signal,
      })
  )
}

const getBoom = (boomId) => {
  return useQuery<IBoom>(
    ['boom', { boomId: boomId }],
    ({ signal }) =>
      get(endpoint + '/' + boomId, {
        signal,
      }),
    {
      retry: (failureCount: number, error: unknown) => {
        return false
      },
    }
  )
}

export const updateBoom = (boom: IBoom) => {
  return patch(endpoint + '/' + boom.id, boom)
}

interface IShareBoom {
  boomId: string
  sharedWith: IBoomSharedWith[]
}

export const shareBoom = ({ boomId, sharedWith }: IShareBoom) => {
  return patch(endpoint + '/' + boomId, {
    sharedWith: sharedWith,
  })
}

interface ISendBoomImage {
  boom: IBoom
  filename: string
}

export const sendBoomImage = ({ boom, filename }: ISendBoomImage) => {
  const url = endpoint + '/' + boom.id + '/image'

  // Symfony is not accepting _method to override method.
  if (boom.imageName === null) {
    return post(
      url + '?_method=PATCH&boomImage=delete',
      {
        boomImage: null,
      },
      {
        headers: { 'content-type': 'application/merge-patch+json' },
      }
    )
  }

  const formData = new FormData()
  formData.append('_method', 'PATCH')

  if (
    typeof boom.imageName === 'object' ||
    !boom.imageName.startsWith('http')
  ) {
    formData.append('boomImage', boom.imageName, filename)
  }

  return post(url + '?_method=PATCH', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export default {
  getBoom,
  getSharedBoom,
  updateBoom,
  shareBoom,
  sendBoomImage,
}
