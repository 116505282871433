import React from 'react'

import { useNavigation } from '@react-navigation/native'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { AppStackParamList } from '../../../../navigation/AppNavigator'
import ButtonIcon from '../../../../components/ButtonIcon'
import colors from '../../../../config/colors'

interface Props {
  buttonType: string
}

const RenderButton: React.FC<Props> = ({ buttonType }) => {
  const navigation = useNavigation<DrawerNavigationProp<AppStackParamList>>()

  switch (buttonType) {
    case 'CIO':
      return (
        <Button
          text={'New CIO'}
          onPress={() => {
            navigation.navigate('CRITICAL_IMPACT_OPPORTUNITY', {
              screen: 'CriticalImpactOpportunity_AddForm',
              params: {},
            })
          }}
        />
      )

    case 'RR':
      return (
        <Button
          text={'Create a Rapid Review'}
          onPress={() => {
            navigation.navigate('RAPID_REVIEW', {
              screen: 'RapidReview_Form',
              params: {},
            })
          }}
        />
      )

    case 'TROPHY':
      return (
        <Button
          text={'Add new trophy'}
          onPress={() => {
            navigation.navigate('TROPHY_CABINET', {
              screen: 'Trophy_Form',
              params: {
                trophyId: null,
                shedAction: true,
              },
            })
          }}
        />
      )

    case 'SHED':
      return (
        <Button
          text={'Rate Your SHED'}
          onPress={() => {
            navigation.navigate('DASHBOARD', {
              screen: 'ShedRatingScreen',
              params: {},
            })
          }}
        />
      )

    case 'BOSS':
      return (
        <Button
          text={'Create a BOSS Practice'}
          onPress={() => {
            navigation.navigate('BOSS', {
              screen: 'Boss_AddForm',
            })
          }}
        />
      )

    default:
      return null // Handle the case where no button types match
  }
}

interface ButtonProps {
  text: string
  onPress: () => void
}

const Button: React.FC<ButtonProps> = ({ text, onPress }) => {
  return (
    <ButtonIcon
      text={text}
      icon="text-box-plus-outline"
      color={colors.white}
      backgroundColor={colors.primary}
      onPress={onPress}
    />
  )
}

export default RenderButton
