import React from 'react'
import ButtonIcon from '../ButtonIcon'

import AppText from '../Text'
import colors from '../../config/colors'

function Intro({ next }) {
  return (
    <>
      <AppText style={{ marginBottom: 20 }}>
        To give you the best possible chance to make the most of your CIO, we
        will now start going through the 4Ps Process with you.
      </AppText>

      <ButtonIcon
        text="Next"
        icon="text-box-plus-outline"
        color={colors.white}
        backgroundColor={colors.primary}
        onPress={next}
      />
    </>
  )
}

export default Intro
