import React from 'react'
import { Image, StyleSheet, View } from 'react-native'

import { defaultStyles } from '../../../config/styles'
import AppText from '../../../components/Text'
import Title from '../../../components/Title'
import Screen from '../../../components/Screen'
import shedTipsQuery from '../../../api/query/shedTips'
import ActivityIndicator from '../../../components/ActivityIndicator'
import AppButton from '../../../components/Button'
import colors from '../../../config/colors'
import Icon from '../../../components/Icon'

function rand(items) {
  // "|" for a kinda "int div"
  return items[(items.length * Math.random()) | 0]
}

function capitalizeFirstLetter(v: string) {
  return v.charAt(0).toUpperCase() + v.substring(1)
}

const TIP_IMAGES = {
  sleep: require('../../../assets/tips.png'),
  hydration: require('../../../assets/tips.png'),
  exercise: require('../../../assets/tips-exercise.png'),
  diet: require('../../../assets/tips.png'),
  error: require('../../../assets/tips.png'),
}
function ShedTipsScreen({ route }) {
  const section = route.params.section
  const { isLoading, isSuccess, isError, data, refetch } =
    shedTipsQuery.ShedTips(section)

  return (
    <Screen style={defaultStyles.screen}>
      <Title style={{ fontSize: 25, color: 'black' }}>
        Shed {capitalizeFirstLetter(section)} Tips
      </Title>

      <View style={defaultStyles.mainContainer}>
        <View>
          <ActivityIndicator visible={isLoading} />
          {isError && (
            <>
              <AppText>Couldn't retrieve your Shed tip.</AppText>
              <AppButton title="Retry" onPress={() => refetch()} />
            </>
          )}
          {isSuccess &&
            typeof data !== 'undefined' &&
            typeof data.tips !== 'undefined' && (
              <>
                <AppText>{data.intro}</AppText>
                <ul style={{ marginLeft: 0, paddingLeft: 0 }}>
                  {data.tips.map((tip, key) => {
                    return (
                      <li
                        style={{ listStyle: 'none', marginBottom: 10 }}
                        key={'tip_' + key}
                      >
                        <AppText>
                          <Icon
                            name="check-bold"
                            size={40}
                            iconColor={colors.primary}
                            backgroundColor={colors.white}
                          />
                          {tip}
                        </AppText>
                      </li>
                    )
                  })}
                </ul>
                <Image
                  style={styles.image}
                  source={TIP_IMAGES[section] ?? TIP_IMAGES.error}
                />
              </>
            )}
        </View>
      </View>
    </Screen>
  )
}

const styles = StyleSheet.create({
  title: {
    fontWeight: 'bold',
    fontSize: 22,
    marginBottom: 15,
  },
  subtitle: {
    fontWeight: 'bold',
    marginBottom: 15,
  },
  graphStyle: {},
  image: {
    width: '100%',
    maxWidth: 320,
    height: 320,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
})

export default ShedTipsScreen
