import React from 'react'
import { useState } from 'react'
import { useFormikContext } from 'formik'
import { View } from 'react-native'

import ErrorMessage from './ErrorMessage'
import ContactPicker from '../picker/ContactPicker'
import TeamMemberList from '../picker/TeamMemberList'

function FormTeamPicker({ field, placeholder, width, group = false }) {
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [currentSelected, setCurrentSelected] = useState({
    id: null,
    name: '',
  })
  const { setFieldTouched, setFieldValue, values, submitCount, errors } =
    useFormikContext()

  return (
    <View>
      <ContactPicker
        name={field}
        group={group}
        placeholder={placeholder}
        showAdd={true}
        width={width}
        onAdd={() => {
          if (!currentSelected) {
            setShowErrorMessage(true)
            return
          }

          setFieldValue(field, [
            ...values[field],
            {
              id: currentSelected.id,
              name: currentSelected.name,
            },
          ])
          setFieldTouched(field)
          setCurrentSelected({
            id: null,
            name: '',
          })
        }}
        onValueChange={(item) => {
          setShowErrorMessage(showErrorMessage && item === undefined)
          setCurrentSelected(item)
          // setFieldValue(field, [...values[field], {
          //   id: item.id,
          //   name: item.name,
          // }]);
          // setFieldTouched(field);
        }}
      />
      <ErrorMessage error={'Select a team member'} visible={showErrorMessage} />
      <ErrorMessage
        error={errors[field]}
        visible={submitCount > 0 && errors[field]}
      />
      <TeamMemberList
        field={field}
        onRemove={(member) => {
          setFieldValue(
            field,
            values[field].filter((teamMember) => teamMember.id !== member.id)
          )
          setFieldTouched(field)
        }}
      />
    </View>
  )
}

export default FormTeamPicker
