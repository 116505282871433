import React, { useState } from 'react'
import * as Yup from 'yup'
import { View } from 'react-native'
import { format, parseISO } from 'date-fns'
import { useFormikContext } from 'formik'

import AppText from '../Text'
import { defaultConfig, defaultStyles } from '../../config/styles'
import FormWhenPicker, { IFormWhenOption } from '../forms/FormWhenPicker'
import { INudge } from 'types/nudge'
import TickButton from '../../components/TickButton'
import InfoButton from '../../components/InfoButton'
import Icon from '../../components/Icon'
import { ICio } from 'types/cio'
import { INudgeOption } from 'types/nudgeOption'
import useApi from '../../hooks/useApi'
import nudgificationsApi from '../../api/nudgifications'
import IsNotificationDisabled from '../../components/IsNotificationDisabled'

export function NudgeValidationSchema() {
  return Yup.date()
    .nullable()
    .default(null)
    .transform((curr) => {
      if (typeof curr === 'object') {
        curr = curr.when
      }

      if (
        !curr ||
        typeof curr === undefined ||
        Object.keys(curr).length === 0 ||
        (curr instanceof Date && !curr.getDate())
      ) {
        return null
      }

      return curr === '' ? null : curr
    })
    .typeError('Invalid Date')
}

interface IFormNudge {
  fieldName: string
  fieldNameOption: string
  nudgeText: string
  formatSource: string
  options: IFormWhenOption[]
  placeholder?: string
  autoSubmit?: boolean
  nudgeDetails?: { title: string; description: string }
}

export function FormNudge({
  fieldName,
  fieldNameOption,
  nudgeText,
  formatSource,
  options,
  autoSubmit = true,
  nudgeDetails = undefined,
}: IFormNudge) {
  const [showForm, setShowForm] = useState<boolean | null>(null)
  const { handleSubmit, setFieldValue, setFieldTouched, values } =
    useFormikContext<ICio>()
  const deleteNudgificationsApi = useApi(nudgificationsApi.deleteNudgification)

  const nudgeData: INudge | null = values[fieldName] ?? null
  console.log(nudgeData)

  if (nudgeData?.id || values[fieldNameOption]?.when) {
    return NudgeSet(
      fieldName,
      fieldNameOption,
      nudgeText,
      nudgeData?.id
        ? nudgeData
        : ({
            id: '',
            nudge: values[fieldNameOption].when,
          } as INudge),
      setFieldValue,
      setFieldTouched,
      deleteNudgificationsApi
    )
  }

  if (nudgeData || showForm || values[fieldNameOption]?.when) {
    return NudgeForm(
      fieldNameOption,
      nudgeText,
      formatSource,
      options,
      autoSubmit,
      nudgeDetails
    )
  } else if (showForm === false) {
    return NoNudge(nudgeText)
  }

  return WantNudge(
    setShowForm,
    handleSubmit,
    fieldName,
    fieldNameOption,
    nudgeText,
    setFieldValue,
    autoSubmit
  )
}

function WantNudge(
  setShowForm: Function,
  handleSubmit: Function,
  fieldName: string,
  fieldNameOption: string,
  nudgeText: string,
  setFieldValue: Function,
  autoSubmit: boolean
) {
  return (
    <View style={defaultStyles.mainContainer}>
      <AppText style={defaultStyles.questionWithoutPadding}>
        {nudgeText}
      </AppText>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <View style={{ width: '49%' }}>
          <TickButton
            title="Yes"
            enabled={true}
            showTick={false}
            onPress={() => {
              setShowForm(true)
            }}
          />
        </View>
        <View style={{ width: '49%' }}>
          <TickButton
            title="No"
            onPress={() => {
              // setFieldValue(fieldName, null)
              setFieldValue(fieldNameOption, {} as INudgeOption)

              if (autoSubmit) {
                handleSubmit()
              } else {
                setShowForm(false)
              }
            }}
          />
        </View>
      </View>
    </View>
  )
}

function NudgeForm(
  fieldNameOption: string,
  nudgeText: string,
  formatSource,
  options,
  autoSubmit: boolean,
  nudgeDetails?: { title: string; description: string }
) {
  console.log(fieldNameOption, formatSource, options)
  return (
    <View style={defaultStyles.mainContainer}>
      <AppText style={defaultStyles.questionWithoutPadding}>
        {nudgeText}
      </AppText>
      <View>
        <FormWhenPicker
          name={fieldNameOption}
          formatSource={formatSource}
          options={options}
          width={100}
          autoSubmit={autoSubmit}
          nudgeDetails={nudgeDetails}
        />
      </View>
    </View>
  )
}

function NoNudge(nudgeText: string) {
  return (
    <View style={defaultStyles.mainContainer}>
      <AppText style={defaultStyles.questionWithoutPadding}>
        {nudgeText}
      </AppText>
      <View>
        <AppText>You opted to not have a nudge.</AppText>
      </View>
    </View>
  )
}

function NudgeSet(
  fieldName: string,
  fieldNameOption: string,
  nudgeText: string,
  nudge: INudge,
  setFieldValue: Function,
  setFieldTouched: Function,
  deleteNudgificationsApi
) {
  const now = new Date()
  const nudgeDate: Date = parseISO(nudge.nudge)

  return (
    <View style={defaultStyles.mainContainer}>
      <AppText style={defaultStyles.questionWithoutPadding}>
        {nudgeText}
      </AppText>
      <View style={defaultStyles.lightBox}>
        <View style={{ alignItems: 'center' }}>
          <Icon
            name="check-circle"
            size={60}
            iconColor={defaultConfig.colors.primary}
          />
        </View>

        <AppText>
          Your nudge {nudgeDate > now ? 'has been' : 'was'} set for{' '}
          {format(nudgeDate, 'do MMMM yyyy')}
        </AppText>

        <View style={{ alignItems: 'center' }}>
          <InfoButton
            text="Delete nudge"
            icon="trash-can"
            showIcon={true}
            onPress={() => {
              setFieldValue(fieldName, null)
              setFieldValue(fieldNameOption, {} as INudgeOption)

              if (nudge.id) {
                deleteNudgificationsApi.request(nudge.id).then(() => {
                  setFieldTouched(fieldName)
                })
              }
            }}
          />
        </View>
        <IsNotificationDisabled />
      </View>
    </View>
  )
}
