import { useState } from 'react'
import { CANCEL_ERROR } from 'apisauce'

const useApi = (apiFunc) => {
  const [data, setData] = useState([])
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)

  const request = async (...args) => {
    setLoading(true)
    // console.log(apiFunc, args);
    const response = await apiFunc(...args)

    if (!response.ok && response.problem === CANCEL_ERROR) {
      return null
    }

    setLoading(false)
    setError(!response.ok)
    setData(response.data)

    return response
  }

  return { data, error, loading, request }
}

export default useApi
