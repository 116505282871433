import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import ProfileScreen from '../screens/app/Profile/ProfileScreen'
import FormScreen from '../screens/app/Profile/FormScreen'
import header from '../components/part/Header'
import RitualScreen from '../screens/app/Profile/RitualScreen'
import PreRitualScreen from '../screens/app/Profile/PreRitualScreen'
import WelcomeScreen from '../screens/app/Profile/WelcomeScreen'
import NudgeScreen from '../screens/app/Profile/NudgeScreen'

const Stack = createStackNavigator()

const ProfileNavigator = () => (
  <Stack.Navigator>
    <Stack.Screen
      name="Profile"
      component={ProfileScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showHamburger: true,
            showNudgifications: true,
          }),
        }
      }}
    />
    <Stack.Screen
      name="Profile_Form"
      component={FormScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showBackButton: true,
            showHamburger: false,
            showNudgifications: false,
          }),
        }
      }}
    />
    <Stack.Screen
      name="Profile_Ritual"
      component={RitualScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showBackButton: true,
            showHamburger: false,
            showNudgifications: false,
          }),
        }
      }}
    />
    <Stack.Screen
      name="Profile_PreRitual"
      component={PreRitualScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showBackButton: true,
            showHamburger: false,
            showNudgifications: false,
          }),
        }
      }}
    />
    <Stack.Screen
      name="Profile_Nudge"
      component={NudgeScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showBackButton: true,
            showHamburger: false,
            showNudgifications: false,
          }),
        }
      }}
    />
    <Stack.Screen
      name="Profile_WelcomeScreen"
      component={WelcomeScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showBackButton: true,
            showHamburger: false,
            showNudgifications: false,
          }),
        }
      }}
    />
  </Stack.Navigator>
)

export default ProfileNavigator
