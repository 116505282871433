import { ScaledSize } from 'react-native'
import { defaultConfig } from '../../config/styles'

export const getDrawerType = (dimensions: ScaledSize) => {
  return dimensions.width > defaultConfig.drawerTypeWidth
    ? 'permanent'
    : 'front'
}

export const isScreenNarrow = (dimensions: ScaledSize) => {
  return getDrawerType(dimensions) != 'permanent'
}
