import React from 'react'
import { View } from 'react-native'

import Screen from '../../../components/Screen'
import { defaultStyles } from '../../../config/styles'
import Button from '../../../components/Button'
import TitleGraphic from '../../../components/TitleGraphic'
import AppText from '../../../components/Text'
import boomQuery from '../../../api/query/boom'
import BoomForm from '../../../components/boom/BoomForm'
import ActivityIndicator from '../../../components/ActivityIndicator'
import { useNavigation } from '@react-navigation/native'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { AppStackParamList } from '../../../navigation/AppNavigator'

function BoomFormScreen() {
  const navigation = useNavigation<DrawerNavigationProp<AppStackParamList>>()

  const { isLoading, isSuccess, isError, data, refetch } =
    boomQuery.getBoom('me')

  return (
    <Screen style={defaultStyles.screen}>
      <TitleGraphic
        section="B.O.O.M"
        title="Best Out Of Me"
        graphic={require('../../../assets/lead-boom.jpg')}
      />
      <View style={defaultStyles.mainContainer}>
        <AppText>
          <p>
            B.O.O.M is a high-performance practice used by great teams to
            fast-form, build real connection and inclusion, and to stay tight.
          </p>
          <p>
            <strong>Your BOOM will be unique to you</strong>
          </p>
          <p>
            Sharing your BOOM with your team enables them to get the best out of
            you when it matters.
          </p>
        </AppText>
        <hr />
        <ActivityIndicator visible={isLoading} />
        {isError && (
          <>
            <AppText>Couldn't retrieve the BOSS practices.</AppText>
            <Button title="Retry" onPress={() => refetch()} />
          </>
        )}
        {isSuccess && <BoomForm boom={data} navigation={navigation} />}
      </View>
    </Screen>
  )
}

export default BoomFormScreen
