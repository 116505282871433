import React from 'react'
import { FlatList, StyleSheet } from 'react-native'

import AppText from '../Text'
import CriticalImpactOpportunityButton from './CriticalImpactOpportunityButton'
import { useNavigation } from '@react-navigation/native'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { AppStackParamList } from '../../navigation/AppNavigator'

function CioList({ title, data }) {
  const navigation = useNavigation<DrawerNavigationProp<AppStackParamList>>()
  return (
    <>
      <AppText style={styles.text}>{title}</AppText>
      <FlatList
        data={data}
        keyExtractor={(contributor, index) => {
          return (
            'ciolist_' +
            index +
            '_' +
            contributor.criticalImpactOpportunity.id.toString()
          )
        }}
        renderItem={({ item }) => (
          <CriticalImpactOpportunityButton
            contributor={item}
            id={item.id}
            onPress={() => {
              navigation.navigate(
                'SharedWithMe_CriticalImpactOpportunity_Overview',
                {
                  criticalImpactOpportunityId:
                    item.criticalImpactOpportunity.id,
                }
              )
            }}
          />
        )}
        ListEmptyComponent={<AppText>There are currently no CIOs</AppText>}
      />
    </>
  )
}

const styles = StyleSheet.create({
  text: {
    fontSize: 25,
    fontWeight: '600',
    marginVertical: 20,
  },
})

export default CioList
