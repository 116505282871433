import { useQuery } from 'react-query'

import { get, patch } from '../../utility/api'
export const endpoint = '/reminders'

const getReminders = (userId) => {
  return useQuery('reminders', ({ signal }) =>
    get('/users/' + userId + endpoint, {
      signal,
    })
  )
}

export const updateReminder = (reminder) => {
  return patch('/users' + endpoint + '/' + reminder.id, reminder, {
    headers: { 'content-type': 'application/merge-patch+json' },
  })
}

export default {
  getReminders,
  updateReminder,
}
