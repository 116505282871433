import React from 'react'
import { StyleSheet, View } from 'react-native'

import AppText from './Text'
import { defaultConfig } from '../config/styles'

function Title({ children, style = {}, ...otherProps }) {
  return (
    <View>
      <AppText style={[styles.title, style]} {...otherProps}>
        {children}
      </AppText>
      <View style={styles.line} />
    </View>
  )
}

const styles = StyleSheet.create({
  title: {
    fontSize: 24,
    fontWeight: '500',
    textAlign: 'center',
    fontFamily: '"Merriweather", serif',
  },
  line: {
    borderBottomColor: defaultConfig.colors.primary,
    borderBottomWidth: 3,
    width: 50,
    marginTop: 20,
    alignSelf: 'center',
    marginBottom: 30,
  },
})

export default Title
