import React from 'react'
import { FlatList, StyleSheet } from 'react-native'

import AppText from '../Text'
import BoomButton from './BoomButton'

function BoomList({ title, navigation, data }) {
  return (
    <>
      <AppText style={styles.text}>{title}</AppText>
      <FlatList
        data={data}
        keyExtractor={(boom) => {
          return 'boom_' + boom.id.toString()
        }}
        renderItem={({ item }) => (
          <BoomButton
            boom={item.userBoom}
            id={item.userBoom.id}
            onPress={() => {
              navigation.navigate('SharedWithMe_Boom_View', {
                sharedBoomId: item.id,
              })
            }}
          />
        )}
        ListEmptyComponent={<AppText>There are currently no Booms</AppText>}
      />
    </>
  )
}

const styles = StyleSheet.create({
  text: {
    fontSize: 25,
    fontWeight: '600',
    marginVertical: 20,
  },
})

export default BoomList
