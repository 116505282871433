import React from 'react'
import { View, TextInput, StyleSheet } from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'

import { defaultStyles, defaultConfig } from '../config/styles'

function AppTextInput({
  icon = null,
  innerRef = null,
  width = '100%',
  ...otherProps
}) {
  return (
    <View style={[defaultStyles.boxBorder, styles.container, { width }]}>
      {icon && (
        <MaterialCommunityIcons
          name={icon}
          size={20}
          color={defaultConfig.colors.medium}
          style={styles.icon}
        />
      )}
      <TextInput
        placeholderTextColor={defaultConfig.colors.medium}
        style={[defaultStyles.text, styles.input]}
        ref={innerRef}
        {...otherProps}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  icon: {
    marginRight: 10,
  },
  input: {
    flex: 1,
    fontWeight: '500',
    fontSize: 16,
    fontFamily: 'Open Sans',
  },
})

export default AppTextInput
