import React from 'react'
import { View } from 'react-native'

import Screen from '../../../components/Screen'
import { defaultStyles } from '../../../config/styles'
import TitleGraphic from '../../../components/TitleGraphic'
import AppText from '../../../components/Text'
import boomQuery from '../../../api/query/boom'
import AppButton from '../../../components/Button'
import ActivityIndicator from '../../../components/ActivityIndicator'
import { ViewBoom } from './components/ViewBoom'

function BoomViewScreen({ route }) {
  const { isLoading, isSuccess, isError, data, refetch } =
    boomQuery.getSharedBoom(route.params.sharedBoomId)

  return (
    <Screen style={defaultStyles.screen}>
      <TitleGraphic
        section="B.O.O.M"
        title="Best Out Of Me"
        graphic={require('../../../assets/lead-boom.jpg')}
      />
      <View style={defaultStyles.mainContainer}>
        <AppText>
          <p>
            B.O.O.M is a high-performance practice used by great teams to
            fast-form, build real connection and inclusion, and to stay tight.
          </p>
        </AppText>
        <hr />
        <ActivityIndicator visible={isLoading} />
        {isError && (
          <>
            <AppText>Couldn't retrieve the nudgifications.</AppText>
            <AppButton title="Retry" onPress={refetch} />
          </>
        )}
        {isSuccess && (
          <>
            <AppText>
              <strong>{data.sharedByName}'s Boom </strong>
            </AppText>

            <ViewBoom boom={data.userBoom} />
          </>
        )}
      </View>
    </Screen>
  )
}

export default BoomViewScreen
