import React from 'react'
import { defaultConfig } from '../config/styles'

import AppButton from './Button'
import Icon from './Icon'

interface ITickButton {
  title?: string | null
  enabled?: boolean
  showTick?: boolean
  onPress?: any | null
  children?: any
  style?: {
    width?: string
  }
}

function TickButton({
  title = null,
  enabled = false,
  showTick = true,
  onPress = null,
  children = undefined,
  style = {},
}: ITickButton) {
  return (
    <div
      style={{
        position: 'relative',
        width: style.width ?? '100%',
        marginTop: 0,
      }}
    >
      <AppButton
        title={title}
        borderColor={enabled ? 'primary' : 'medium'}
        backgroundColor={enabled ? 'lightPrimary' : 'white'}
        textColor={'black'}
        emphasis={enabled}
        onPress={onPress}
        style={style}
      >
        {children}
      </AppButton>

      {enabled && showTick && (
        <div
          style={{
            position: 'absolute',
            top: 5,
            right: -5,
            zIndex: 50,
          }}
        >
          <Icon
            name="check"
            size={20}
            iconColor={defaultConfig.colors.white}
            backgroundColor={defaultConfig.colors.primary}
          />
        </div>
      )}
    </div>
  )
}

export default TickButton
