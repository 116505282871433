import React from 'react'
import { StyleSheet, View } from 'react-native'

import { defaultStyles } from '../../../config/styles'
import Title from '../../../components/Title'
import AppText from '../../../components/Text'
import colors from '../../../config/colors'
import Icon from '../../../components/Icon'
import Screen from '../../../components/Screen'
import Button from '../../../components/ButtonIcon'
import { useNavigation } from '@react-navigation/native'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { AppStackParamList } from '../../../navigation/AppNavigator'

function DoneScreen() {
  const navigation = useNavigation<DrawerNavigationProp<AppStackParamList>>()

  return (
    <Screen style={defaultStyles.screen}>
      <Title style={{ fontSize: 25, color: 'black' }}>
        Critical Impact Opportunity
      </Title>
      <View style={[defaultStyles.mainContainer, styles.mainContainer]}>
        <Icon
          name="check-circle"
          size={80}
          iconColor={colors.primary}
          backgroundColor={colors.white}
        />
        <AppText style={styles.text}>
          Well done. You have completed the first two Ps (Pick & Prepare) of
          this CIO process.
        </AppText>
        <br />
        <AppText style={styles.text}>
          Now, knowing what you have put in place, you can focus on your
          content. You will receive a nudgification in advance of the times you
          have indicated for preparation, rehearsal and, of course, your CIO
          itself.
        </AppText>
        <Button
          text="Back to CIOs"
          color={colors.white}
          backgroundColor={colors.primary}
          onPress={() => {
            navigation.navigate('CRITICAL_IMPACT_OPPORTUNITY', {
              screen: 'CriticalImpactOpportunity_List',
            })
          }}
          style={{ fontWeight: 600, marginLeft: 0 }}
        />
      </View>
    </Screen>
  )
}

const styles = StyleSheet.create({
  mainContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    fontSize: 25,
    fontWeight: '600',
    marginVertical: 20,
  },
})

export default DoneScreen
