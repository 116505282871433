import React, { useEffect } from 'react'
import { Platform, View, Image } from 'react-native'
import { WebView } from 'react-native-webview'

import ActivityIndicator from '../components/ActivityIndicator'
import Button from '../components/Button'
import AppText from '../components/Text'
import welcomeQuery from '../api/query/welcome'

interface Props {
  onload: () => void
}

const WelcomeMessage: React.FC<Props> = ({ onload }) => {
  const { isLoading, isSuccess, isError, data, refetch } =
    welcomeQuery.getWelcomeMessage()

  useEffect(() => {
    if (!isSuccess) {
      return
    }

    if (onload) {
      onload()
    }
  }, [isSuccess])

  return (
    <View
      style={[{ padding: 20, textAlign: 'center', fontFamily: 'Open Sans' }]}
    >
      <ActivityIndicator visible={isLoading} />
      {isError && (
        <>
          <AppText>Couldn't retrieve the welcome message.</AppText>
          <Button title="Retry" onPress={refetch} />
        </>
      )}
      {isSuccess && !data.message && <AppText>No welcome message set.</AppText>}
      {isSuccess &&
        data.message &&
        (Platform.OS === 'web' ? (
          <div
            dangerouslySetInnerHTML={{
              __html: data.message,
            }}
          ></div>
        ) : (
          <WebView
            originWhitelist={['*']}
            source={{ html: data }}
            style={{ marginTop: 20 }}
          />
        ))}
    </View>
  )
}

export default WelcomeMessage
