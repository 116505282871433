export enum Sections {
  intro = 1,
  main = 2,
  pick = 3,
  prep = 4,
  perform = 5,
  pause = 6,
}

export const SectionOrder = {
  [Sections.intro]: 1,
  [Sections.main]: 2,
  [Sections.pick]: 3,
  [Sections.prep]: 4,
  [Sections.perform]: 5,
  [Sections.pause]: 6,
} as const
