import React from 'react'

import { View } from 'react-native'
import Screen from '../../../components/Screen'
import { defaultStyles } from '../../../config/styles'
import TitleGraphic from '../../../components/TitleGraphic'
import SharedButtons from './components/SharedButtons'

function ListScreen() {
  return (
    <Screen style={defaultStyles.screen}>
      <TitleGraphic
        section="Shared With Me"
        title="Shared By Others"
        graphic={require('/assets/lead-cio.jpg')}
      />
      <View style={defaultStyles.mainContainer}>
        <SharedButtons />
      </View>
    </Screen>
  )
}

export default ListScreen
