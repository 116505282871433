import React from 'react'
import { StyleSheet, View } from 'react-native'

import colors from '../../config/colors'
import AppText from '../Text'

const CircleLetter = ({ letter, size = 60, backgroundColor }) => {
  return (
    <View
      style={[
        styles.circle,
        { width: size, height: size, backgroundColor: backgroundColor },
      ]}
    >
      <AppText style={[styles.text, { fontSize: 28 }]}>{letter}</AppText>
    </View>
  )
}

const styles = StyleSheet.create({
  circle: {
    backgroundColor: colors.primary,
    borderRadius: 50,
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    color: colors.white,
    fontWeight: '700',
    fontFamily: 'Open Sans',
  },
})

export default CircleLetter
