import React from 'react'
import { ImageBackground, StyleSheet, View } from 'react-native'

import AppText from './Text'

function TitleGraphic({ title, offset = true, section = null, graphic }) {
  return (
    <ImageBackground
      style={[
        {
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          overflow: 'hidden',
        },
        offset
          ? {
              marginBottom: 20,
              marginTop: -20,
              marginLeft: -20,
              marginRight: -20,
            }
          : {},
      ]}
      source={graphic}
      resizeMode="cover"
    >
      <View style={[styles.child, { paddingVertical: 50 }]}>
        {section && (
          <AppText style={[styles.text, styles.section]}>{section}</AppText>
        )}
        <AppText style={[styles.text, styles.title]}>{title}</AppText>
      </View>
    </ImageBackground>
  )
}

const styles = StyleSheet.create({
  child: {
    flex: 1,
    backgroundColor: 'rgba(0,0,0,0.55)',
  },
  title: {
    fontSize: 25,
  },
  section: {
    fontSize: 16,
    paddingBottom: 10,
    textTransform: 'uppercase',
  },
  text: {
    fontWeight: '600',
    textAlign: 'center',
    fontFamily: '"Open Sans", sans-serif',
    color: 'white',
  },
})

export default TitleGraphic
