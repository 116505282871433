import React from 'react'
import ErrorBoundary from 'react-native-error-boundary'
import { Provider } from 'react-redux'

import OfflineNotice from './src/components/OfflineNotice'
import logger from './src/utility/logger'
import ErrorScreen from './src/screens/ErrorScreen'
import { post } from './src/utility/api'
import { GestureHandlerRootView } from 'react-native-gesture-handler'
import { store } from './src/redux/store'
import AppNavigation from './AppNavigation'

logger.start()

export default function App() {
  // https://reactnavigation.org/docs/navigation-container/#documenttitleenabled

  const errorHandler = (error: Error, stackTrace: string) => {
    /* Log the error to an error reporting service */
    console.log('Error Occurred', error, stackTrace)

    post('/error_reports', {
      errorMessage: JSON.stringify({
        name: error.name,
        message: error.message,
      }),
      stackTrace: JSON.stringify(stackTrace),
    })
  }

  return (
    <GestureHandlerRootView style={{ flex: 1 }}>
      <Provider store={store}>
        <OfflineNotice />
        <ErrorBoundary
          FallbackComponent={ErrorScreen}
          onError={errorHandler}
          key={location.pathname}
        >
          <AppNavigation />
        </ErrorBoundary>
      </Provider>
    </GestureHandlerRootView>
  )
}
