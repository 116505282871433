import { QueryFunctionContext, useQuery } from 'react-query'
import { get } from '../../utility/api'
import { IFaqItem } from 'types/FaqItem'
import { IFaqCollectionItem } from 'types/FaqCollectionItem'

const endpoint = '/faqs'

const getFaqs = () => {
  return useQuery<IFaqCollectionItem[]>(['faqs'], ({ signal }) =>
    get(endpoint, {
      signal,
    })
  )
}

const getFaq = (faqId: string) => {
  return useQuery<IFaqItem>(
    ['faq', { faqId: faqId }],
    ({ signal }: QueryFunctionContext) =>
      get(endpoint + '/' + faqId, { signal }),
    {
      retry: (failureCount: number, error: unknown) => {
        return failureCount <= 2
      },
    }
  )
}

export default {
  getFaqs,
  getFaq,
}
