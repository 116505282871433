import React from 'react'
import {
  GestureResponderEvent,
  Pressable,
  StyleSheet,
  View,
} from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'

import colors from '../config/colors'
import AppText from './Text'
import { Badge } from 'react-native-elements'

interface IButtonIcon {
  icon?: keyof typeof MaterialCommunityIcons.glyphMap | null
  text?: string
  onPress?: (event: GestureResponderEvent) => void
  visible?: boolean
  color?: string
  backgroundColor?: string
  buttonBackgroundColor?: string
  buttonBackgroundShape?: keyof typeof MaterialCommunityIcons.glyphMap
  borderColor?: string
  size?: number
  badgeValue?: number
}

// https://reactnativeelements.com/docs/components/badge
const ButtonIcon = ({
  icon = null,
  onPress,
  visible = true,
  badgeValue = 0,
  buttonBackgroundShape = 'circle',
  ...props
}: IButtonIcon) => {
  let colour = colors.black
  let backgroundColor = colors.white
  let buttonBackgroundColor = backgroundColor
  let borderColor = colors.white
  let size = 20

  if (props.color) {
    colour = props.color
  }

  if (props.backgroundColor) {
    backgroundColor = props.backgroundColor

    // default border colour to background color
    borderColor = props.backgroundColor
    buttonBackgroundColor = backgroundColor
  }

  // If button is bigger than the area, then we need to just colour the icon
  if (props.buttonBackgroundColor) {
    buttonBackgroundColor = props.buttonBackgroundColor
  }

  if (props.borderColor) {
    borderColor = props.borderColor
  }

  if (props.size) {
    size = props.size
  }

  const opacity = visible ? 1 : 0

  return (
    <View style={{ flexDirection: 'row', opacity: opacity, zIndex: 1000 }}>
      <Pressable
        disabled={!visible || !onPress}
        onPress={onPress}
        style={[
          styles.button,
          {
            backgroundColor: backgroundColor,
            flexDirection: 'row',
            borderColor: borderColor,
            borderWidth: 2,
            zIndex: 1001,
          },
        ]}
        hitSlop={5}
      >
        {badgeValue > 0 && (
          <Badge
            badgeStyle={styles.badge}
            textStyle={styles.badgeText}
            value={badgeValue}
            status="error"
            containerStyle={[styles.badgeContainer]}
          />
        )}

        {icon && (
          <View>
            <MaterialCommunityIcons
              name={buttonBackgroundShape}
              color={buttonBackgroundColor}
              size={size}
              style={{
                marginLeft: 5,
                marginRight: 10,
              }}
            />
            <MaterialCommunityIcons
              name={icon}
              color={colour}
              size={size}
              style={{
                marginLeft: 5,
                marginRight: 10,
                top: 0,
                left: 0,
                position: 'absolute',
              }}
            />
          </View>
        )}

        {props.text && (
          <AppText style={[styles.text, { color: colour, fontWeight: 600 }]}>
            {props.text}
          </AppText>
        )}
      </Pressable>
    </View>
  )
}

const styles = StyleSheet.create({
  button: {
    backgroundColor: colors.primary,
    borderRadius: 50,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 15,
    width: '100%',
    marginVertical: 10,
  },
  text: {
    color: colors.white,
    fontSize: 18,
    fontWeight: '600',
    fontFamily: 'Open Sans',
    textAlign: 'center',
  },
  badge: {
    borderRadius: 10,
    height: 20,
    paddingVertical: 10,
  },
  badgeContainer: {
    position: 'absolute',
    right: 0,
    top: -10,
  },
  badgeText: {
    fontSize: 14,
  },
})

export default ButtonIcon
