const store = (key: string, value) => {
  return
}

const get = (key: string) => {
  return null
}

export default {
  store,
  get,
}
