import React from 'react'
import { TouchableHighlight } from 'react-native'
import Swipeable from 'react-native-gesture-handler/Swipeable'

import PlainListItem from './PlainListItem'
import colors from '../../config/colors'

function ListItem({
  title,
  subTitle,
  image,
  IconComponent,
  onPress,
  renderRightActions,
}) {
  return (
    <Swipeable
      renderRightActions={renderRightActions}
      useNativeAnimations={false}
    >
      <TouchableHighlight underlayColor={colors.light} onPress={onPress}>
        <PlainListItem
          title={title}
          subTitle={subTitle}
          image={image}
          IconComponent={IconComponent}
          showChevron={true}
        />
      </TouchableHighlight>
    </Swipeable>
  )
}

export default ListItem
