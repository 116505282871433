import { ApiResponse } from 'apisauce'
import client from './client'

export interface IRenewToken {
  token: string
  refreshToken: string
}

interface IRenewResponse {
  ok: boolean
  data: IRenewToken
}

const login = <T, U = null>(
  email: string,
  password: string
): Promise<ApiResponse<T, U>> =>
  client.post('/authentication_token', { email, password, renew: false })

const register = <T, U = null>(email: string): Promise<ApiResponse<T, U>> =>
  client.post('/register', { email, renew: false })

const renew = <T, U = null>(renewToken): Promise<ApiResponse<T, U>> =>
  client.post('/refresh_token', { refreshToken: renewToken, renew: false })

const publicKey = <T, U = null>(): Promise<ApiResponse<T, U>> =>
  client.get('/register/key', { renew: false })

export default {
  login,
  register,
  publicKey,
  renew,
}
