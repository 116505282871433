import React from 'react'
import { Formik } from 'formik'

const AppForm = ({
  initialValues,
  onSubmit,
  validationSchema,
  innerRef = null,
  enableReinitialize = false,
  children,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      innerRef={innerRef}
      enableReinitialize={enableReinitialize}
    >
      {() => <>{children}</>}
    </Formik>
  )
}

export default AppForm
