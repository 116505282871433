import React from 'react'
import { Platform, StyleSheet, View } from 'react-native'

import Title from '../../../components/Title'
import colors from '../../../config/colors'
import { defaultStyles } from '../../../config/styles'
import Screen from '../../../components/Screen'
import Button from '../../../components/ButtonIcon'
import faqQuery from '../../../api/query/faq'
import AppText from '../../../components/Text'
import AppButton from '../../../components/Button'
import ActivityIndicator from '../../../components/ActivityIndicator'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { AppStackParamList } from '../../../navigation/AppNavigator'
import { useNavigation } from '@react-navigation/native'

function FaqViewScreen({ route }) {
  const navigation = useNavigation<DrawerNavigationProp<AppStackParamList>>()
  const faqId = route.params.faqId
  const { isLoading, isSuccess, isError, data, refetch } = faqQuery.getFaq(
    route.params.faqId
  )

  return (
    <Screen style={defaultStyles.screen}>
      <ActivityIndicator visible={isLoading} />
      {isError && (
        <>
          <AppText>Couldn't retrieve the FAQ.</AppText>
          <AppButton title="Retry" onPress={() => refetch()} />
        </>
      )}
      {isSuccess && (
        <>
          <Title style={{ fontSize: 25, color: 'black' }}>
            {data.question}
          </Title>
          <View style={[defaultStyles.mainContainer, styles.mainContainer]}>
            <View style={styles.answer}>
              {Platform.OS === 'web' ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.answer,
                  }}
                />
              ) : (
                <WebView
                  originWhitelist={['*']}
                  source={{ html: data.answer }}
                  style={{ marginTop: 20 }}
                />
              )}
            </View>
            <Button
              text="Back to dashboard"
              showIcon={false}
              color={colors.white}
              backgroundColor={colors.primary}
              onPress={() => {
                navigation.navigate('DASHBOARD', {
                  screen: 'Dashboard_Screen',
                })
              }}
              style={{ fontWeight: 600, marginLeft: 0 }}
            />
          </View>
        </>
      )}
    </Screen>
  )
}

const styles = StyleSheet.create({
  answer: {
    flex: 1,
    paddingBottom: 10,
  },
  mainContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    fontSize: 25,
    fontWeight: '600',
    marginVertical: 20,
  },
})

export default FaqViewScreen
