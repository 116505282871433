import authStorage from '../auth/storage'

import ProfileNavigator from '../navigation/ProfileNavigator'
import CriticalImpactOpportunityNavigator from '../navigation/CriticalImpactOpportunityNavigator'
import RapidReviewNavigator from '../navigation/RapidReviewNavigator'
import BossNavigator from '../navigation/BossNavigator'
import BoomNavigator from '../navigation/BoomNavigator'
import ReminderNavigator from '../navigation/ReminderNavigator'
import ResourceNavigator from '../navigation/ResourceNavigator'
import HelpNavigator from '../navigation/HelpNavigator'
import DashboardNavigator from '../navigation/DashboardNavigator'
import TrophyCabinetNavigator from '../navigation/TrophyCabinetNavigator'
import TeamNavigator from '../navigation/TeamNavigator'
import SharedWithMeNavigator from '../navigation/SharedWithMeNavigator'
import { FEATURE } from './features'

export interface IRoute {
  name: string
  label: string
  groupName: string | null
  activeTintColor?: string
  routeStack: () => JSX.Element
  routeName?: string
  visible: boolean
  enabled: boolean
}

export const getRoutes = (userFeatures: string[]): IRoute[] => {
  const routes: IRoute[] = []

  if (!userFeatures) {
    return routes
  }

  let enableCio = userFeatures.includes(FEATURE.CIO)
  let enableRapidReview = userFeatures.includes(FEATURE.RAPID_REVIEW)
  let enableBoss = userFeatures.includes(FEATURE.BOSS)
  let enableTrophyCabinet = userFeatures.includes(FEATURE.TROPHY)
  let enableBoom =
    enableBoss || enableTrophyCabinet || userFeatures.includes(FEATURE.BOOM)

  let enableReminders = userFeatures.includes(FEATURE.REMINDER)
  let enableResources = userFeatures.includes(FEATURE.RESOURCE)

  routes.push({
    name: 'DASHBOARD',
    label: 'Dashboard',
    groupName: null,
    activeTintColor: '#FF6F00',
    routeStack: DashboardNavigator,
    routeName: 'Dashboard_Screen',
    visible: true,
    enabled: true,
  })

  routes.push({
    name: 'CRITICAL_IMPACT_OPPORTUNITY',
    label: 'Critical Impact Opportunities',
    groupName: null,
    activeTintColor: '#FF6F00',
    routeStack: CriticalImpactOpportunityNavigator,
    routeName: 'CriticalImpactOpportunity_Intro',
    visible: true,
    enabled: enableCio,
  })

  routes.push({
    name: 'RAPID_REVIEW',
    label: 'Rapid Reviews',
    groupName: null,
    activeTintColor: '#FF6F00',
    routeStack: RapidReviewNavigator,
    routeName: 'RapidReview_Hub',
    visible: true,
    enabled: enableRapidReview,
  })

  routes.push({
    name: 'SHARED_WITH_ME',
    label: 'Shared With Me',
    groupName: null,
    activeTintColor: '#FF6F00',
    routeStack: SharedWithMeNavigator,
    routeName: 'SharedWithMe_List',
    visible: true,
    enabled: enableCio || enableRapidReview,
  })

  routes.push({
    name: 'BOOM',
    label: 'Best Out Of Me',
    groupName: null,
    activeTintColor: '#FF6F00',
    routeStack: BoomNavigator,
    routeName: 'Boom_Intro',
    visible: true,
    enabled: enableBoom,
  })

  routes.push({
    name: 'REMINDER',
    label: 'Nudges',
    groupName: null,
    activeTintColor: '#FF6F00',
    routeStack: ReminderNavigator,
    visible: true,
    enabled: enableReminders,
  })

  routes.push({
    name: 'PROFILE',
    label: 'My Profile',
    groupName: '',
    activeTintColor: '#FF6F00',
    routeStack: ProfileNavigator,
    routeName: 'Profile',
    visible: true,
    enabled: true,
  })

  routes.push({
    name: 'RESOURCE',
    label: 'HPP Refreshers',
    groupName: '',
    activeTintColor: '#FF6F00',
    routeStack: ResourceNavigator,
    routeName: 'Resource_List',
    visible: true,
    enabled: enableResources,
  })

  routes.push({
    name: 'HELP',
    label: 'Help',
    groupName: '',
    activeTintColor: '#FF6F00',
    routeStack: HelpNavigator,
    visible: true,
    enabled: true,
  })

  // Hidden from Sidebar

  routes.push({
    name: 'BOSS',
    label: 'My BOSS Practice',
    groupName: '',
    routeStack: BossNavigator,
    routeName: 'Boss_List',
    visible: false,
    enabled: enableBoss,
  })

  routes.push({
    name: 'TROPHY_CABINET',
    label: 'My Trophy Cabinet',
    groupName: '',
    activeTintColor: '#FF6F00',
    routeStack: TrophyCabinetNavigator,
    routeName: 'Trophy_List',
    visible: false,
    enabled: enableTrophyCabinet,
  })

  routes.push({
    name: 'TEAM',
    label: 'My Teams',
    groupName: '',
    activeTintColor: '#FF6F00',
    routeStack: TeamNavigator,
    routeName: 'Team_List',
    visible: false,
    enabled: true,
  })

  return routes
}
