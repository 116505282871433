import React from 'react'
import { StyleSheet, View } from 'react-native'

import AppText from '../Text'
import colors from '../../config/colors'

function MeetingCode({ code }) {
  return (
    <View style={styles.panel}>
      <AppText style={styles.code}>{code}</AppText>
    </View>
  )
}

const styles = StyleSheet.create({
  code: {
    fontSize: 24,
    fontWeight: 'bold',
    letterSpacing: 10,
  },
  panel: {
    backgroundColor: colors.light,
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
})

export default MeetingCode
