import React from 'react'
import { useFormikContext } from 'formik'

import ErrorMessage from './ErrorMessage'
import { DimensionValue } from 'react-native'
import { Picker } from '../picker/Picker'

type ItemGroup = {
  id: number
  name: string
}

type Item = {
  label?: string
  value: string | number
  group?: ItemGroup
}

type ItemProp = {
  value: string | number
  label?: string
}

interface IAppFormPicker {
  items: Item[]
  name: string
  placeholder: string
  type: string
  width: DimensionValue
  group?: boolean
  onChange?: (value) => void
  showErrorMessage: boolean
}
function AppFormPicker({
  items,
  name,
  placeholder,
  type,
  width,
  group = false,
  onChange,
  showErrorMessage = true,
}: IAppFormPicker) {
  const { errors, setFieldValue, touched, values } = useFormikContext()
  const userGroups: ItemGroup[] = []

  if (group) {
    items.map((item: Item) => {
      if (
        item.group !== undefined &&
        userGroups.findIndex((userGroup: ItemGroup) => {
          return item.group && userGroup.id === item.group.id
        }) === -1
      ) {
        userGroups.push(item.group)
      }
    })
  }

  const isArray = name.indexOf('.') !== -1
  let actualName = ''
  let position

  // console.log(name, isArray, name.split("."));
  if (isArray) {
    ;[actualName, position] = name.split('.')
  }

  const actualValue =
    typeof position !== 'undefined'
      ? values[actualName][position]
      : values[name]

  return (
    <>
      <Picker
        onValueChange={(item) => {
          let valueChangedTo

          if (type === 'contact') {
            const found = items.find((element) => element.value === item)

            if (found) {
              actualValue.label = found.label
              actualValue.value = found.value
            } else {
              actualValue.label = ''
              actualValue.value = ''
            }

            valueChangedTo = actualValue
          } else if (type === 'int') {
            valueChangedTo = parseInt(item)
          } else {
            valueChangedTo = parseInt(item)
          }

          setFieldValue(name, valueChangedTo)

          if (onChange) {
            onChange(valueChangedTo)
          }
        }}
        placeholder={placeholder}
        selectedValue={
          type === 'contact' ? actualValue.value : values[name] ?? ''
        }
        width={width}
        style={{
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: '#6e6969',
          padding: 10,
          fontSize: 16,
          fontFamily: 'Open Sans',
          borderRadius: 10,
          marginTop: 10,
        }}
      >
        {placeholder && (
          <Picker.Item label={placeholder} value={type === 'int' ? 0 : ''} />
        )}
        {!group &&
          items.map((item: Item) => {
            return (
              <Picker.Item
                key={item.value}
                label={String(item.label ?? item.value)}
                value={item.value}
              />
            )
          })}

        {group &&
          userGroups.map((userGroup) => {
            const groupItems: JSX.Element[] = []

            items.map((item: Item) => {
              if (item.group?.id !== userGroup?.id) {
                return
              }

              groupItems.push(
                <Picker.Item
                  key={item.value}
                  label={String(item.label ?? item.value)}
                  value={item.value}
                />
              )
            })

            return (
              <optgroup key={userGroup?.id} label={userGroup.name}>
                {groupItems}
              </optgroup>
            )
          })}
      </Picker>
      {showErrorMessage && (
        <ErrorMessage error={errors[name]} visible={touched[name]} />
      )}
    </>
  )
}

export default AppFormPicker
