import React, { useEffect } from 'react'
import StartupModal from './StartupModal'
import appStateQuery from '../../../api/query/appState'
import { useMutation, useQueryClient } from 'react-query'
import { STATE_KEY } from '../../../config/constants'
import { QUERY_KEYS } from '../../../config/queryKeys'
import CIWelcomeScreen from '../../../screens/app/Startup/CIWelcomeScreen'

interface IWelcomeMessageModal {
  onFinish: () => void
}

const CiWelcomeMessageModal: React.FC<IWelcomeMessageModal> = ({
  onFinish,
}) => {
  const queryClient = useQueryClient()
  const welcomeMessageState = appStateQuery.getAppState(
    STATE_KEY.hasSeenCiWelcomeMessage
  )

  const stateMutation = useMutation(appStateQuery.postAppState, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS.appState.all)
      queryClient.invalidateQueries([
        QUERY_KEYS.appState.item(STATE_KEY.hasSeenCiWelcomeMessage),
      ])
      onFinish()
    },
    onError: (error) => {
      alert('Failed to update state')
      console.log(error)
      onFinish()
    },
  })

  function changeState(stateValue: boolean) {
    stateMutation.mutate({
      stateKey: STATE_KEY.hasSeenCiWelcomeMessage,
      stateValue: stateValue,
    })
  }

  useEffect(() => {
    if (welcomeMessageState.data?.stateValue) {
      onFinish()
    }
  }, [welcomeMessageState.data])

  if (welcomeMessageState.isLoading || !welcomeMessageState.isSuccess) {
    return null
  }

  return (
    <StartupModal
      modalVisible={welcomeMessageState.data.stateValue !== true}
      setModalVisible={changeState}
    >
      <CIWelcomeScreen
        onClose={() => {
          changeState(true)
        }}
      />
    </StartupModal>
  )
}

export default CiWelcomeMessageModal
