import React from 'react'
import { useFormikContext } from 'formik'
import { useFocusEffect } from '@react-navigation/native'

import TextInput from '../TextInput'
import ErrorMessage from './ErrorMessage'

function AppFormField({
  name,
  width = '100%',
  submitOnEnter = false,
  defaultValue = '',
  ignoreBlur = false,
  ...otherProps
}) {
  const {
    handleSubmit,
    setFieldTouched,
    setFieldValue,
    errors,
    touched,
    values,
  } = useFormikContext()

  let actualName = name
  let position = undefined
  let value = values[name]

  if (defaultValue && !value) {
    value = defaultValue
  }

  useFocusEffect(
    React.useCallback(() => {
      if (defaultValue && !value) {
        setFieldValue(name, defaultValue)
        setFieldTouched(name)
      }
    }, [value, defaultValue])
  )

  const isArray = name.indexOf('.') !== -1

  if (isArray) {
    const nameParts = name.split('.')
    actualName = nameParts[0]
    position = nameParts[1]

    value = values[actualName][position]
  }

  const actualValue = value

  if (typeof value === 'object') {
    value = value.label
  }

  let error = errors[actualName]
  let visible = touched[actualName]

  if (isArray && typeof position !== 'undefined') {
    error = errors[actualName]?.[position]
    visible = touched[actualName]?.[position]

    if (
      !error ||
      typeof error === 'undefined' ||
      !visible ||
      typeof visible === 'undefined'
    ) {
      error = ''
      visible = false
    }
  }

  if (submitOnEnter) {
    otherProps.onKeyPress = ({ key }) => {
      if (key === 'Enter') {
        handleSubmit()
      }
    }
  }

  return (
    <>
      <TextInput
        onBlur={() => !ignoreBlur && setFieldTouched(name)}
        onChangeText={(text) => {
          if (typeof actualValue === 'object') {
            actualValue.label = text
            actualValue.value = text

            setFieldValue(name, actualValue)
          } else {
            setFieldValue(name, text)
          }
        }}
        value={value || ''}
        width={width}
        style={{
          outlineStyle: 'none',
          fontSize: 16,
          fontFamily: 'Open Sans',
          width: '100%',
        }}
        {...otherProps}
      />
      <ErrorMessage error={error} visible={visible} />
    </>
  )
}

export default AppFormField
