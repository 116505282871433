import React from 'react'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
// import { BarCodeScanningResult, Camera } from 'expo-camera'
import { BarCodeScanner } from 'expo-barcode-scanner'

class CameraScreen extends React.Component {
  camera = null

  _focusListener = null
  _blurListener = null

  state = {
    hasCameraPermission: null,
    isFocused: true,
    cameraType: Camera.Constants.Type.front,
    cameras: 2,
  }

  async componentDidMount() {
    const { status } = await Camera.requestPermissionsAsync()
    const hasCameraPermission = status === 'granted'

    this._focusListener = this.props.navigation.addListener('focus', () => {
      this.setState({ isFocused: true })
    })

    this._blurListener = this.props.navigation.addListener('blur', () => {
      this.setState({ isFocused: false })
    })

    this.setState({ hasCameraPermission })

    const cameraTypes = await Camera.getAvailableCameraTypesAsync()
    this.setState({ cameras: cameraTypes.length })
    console.log(cameraTypes)
    if (cameraTypes.length === 1) {
      this.setState({
        cameraType:
          cameraTypes[0] === Camera.Constants.Type.back
            ? Camera.Constants.Type.back
            : Camera.Constants.Type.front,
      })
    }
  }

  componentWillUnmount() {
    if (this._focusListener) {
      this._focusListener()
      this._focusListener = null
    }

    if (this._blurListener) {
      this._blurListener()
      this._blurListener = null
    }
  }

  render() {
    const { hasCameraPermission, isFocused } = this.state

    if (hasCameraPermission === null) {
      return <View />
    } else if (hasCameraPermission === false) {
      return <Text>Access to camera has been denied.</Text>
    } else if (isFocused) {
      return (
        <View style={{ flex: 1 }}>
          <Camera
            style={{ flex: 1 }}
            type={this.state.cameraType}
            // barCodeScannerSettings={{
            //     barCodeTypes: [BarCodeScanner.Constants.BarCodeType.qr],
            // }}
            onBarCodeScanned={({ data, type }) => {
              console.log(type, data)

              if (type !== BarCodeScanner.Constants.BarCodeType.qr) {
                return
              }

              alert(data)
              // joinMeeting(meetingCode);
            }}
          >
            {this.state.cameras > 1 && (
              <View style={styles.buttonContainer}>
                <TouchableOpacity
                  style={styles.button}
                  onPress={() => {
                    this.setState({
                      cameraType:
                        this.state.cameraType === Camera.Constants.Type.back
                          ? Camera.Constants.Type.front
                          : Camera.Constants.Type.back,
                    })
                  }}
                >
                  <Text style={styles.text}> Flip </Text>
                </TouchableOpacity>
              </View>
            )}
          </Camera>
        </View>
      )
    }

    return null
  }
}

export default CameraScreen

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  camera: {
    flex: 1,
  },
  buttonContainer: {
    flex: 1,
    backgroundColor: 'transparent',
    flexDirection: 'row',
    margin: 20,
  },
  button: {
    flex: 0.1,
    alignSelf: 'flex-end',
    alignItems: 'center',
  },
  text: {
    fontSize: 18,
    color: 'white',
  },
})
