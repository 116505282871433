import React from 'react'
import { View } from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'

interface IIcon {
  name
  size?: number
  backgroundColor?: string
  iconColor?: string
}

function Icon({
  name,
  size = 40,
  backgroundColor = 'transparent',
  iconColor = '#fff',
}: IIcon) {
  return (
    <View
      style={{
        width: size,
        height: size,
        borderRadius: size / 2,
        backgroundColor: backgroundColor,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <MaterialCommunityIcons name={name} color={iconColor} size={size * 0.5} />
    </View>
  )
}

export default Icon
