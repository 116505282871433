import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { IWhiteLabel } from '../../auth/WhiteLabelContext'
import { RootState } from '../store'

export interface WhiteLabelState {
  whiteLabel: IWhiteLabel | null
  status: 'idle' | 'loading' | 'failed'
}

const initialState: WhiteLabelState = {
  whiteLabel: null,
  status: 'idle',
}

export const fetchWhiteLabel = createAsyncThunk(
  'whiteLabel/fetchWhiteLabel',
  async (domain: string) => {
    const response = await fetch(`//${window.location.host}/api/settings`)
    if (!response.ok) {
      throw new Error('Failed to fetch white label data')
    }
    const data: IWhiteLabel = await response.json()
    return data
  }
)

const whiteLabelSlice = createSlice({
  name: 'whiteLabel',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchWhiteLabel.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchWhiteLabel.fulfilled, (state, action) => {
        state.whiteLabel = action.payload
        state.status = 'idle'
      })
      .addCase(fetchWhiteLabel.rejected, (state) => {
        state.status = 'failed'
      })
  },
})

export const selectWhiteLabel = (state: RootState) => state.whiteLabel
export const whiteLabelReducer = whiteLabelSlice.reducer
