import React from 'react'
import { StyleSheet, View } from 'react-native'

import { defaultStyles } from '../../config/styles'
import colors from '../../config/colors'
import AppText from '../Text'
import CircleLetter from './CircleLetter'

const LetterRating = ({ letter, rating }) => {
  const backgroundColor = rating ? colors.primary : colors.medium

  return (
    <View style={styles.container}>
      <CircleLetter letter={letter} backgroundColor={backgroundColor} />
      <AppText style={[defaultStyles.text, styles.rating]}>{rating}</AppText>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  rating: {
    fontWeight: '700',
    fontFamily: 'Open Sans',
  },
})

export default LetterRating
