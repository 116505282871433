import React from 'react'
import { StyleSheet, View } from 'react-native'
import { format, parseISO } from 'date-fns'
import { useMutation, useQueryClient } from 'react-query'

import criticalImpactOpportunityQuery from '../../../api/query/criticalImpactOpportunity'
import Screen from '../../../components/Screen'
import AppText from '../../../components/Text'
import colors from '../../../config/colors'
import { defaultStyles } from '../../../config/styles'
import ButtonIcon from '../../../components/ButtonIcon'
import InfoButton from '../../../components/InfoButton'
import TitleGraphic from '../../../components/TitleGraphic'
import ActivityIndicator from '../../../components/ActivityIndicator'
import AppButton from '../../../components/Button'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { AppStackParamList } from '../../../navigation/AppNavigator'
import { useNavigation } from '@react-navigation/native'

function MenuScreen({ route }) {
  const navigation = useNavigation<DrawerNavigationProp<AppStackParamList>>()
  const owner =
    route?.name !== 'SharedWithMe_CriticalImpactOpportunity_Overview'
  const cioId: string = route.params?.criticalImpactOpportunityId
  const cioQuery =
    criticalImpactOpportunityQuery.getCriticalImpactOpportunity(cioId)
  const queryClient = useQueryClient()

  const mutation = useMutation(
    criticalImpactOpportunityQuery.archiveCriticalImpactOpportunity,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('cios')
        queryClient.invalidateQueries('cios_archived')

        navigation.navigate('CriticalImpactOpportunity_List')
      },
      onError: (error) => {
        alert('Failed to archive CIO')
        console.log(error)
      },
    }
  )

  const { isLoading, isSuccess, isError, data, refetch } = cioQuery

  return (
    <Screen style={defaultStyles.screen}>
      <TitleGraphic
        section="Critical Impact Opportunities"
        title="CIO"
        graphic={require('../../../assets/lead-cio.jpg')}
      />
      <View style={defaultStyles.mainContainer}>
        {isLoading && <ActivityIndicator visible={isLoading} />}
        {isError && (
          <>
            <AppText>Couldn't retrieve the CIO.</AppText>
            <AppButton title="Retry" onPress={() => refetch()} />
          </>
        )}
        {isSuccess && (
          <>
            <AppText style={[defaultStyles.text, styles.title]}>
              What is your CIO?
            </AppText>
            <View style={styles.card}>
              <AppText>{data.what}</AppText>
            </View>
            <AppText style={[defaultStyles.text, styles.title]}>When</AppText>
            <View style={[styles.card, { flexDirection: 'row' }]}>
              <AppText>
                {format(
                  typeof data.date === 'string'
                    ? parseISO(data.date)
                    : data.date,
                  'do MMMM yyyy'
                )}
              </AppText>
            </View>
            <AppText style={[defaultStyles.text, styles.title]}>Why</AppText>
            <View style={styles.card}>
              <AppText>{data.why}</AppText>
            </View>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'center',
                marginTop: 20,
                marginBottom: 20,
              }}
            >
              <InfoButton
                text="Export this CIO as a PDF"
                icon="download"
                size={18}
                onPress={async () => {
                  if (
                    null ===
                    (await criticalImpactOpportunityQuery.downloadPdf(data.id))
                  ) {
                    alert('Window failed to open.  Is the popup blocked?')
                  }
                }}
              />
            </View>
            <View>
              <hr style={{ width: '100%' }} />

              <View style={{ flexDirection: 'row' }}>
                <View style={{ flex: 1, marginRight: 5 }}>
                  <ButtonIcon
                    text="Back to CIOs"
                    icon="text-box-search-outline"
                    color={colors.primary}
                    backgroundColor={colors.white}
                    borderColor={colors.primary}
                    onPress={() => {
                      if (!owner) {
                        navigation.navigate('SHARED_WITH_ME', {
                          screen: 'SharedWithMe_CriticalImpactOpportunity_List',
                          params: {
                            criticalImpactOpportunityId: data.id,
                          },
                        })

                        return
                      }

                      navigation.navigate('CRITICAL_IMPACT_OPPORTUNITY', {
                        screen: 'CriticalImpactOpportunity_List',
                        params: {},
                      })
                    }}
                  />
                </View>
                <View style={{ flex: 1, marginLeft: 5 }}>
                  <ButtonIcon
                    text="View"
                    icon="text-box-outline"
                    color={colors.primary}
                    backgroundColor={colors.white}
                    borderColor={colors.primary}
                    onPress={() => {
                      if (!owner) {
                        navigation.navigate('SHARED_WITH_ME', {
                          screen: 'SharedWithMe_CriticalImpactOpportunity_View',
                          params: {
                            criticalImpactOpportunityId: data.id,
                          },
                        })

                        return
                      }

                      navigation.navigate('CRITICAL_IMPACT_OPPORTUNITY', {
                        screen: 'CriticalImpactOpportunity_View',
                        params: {
                          criticalImpactOpportunityId: data.id,
                        },
                      })
                    }}
                  />
                </View>
                {owner && data.statusString !== 'Archived' && (
                  <View style={{ flex: 1, marginLeft: 5 }}>
                    <ButtonIcon
                      text="Edit"
                      icon="text-box-plus-outline"
                      color={colors.white}
                      backgroundColor={colors.primary}
                      onPress={() => {
                        navigation.navigate('CRITICAL_IMPACT_OPPORTUNITY', {
                          screen: 'CriticalImpactOpportunity_Form',
                          params: {
                            criticalImpactOpportunityId: data.id,
                          },
                        })
                      }}
                    />
                  </View>
                )}
              </View>

              {owner && data.statusString !== 'Archived' && (
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'center',
                    marginTop: 20,
                  }}
                >
                  <InfoButton
                    text="Archive CIO"
                    size={18}
                    icon="archive-check"
                    onPress={() => {
                      mutation.mutate(data.id)
                    }}
                  />
                </View>
              )}
            </View>
          </>
        )}
      </View>
    </Screen>
  )
}

const styles = StyleSheet.create({
  card: {
    borderRadius: 10,
    backgroundColor: colors.light,
    marginBottom: 20,
    padding: 20,
  },
  title: {
    fontWeight: 'bold',
  },
})

export default MenuScreen
