import React, { useState } from 'react'
import { Image, StyleSheet, View } from 'react-native'
import { useMutation, useQueryClient } from 'react-query'
import * as Yup from 'yup'
import * as ImagePicker from 'expo-image-picker'

import { Form, FormField, SubmitButton } from '../forms'

import colors from '../../config/colors'
import ButtonIcon from '../ButtonIcon'
import AppText from '../Text'
import TextHighlight from '../TextHighlight'
import boomQuery from '../../api/query/boom'
import { convertUploadedFile } from '../../utility/upload'
import { cacheBustUrl } from '../../utility/url'
import { IBoom } from 'types/boom'

const validationSchema = Yup.object().shape({
  outsideWork: Yup.string().notRequired().label('Outside work'),
  personalAchievement: Yup.string()
    .notRequired()
    .label('Personal achievements'),
  whatMatters: Yup.string().notRequired().label('What matters'),
  superStrength: Yup.string().notRequired().label("What's your super strength"),
  driveMad: Yup.string().notRequired().label('Drives you mad'),
  helpMe1: Yup.string().notRequired().label('help me1'),
  helpMe2: Yup.string().notRequired().label('help me2'),
})

interface IBoomForm {
  boom: IBoom
  navigation: any
}
function BoomForm({ boom, navigation }: IBoomForm) {
  const queryClient = useQueryClient()
  const formikRef = React.createRef()

  const [initialValues, setInitialValues] = useState<IBoom>(boom)
  const [boomImage, setBoomImage] = useState<string | null>(
    initialValues.fullImagePath
  )
  console.log(boom, initialValues)
  const boomUpdateMutation = useMutation(boomQuery.updateBoom, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['boom', { boomId: data.id }])

      setInitialValues(data)
      updateBoomImage(boom.id)

      navigation.navigate('BOOM', {
        screen: 'Boom',
        params: {},
      })
    },
    onError: (error) => {
      alert('Failed to update your Boom')
      console.log(error)
    },
  })

  function updateBoomImage(boomId): boolean {
    if (boom.imageName === boomImage) {
      console.log('skipped update image', boom.imageName, boomImage)
      return false
    }

    convertUploadedFile(boomImage).then((boomImage) => {
      boomUpdateWithImageMutation.mutate({
        boom: {
          id: boomId,
          imageName: boomImage ?? '',
        } as IBoom,
        filename: 'uploaded_file',
      })
    })

    return true
  }

  const boomUpdateWithImageMutation = useMutation(boomQuery.sendBoomImage, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['boom', { boomId: data.id }])
    },
    onError: (error) => {
      if (error.response.status === 413) {
        alert('Failed to update the Boom image: Image is too large')
      } else {
        alert('Failed to update the Boom image')
      }
    },
  })

  const handleSubmit = async (newBoom) => {
    console.log('handleSubmit', boom)

    boomUpdateMutation.mutate({
      id: boom.id,
      ...newBoom,
    })
  }

  const pickImage = async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      // base64: true,
      quality: 1,
    })

    console.log(result)

    if (!result.canceled) {
      setBoomImage(result.assets[0]['uri'])
    }
  }

  return (
    <Form
      innerRef={formikRef}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      initialValues={initialValues}
    >
      <View style={styles.table}>
        <View style={[styles.row]}>
          <AppText style={styles.heading}>
            <TextHighlight>ONE</TextHighlight> thing I love doing outside of
            work and why:
          </AppText>
          <FormField
            name="outsideWork"
            placeholder="e.g., mountain-biking because it clears my head"
          />
        </View>
        <View style={[styles.row]}>
          <AppText style={styles.heading}>
            <TextHighlight>ONE</TextHighlight> personal achievement:
          </AppText>
          <AppText>
            (A photo of this might help others appreciate your achievement. Max
            image size is 1.5MB)
          </AppText>
          <FormField
            name="personalAchievement"
            placeholder="e.g., I did a charity walk the length of the UK"
          />

          <View style={[styles.upload]}>
            <ButtonIcon
              text="Choose a file to upload"
              icon="cloud-upload"
              onPress={pickImage}
              color={colors.primary}
              backgroundColor={colors.white}
              borderColor={colors.primary}
            />
          </View>
          {boomImage && (
            <Image
              source={{
                uri: cacheBustUrl(boomImage),
                headers: {
                  Pragma: 'no-cache',
                },
              }}
              style={{
                width: 200,
                height: 200,
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            />
          )}
          {boomImage && (
            <ButtonIcon
              text="Remove"
              icon="delete"
              onPress={() => {
                setBoomImage(null)
              }}
              color={colors.primary}
              backgroundColor={colors.white}
              borderColor={colors.primary}
            />
          )}
        </View>
        <View style={[styles.row]}>
          <AppText style={styles.heading}>
            <TextHighlight>ONE</TextHighlight> thing that really matters to me:
          </AppText>
          <FormField
            name="whatMatters"
            placeholder="e.g., people/places/causes"
          />
        </View>
        <View style={[styles.row]}>
          <AppText style={styles.heading}>
            <TextHighlight>ONE</TextHighlight> super-strength of mine:
          </AppText>
          <FormField
            name="superStrength"
            placeholder="e.g., attention to detail"
          />
        </View>
        <View style={[styles.row]}>
          <AppText style={styles.heading}>
            <TextHighlight>ONE</TextHighlight> thing drives me mad:
          </AppText>
          <FormField name="driveMad" placeholder="e.g., lack of clarity" />
        </View>
        <View style={[styles.row]}>
          <AppText style={styles.heading}>
            <TextHighlight>TWO</TextHighlight> ways that the team could help me
            do my best work:
          </AppText>
          <AppText>(Express them as requests)</AppText>
          <FormField
            name="helpMe1"
            placeholder="e.g., Please can you be clear about what you want from me?"
          />
          <FormField
            name="helpMe2"
            placeholder="e.g., Please can you cc me if you think I need to be aware?"
          />
        </View>

        <SubmitButton title={'Save'} />
      </View>
    </Form>
  )
}

const styles = StyleSheet.create({
  heading: {
    fontWeight: '700',
    minWidth: 150,
    marginBottom: 10,
  },
  icon: {
    fontWeight: '700',
    width: 40,
    height: 40,
    lineHeight: 40,
    borderRadius: 40,
    backgroundColor: colors.primary,
    color: '#fff',
    textAlign: 'center',
    marginRight: 10,
  },
  field: {},
  title: {
    fontWeight: '700',
    height: 40,
    lineHeight: 40,
  },
  table: {},
  row: {
    paddingVertical: 20,
  },
  rowBorder: {
    borderBottomColor: colors.light,
    borderBottomWidth: StyleSheet.hairlineWidth,
    paddingVertical: 20,
  },
  horizontalAlign: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  upload: {
    marginTop: 10,
  },
})

export default BoomForm
