import React from 'react'
import { Platform, Text } from 'react-native'
import * as Linking from 'expo-linking'

interface IAnchor {
  href?: string
  children
  newWindow?: boolean
  onPress?(): void
}

function Anchor({
  href,
  children,
  newWindow = false,
  onPress,
  ...props
}: IAnchor) {
  const _handlePress = () => {
    console.log(newWindow, Platform.OS)
    if (href && newWindow && Platform.OS === 'web') {
      console.log('new window')
      let myWindow = window.open(href, '_blank')
      if (myWindow) myWindow.opener = null
    } else if (href) {
      Linking.openURL(href)
    }
    onPress && onPress()
  }

  return (
    <Text {...props} onPress={_handlePress}>
      {children}
    </Text>
  )
}

export default Anchor
