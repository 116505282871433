import React from 'react'
import StartupModal from './StartupModal'
import appStateQuery from '../../../api/query/appState'
import { useMutation, useQueryClient } from 'react-query'
import NudgeView from '../../profile/NudgeView'
import Title from '../../Title'
import AppText from '../../Text'
import { QUERY_KEYS } from '../../../config/queryKeys'
import { STATE_KEY } from '../../../config/constants'

function NudgeMessageModal() {
  const queryClient = useQueryClient()
  const nudgeMessageState = appStateQuery.getAppState(
    STATE_KEY.hasSeenNudgeMessage
  )

  const stateMutation = useMutation(appStateQuery.postAppState, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS.appState.all)
      queryClient.invalidateQueries(
        QUERY_KEYS.appState.item(STATE_KEY.hasSeenNudgeMessage)
      )
    },
    onError: (error) => {
      alert('Failed update state')
      console.log(error)
    },
  })

  if (
    !nudgeMessageState.isSuccess ||
    nudgeMessageState.data.stateValue === true
  ) {
    return null
  }

  function changeState(stateValue: boolean) {
    stateMutation.mutate({
      stateKey: STATE_KEY.hasSeenNudgeMessage,
      stateValue: stateValue,
    })
  }

  return (
    <StartupModal modalVisible={true} setModalVisible={changeState}>
      <Title style={{ fontSize: 25, color: 'black' }}>Nudges</Title>
      <AppText>
        To help you build the most useful habits, it’s important that we can
        nudge you with a reminder every now and then. It could be a nudge to
        rate your SHED, to remind you of a time to practice or to complete a
        review. Either way, giving you nudges - or as we like to call them,
        nudgifications – is an essential part of making the most of
        HabitBuilder. With that in mind…
      </AppText>
      <hr style={{ width: '50%', marginTop: 40, marginBottom: 40 }} />
      <NudgeView
        onClose={() => {
          changeState(true)
        }}
      />
    </StartupModal>
  )
}

export default NudgeMessageModal
