import useAppSelector from './useAppSelector'
import {
  fetchWhiteLabel,
  selectWhiteLabel,
} from '../redux/slices/whiteLabelSlice'
import { useEffect } from 'react'
import useAppDispatch from './useAppDispatch'

const useWhiteLabel = () => {
  const dispatch = useAppDispatch()
  const whiteLabel = useAppSelector(selectWhiteLabel)
  const currentDomain = window.location.host

  useEffect(() => {
    dispatch(fetchWhiteLabel(currentDomain))
  }, [dispatch, currentDomain])

  return whiteLabel
}

export default useWhiteLabel
