import React from 'react'
import { View } from 'react-native'

import Screen from '../../../components/Screen'
import AppText from '../../../components/Text'
import colors from '../../../config/colors'
import { defaultStyles } from '../../../config/styles'
import ButtonIcon from '../../../components/ButtonIcon'
import InfoButton from '../../../components/InfoButton'
import TitleGraphic from '../../../components/TitleGraphic'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { AppStackParamList } from '../../../navigation/AppNavigator'
import { useNavigation } from '@react-navigation/native'

function IntroScreen() {
  const navigation = useNavigation<DrawerNavigationProp<AppStackParamList>>()

  return (
    <Screen style={defaultStyles.screen}>
      <TitleGraphic
        section="Critical Impact Opportunities"
        title="Your CIOs"
        graphic={require('../../../assets/lead-cio.jpg')}
      />
      <View style={defaultStyles.mainContainer}>
        <AppText>
          <b>Your CIOs – focusing your energy on what matters most.</b>
          <br />
          <br />
          Being able to perform at our best - giving 100% - all day is
          impossible.
          <br />
          <br />
          Identifying the interactions that matters most – your Critical Impact
          Opportunities – will enable you to have the impact you want when it
          matters.
          <br />
          <br />
          This practice supports you to prepare for and perform in upcoming CIOs
          so you and your team have the best possible chance of having the best
          possible impact.
          <br />
        </AppText>

        <View style={{ flexDirection: 'row' }}>
          <View style={{ flex: 1 }}>
            <ButtonIcon
              text="Your Current CIOs"
              icon="text-box-search-outline"
              color={colors.primary}
              backgroundColor={colors.white}
              borderColor={colors.primary}
              onPress={() => {
                navigation.navigate('CRITICAL_IMPACT_OPPORTUNITY', {
                  screen: 'CriticalImpactOpportunity_List',
                  params: {},
                })
              }}
            />
          </View>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <View style={{ flex: 1 }}>
            <ButtonIcon
              text="Create a New CIO"
              icon="text-box-plus-outline"
              color={colors.white}
              backgroundColor={colors.primary}
              onPress={() => {
                navigation.navigate('CRITICAL_IMPACT_OPPORTUNITY', {
                  screen: 'CriticalImpactOpportunity_AddForm',
                  params: {},
                })
              }}
            />
          </View>
        </View>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            marginTop: 20,
          }}
        >
          <InfoButton
            text="Learn more about CIOs"
            onPress={() => {
              navigation.navigate('RESOURCE', {
                screen: 'Resource_View',
                params: {
                  slug: 'critical-impact-opportunities-cios',
                },
              })
            }}
          />
        </View>
      </View>
    </Screen>
  )
}

export default IntroScreen
