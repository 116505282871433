import React from 'react'
import { StyleSheet, View } from 'react-native'

import shedQuery from '../../api/query/shed'
import { defaultStyles } from '../../config/styles'
import HighProgress from './HighProgress'
import LowProgress from './LowProgress'

const ShedActions = () => {
  const shedApi = shedQuery.ShedRequireAction()

  if (!shedApi.isSuccess || !shedApi.data.rating) {
    return null
  }

  return (
    <View style={[defaultStyles.lightBox, styles.container]}>
      {shedApi.data.rating === 'high' ? (
        <HighProgress action={shedApi.data} />
      ) : (
        <LowProgress action={shedApi.data} />
      )}
    </View>
  )
}

export default ShedActions

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20,
    paddingTop: 20,
    paddingBottom: 5,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  icon: {
    marginTop: 0,
  },
  text: {
    marginLeft: 10,
  },
})
