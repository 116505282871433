import React from 'react'
import {
  ImageBackground,
  ScrollView,
  StyleSheet,
  View,
  Image,
} from 'react-native'

import AppText from '../components/Text'
import Button from '../components/Button'

interface IErrorScreen {
  error: Error
}

const ErrorScreen: React.FC<IErrorScreen> = ({ error }) => {
  return (
    <ScrollView style={styles.scrollView}>
      <ImageBackground style={styles.background}>
        <View style={styles.logoContainer}>
          <Image style={styles.logo} source={require('../assets/logo.png')} />
          <AppText>Something went wrong.</AppText>
          <AppText>{error.toString()}</AppText>
        </View>

        <View style={styles.buttonsContainer}>
          <Button title="Restart App" onPress={() => location.reload()} />
        </View>
      </ImageBackground>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  background: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    minHeight: 640,
  },
  buttonsContainer: {
    padding: 20,
    width: '100%',
    maxWidth: 360,
  },
  logo: {
    width: 300,
    height: 100,
  },
  scrollView: {
    flex: 1,
  },
  splash: {
    width: 300,
    height: 426,
  },
  habit: {
    width: 300,
    height: 50,
  },
  logoContainer: {
    position: 'absolute',
    top: 40,
    alignItems: 'center',
  },
  splashContainer: {
    position: 'absolute',
    top: '50%',
    marginTop: -223,
    alignItems: 'center',
  },
})

export default ErrorScreen
