import { useQuery } from 'react-query'

import { get, post, patch, remove } from '../../utility/api'
export const endpoint = '/teams'

const getTeams = (userId) => {
  return useQuery('teams', ({ signal }) =>
    get('/users/' + userId + endpoint + '?order[name]=asc', {
      signal,
    })
  )
}

const getTeam = (teamId: string | null) => {
  if (teamId === null) {
    return {
      isLoading: false,
      isSuccess: true,
      isError: false,
      data: {
        id: null,
        name: '',
        users: [],
        emails: [],
      },
      refetch: () => {},
    }
  }

  return useQuery(['team', { teamId: teamId }], ({ signal }) =>
    get(`${endpoint}/${teamId}`, {
      signal,
    })
  )
}

const deleteTeam = (id) => {
  return remove(endpoint + '/' + id)
}

export const addTeam = (team) => {
  team.users = team.users.map((member) => {
    return '/users/' + member.id
  })

  return post(endpoint, team)
}

export const updateTeam = (team) => {
  team.users = team.users.map((member) => {
    return '/users/' + member.id
  })

  return patch(endpoint + '/' + team.id, team, {
    headers: { 'content-type': 'application/merge-patch+json' },
  })
}

export default {
  addTeam,
  updateTeam,
  deleteTeam,
  getTeams,
  getTeam,
}
