import React from 'react'
import { useFormikContext } from 'formik'

import Button from '../Button'

function SubmitButton(props) {
  const { handleSubmit } = useFormikContext()

  if (props.loading) {
    props = {
      ...props,
      title: 'processing ...',
      backgroundColor: 'secondary',
    }
  }

  return <Button {...props} onPress={handleSubmit} />
}

export default SubmitButton
