import React from 'react'
import { Text, TouchableOpacity, View } from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'

import { defaultConfig } from '../config/styles'

interface IIconTextButton {
  text: string
  size?: number
  showIcon: boolean
  icon
  color: string
  onPress: any
  style?: object
}

function IconTextButton({
  text,
  size = 15,
  showIcon,
  icon,
  color,
  onPress = {},
  style = {},
}: IIconTextButton) {
  return (
    <View style={{ flex: 1, flexDirection: 'row' }}>
      <TouchableOpacity
        onPress={onPress}
        style={[style, { flexDirection: 'row' }]}
      >
        {showIcon && (
          <MaterialCommunityIcons
            name={icon}
            color={defaultConfig.colors.secondary}
            size={size + 3}
            style={{
              marginRight: 5,
              color: color,
            }}
          />
        )}
        <Text
          style={{
            fontSize: size,
            color: color,
            fontFamily: 'Open Sans',
            fontWeight: '600',
            marginBottom: 0,
          }}
        >
          {text}
        </Text>
      </TouchableOpacity>
    </View>
  )
}

export default IconTextButton
