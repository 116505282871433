import React, { useEffect } from 'react'
import { View } from 'react-native'

import ActivityIndicator from '../../../components/ActivityIndicator'
import Screen from '../../../components/Screen'
import Button from '../../../components/Button'
import AppText from '../../../components/Text'
import { defaultStyles } from '../../../config/styles'
import TitleGraphic from '../../../components/TitleGraphic'
import sharedWithMeQuery from '../../../api/query/sharedWithMe'
import RrList from '../../../components/shared-with-me/RrList'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { AppStackParamList } from '../../../navigation/AppNavigator'
import { useNavigation } from '@react-navigation/native'
import appStateQuery from '../../../api/query/appState'
import { useMutation, useQueryClient } from 'react-query'
import { QUERY_KEYS } from '../../../config/queryKeys'
import { STATE_KEY } from '../../../config/constants'

function RrScreen() {
  const queryClient = useQueryClient()
  const navigation = useNavigation<DrawerNavigationProp<AppStackParamList>>()
  const rapidReviewApi = sharedWithMeQuery.getRapidReviews()

  const stateMutation = useMutation(appStateQuery.postAppState, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS.appState.all)
      queryClient.invalidateQueries(
        QUERY_KEYS.appState.item(STATE_KEY.lastSeenSharedRapidReview)
      )
    },
    onError: (error) => {
      alert('Failed update state')
      console.error(error)
    },
  })

  useEffect(() => {
    console.log('updating ')
    stateMutation.mutate({
      stateKey: STATE_KEY.lastSeenSharedRapidReview,
      stateValue: new Date().toISOString(),
    })
  }, [])

  return (
    <Screen style={defaultStyles.screen}>
      <TitleGraphic
        section="Shared With Me"
        title="Shared Rapid Reviews"
        graphic={require('../../../assets/lead-cio.jpg')}
      />

      <View style={defaultStyles.mainContainer}>
        <ActivityIndicator visible={rapidReviewApi.isLoading} />
        {rapidReviewApi.isError && (
          <>
            <AppText>Couldn't retrieve the shared Rapid Reviews.</AppText>
            <Button title="Retry" onPress={() => rapidReviewApi.refetch()} />
          </>
        )}
        {rapidReviewApi.isSuccess && (
          <>
            <RrList
              navigation={navigation}
              title="Shared Rapid Reviews"
              data={rapidReviewApi.data}
            />
          </>
        )}
      </View>
    </Screen>
  )
}

export default RrScreen
