import Title from '../../../../../components/Title'
import { useSession } from '../../../../../hooks/useSession'
import { useEffect } from 'react'
import { IWidget } from './IWidget'

export const WelcomeWidgetName = 'welcome'

export const WelcomeWidget: React.FC<IWidget> = ({ onLoaded }) => {
  useEffect(() => {
    onLoaded(WelcomeWidgetName)
  }, [onLoaded])

  const { user } = useSession()
  const hour = new Date().getHours()
  const welcomeMessage =
    'Good ' +
    ((hour < 12 && 'morning') || (hour < 18 && 'afternoon') || 'evening')

  return (
    <Title style={{ fontSize: 25, color: 'black' }}>
      {welcomeMessage}, {user?.name.split(' ')[0]}
    </Title>
  )
}
