import { ApiErrorResponse, ApiOkResponse, ApiResponse, create } from 'apisauce'
import cache from '../utility/cache.web'
import authStorage from '../auth/storage'
import settings from '../config/settings'
import { AxiosRequestConfig } from 'axios'

const apiClient = create({
  baseURL: settings.apiUrl,
})

const securityCheckUrl = (url) => {
  const securityCheck = new RegExp('^/(register|authentication|refresh)', 'i')

  return url.match(securityCheck)
}

apiClient.addAsyncRequestTransform(async (request) => {
  if (securityCheckUrl(request.url)) {
    return
  }

  const authToken = await authStorage.getToken()
  // request.params.renew && request.params.renew === false
  //   ? await authStorage.getToken()
  //   : await authStorage.checkAndGetToken();

  if (!authToken) return

  //   console.log(
  //     "Bearer " + authToken[authStorage.responseToken],
  //     authToken,
  //     authStorage.responseToken
  //   );
  request.headers['Authorization'] = authToken
    ? 'Bearer ' + authToken[authStorage.responseToken]
    : null
})

const get = apiClient.get
apiClient.get = async <T, U = T>(
  url: string,
  params?: {},
  axiosConfig?: AxiosRequestConfig
): Promise<ApiResponse<T, U>> => {
  if (!securityCheckUrl(url)) {
    await authStorage.checkAndGetToken()
  }

  const response = await get<T, U>(url, params, axiosConfig)

  if (response.ok) {
    cache.store(url, response.data)
    return response
  }

  const data = await cache.get(url)

  if (!data) {
    return {} as ApiErrorResponse<U>
  }

  return {
    ok: true,
    data,
    problem: null,
    originalError: null,
  } as ApiOkResponse<T>
}

export default apiClient
