import React from 'react'
import { FlatList, StyleSheet } from 'react-native'

import AppText from '../Text'
import RapidReviewButton from './RapidReviewButton'

function RrList({ title, navigation, data }) {
  return (
    <>
      <AppText style={styles.text}>{title}</AppText>
      <FlatList
        data={data}
        keyExtractor={(rapidReview) => {
          return 'rrlist_' + rapidReview.id.toString()
        }}
        renderItem={({ item }) => (
          <RapidReviewButton
            rapidReview={item}
            id={item.id}
            onPress={() => {
              navigation.navigate('SharedWithMe_RapidReview_View', {
                rapidReviewId: item.id,
              })
            }}
          />
        )}
        ListEmptyComponent={
          <AppText>There are currently no Rapid Reviews</AppText>
        }
      />
    </>
  )
}

const styles = StyleSheet.create({
  text: {
    fontSize: 25,
    fontWeight: '600',
    marginVertical: 20,
  },
})

export default RrList
