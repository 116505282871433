import React, { useEffect, useState, useRef } from 'react'
import {
  View,
  Text,
  Modal,
  FlatList,
  StyleSheet,
  Pressable,
} from 'react-native'
import { Ionicons } from '@expo/vector-icons'
import colors from '../../config/colors'
import AppText from '../Text'

type OptGroupProps = {
  label: string
  children: React.ReactNode
}

type PickerItemProps = {
  label: string
  value: string | number | null
}

type PickerProps = {
  selectedValue: string | number | undefined
  onValueChange?: (value: string | number) => void
  children: React.ReactNode | React.ReactElement<OptGroupProps>[]
  placeholder?: string
  onBlur?: () => void
  onTouchEnd?: () => void
}

type GroupedItem = {
  groupName: string | null
  items: Item[]
}

type ItemGroup = {
  id: string
  name: string
}

type Item = {
  label?: string
  value: string | number
  group?: ItemGroup
}

type PickerPosition = {
  x: number
  y: number
  width: number
  height: number
} | null

export const Picker: React.FC<PickerProps> & {
  Item: React.FC<PickerItemProps>
  OptGroup: React.FC<OptGroupProps>
} = ({
  selectedValue,
  onValueChange,
  children,
  placeholder,
  onBlur,
  onTouchEnd,
}) => {
  const [modalHeight, setModalHeight] = useState(0)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [selectedLabel, setSelectedLabel] = useState<string>(
    placeholder ?? 'Select...'
  )
  const [pickerPosition, setPickerPosition] = useState<PickerPosition>(null)
  const [hoveredItem, setHoveredItem] = useState<string | number | null>(null)
  const pickerRef = useRef(null)

  useEffect(() => {
    const handleScroll = () => {
      if (isModalVisible) {
        pickerRef.current?.measure((x, y, width, height, pageX, pageY) => {
          let newPageY = pageY
          if (pageY > window.innerHeight / 2) {
            newPageY = pageY - (modalHeight + height)
          }
          setPickerPosition({ x: pageX, y: newPageY, width, height })
        })
      }
    }

    handleScroll()

    if (isModalVisible) {
      window.addEventListener('scroll', handleScroll)
    }

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [isModalVisible, modalHeight])

  Picker.OptGroup.displayName = 'optgroup'
  let selectedItem: Item | PickerItemProps | null = null
  const groups: GroupedItem[] = React.Children.toArray(children).reduce<
    GroupedItem[]
  >((acc, child) => {
    if (React.isValidElement(child) && child.type === 'optgroup') {
      console.log('GROUP', child)
      const groupItems: Item[] = React.Children.toArray(child.props.children)
        .filter((subChild) => React.isValidElement<PickerItemProps>(subChild))
        .map((subChild) => {
          if (subChild.props.value === selectedValue) {
            selectedItem = subChild.props
          }

          return {
            label: subChild.props.label,
            value: subChild.props.value,
            group: {
              id: child.props.label,
              name: child.props.label,
            } as ItemGroup,
          }
        })

      acc.push({
        groupName: child.props.label,
        items: groupItems,
      })
    } else if (React.isValidElement<PickerItemProps>(child)) {
      if (child.props.value === selectedValue) {
        selectedItem = child.props
      }

      acc.push({
        groupName: null,
        items: [
          {
            label: child.props.label,
            value: child.props.value,
          },
        ],
      })
    }

    return acc
  }, [])

  if (selectedItem && selectedItem.label !== selectedLabel) {
    setSelectedLabel(selectedItem.label ?? '')
  }

  const toggleModal = () => {
    setIsModalVisible(!isModalVisible)

    if (!isModalVisible) {
      onBlur?.()
    }
  }

  return (
    <View>
      <Pressable
        ref={pickerRef}
        onPress={() => {
          console.log('TouchableOpacity')
          toggleModal()
        }}
        onTouchEnd={onTouchEnd}
        style={styles.touchable}
      >
        <AppText>{selectedLabel}</AppText>
        <Ionicons
          name={isModalVisible ? 'chevron-up' : 'chevron-down'}
          size={20}
          color="#000"
          style={styles.chevronIcon}
        />
      </Pressable>

      <View
        style={[
          styles.modalContent,
          {
            top: -5000,
            left: pickerPosition?.x,
            width: pickerPosition?.width,
          },
        ]}
      >
        <Modal visible={isModalVisible} transparent={true} animationType="none">
          <Pressable style={styles.outerModalContainer} onPress={toggleModal}>
            {/* Actual Modal Content */}
            <Pressable
              onPress={(e) => e.stopPropagation()} // Prevent event propagation
              style={{
                ...styles.modalContent,
                top: (pickerPosition?.y || 0) + (pickerPosition?.height || 0),
                left: pickerPosition?.x,
                width: pickerPosition?.width,
              }}
            >
              <FlatList
                style={{ width: '100%' }}
                data={groups}
                onLayout={(event) => {
                  const { height } = event.nativeEvent.layout
                  setModalHeight(height)
                }}
                renderItem={({ item }) => (
                  <View key={'groups_' + (item.groupName ?? '')}>
                    {item.groupName ? (
                      <Text style={styles.groupLabel}>{item.groupName}</Text>
                    ) : null}
                    {item.items.map((subItem) => {
                      return (
                        <Pressable
                          key={
                            (item.groupName ?? '') +
                            '_' +
                            (subItem.label ?? subItem.value)
                          }
                          style={[
                            styles.item,
                            hoveredItem === subItem.value
                              ? styles.itemHovered
                              : null,
                          ]}
                          onPress={() => {
                            onValueChange?.(subItem.value)
                            setIsModalVisible(false)
                          }}
                          onHoverIn={() => setHoveredItem(subItem.value)}
                          onHoverOut={() => setHoveredItem(null)}
                        >
                          <Text
                            style={[
                              styles.itemText,
                              hoveredItem === subItem.value
                                ? styles.itemTextHovered
                                : null,
                            ]}
                          >
                            {subItem.label}
                          </Text>
                        </Pressable>
                      )
                    })}
                  </View>
                )}
                keyExtractor={(item, i, ...props) => {
                  return item.groupName ?? ''
                }}
              />
            </Pressable>
          </Pressable>
        </Modal>
      </View>
    </View>
  )
}

Picker.Item = ({ label, value }: PickerItemProps) => null
Picker.OptGroup = ({ label, children }: OptGroupProps) => null

const styles = StyleSheet.create({
  touchable: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 10,
    borderColor: '#ccc',
    borderWidth: 1,
    borderRadius: 4,
  },
  chevronIcon: {
    marginLeft: 10,
  },
  groupLabel: {
    fontWeight: 'bold',
    padding: 10,
  },
  item: {
    padding: 15,
    // borderBottomWidth: 1,
    // borderBottomColor: '#ccc',
    minWidth: '100%',
    alignItems: 'center',
  },
  itemHovered: {
    backgroundColor: colors.primary,
    color: colors.white,
  },
  modalContent: {
    position: 'absolute',
    maxHeight: '60%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.white,
    borderColor: colors.lightGrey,
    borderWidth: 2,
  },
  outerModalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
  },
  itemText: {},
  itemTextHovered: {},
})
