export function defaultTrophy(trophy?) {
  if (!trophy) {
    trophy = {}
  }

  let trophyImage = trophy.trophy ?? null

  if (trophyImage && trophyImage.id) {
    trophyImage = trophyImage.id
  }

  return {
    id: trophy.id ?? null,
    trophyName: trophy.trophyName ?? '',
    strengths: trophy.strengths ?? '',
    insights: trophy.insights ?? '',
    trigger: trophy.fullTriggerUrl ?? null,
    trophy: trophyImage,
  }
}
