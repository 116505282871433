import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'

import header from '../components/part/Header'
import FaqScreen from '../screens/app/Help/FaqScreen'
import MessageScreen from '../screens/app/Help/MessageScreen'
import FaqViewScreen from '../screens/app/Help/FaqViewScreen'

const Stack = createStackNavigator()

const HelpNavigator = () => (
  <Stack.Navigator initialRouteName="FAQ_Screen">
    <Stack.Screen
      name="FAQ_Screen"
      component={FaqScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showHamburger: true,
            showBackButton: false,
            showNudgifications: true,
          }),
        }
      }}
    />
    <Stack.Screen
      name="FAQ_View"
      component={FaqViewScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showHamburger: false,
            showBackButton: true,
            showNudgifications: false,
          }),
        }
      }}
    />
    <Stack.Screen
      name="Message_Screen"
      component={MessageScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showHamburger: false,
            showBackButton: true,
            showNudgifications: false,
          }),
        }
      }}
    />
  </Stack.Navigator>
)

export default HelpNavigator
