const joinMeeting = (user, joinMeetingApi, code: string, onEvent) => {
  joinMeetingApi
    .request(user.id, code)
    .then(onEvent)
    .catch((error) => {
      console.log(error)
    })
}

export default joinMeeting
