import React, { useContext } from 'react'
import { View } from 'react-native'

import { defaultStyles } from '../../../config/styles'
import Screen from '../../../components/Screen'
import WelcomeMessage from '../../../components/WelcomeMessage'
import { ScrollToTopContext } from '../../../components/startup/components/ScrollToTopContext'

function WelcomeScreen() {
  const scrollToTop = useContext(ScrollToTopContext)

  return (
    <Screen style={defaultStyles.screen}>
      <View style={defaultStyles.mainContainer}>
        <WelcomeMessage onload={scrollToTop} />
      </View>
    </Screen>
  )
}

export default WelcomeScreen
