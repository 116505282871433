import React from 'react'
import { StyleSheet, View } from 'react-native'
import { useMutation, useQueryClient } from 'react-query'
import * as Yup from 'yup'

import { Form, FormField, SubmitButton } from '../../../components/forms'

import Screen from '../../../components/Screen'
import AppText from '../../../components/Text'
import colors from '../../../config/colors'
import { defaultStyles } from '../../../config/styles'

import TitleGraphic from '../../../components/TitleGraphic'
import bossQuery from '../../../api/query/boss'
import BossLabel from '../../../components/boss/label/BossLabel'
import ActivityIndicator from '../../../components/ActivityIndicator'
import AppButton from '../../../components/Button'
import { useNavigation } from '@react-navigation/native'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { AppStackParamList } from '../../../navigation/AppNavigator'

const validationSchema = Yup.object().shape({
  name: Yup.string().required().min(1).max(40).label('Name'),
  boss: Yup.object().shape({
    body: Yup.string().label('Body'),
    oxygen: Yup.string().label('Oxygen'),
    selfEnergy: Yup.string().label('Self Energy'),
    selfAction: Yup.string().label('Self Action'),
  }),
})

export default function FormScreen({ route }) {
  const navigation = useNavigation<DrawerNavigationProp<AppStackParamList>>()

  const queryClient = useQueryClient()
  const formikRef = React.createRef()
  const bossId = route.params?.bossPracticeId ?? undefined
  const addPractice =
    route.params?.duplicate === 'true' ||
    route.params?.duplicate === true ||
    bossId === undefined

  const { isLoading, isError, data, refetch } = bossQuery.getBoss(bossId)

  function redirectList() {
    navigation.navigate('BOSS', {
      screen: 'Boss_List',
    })
  }

  const bossUpdateMutation = useMutation(bossQuery.updateBoss, {
    onSuccess: (data) => {
      queryClient.invalidateQueries('bosses')
      queryClient.invalidateQueries(['boss', { bossId: data.id }])

      redirectList()
    },
    onError: (error) => {
      alert('Failed to update the BOSS Practice')
      console.log(error)
    },
  })

  const bossAddMutation = useMutation(bossQuery.addBoss, {
    onSuccess: (data) => {
      queryClient.invalidateQueries('bosses')

      redirectList()
    },
    onError: (error) => {
      alert('Failed to add the BOSS Practice')
      console.log(error)
    },
  })

  const handleSubmit = async (newBossPractice) => {
    console.log('handleSubmit', newBossPractice, newBossPractice.id, data)

    if (!addPractice) {
      bossUpdateMutation.mutate(newBossPractice)
    } else {
      bossAddMutation.mutate({
        ...newBossPractice,
        id: undefined,
      })
    }
  }

  return (
    <Screen style={defaultStyles.screen}>
      <TitleGraphic
        section="BOSS Practice"
        title="View BOSS Practices"
        graphic={require('../../../assets/lead-boss.jpg')}
      />

      <View style={defaultStyles.mainContainer}>
        <ActivityIndicator visible={isLoading} />
        {isError && (
          <>
            <AppText>Couldn't retrieve the boss practice.</AppText>
            <AppButton title="Retry" onPress={refetch} />
          </>
        )}

        {!isError && !isLoading && (
          <>
            <Form
              innerRef={formikRef}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
              initialValues={
                data !== undefined
                  ? data
                  : {
                      name: '',
                      boss: {
                        body: '',
                        oxygen: '',
                        selfEnergy: '',
                        selfAction: '',
                      },
                    }
              }
            >
              <View style={styles.table}>
                <View style={[styles.rowBorder]}>
                  <AppText style={styles.heading}>
                    <b>Boss Practice</b>
                  </AppText>
                  <View style={styles.field}>
                    <FormField
                      maxLength={40}
                      name="name"
                      placeholder="What event is this in preparation for?"
                    />
                  </View>
                </View>
                <View style={[styles.rowBorder]}>
                  <BossLabel
                    letter="B"
                    circleColour={colors.blue}
                    label="Body posture"
                  />
                  <View style={styles.field}>
                    <FormField
                      maxLength={180}
                      name="boss.body"
                      placeholder="Choose the posture or movement that will prepare you best"
                      numberOfLines={3}
                    />
                  </View>
                </View>
                <View style={[styles.rowBorder]}>
                  <BossLabel
                    letter="O"
                    circleColour={colors.blue}
                    label="Oxygen - Breath Practice"
                  />
                  <View style={styles.field}>
                    <FormField
                      maxLength={180}
                      name="boss.oxygen"
                      placeholder="Choose the breathing technique that will best support you to perform well"
                      numberOfLines={3}
                    />
                  </View>
                </View>
                <View style={[styles.rowBorder]}>
                  <BossLabel
                    letter="S"
                    circleColour={colors.red}
                    label="Self-Talk for Energy"
                  />
                  <View style={styles.field}>
                    <FormField
                      maxLength={180}
                      name="boss.selfEnergy"
                      placeholder="Choose a mantra that increases your motivation to act – from your ambition, confidence or purpose"
                      numberOfLines={3}
                    />
                  </View>
                </View>
                <View style={[styles.rowBorder]}>
                  <BossLabel
                    letter="S"
                    circleColour={colors.green}
                    label="Self-Talk for Action"
                  />
                  <View style={styles.field}>
                    <FormField
                      maxLength={180}
                      name="boss.selfAction"
                      placeholder="Choose a mantra that gets you into action – however small"
                      numberOfLines={3}
                    />
                  </View>
                </View>

                <SubmitButton title={!addPractice ? 'Save' : 'Create'} />
              </View>
            </Form>
          </>
        )}
      </View>
    </Screen>
  )
}

const styles = StyleSheet.create({
  heading: {
    fontWeight: '400',
    minWidth: 150,
    marginBottom: 10,
  },
  icon: {
    fontWeight: '700',
    width: 40,
    height: 40,
    lineHeight: 40,
    borderRadius: 40,
    backgroundColor: colors.primary,
    color: '#fff',
    textAlign: 'center',
    marginRight: 10,
  },
  field: {},
  title: {
    fontWeight: '700',
    height: 40,
    lineHeight: 40,
  },
  table: {},
  row: {
    flexDirection: 'row',
    paddingVertical: 20,
  },
  rowBorder: {
    borderBottomColor: colors.light,
    borderBottomWidth: StyleSheet.hairlineWidth,
    paddingVertical: 20,
  },
  horizontalAlign: {
    flexDirection: 'row',
    alignItems: 'center',
  },
})
