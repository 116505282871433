import React, { useState } from 'react'
import { Image, StyleSheet, View } from 'react-native'
import { useMutation, useQueryClient } from 'react-query'
import * as Yup from 'yup'
import * as ImagePicker from 'expo-image-picker'

import { Form, FormField, SubmitButton } from '../../../components/forms'

import Screen from '../../../components/Screen'
import AppText from '../../../components/Text'
import colors from '../../../config/colors'
import { defaultStyles } from '../../../config/styles'

import TitleGraphic from '../../../components/TitleGraphic'
import trophyCabinetQuery from '../../../api/query/trophyCabinet'
import TrophyPicker from '../../../components/trophy/TrophyPicker'
import { convertUploadedFile } from '../../../utility/upload'
import { toIRI } from '../../../utility/resource'
import { cacheBustUrl } from '../../../utility/url'
import ButtonIcon from '../../../components/ButtonIcon'
import AppButton from '../../../components/Button'
import ActivityIndicator from '../../../components/ActivityIndicator'
import { defaultTrophy } from '../../../utility/defaultTrophy'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { AppStackParamList } from '../../../navigation/AppNavigator'
import { useNavigation } from '@react-navigation/native'
import appStateQuery from '../../../api/query/appState'
import { QUERY_KEYS } from '../../../config/queryKeys'
import { STATE_KEY } from '../../../config/constants'

const validationSchema = Yup.object().shape({
  trophyName: Yup.string().required().min(1).max(100).label('Trophy name'),
  strengths: Yup.string().label('Key Strengths'),
  insights: Yup.string().label('Personal Insights'),
  trophy: Yup.string().required().label('Trophy'),
})

export default function FormScreen({ route }) {
  const navigation = useNavigation<DrawerNavigationProp<AppStackParamList>>()
  const queryClient = useQueryClient()
  const formikRef = React.createRef()

  const trophyId = route?.params?.trophyId
  const isShedAction: boolean =
    route?.params?.shedAction === 'true'
      ? true
      : Boolean(route?.params?.shedAction)

  const { isLoading, isSuccess, isError, data, refetch } =
    trophyCabinetQuery.getTrophy(trophyId)

  const [triggerImage, setTriggerImage] = useState<string | null>(
    data?.triggerImage
  )

  function redirectList() {
    navigation.navigate('TROPHY_CABINET', {
      screen: 'Trophy_List',
    })
  }

  const stateMutation = useMutation(appStateQuery.postAppState, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS.appState.all)
      queryClient.invalidateQueries([
        QUERY_KEYS.appState.item(STATE_KEY.lastSeenShedActionHigh),
        QUERY_KEYS.appState.item(STATE_KEY.lastSeenShedActionLow),
      ])
    },
    onError: (error) => {
      console.log(error)
    },
  })

  const trophyUpdateMutation = useMutation(trophyCabinetQuery.updateTrophy, {
    onSuccess: (data) => {
      queryClient.invalidateQueries('trophy_cabinet')
      queryClient.invalidateQueries(['trophy', { trophyId: data.id }])

      if (!updateTrigger(data.id)) {
        redirectList()
      }
    },
    onError: (error) => {
      alert('Failed to update the Trophy Cabinet')
      console.log(error)
    },
  })

  function updateTrigger(trophyId): boolean {
    if (data.trigger === triggerImage) {
      console.log('skipped updateTrigger', data.trigger, triggerImage)
      return false
    }

    console.log('running updateTrigger')

    convertUploadedFile(triggerImage).then((trigger) => {
      trophyUpdateWithTriggerMutation.mutate({
        trophy: {
          id: trophyId,
          trigger: trigger,
        },
        filename: 'uploaded_file',
      })
    })

    return true
  }

  const trophyUpdateWithTriggerMutation = useMutation(
    trophyCabinetQuery.sendTrophyTriggerImage,
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries('trophy_cabinet')
        queryClient.invalidateQueries(['trophy', { trophyId: data.id }])

        redirectList()
      },
      onError: (error) => {
        if (error.response.status === 413) {
          alert(
            'Failed to update the Trophy Cabinet trigger: Image is too large'
          )
        } else {
          alert('Failed to update the Trophy Cabinet trigger')
        }
      },
    }
  )

  const trophyAddMutation = useMutation(trophyCabinetQuery.addTrophy, {
    onSuccess: (data) => {
      queryClient.invalidateQueries('trophy_cabinet')

      if (!updateTrigger(data.id)) {
        redirectList()
      }
    },
    onError: (error) => {
      alert('Failed to add the Trophy to the Cabinet')
      console.log(error)
    },
  })

  const handleSubmit = async (newTrophy) => {
    newTrophy.trophy = toIRI(newTrophy.trophy, 'trophies')

    console.log('handleSubmit', newTrophy, newTrophy.id, data)

    if (newTrophy.id) {
      trophyUpdateMutation.mutate({ trophy: newTrophy })
    } else {
      if (isShedAction) {
        stateMutation.mutate({
          stateKey: STATE_KEY.lastSeenShedActionHigh,
          stateValue: new Date().toISOString(),
        })
      }

      trophyAddMutation.mutate(newTrophy)
    }
  }

  const pickImage = async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      // base64: true,
      quality: 1,
    })

    console.log(result)

    if (!result.canceled) {
      setTriggerImage(result.assets[0]['uri'])
    }
  }

  return (
    <Screen style={defaultStyles.screen}>
      <TitleGraphic
        section="Trophy Cabinet"
        title="Add a Trophy"
        graphic={require('../../../assets/lead-trophy.jpg')}
      />

      <View style={defaultStyles.mainContainer}>
        <ActivityIndicator visible={isLoading} />
        {isError && (
          <>
            <AppText>Couldn't retrieve the Trophy Cabinet.</AppText>
            <AppButton title="Retry" onPress={() => refetch()} />
          </>
        )}
        {isSuccess && (
          <Form
            innerRef={formikRef}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            initialValues={defaultTrophy(data)}
          >
            <View style={styles.table}>
              <View style={[styles.row]}>
                <AppText style={styles.heading}>What's your Trophy?</AppText>
                <FormField
                  maxLength={40}
                  name="trophyName"
                  placeholder="What are you celebrating?"
                />
              </View>
              <View style={[styles.row]}>
                <AppText style={styles.heading}>
                  What strengths of yours did your success highlight?
                </AppText>
                <FormField
                  name="strengths"
                  multiline
                  numberOfLines={3}
                  placeholder="What was useful in achieving this?"
                />
              </View>
              <View style={[styles.row]}>
                <AppText style={styles.heading}>
                  What lessons can you take forward from your success?
                </AppText>
                <FormField
                  name="insights"
                  multiline
                  numberOfLines={3}
                  placeholder="Any new strengths? What were the benefits?"
                />
              </View>
              <View style={[styles.row]}>
                <AppText style={styles.heading}>
                  What’s the trigger that quickly connects you to this Trophy?
                </AppText>
                <AppText>
                  Insert an image here to easily remind you of your success.
                  <br />
                  (Max image size is 1.5MB)
                </AppText>
                <View style={[styles.upload]}>
                  <ButtonIcon
                    text="Choose a file to upload"
                    icon="cloud-upload"
                    onPress={pickImage}
                    color={colors.primary}
                    backgroundColor={colors.white}
                    borderColor={colors.primary}
                  />
                </View>
                {triggerImage && (
                  <Image
                    source={{
                      uri: cacheBustUrl(triggerImage),
                      headers: {
                        Pragma: 'no-cache',
                      },
                    }}
                    style={{
                      width: 200,
                      height: 200,
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}
                  />
                )}
                {triggerImage && (
                  <ButtonIcon
                    text="Remove"
                    icon="delete"
                    onPress={() => {
                      setTriggerImage(null)
                    }}
                    color={colors.primary}
                    backgroundColor={colors.white}
                    borderColor={colors.primary}
                  />
                )}
              </View>
              <View style={[styles.row]}>
                <AppText style={styles.heading}>
                  Choose your trophy icon
                </AppText>
                <TrophyPicker field="trophy" />
              </View>

              <SubmitButton title={trophyId ? 'Save' : 'Add'} />
            </View>
          </Form>
        )}
      </View>
    </Screen>
  )
}

const styles = StyleSheet.create({
  heading: {
    fontWeight: '700',
    minWidth: 150,
    marginBottom: 10,
  },
  icon: {
    fontWeight: '700',
    width: 40,
    height: 40,
    lineHeight: 40,
    borderRadius: 40,
    backgroundColor: colors.primary,
    color: '#fff',
    textAlign: 'center',
    marginRight: 10,
  },
  field: {},
  title: {
    fontWeight: '700',
    height: 40,
    lineHeight: 40,
  },
  table: {},
  row: {
    paddingVertical: 20,
  },
  rowBorder: {
    borderBottomColor: colors.light,
    borderBottomWidth: StyleSheet.hairlineWidth,
    paddingVertical: 20,
  },
  horizontalAlign: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  upload: {
    marginTop: 10,
  },
})
