import React from 'react'
import { View } from 'react-native'

import ActivityIndicator from '../../../../components/ActivityIndicator'
import AppText from '../../../../components/Text'
import colors from '../../../../config/colors'
import sharedWithMeQuery from '../../../../api/query/sharedWithMe'
import ButtonIcon from '../../../../components/ButtonIcon'
import { useNavigation } from '@react-navigation/native'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { AppStackParamList } from '../../../../navigation/AppNavigator'
import { useAppState } from '../../../../hooks/useAppState'
import { dashboardStyles } from '../../../../config/styles'

interface Props {
  editing?: boolean
  padding?: boolean
}

function SharedButtons({ editing = false, padding = false }: Readonly<Props>) {
  const appState = useAppState()
  const navigation = useNavigation<DrawerNavigationProp<AppStackParamList>>()
  const cioApi = sharedWithMeQuery.getCriticalImpactOpportunities()
  const rapidReviewApi = sharedWithMeQuery.getRapidReviews()
  const boomApi = sharedWithMeQuery.getBooms()

  let totalItems = 0
  let boomUnreadCount = 0
  let cioUnreadCount = 0
  let rapidReviewUnreadCount = 0

  const isSuccess =
    cioApi.isSuccess || rapidReviewApi.isSuccess || boomApi.isSuccess

  if (cioApi.isSuccess) {
    totalItems += cioApi.data.length
    cioUnreadCount = countUnreadItems(cioApi.data, appState.lastSeenSharedCio)
  }

  if (rapidReviewApi.isSuccess) {
    totalItems += rapidReviewApi.data.length
    rapidReviewUnreadCount = countUnreadItems(
      rapidReviewApi.data,
      appState.lastSeenSharedRapidReview
    )
  }

  if (boomApi.isSuccess) {
    totalItems += boomApi.data.length
    boomUnreadCount = countUnreadItems(
      boomApi.data,
      appState.lastSeenSharedBoom
    )
  }

  return (
    <>
      <ActivityIndicator
        visible={
          cioApi.isLoading || rapidReviewApi.isLoading || boomApi.isLoading
        }
      />

      <View
        style={[
          { flexDirection: 'row' },
          padding ? dashboardStyles.blockBody : {},
        ]}
      >
        <View style={{ flex: 1 }}>
          {cioApi.isSuccess && cioApi.data.length > 0 && (
            <ButtonIcon
              text="Shared Critical Impact Opportunities"
              icon="text-box-search-outline"
              color={colors.white}
              backgroundColor={colors.primary}
              badgeValue={cioUnreadCount}
              onPress={() => {
                if (editing) {
                  return
                }

                navigation.navigate('SHARED_WITH_ME', {
                  screen: 'SharedWithMe_CriticalImpactOpportunity_List',
                })
              }}
            />
          )}

          {rapidReviewApi.isSuccess && rapidReviewApi.data.length > 0 && (
            <ButtonIcon
              text="Shared Rapid Reviews"
              icon="text-box-search-outline"
              color={colors.white}
              backgroundColor={colors.primary}
              badgeValue={rapidReviewUnreadCount}
              onPress={() => {
                if (editing) {
                  return
                }

                navigation.navigate('SHARED_WITH_ME', {
                  screen: 'SharedWithMe_RapidReview_List',
                })
              }}
            />
          )}

          {boomApi.isSuccess && boomApi.data.length > 0 && (
            <ButtonIcon
              text="Shared Booms"
              icon="text-box-search-outline"
              color={colors.white}
              backgroundColor={colors.primary}
              badgeValue={boomUnreadCount}
              onPress={() => {
                if (editing) {
                  return
                }

                navigation.navigate('SHARED_WITH_ME', {
                  screen: 'SharedWithMe_Boom_List',
                })
              }}
            />
          )}

          {isSuccess && 0 === totalItems && (
            <AppText>Nothing has currently been shared with you.</AppText>
          )}
        </View>
      </View>
    </>
  )
}

function countUnreadItems(
  items: { updatedAt: Date }[],
  lastSeenShared: Date | null
): number {
  if (lastSeenShared === null) {
    return items.length
  }

  return items.filter((item) => item.updatedAt > lastSeenShared).length
}

export default SharedButtons
