import React, { useState } from 'react'
import { Dimensions, StyleSheet, TouchableOpacity, View } from 'react-native'
import { format, parseISO } from 'date-fns'
import { LineChart } from 'react-native-chart-kit'
import { LineChartData } from 'react-native-chart-kit/dist/line-chart/LineChart'
import { Dataset } from 'react-native-chart-kit/dist/HelperTypes'

import { defaultStyles, defaultConfig } from '../../../config/styles'
import AppText from '../../../components/Text'
import Title from '../../../components/Title'
import Screen from '../../../components/Screen'
import shedQuery from '../../../api/query/shed'
import ActivityIndicator from '../../../components/ActivityIndicator'
import AppButton from '../../../components/Button'
import { AbstractChartConfig } from 'react-native-chart-kit/dist/AbstractChart'
import { useNavigation } from '@react-navigation/native'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { AppStackParamList } from '../../../navigation/AppNavigator'
import ShedActions from '../../../components/shed/ShedActions'
import InfoButton from '../../../components/InfoButton'

const MAX_NUMBER_OF_DATE_LABEL = 12
const MAX_NUMBER_OF_DAYS = 7

function ShedProgressScreen() {
  const navigation = useNavigation<DrawerNavigationProp<AppStackParamList>>()
  const [screenWidth, setScreenWidth] = useState(
    Dimensions.get('window').width / 2
  )
  const { isLoading, isSuccess, isError, data, refetch } =
    shedQuery.ShedRatings()

  const onLayout = (event) => {
    const { x, y, height, width } = event.nativeEvent.layout

    // remove padding from view width
    setScreenWidth(width - 20)
  }

  const chartConfig: AbstractChartConfig = {
    backgroundGradientFrom: defaultConfig.colors.white,
    backgroundGradientTo: defaultConfig.colors.white,
    backgroundColor: defaultConfig.colors.white,
    color: (opacity = 1) => defaultConfig.colors.lightGrey,
    labelColor: (opacity = 1) => defaultConfig.colors.grey,
    decimalPlaces: 0,
    width: screenWidth,
    propsForBackgroundLines: {
      strokeDasharray: '',
    },
  }

  const dataset: Dataset[] = [
    {
      data: [],
      color: () => defaultConfig.colors.secondary,
    },
    {
      data: [],
      color: () => defaultConfig.colors.primary,
    },
    {
      data: [],
      color: () => defaultConfig.colors.red,
    },
    {
      data: [],
      color: () => defaultConfig.colors.green,
    },
  ]

  const chartData: LineChartData = {
    labels: [],
    legend: ['Sleep', 'Hydration', 'Exercise', 'Diet'],
    datasets: [],
  }

  if (!isLoading && data) {
    const graphData = data.slice(0, MAX_NUMBER_OF_DAYS)
    const showEveryXLabel = Math.ceil(
      graphData.length / MAX_NUMBER_OF_DATE_LABEL
    )
    graphData.map((rating, index) => {
      chartData.labels.unshift(
        index % showEveryXLabel ? '' : format(parseISO(rating.date), 'do MMM')
      )
      dataset[0].data.unshift(rating.sleep)
      dataset[1].data.unshift(rating.hydration)
      dataset[2].data.unshift(rating.exercise)
      dataset[3].data.unshift(rating.diet)
    })
  }

  chartData.datasets = dataset

  return (
    <Screen style={defaultStyles.screen}>
      <Title style={{ fontSize: 25, color: 'black' }}>Shed Progress</Title>

      <View style={defaultStyles.mainContainer}>
        <View onLayout={onLayout}>
          <ActivityIndicator visible={isLoading} />
          {isError && (
            <>
              <AppText>Couldn't retrieve your Shed ratings.</AppText>
              <AppButton title="Retry" onPress={() => refetch()} />
            </>
          )}
          {isSuccess && (
            <>
              <LineChart
                bezier
                style={styles.graphStyle}
                data={chartData}
                width={screenWidth}
                height={220}
                chartConfig={chartConfig}
                // verticalLabelRotation={30}
                withShadow={false}
                fromZero={true}
                segments={5}
              />
              <ShedActions />
              <View
                style={{
                  flex: 1,
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginVertical: 20,
                }}
              >
                <AppText style={{ ...styles.subtitle, flex: 1 }}>
                  Last 30 days
                </AppText>
                <View style={{ flex: 1, alignItems: 'flex-end' }}>
                  <InfoButton
                    text="Export as PDF"
                    icon="download"
                    size={18}
                    onPress={async () => {
                      if (null === (await shedQuery.downloadPdf())) {
                        alert('Window failed to open.  Is the popup blocked?')
                      }
                    }}
                  />
                </View>
              </View>
              <table>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Sleep</th>
                    <th>Hydration</th>
                    <th>Exercise</th>
                    <th>Diet</th>
                  </tr>
                </thead>
                <tbody>
                  {data.length === 0 && (
                    <tr>
                      <td colSpan={5}>No ratings found.</td>
                    </tr>
                  )}
                  {data.map((rating) => {
                    return (
                      <tr key={rating.date}>
                        <td>{format(parseISO(rating.date), 'do MMM')}</td>
                        <td>{rating.sleep}</td>
                        <td>{rating.hydration}</td>
                        <td>{rating.exercise}</td>
                        <td>{rating.diet}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </>
          )}
        </View>
        {!isLoading && (
          <TouchableOpacity
            style={styles.shedProgress}
            onPress={() => {
              navigation.navigate('DASHBOARD', {
                screen: 'ShedRatingScreen',
                params: {
                  previous: true,
                },
              })
            }}
          >
            <div style={styles.shedProgressText}>Rate Your SHED</div>
          </TouchableOpacity>
        )}
      </View>
    </Screen>
  )
}

const styles = StyleSheet.create({
  title: {
    fontWeight: 'bold',
    fontSize: 22,
    marginBottom: 15,
  },
  subtitle: {
    fontWeight: 'bold',
  },
  graphStyle: {},
  shedProgress: {
    flexDirection: 'row',
    paddingHorizontal: 15,
    paddingVertical: 10,
    marginTop: 20,
    fontSize: '16px',
    fontWeight: 'bold',
    fontFamily: 'open sans',
    color: defaultConfig.colors.primary,
    marginHorizontal: 'auto',
  },
  shedProgressText: {
    marginTop: 8,
  },
})

export default ShedProgressScreen
