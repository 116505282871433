import React, { useState } from 'react'
import { Modal, StyleSheet, View, FlatList, Pressable } from 'react-native'
import { Gravatar } from 'react-native-gravatar'

import { ListItem, ListItemSeparator } from '../../../components/lists'
import colors from '../../../config/colors'
import { defaultStyles } from '../../../config/styles'
import Icon from '../../../components/Icon'
import Text from '../../../components/Text'
import Screen from '../../../components/Screen'
import { useSession } from '../../../hooks/useSession'
import Button from '../../../components/Button'
import ButtonIcon from '../../../components/ButtonIcon'
import GravatarText from '../../../components/profile/GravatarText'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { AppStackParamList } from '../../../navigation/AppNavigator'
import { useNavigation } from '@react-navigation/native'

interface MenuItem {
  title: string
  targetComponent: string
  targetScreen: string
  icon: {
    name: string
  }
}

const menuItems: MenuItem[] = [
  {
    title: 'Edit Profile',
    targetComponent: 'PROFILE',
    targetScreen: 'Profile_Form',
    icon: {
      name: 'account-circle',
    },
  },
  {
    title: 'Edit HPP Profile',
    targetComponent: 'PROFILE',
    targetScreen: 'Profile_Ritual',
    icon: {
      name: 'account-details',
      navigate: true,
    },
  },
  {
    title: 'My BOSS Practices',
    targetComponent: 'BOSS',
    targetScreen: 'Boss_List',
    icon: {
      name: 'playlist-check',
    },
  },
  {
    title: 'My Teams',
    targetComponent: 'TEAM',
    targetScreen: 'Team_List',
    icon: {
      name: 'account-group',
    },
  },
  {
    title: 'Nudges',
    targetComponent: 'PROFILE',
    targetScreen: 'Profile_Nudge',
    icon: {
      name: 'bell',
    },
  },
  {
    title: 'Welcome Message',
    targetComponent: 'PROFILE',
    targetScreen: 'Profile_WelcomeScreen',
    icon: {
      name: 'message-reply-text',
    },
  },
]

function AccountScreen() {
  const navigation = useNavigation<DrawerNavigationProp<AppStackParamList>>()
  const { user, logOut, userFeatures } = useSession()
  const [modalVisible, setModalVisible] = useState(false)

  const availableMenuItems: MenuItem[] = menuItems.filter((item) =>
    userFeatures?.includes('FEATURE_' + item.targetComponent)
  )

  return (
    <Screen style={defaultStyles.screen}>
      <View style={styles.userContainer}>
        <Pressable onPress={() => setModalVisible(true)}>
          <Gravatar
            options={{
              d: 'mp',
              email: user?.username,
              parameters: { size: '200', d: 'mm' },
              secure: true,
              p: Math.random(),
            }}
            style={styles.roundedProfileImage}
          />
        </Pressable>

        <Text style={styles.title} numberOfLines={1}>
          {user?.name}
        </Text>
        {!!user?.job_title && typeof user.job_title === 'string' && (
          <Text style={styles.jobTitle} numberOfLines={2}>
            {user.job_title}
          </Text>
        )}
      </View>
      <View style={defaultStyles.mainContainer}>
        <View style={styles.listContainer}>
          <FlatList
            data={availableMenuItems}
            keyExtractor={(menuItem) => menuItem.title}
            ItemSeparatorComponent={ListItemSeparator}
            renderItem={({ item }) => (
              <ListItem
                title={item.title}
                IconComponent={
                  <Icon
                    name={item.icon.name}
                    size={50}
                    iconColor={colors.secondary}
                    backgroundColor={colors.white}
                  />
                }
                onPress={() =>
                  navigation.navigate(item.targetComponent, {
                    screen: item.targetScreen,
                  })
                }
              />
            )}
          />
        </View>
      </View>
      <br />
      <View style={styles.bottomButton}>
        <ButtonIcon text="Log Out" icon="logout" onPress={() => logOut()} />
      </View>
      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(!modalVisible)
        }}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <GravatarText />
            <Button
              title="Close"
              onPress={() => setModalVisible(!modalVisible)}
              style={{
                width: 'auto',
              }}
            />
          </View>
        </View>
      </Modal>
    </Screen>
  )
}

const styles = StyleSheet.create({
  bottomButton: {
    textAlign: 'center',
  },
  listContainer: {
    borderBottomColor: colors.light,
    borderTopColor: colors.light,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderTopWidth: StyleSheet.hairlineWidth,
    marginHorizontal: 30,
  },
  userContainer: {
    marginVertical: 20,
    textAlign: 'center',
    alignItems: 'center',
  },

  roundedProfileImage: {
    width: 200,
    height: 200,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: colors.medium,
    borderRadius: 100,
    textAlign: 'center',
    marginVertical: 20,
    marginHorizontal: 30,
  },

  title: {
    color: colors.black,
    fontSize: 30,
    marginBottom: 10,
  },
  jobTitle: {
    color: colors.medium,
    marginBottom: 20,
  },

  /* Modal Styles */
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.7)',
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
})

export default AccountScreen
