import React from 'react'
import { View } from 'react-native'
import Moment from 'moment'

import TitleGraphic from '../../../../components/TitleGraphic'
import Screen from '../../../../components/Screen'
import AppText from '../../../../components/Text'
import { defaultStyles } from '../../../../config/styles'
import rrQuery from '../../../../api/query/rapidReview'
import ActivityIndicator from '../../../../components/ActivityIndicator'
import Title from '../../../../components/Title'
import AppButton from '../../../../components/Button'
import DisplayRapidReview from './components/DisplayRapidReview'

export default function ViewScreen({ route }) {
  const { rapidReviewId } = route.params

  const { isLoading, isSuccess, isError, data, refetch } =
    rrQuery.getRapidReview(rapidReviewId)

  Moment.locale('en')

  return (
    <Screen style={defaultStyles.screen}>
      <TitleGraphic
        section="Rapid Reviews"
        title="View Rapid Reviews"
        graphic={require('../../../../assets/lead-rapid-review.jpg')}
      />

      <View style={defaultStyles.mainContainer}>
        <ActivityIndicator visible={isLoading} />
        {isError && (
          <>
            <Title style={{ fontSize: 25, color: 'black' }}>
              Error Loading Rapid Review
            </Title>
            <AppText>Couldn't retrieve the rapid review.</AppText>
            <AppButton title="Retry" onPress={() => refetch()} />
          </>
        )}
        {isSuccess && <DisplayRapidReview rapidReview={data} />}
      </View>
    </Screen>
  )
}
