import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'

import header from '../components/part/Header'
import FormScreen from '../screens/app/Reminder/FormScreen'

const Stack = createStackNavigator()

const ReminderNavigator = () => (
  <Stack.Navigator initialRouteName="Reminder_Screen">
    <Stack.Screen
      name="Reminder_Screen"
      component={FormScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showHamburger: true,
            showNudgifications: true,
          }),
        }
      }}
    />
  </Stack.Navigator>
)

export default ReminderNavigator
