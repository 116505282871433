import { useQuery } from 'react-query'

import { get, post, patch } from '../../utility/api'
import { IRapidReview } from 'types/rapidReview'
import { IMeetingItem } from 'types/MeetingItem'

export const endpoint = '/meetings'

const getMeetings = (userId: string) => {
  return useQuery('meetings', ({ signal }) =>
    get('/users/' + userId + '/attending_meetings?order[date]=desc', {
      signal,
    })
  )
}

const getMeeting = (meetingId: string) => {
  return useQuery(
    ['meeting', { meetingId: meetingId }],
    ({ signal }) =>
      get(endpoint + '/' + meetingId, {
        signal,
      }),
    {
      refetchOnWindowFocus: false,
    }
  )
}

const getPastMeeting = (meetingId: string) => {
  return useQuery('past_meetings', ({ signal }) =>
    get(endpoint + `/${meetingId}/past` + '?order[date]=desc', {
      signal,
    })
  )
}

const joinMeeting = (meetingCode: string) =>
  post(endpoint + '/join/' + meetingCode, [])

const startMeeting = (meetingId: string) =>
  post(endpoint + `/${meetingId}/start`, [])

const getMeetingSuggestions = (meetingId: string) => {
  return useQuery<IRapidReview[]>('meeting_suggestions', ({ signal }) =>
    get(endpoint + `/${meetingId}/suggestions` + '?order[date]=desc', {
      signal,
    })
  )
}

const endMeeting = (meeting: IMeetingItem) => {
  return post(endpoint + `/${meeting.id}/end`, meeting)
}

const addMeeting = (meeting: IMeetingItem) => {
  return post(endpoint, meeting, {
    // onUploadProgress: (progress) =>
    //   onUploadProgress(progress.loaded / progress.total),
  })
}

const getMeetingReviews = (meetingId: string) => {
  return useQuery(
    ['meeting_rapidReviews', { meetingId: meetingId }],
    ({ signal }) =>
      get(endpoint + `/${meetingId}/rapid_reviews` + '?order[date]=desc', {
        signal,
      })
  )
}

export default {
  getMeetings,
  getMeeting,
  getPastMeeting,
  joinMeeting,
  addMeeting,
  startMeeting,
  getMeetingSuggestions,
  endMeeting,
  getMeetingReviews,
}
