import React, { useState } from 'react'
import { StyleSheet, View } from 'react-native'

import { defaultStyles, defaultConfig } from '../../config/styles'
import InfoButton from '../InfoButton'
import AppText from '../Text'
import CircleLetter from './CircleLetter'
import { ErrorMessage, FormPicker as Picker } from '../../components/forms'
import { useFormikContext } from 'formik'
import { useNavigation } from '@react-navigation/native'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { AppStackParamList } from '../../navigation/AppNavigator'

const borderRadius = 10
const ratings = [
  { value: 0 },
  { value: 1 },
  { value: 2 },
  { value: 3 },
  { value: 4 },
  { value: 5 },
]

const Panel = ({ section, ...props }) => {
  const navigation = useNavigation<DrawerNavigationProp<AppStackParamList>>()
  const { errors, touched, values } = useFormikContext()
  const name = section.toLowerCase()
  const [rating, setRating] = useState(values[name])
  const letter = section.charAt(0)

  return (
    <View style={[styles.panel, { borderColor: higlightColour(rating) }]}>
      <View style={styles.row1}>
        <CircleLetter
          letter={letter}
          size={40}
          backgroundColor={higlightColour(rating)}
        />
        <AppText style={[defaultStyles.text, styles.sectionText]}>
          {section}
        </AppText>
        <View style={{ flexDirection: 'row' }}>
          <ErrorMessage
            error={errors[name]}
            visible={touched[name]}
            style={{ alignItems: 'center', marginTop: 10, marginRight: 20 }}
          />
          <Picker
            items={ratings}
            name={name}
            placeholder=""
            width={40}
            type="int"
            showErrorMessage={false}
            onChange={(value: number) => {
              setRating(value)

              if (props.onChange) {
                props.onChange(value)
              }
            }}
          />
        </View>
      </View>
      <View style={[styles.row2, { borderColor: higlightColour(rating) }]}>
        <View
          style={[
            styles.button,
            {
              borderColor: higlightColour(rating),
              borderRightWidth: 1,
              margin: 0,
            },
          ]}
        >
          <InfoButton
            icon="finance"
            text="Progress"
            onPress={() => {
              navigation.navigate('DASHBOARD', {
                screen: 'ShedProgressScreen',
              })
            }}
            style={{
              paddingVertical: 5,
              paddingHorizontal: 30,
            }}
          />
        </View>
        <View style={styles.button}>
          <InfoButton
            icon="lightbulb-on"
            text={section + ' tips'}
            onPress={() => {
              navigation.navigate('DASHBOARD', {
                screen: 'ShedTipsScreen',
                params: {
                  section: section.toLowerCase(),
                },
              })
            }}
          />
        </View>
      </View>
    </View>
  )
}

function higlightColour(rating) {
  return parseInt(rating) > 0
    ? defaultConfig.colors.primary
    : defaultConfig.colors.medium
}

const styles = StyleSheet.create({
  button: {
    alignContent: 'center',
    alignItems: 'center',
    margin: 0,
    flex: 1,
    padding: 1,
  },
  panel: {
    marginBottom: 20,
    borderRadius: borderRadius,
    borderWidth: 1,
  },
  sectionText: {
    flex: 1,
    fontSize: 25,
    fontWeight: 'bold',
    marginLeft: 15,
  },
  row1: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  row2: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: defaultConfig.colors.lightPrimary,
    flex: 1,
    borderTopWidth: 1,
    borderBottomRightRadius: borderRadius,
    borderBottomLeftRadius: borderRadius,
    paddingVertical: 10,
  },
})

export default Panel
