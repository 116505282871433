import { INudgification, INudgificationData } from 'types/nudgification'
import client from './client'

const endpoint = '/nudgifications'

const getNudgifications = (userId) => {
  return client.get('/users/' + userId + endpoint)
}

const getNudgificationCount = (userId) => {
  return client.get(`/users/${userId}/nudgifications/count`)
}

const deleteNudgification = (id) => {
  return client.delete(endpoint + '/' + id)
}

export const addNudgification = (userId, nudgification: INudgification) => {
  const data: INudgificationData = {
    details: {
      title: nudgification.details.title,
      description: nudgification.details.description,
    },
    nudge: nudgification.nudge,
    user: '/users/' + userId,
  }

  if (nudgification.details.link) {
    data.component = nudgification.details.link.component
    data.componentId = nudgification.details.link.id
  }

  return client.post(endpoint, data, {
    // onUploadProgress: (progress) =>
    //   onUploadProgress(progress.loaded / progress.total),
  })
}

export default {
  addNudgification,
  deleteNudgification,
  getNudgifications,
  getNudgificationCount,
}
