import React from 'react'

import { useSession } from '../hooks/useSession'
import userQuery from '../api/query/user'
import WarningButton from './WarningButton'
import { useNavigation } from '@react-navigation/native'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { AppStackParamList } from '../navigation/AppNavigator'

interface Props {
  allowNavigation?: boolean
}

const IsNotificationDisabled: React.FC<Props> = ({
  allowNavigation = false,
}) => {
  const navigation = useNavigation<DrawerNavigationProp<AppStackParamList>>()
  const { user } = useSession()

  if (!user) {
    return null
  }

  const getUserApi = userQuery.getUser(user.id)

  if (getUserApi.isSuccess && getUserApi.data.nudge.length === 0) {
    return (
      <WarningButton
        text="Nudges are currently disabled in your Profile."
        onPress={() => {
          if (allowNavigation) {
            navigation.navigate('PROFILE', {
              screen: 'Profile_Nudge',
            })
          }
        }}
      />
    )
  }

  return null
}

export default IsNotificationDisabled
