import { QueryFunctionContext, useQuery } from 'react-query'
import ms from 'ms'
import { get, post } from '../../utility/api'
import { IAppStateItem } from 'types/AppStateItem'
import { IAppStateCollection } from 'types/AppStateCollection'
import { AppStateKey } from '../../@types/misc'
import { QUERY_KEYS } from '../../config/queryKeys'

const endpoint = '/states'

const getAppStates = () => {
  return useQuery<IAppStateCollection>(
    QUERY_KEYS.appState.all,
    ({ signal }) =>
      get(endpoint, {
        signal,
      }),
    { staleTime: ms('5s') }
  )
}

const getAppState = (stateKey: AppStateKey) => {
  return useQuery<IAppStateItem>(
    QUERY_KEYS.appState.item(stateKey),
    ({ signal }: QueryFunctionContext) =>
      get(endpoint + '/' + stateKey, { signal }),
    {
      retry: (failureCount: number, error: unknown) => {
        return failureCount <= 2
      },
    }
  )
}

export const postAppState = (state: IAppStateItem) => {
  return post(endpoint, state)
}

export default {
  getAppStates,
  getAppState,
  postAppState,
}
