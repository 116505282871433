import React from 'react'
import { View, Text, SafeAreaView, StyleSheet } from 'react-native'

import { DrawerContentScrollView, DrawerItem } from '@react-navigation/drawer'
import ButtonIcon from './ButtonIcon'
import { useSession } from '../hooks/useSession'

interface ICustomSidebar {
  state: {
    routes
  }
  descriptors
  navigation
}

const CustomSidebar = (props: ICustomSidebar) => {
  const { state, descriptors, navigation } = props
  let lastGroupName = ''
  let newGroup = true

  const styles = StyleSheet.create({
    sectionView: {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: 12,
    },
    separatorLine: {
      flex: 1,
      backgroundColor: '#ccc',
      height: 1.2,
      marginLeft: 10,
      marginRight: 20,
    },
    menuItem: {
      fontSize: 17,
      color: '#3E4E63',
      paddingLeft: 10,
      fontFamily: 'Open Sans',
      fontWeight: 600,
    },
    logOut: {
      marginTop: 20,
    },
  })

  const auth = useSession()

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <DrawerContentScrollView {...props}>
        {/* <View style={{ paddingVertical: "20px", flexDirection: "row" }}>
          <View style={{ width: "25%" }} />
          <View style={{ width: "50%" }}>
            <Image
              key="logo"
              source={require("../assets/logo-icon.png")}
              style={{ width: "50%", height: "50px" }}
              resizeMode="center"
            />
          </View>
          <View style={{ width: "25%" }}>
            <Notification navigation={navigation} />
          </View>
        </View> */}

        {state.routes.map((route) => {
          const {
            drawerLabel,
            activeTintColor,
            activeBackgroundColor,
            inactiveTintColor,
            inactiveBackgroundColor,
            groupName,
            visible,
            enabled,
            defaultRoute,
          } = descriptors[route.key].options

          if (!enabled) {
            return null
          }

          if (lastGroupName !== groupName) {
            newGroup = true
            lastGroupName = groupName
          } else {
            newGroup = false
          }
          return (
            <View key={'R_' + route.key}>
              {newGroup && groupName !== null ? (
                <View key={'C_' + groupName} style={styles.sectionView}>
                  <Text key={groupName} style={{ marginLeft: 10 }}>
                    {groupName}
                  </Text>
                  <View key={'L_' + groupName} style={styles.separatorLine} />
                </View>
              ) : null}
              {visible && (
                <DrawerItem
                  key={route.key}
                  label={({ color }) => (
                    <Text style={{ color }} style={styles.menuItem}>
                      {drawerLabel}
                    </Text>
                  )}
                  focused={
                    state.routes.findIndex((e) => e.name === route.name) ===
                    state.index
                  }
                  activeTintColor={activeTintColor}
                  activeBackgroundColor={activeBackgroundColor}
                  inactiveTintColor={inactiveTintColor}
                  inactiveBackgroundColor={inactiveBackgroundColor}
                  onPress={() => {
                    const params = {}

                    if (defaultRoute) {
                      params.screen = defaultRoute
                    }

                    navigation.navigate(route.name, params)
                  }}
                />
              )}
            </View>
          )
        })}

        <View style={styles.logOut}>
          <ButtonIcon
            color="#3E4E63"
            icon="arrow-left-bold-box"
            onPress={() => auth.logOut()}
            text="Logout"
          />
        </View>
      </DrawerContentScrollView>
    </SafeAreaView>
  )
}

export default CustomSidebar
