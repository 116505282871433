import React, { useEffect } from 'react'
import { StyleSheet, View, TouchableOpacity } from 'react-native'
import { useNavigation } from '@react-navigation/native'

import { defaultStyles } from '../../../../../config/styles'
import AppText from '../../../../../components/Text'
import LetterRating from '../../../../../components/shed/LetterRating'
import shedQuery from '../../../../../api/query/shed'
import Icon from '../../../../../components/Icon'
import colors from '../../../../../config/colors'
import { IWidget } from './IWidget'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { AppStackParamList } from '../../../../../navigation/AppNavigator'

export const TodayWidgetName = 'today'

export const TodayWidget: React.FC<IWidget> = ({ editing, onLoaded }) => {
  useEffect(() => {
    onLoaded(TodayWidgetName)
  }, [onLoaded])

  const { isFetched, data } = shedQuery.ShedRating()
  const navigation = useNavigation<DrawerNavigationProp<AppStackParamList>>()
  const isRated =
    isFetched &&
    data &&
    !(
      data.sleep === null ||
      data.hydration === null ||
      data.exercise === null ||
      data.diet === null
    )

  return (
    <TouchableOpacity
      style={{ alignItems: 'center' }}
      onPress={() => {
        if (editing) {
          return
        }

        navigation.navigate('DASHBOARD', {
          screen: 'ShedRatingScreen',
          params: {
            previous: false,
          },
        })
      }}
    >
      <AppText style={[defaultStyles.text, styles.title]}>
        How is your SHED today?
      </AppText>
      <View style={styles.shedLayout}>
        <LetterRating letter="S" rating={data ? data.sleep : 0} />
        <LetterRating letter="H" rating={data ? data.hydration : 0} />
        <LetterRating letter="E" rating={data ? data.exercise : 0} />
        <LetterRating letter="D" rating={data ? data.diet : 0} />
      </View>
      <View style={styles.shedStatus}>
        {isFetched && !isRated && <>Rate your shed today</>}
        {isFetched && isRated && (
          <>
            <Icon
              name="check"
              size={24}
              iconColor={colors.white}
              backgroundColor={colors.secondary}
            />
            <div>&nbsp; Rated today, check in again tomorrow</div>
          </>
        )}
      </View>
      <TouchableOpacity
        style={styles.shedProgress}
        onPress={() => {
          if (editing) {
            return
          }

          navigation.navigate('DASHBOARD', {
            screen: 'ShedProgressScreen',
          })
        }}
      >
        <Icon
          name="chart-line"
          size={40}
          iconColor={colors.primary}
          backgroundColor={colors.white}
        />
        <div style={styles.shedProgressText}>View your progress</div>
      </TouchableOpacity>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  icon: {
    marginRight: 10,
  },
  shedLayout: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20,
    width: '50%',
    minWidth: 300,
  },
  shedStatus: {
    borderRadius: 50,
    flexDirection: 'row',
    backgroundColor: '#D9FADC',
    paddingHorizontal: 15,
    paddingVertical: 10,
    marginTop: 20,
    fontSize: '16px',
    fontWeight: 'bold',
    fontFamily: 'open sans',
  },
  shedProgress: {
    flexDirection: 'row',
    paddingHorizontal: 15,
    paddingVertical: 10,
    marginVertical: 10,
    fontSize: '16px',
    fontWeight: 'bold',
    fontFamily: 'open sans',
    color: colors.primary,
  },
  shedProgressText: {
    marginTop: 8,
  },
  title: {
    fontWeight: 'bold',
  },
})
