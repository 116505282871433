import React from 'react'
import LottieView from 'lottie-react-native'
import LottieWebView from 'react-native-web-lottie'

import { Platform, View, StyleSheet } from 'react-native'

interface IActivityIndicator {
  visible?: boolean
}

function ActivityIndicator({ visible = false }: IActivityIndicator) {
  if (!visible) return null

  return (
    <View style={styles.overlay}>
      {Platform.OS === 'web' ? (
        <LottieWebView
          autoPlay
          loop
          source={require('../assets/animations/loader.json')}
        />
      ) : (
        <LottieView
          autoPlay
          loop
          source={require('../assets/animations/loader.json')}
        />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  overlay: {
    position: 'absolute',
    backgroundColor: 'white',
    height: '100%',
    opacity: 0.8,
    width: '100%',
    zIndex: 1,
  },
})

export default ActivityIndicator
