import { useQuery } from 'react-query'
import FormData from 'form-data'

import { get, post, patch, remove } from '../../utility/api'
import { removeKeys } from '../../utility/array'
import { defaultTrophy } from '../../utility/defaultTrophy'
export const endpoint = '/trophy_cabinets'

const getTrophies = (userId) => {
  return useQuery('trophy_cabinet', ({ signal }) =>
    get('/users/' + userId + endpoint + '?order[updated]=desc', {
      signal,
    })
  )
}

const getDashboardTrophies = (userId) => {
  return useQuery('trophy_cabinet', ({ signal }) =>
    get('/users/' + userId + endpoint + '?order[updated]=desc&pageSize=3', {
      signal,
    })
  )
}

const getTrophy = (trophyId: string | null) => {
  if (null === trophyId || trophyId === 'null') {
    return {
      isLoading: false,
      isSuccess: true,
      isError: false,
      data: defaultTrophy(),
      request: () => {},
    }
  }
  return useQuery(['trophy', { trophyId: trophyId }], ({ signal }) =>
    get(`${endpoint}/${trophyId}`, {
      signal,
    })
  )
}

const deleteTrophy = (id) => {
  return remove(endpoint + '/' + id)
}

export const addTrophy = (trophy) => {
  return post(endpoint, removeKeys(trophy, ['trigger']))
}

export const updateTrophy = ({ trophy }) => {
  return patch(endpoint + '/' + trophy.id, removeKeys(trophy, ['trigger']), {
    headers: { 'content-type': 'application/merge-patch+json' },
  })
}

export const sendTrophyTriggerImage = ({ trophy, filename }) => {
  const url = endpoint + '/' + trophy.id + '/trigger'

  // Symfony is not accepting _method to override method.
  if (trophy.trigger === null) {
    return post(url + '?trigger=delete', {
      trigger: null,
    })
  }

  const formData = new FormData()

  if (
    typeof trophy.trigger === 'object' ||
    !trophy.trigger.startsWith('http')
  ) {
    formData.append('trigger', trophy.trigger, filename)
  }

  return post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export default {
  addTrophy,
  updateTrophy,
  deleteTrophy,
  getTrophies,
  getTrophy,
  sendTrophyTriggerImage,
  getDashboardTrophies,
}
