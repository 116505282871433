import React from 'react'
import { StyleSheet, View } from 'react-native'
import { useFormikContext } from 'formik'

import ActivityIndicator from '../ActivityIndicator'
import AppText from '../../components/Text'
import Button from '../Button'
import { defaultConfig } from '../../config/styles'
import trophyQuery from '../../api/query/trophy'
import TrophyImage from './TrophyImage'
import RadioButton from '../RadioButton'
import { ErrorMessage } from '../forms'

function TrophyPicker({ field }) {
  const { isLoading, isSuccess, isError, data, refetch } =
    trophyQuery.getTrophyImages()
  const { setFieldValue, submitCount, values } = useFormikContext()

  return (
    <>
      <ActivityIndicator visible={isLoading} />
      {isError && (
        <>
          <AppText>Couldn't retrieve the Trophy icons.</AppText>
          <Button title="Retry" onPress={() => refetch()} />
        </>
      )}
      <View style={styles.horizontalAlign}>
        {isSuccess &&
          data.length > 0 &&
          data.map((trophy) => (
            <RadioButton
              title={null}
              enabled={values[field] === trophy.id.toString()}
              key={trophy.id.toString()}
              onChange={(...params) => {
                console.log(trophy, params)
                setFieldValue(field, trophy.id.toString())
              }}
              style={trophyStyles}
            >
              <TrophyImage trophy={trophy} />
            </RadioButton>
          ))}
        {isSuccess && data.length === 0 && (
          <AppText>There are currently no Trophies in your Cabinet.</AppText>
        )}
      </View>
      <ErrorMessage
        error={'Choose a trophy icon'}
        visible={submitCount > 0 && !values[field]}
      />
    </>
  )
}

export default TrophyPicker

const trophyStyles = {
  borderRadius: 20,
  height: '100',
  width: '100',
  marginHorizontal: 20,
}

const styles = StyleSheet.create({
  bold: {
    fontWeight: '600',
  },
  horizontalAlign: {
    alignContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    width: '100%',
  },
  icon: {
    fontWeight: '700',
    width: 40,
    height: 40,
    lineHeight: 40,
    borderRadius: 40,
    backgroundColor: defaultConfig.colors.primary,
    color: '#fff',
    textAlign: 'center',
    marginRight: 10,
  },
  image: {
    width: 80,
    height: 80,
  },
  layout: {
    marginTop: 10,
    paddingTop: 10,
  },
})
