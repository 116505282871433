import { useQuery } from 'react-query'

import { get } from '../../utility/api'

const getWelcomeMessage = () => {
  return useQuery('welcome_message', ({ signal }) =>
    get('/welcome_message', {
      signal,
    })
  )
}

export default {
  getWelcomeMessage,
}
