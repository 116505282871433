import React from 'react'
import { View, StyleSheet, TouchableWithoutFeedback } from 'react-native'

import Text from '../Text'
import colors from '../../config/colors'

function RapidReviewButton({ date, meetingName, id, onPress }) {
  return (
    <TouchableWithoutFeedback onPress={onPress}>
      <View style={styles.card}>
        <View style={styles.detailsContainer}>
          <Text style={styles.title} numberOfLines={1}>
            {date}
          </Text>
          <Text style={styles.subTitle} numberOfLines={2}>
            {meetingName}
          </Text>
        </View>
      </View>
    </TouchableWithoutFeedback>
  )
}

const styles = StyleSheet.create({
  card: {
    borderRadius: 10,
    backgroundColor: colors.light,
    marginBottom: 20,
    overflow: 'hidden',
  },
  detailsContainer: {
    padding: 20,
  },
  subTitle: {},
  title: {
    marginBottom: 7,
    color: colors.secondary,
    fontWeight: 'bold',
    flex: 1,
  },
})

export default RapidReviewButton
