import React from 'react'
import { StyleSheet, View } from 'react-native'

import ActivityIndicator from '../../../../components/ActivityIndicator'
import meetingQuery from '../../../../api/query/meeting'
import { defaultStyles } from '../../../../config/styles'
import Screen from '../../../../components/Screen'
import Button from '../../../../components/Button'
import AppText from '../../../../components/Text'
import { useSession } from '../../../../hooks/useSession'
import {
  MeetingList,
  PAST,
} from '../../../../components/rapid-review/MeetingList'
import TitleGraphic from '../../../../components/TitleGraphic'

function ListScreen() {
  const { user } = useSession()
  const getMeetingApi = meetingQuery.getMeetings(user.id)

  return (
    <Screen style={defaultStyles.screen}>
      <TitleGraphic
        title="Team Rapid Reviews"
        graphic={require('../../../../assets/lead-rapid-review.jpg')}
      />
      <View style={defaultStyles.mainContainer}>
        <ActivityIndicator visible={getMeetingApi.isLoading} />
        <View style={{ marginTop: '50px' }}>
          {getMeetingApi.isError && (
            <>
              <AppText style={styles.title}>Meetings</AppText>
              <AppText>Couldn't retrieve the meetings.</AppText>
              <Button title="Retry" onPress={() => getMeetingApi.refetch()} />
            </>
          )}
          {getMeetingApi.isSuccess && (
            <MeetingList
              data={getMeetingApi.data}
              display={PAST}
              title="Past meetings"
              noDataMessage="No past meetings"
            />
          )}
        </View>
      </View>
    </Screen>
  )
}

const styles = StyleSheet.create({
  text: {
    fontSize: 25,
    fontWeight: '600',
    marginVertical: 20,
  },
  title: {
    fontWeight: '600',
  },
})

export default ListScreen
