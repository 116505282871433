import React from 'react'
import { Alert, Image, Platform, StyleSheet, View } from 'react-native'
import { useMutation, useQueryClient } from 'react-query'

import TitleGraphic from '../../../components/TitleGraphic'
import Screen from '../../../components/Screen'
import AppText from '../../../components/Text'
import { cacheBustUrl } from '../../../utility/url'
import colors from '../../../config/colors'
import { defaultStyles } from '../../../config/styles'
import Button from '../../../components/Button'
import ButtonIcon from '../../../components/ButtonIcon'
import trophyCabinetQuery from '../../../api/query/trophyCabinet'
import TrophyImage from '../../../components/trophy/TrophyImage'
import ActivityIndicator from '../../../components/ActivityIndicator'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { AppStackParamList } from '../../../navigation/AppNavigator'
import { useNavigation } from '@react-navigation/native'

export default function ViewScreen({ route }) {
  const navigation = useNavigation<DrawerNavigationProp<AppStackParamList>>()
  const queryClient = useQueryClient()
  const trophyId = route.params.trophyId
  const { isLoading, isSuccess, isError, data, refetch } =
    trophyCabinetQuery.getTrophy(trophyId)

  const trophyDeleteMutation = useMutation(trophyCabinetQuery.deleteTrophy, {
    onSuccess: () => {
      queryClient.invalidateQueries('trophy_cabinet')

      navigation.navigate('TROPHY_CABINET', {
        screen: 'Trophy_List',
      })
    },
    onError: (error) => {
      alert('Failed to delete the Trophy from the Cabinet')
      console.log(error)
    },
  })

  return (
    <Screen style={defaultStyles.screen}>
      <TitleGraphic
        section="Trophy Cabinet"
        title="View your Trophy"
        graphic={require('../../../assets/lead-trophy.jpg')}
      />

      <View style={defaultStyles.mainContainer}>
        <ActivityIndicator visible={isLoading} />
        {isError && (
          <>
            <AppText>Couldn't retrieve the BOSS practices.</AppText>
            <Button title="Retry" onPress={() => refetch()} />
          </>
        )}
        {isSuccess && (
          <>
            <View style={styles.table}>
              <View style={[styles.row]}>
                <TrophyImage trophy={data.trophy} />
                <AppText style={[styles.title]}>{data.trophyName}</AppText>
              </View>
              {data.strengths !== '' && (
                <View style={[styles.row, defaultStyles.lightBox]}>
                  <AppText style={[styles.title]}>Key Strengths</AppText>
                  <AppText>{data.strengths}</AppText>
                </View>
              )}
              {data.insights !== '' && (
                <View style={[styles.row, defaultStyles.lightBox]}>
                  <AppText style={[styles.title]}>Personal Insights</AppText>
                  <AppText>{data.insights}</AppText>
                </View>
              )}
              {data.fullTriggerUrl && (
                <View style={[styles.row, defaultStyles.lightBox]}>
                  <AppText style={[styles.title]}>Your Trigger</AppText>
                  <Image
                    source={{
                      uri: cacheBustUrl(data.fullTriggerUrl),
                      headers: {
                        Pragma: 'no-cache',
                      },
                    }}
                    style={{
                      width: 200,
                      height: 200,
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}
                  />
                </View>
              )}
            </View>
            <View style={styles.buttonRow}>
              <View style={styles.button}>
                <ButtonIcon
                  text="Edit"
                  icon="square-edit-outline"
                  color={colors.primary}
                  backgroundColor={colors.white}
                  borderColor={colors.primary}
                  onPress={() => {
                    console.log('Edit', data)
                    navigation.navigate('TROPHY_CABINET', {
                      screen: 'Trophy_Form',
                      params: {
                        trophyId: data.id,
                        shedAction: false,
                      },
                    })
                  }}
                />
              </View>
              <View style={styles.button}>
                <ButtonIcon
                  text="Delete"
                  icon="delete"
                  color={colors.primary}
                  backgroundColor={colors.white}
                  borderColor={colors.primary}
                  onPress={() => {
                    if (Platform.OS === 'web') {
                      if (
                        confirm('Are you sure you want to delete this Trophy?')
                      ) {
                        trophyDeleteMutation.mutate(data.id)
                      }
                    } else {
                      Alert.alert(
                        'Delete',
                        'Are you sure you want to delete this Trophy?',
                        [
                          {
                            text: 'Yes',
                            style: 'destructive',
                            onPress: () => {
                              trophyDeleteMutation.mutate(data.id)
                            },
                          },
                          { text: 'No', style: 'cancel' },
                        ]
                      )
                    }
                  }}
                />
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.button}>
                <Button
                  title="Back to your trophies"
                  onPress={() => {
                    navigation.navigate('TROPHY_CABINET', {
                      screen: 'Trophy_List',
                    })
                  }}
                />
              </View>
            </View>
          </>
        )}
      </View>
    </Screen>
  )
}

const styles = StyleSheet.create({
  button: {
    width: '50%',
    marginRight: 10,
  },
  buttonRow: {
    alignItems: 'center',
    flexDirection: 'row',
    paddingVertical: 20,
  },
  title: {
    fontWeight: '700',
    height: 40,
    lineHeight: 40,
  },
  row: {
    alignItems: 'center',
    paddingVertical: 20,
    textAlign: 'center',
  },
})
