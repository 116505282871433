import React from 'react'
import { View } from 'react-native'

import Screen from '../../../components/Screen'

import { defaultStyles } from '../../../config/styles'
import TitleGraphic from '../../../components/TitleGraphic'
import useCriticalImpactOpportunity from '../../../hooks/useCriticalImpactOpportunity'
import { RapidReviewing } from './components/RapidReviewing'

export default function FormPauseReviewScreen() {
  const cioId: string = useCriticalImpactOpportunity()

  return (
    <Screen style={defaultStyles.screen}>
      <TitleGraphic
        section="Critical Impact Opportunities"
        title="Create a CIO"
        graphic={require('../../../assets/lead-cio.jpg')}
      />
      <View style={defaultStyles.mainContainer}>
        <RapidReviewing cioId={cioId} />
      </View>
    </Screen>
  )
}
