import { useRoute } from '@react-navigation/native'
import invariant from 'tiny-invariant'

function useCriticalImpactOpportunity() {
  const route = useRoute()
  const cioId: string | null = route.params?.criticalImpactOpportunityId ?? null

  invariant(cioId, 'Critical Impact Opportunity not specified')

  return cioId
}

export default useCriticalImpactOpportunity
