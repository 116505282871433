export const QUERY_KEYS = {
  appState: {
    all: 'app-states',
    item: (key: string) => ['app-state', { key }],
  },
  boss: {
    all: 'bosses',
    item: (id?: string) => ['boss', { id }],
    widget: (id: string) => ['bosses_widget', { id }],
  },
}
