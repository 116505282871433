import React, { useState } from 'react'
import { FlatList, StyleSheet, View } from 'react-native'

import AppText from '../../../../components/Text'
import colors from '../../../../config/colors'
import { defaultStyles } from '../../../../config/styles'
import Screen from '../../../../components/Screen'
import Button from '../../../../components/ButtonIcon'
import { format, formatDistance, parseISO } from 'date-fns'
import TitleGraphic from '../../../../components/TitleGraphic'

import ActivityIndicator from '../../../../components/ActivityIndicator'
import RapidReviewButton from '../../../../components/screen-button/RapidReviewButton'
import InfoButton from '../../../../components/InfoButton'
import { useSession } from '../../../../hooks/useSession'
import { toUUID } from '../../../../utility/resource'
import meetingQuery from '../../../../api/query/meeting'
import AppButton from '../../../../components/Button'
import { useQueryClient } from 'react-query'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { AppStackParamList } from '../../../../navigation/AppNavigator'
import { useNavigation } from '@react-navigation/native'

function InProgressMeetingScreen({ route }) {
  const navigation = useNavigation<DrawerNavigationProp<AppStackParamList>>()
  const queryClient = useQueryClient()
  const { user } = useSession()
  const meetingId = route.params.meetingId
  const { data, isLoading, isError, isSuccess, refetch } =
    meetingQuery.getMeeting(meetingId)
  const getMeetingReviewsApi = meetingQuery.getMeetingReviews(meetingId)
  const meetingOwner = user?.id === toUUID(data?.organiser)

  return (
    <Screen style={defaultStyles.screen}>
      <ActivityIndicator visible={isLoading} />
      <TitleGraphic
        section="Meetings"
        title="Rapid Reviews"
        graphic={require('../../../../assets/lead-rapid-review.jpg')}
      />
      <View style={defaultStyles.mainContainer}>
        <ActivityIndicator visible={isLoading} />
        {isError && (
          <>
            <AppText>Couldn't retrieve the Meeting.</AppText>
            <AppButton title="Retry" onPress={() => refetch()} />
          </>
        )}
        {isSuccess && (
          <>
            <View style={styles.table}>
              <View style={[styles.row]}>
                <AppText style={styles.heading}>Meeting: </AppText>
                <AppText>{data.name}</AppText>
              </View>
              <View style={[styles.row]}>
                <AppText style={styles.heading}>Date: </AppText>
                <AppText>
                  {format(parseISO(data.date), 'do MMMM yyyy')} (in progress)
                </AppText>
              </View>
              <View style={[styles.row, { flexDirection: 'column' }]}>
                <AppText style={[styles.heading, { marginBottom: 20 }]}>
                  Rapid reviews:{' '}
                </AppText>

                {getMeetingReviewsApi.isError && (
                  <>
                    <AppText style={styles.title}>Meetings</AppText>
                    <AppText>Couldn't retrieve the meetings.</AppText>
                    <AppButton
                      title="Retry"
                      onPress={() => getMeetingReviewsApi.refetch()}
                    />
                  </>
                )}
                {getMeetingReviewsApi.isSuccess && (
                  <>
                    <FlatList
                      data={getMeetingReviewsApi.data}
                      keyExtractor={(meeting) => {
                        return meeting.id.toString()
                      }}
                      renderItem={({ item }) => (
                        <RapidReviewButton
                          date={format(parseISO(item.date), 'do MMMM yyyy')}
                          meetingName={item.meetingName}
                          id={item.id}
                          onPress={() => {
                            navigation.navigate('RAPID_REVIEW', {
                              screen: 'Meeting_RapidReview_View',
                              params: {
                                rapidReviewId: item.id,
                              },
                            })
                          }}
                        />
                      )}
                      ListEmptyComponent={
                        <View style={styles.card}>
                          <AppText>No rapid reviews</AppText>
                        </View>
                      }
                    />
                  </>
                )}
              </View>

              <InfoButton
                text="Refresh reviews"
                icon="refresh"
                onPress={() =>
                  queryClient.invalidateQueries(['meeting_rapidReviews'])
                }
              />
            </View>
            <Button
              text="Add rapid review"
              showIcon={false}
              color={colors.primary}
              backgroundColor={colors.white}
              borderColor={colors.primary}
              onPress={() => {
                navigation.navigate('RAPID_REVIEW', {
                  screen: 'RapidReview_Form',
                  params: {
                    meetingId: meetingId,
                  },
                })
              }}
            />
            {meetingOwner && (
              <Button
                text="View suggestions"
                showIcon={false}
                color={colors.white}
                backgroundColor={colors.primary}
                onPress={() => {
                  navigation.navigate('RAPID_REVIEW', {
                    screen: 'Meeting_End',
                    params: {
                      meetingId: meetingId,
                    },
                  })
                }}
                style={{ fontWeight: 600, marginLeft: 0 }}
              />
            )}
          </>
        )}
      </View>
    </Screen>
  )
}

const styles = StyleSheet.create({
  meetingCode: {
    justifyContent: 'flex-start',
    marginVertical: 100,
    width: '100%',
  },
  card: {},
  code: {
    fontSize: 24,
    fontWeight: 'bold',
    letterSpacing: 10,
  },
  panel: {
    backgroundColor: colors.light,
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  text: {
    fontSize: 25,
    fontWeight: '600',
    marginVertical: 20,
  },
  heading: {
    fontWeight: '700',
    minWidth: 150,
  },
  table: {},
  row: {
    flexDirection: 'row',
    paddingVertical: 20,
  },
})

export default InProgressMeetingScreen
