import appState from '../api/query/appState'

export function useAppStateQuery() {
  return appState.getAppStates()

  // return useQuery({
  //   queryKey: QUERY_KEYS.appState.all,
  //   queryFn: () => appState.getAppStates(),
  //   staleTime: ms('5s'),
  // })
}
