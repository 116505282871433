import React, { useState } from 'react'
import { useFormikContext } from 'formik'
import { View } from 'react-native'

import ErrorMessage from './ErrorMessage'
import ContactPicker, {
  IFormOnChangeContactPicker,
} from '../picker/ContactPicker'

interface IFormContactPicker {
  name: string
  group: boolean
  onChange?(props: IFormOnChangeContactPicker): void
  changeUpdateForm?: boolean
  showOther?: boolean
  placeholder: string
  showAdd?: boolean
  teams?: boolean
  width: any
}

function FormContactPicker({
  name,
  group = false,
  onChange,
  changeUpdateForm = false,
  showOther = false,
  placeholder,
  showAdd = false,
  teams = false,
  width,
}: IFormContactPicker) {
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [currentSelected, setCurrentSelected] =
    useState<IFormOnChangeContactPicker>({
      id: null,
      name: '',
    })
  const { setFieldTouched, setFieldValue, values, submitCount, errors } =
    useFormikContext()

  return (
    <View>
      <ContactPicker
        name={name}
        group={group}
        placeholder={placeholder}
        showAdd={showAdd}
        showTeams={teams}
        showOther={showOther}
        width={width}
        onAdd={(member) => {
          if (!currentSelected) {
            setShowErrorMessage(true)
            return
          }

          setFieldValue(name, [...values[name], member])
          setFieldTouched(name)
          setCurrentSelected({
            id: null,
            name: '',
          })
        }}
        onValueChange={(item: IFormOnChangeContactPicker) => {
          setShowErrorMessage(showErrorMessage && item === undefined)
          setCurrentSelected(item)

          if (onChange) {
            onChange(item)
          }

          if (changeUpdateForm) {
            setFieldValue(name, item)
            setFieldTouched(name)
          }
        }}
      />
      <ErrorMessage error={'Select a team member'} visible={showErrorMessage} />
      <ErrorMessage
        error={errors[name]}
        visible={submitCount > 0 && errors[name]}
      />
    </View>
  )
}

export default FormContactPicker
