import { QueryFunctionContext, useQuery } from 'react-query'
import { get } from '../../utility/api'
import { IResourceItem } from 'types/ResourceItem'
import { IResourceCollectionItem } from 'types/ResourceCollectionItem'

const endpoint = '/resources'

const getResources = () => {
  return useQuery<IResourceCollectionItem[]>(['resources'], ({ signal }) =>
    get(endpoint, {
      signal,
    })
  )
}

const getResource = (slug: string) => {
  return useQuery<IResourceItem>(
    ['resource', slug],
    ({ signal }: QueryFunctionContext) =>
      get(endpoint + '/' + slug, { signal }),
    {
      retry: (failureCount: number, error: unknown) => {
        if (error instanceof Error) {
          if (error.message === 'Request failed with status code 403') {
            return false
          }
        }

        return failureCount <= 2
      },
    }
  )
}

export default {
  getResources,
  getResource,
}
