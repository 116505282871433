import React from 'react'
import { StyleSheet, View } from 'react-native'
import { useMutation, useQueryClient } from 'react-query'
import * as Yup from 'yup'

import criticalImpactOpportunityQuery from '../../../../api/query/criticalImpactOpportunity'

import { defaultStyles } from '../../../../config/styles'
import { ICio } from 'types/cio'
import AppText from '../../../../components/Text'
import {
  ErrorMessage,
  Form,
  FormField,
  SubmitButton,
} from '../../../../components/forms'
import { FieldArray } from 'formik'
import { useNavigation } from '@react-navigation/native'
import { Sections } from '../../../../utility/cioSection'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { AppStackParamList } from '../../../../navigation/AppNavigator'

export function AnythingAdd({ cioId }) {
  const navigation = useNavigation<DrawerNavigationProp<AppStackParamList>>()
  const { isLoading, isSuccess, isError, data, refetch } =
    criticalImpactOpportunityQuery.getCriticalImpactOpportunity(cioId)

  const queryClient = useQueryClient()
  const cioUpdateMutation = useMutation(
    criticalImpactOpportunityQuery.updateCriticalImpactOpportunity,
    {
      onSuccess: (data: ICio) => {
        queryClient.invalidateQueries('cios')
        queryClient.invalidateQueries([
          'cio',
          { criticalImpactOpportunityId: data.id },
        ])

        navigation.navigate('CRITICAL_IMPACT_OPPORTUNITY', {
          screen: 'CriticalImpactOpportunity_Form_Pause_RapidReview',
          params: {
            criticalImpactOpportunityId: data.id,
          },
        })
      },
      onError: (error) => {
        alert('Failed to update the CIO')
        console.log(error)
      },
    }
  )

  if (!data) {
    return null
  }

  return (
    <>
      <AppText style={styles.bold}>
        In your preparation, you said you would follow up by:
      </AppText>

      <View style={[defaultStyles.lightBox]}>
        <AppText>
          {data['prepAfter'] ? data['prepAfter'] : 'You skipped this question'}
        </AppText>
      </View>

      <AppText style={styles.bold}>Anything to add?</AppText>

      <Form
        onSubmit={(newCio) => {
          newCio.section = Sections.pause
          cioUpdateMutation.mutate(newCio)
        }}
        initialValues={data}
        validationSchema={Yup.object().shape({
          anythingElse: Yup.array()
            .of(Yup.string().required().min(1).label('This'))
            .label('Anything else?'),
        })}
      >
        <FieldArray
          name="anythingElse"
          render={(arrayHelpers) => (
            <div>
              {arrayHelpers.form.values.anythingElse.map((item, index) => {
                return (
                  <div key={index}>
                    <button
                      type="button"
                      onClick={() => {
                        arrayHelpers.remove(index)
                      }}
                      style={{
                        borderWidth: 0,
                        fontWeight: 600,
                        position: 'absolute',
                        marginTop: 15,
                        right: 15,
                        backgroundColor: '#ddd',
                        color: '#777',
                        borderRadius: 10,
                        width: 20,
                        height: 20,
                        fontSize: 16,
                        zIndex: 10,
                      }}
                    >
                      -
                    </button>
                    <FormField name={`anythingElse.${index}`} />
                  </div>
                )
              })}

              <button
                type="button"
                onClick={() => {
                  arrayHelpers.push('')
                }}
                style={{
                  backgroundColor: '#ffffff',
                  borderWidth: 0,
                  fontFamily: 'Open Sans',
                  fontSize: 16,
                  fontWeight: 600,
                  color: '#3EACBE',
                  marginTop: 20,
                  width: '100%',
                }}
              >
                + Add another item
              </button>

              <ErrorMessage
                error={arrayHelpers.form.errors['anythingElse']}
                visible={arrayHelpers.form.touched['anythingElse']}
              />
            </div>
          )}
        />

        <SubmitButton title="That's sufficient" />
      </Form>
    </>
  )
}

const styles = StyleSheet.create({
  bold: {
    fontWeight: '600',
  },
})
