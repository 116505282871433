import React from 'react'
import { Platform, View } from 'react-native'
import { WebView } from 'react-native-webview'

import { defaultStyles } from '../../../config/styles'
import Screen from '../../../components/Screen'
import Title from '../../../components/Title'
import VimeoPlayer from '../../../components/VimeoPlayer'
import resourceQuery from '../../../api/query/resource'
import ActivityIndicator from '../../../components/ActivityIndicator'
import AppText from '../../../components/Text'
import AppButton from '../../../components/Button'
import RenderButton from './components/RenderButton'

function ViewScreen({ route }) {
  const { slug } = route.params
  const { isLoading, isSuccess, isError, data, refetch } =
    resourceQuery.getResource(slug)

  const buttons: string[] = []

  if (slug === 'critical-impact-opportunities-cios') {
    buttons.push('CIO')
  } else if (slug === 'rapid-review') {
    buttons.push('RR')
  } else if (slug === 'home-support') {
    buttons.push('RR')
  } else if (slug === 'bossing-your-brains') {
    buttons.push('BOSS')
  } else if (slug === 'building-a-new-habit') {
  } else if (slug === 'future-stories') {
    buttons.push('TROPHY')
  } else if (slug === 'looking-after-your-shed') {
    buttons.push('SHED')
  } else if (slug === 'mapping-out-your-narrative') {
    buttons.push('TROPHY')
  } else if (slug === 'proud-past-trophies') {
    buttons.push('TROPHY')
  } else if (slug === 'the-s-a-i-l-practice') {
  } else if (slug === 'the-three-brains-their-energies') {
  }

  return (
    <Screen style={defaultStyles.screen}>
      <Title style={{ fontSize: 25, color: 'black' }}>{data?.title}</Title>

      <View style={defaultStyles.mainContainer}>
        <ActivityIndicator visible={isLoading} />
        {isError && (
          <>
            <Title style={{ fontSize: 25, color: 'black' }}>
              Error Loading Animation
            </Title>
            <AppText>
              Couldn't retrieve the animation or you do not have access to this
              animation.
            </AppText>
            <AppButton title="Retry" onPress={() => refetch()} />
          </>
        )}
        {isSuccess && !!data.videoUrl && (
          <VimeoPlayer videoUrl={data.videoUrl} />
        )}
        {Platform.OS === 'web' ? (
          <div
            dangerouslySetInnerHTML={{
              __html: data?.resource ?? '',
            }}
            style={{ userSelect: 'auto', WebkitUserSelect: 'auto' }}
          />
        ) : (
          <WebView
            originWhitelist={['*']}
            source={{ html: data?.resource ?? '' }}
            style={{ marginTop: 20 }}
            selectable
          />
        )}
        {isSuccess && (
          <div>
            {buttons.map((buttonType) => (
              <RenderButton key={buttonType} buttonType={buttonType} />
            ))}
          </div>
        )}
      </View>
    </Screen>
  )
}

export default ViewScreen
