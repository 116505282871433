import { useQuery } from 'react-query'

import { displayInNewTab, get, post } from '../../utility/api'
export const endpoint = '/sheds'

export enum ShedSections {
  sleep = 'sleep',
  hydration = 'hydration',
  exercise = 'exercise',
  diet = 'diet',
}

export interface ShedRatingType {
  date: string
  sleep: number
  hydration: number
  exercise: number
  diet: number
}

export interface ShedActionType {
  sections: ShedSections[]
  actions: string[]
  reviewDate: string
}

export interface ShedRequireActionType {
  sections: ShedSections[]
  rating: 'high' | 'low'
  length: number
}

export interface ShedStateType extends ShedRatingType {
  found: boolean
  date: string
}

const ShedRatings = () => {
  return useQuery('shedRatings', ({ signal }) =>
    get(endpoint + '?date[after]=-30+day&date[before]=today', {
      signal,
    })
  )
}

const ShedRequireAction = () => {
  return useQuery<ShedRequireActionType>('shedActions', ({ signal }) =>
    get(endpoint + '/actions', {
      signal,
    })
  )
}

const ShedRating = (date?: string) => {
  const shedDate = date ?? 'today'

  return useQuery<ShedRatingType>({
    queryKey: ['shedRating', { shedDate }],
    queryFn: ({ signal }) =>
      get(endpoint + '/' + shedDate, {
        signal,
      }),
    retry: (failureCount, error) => {
      if (error instanceof Error) {
        if (error.message === 'Request failed with status code 404') {
          return false
        }
      }

      return failureCount <= 2
    },
  })
}

const UpdateShedAction = async (data: ShedActionType) => {
  return post(endpoint + '/actions', data)
}

const UpdateShedRating = async (data: ShedRatingType) => {
  return post(endpoint, data)
}

const downloadPdf = async () => {
  return await displayInNewTab(endpoint + '/pdf', {})
}

export default {
  ShedRatings,
  ShedRating,
  ShedRequireAction,
  UpdateShedAction,
  UpdateShedRating,
  downloadPdf,
}
