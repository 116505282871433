import React, { useContext } from 'react'
import { StyleSheet, View, Image } from 'react-native'

import Button from '../../../components/Button'
import WelcomeMessage from '../../../components/WelcomeMessage'
import { ScrollToTopContext } from '../../../components/startup/components/ScrollToTopContext'

interface IWelcomeScreen {
  onClose?: () => void
}

const WelcomeScreen: React.FC<IWelcomeScreen> = ({ onClose }) => {
  const scrollToTop = useContext(ScrollToTopContext)

  return (
    <View>
      <View style={styles.logoContainer}>
        <Image
          style={styles.logo}
          source={require('../../../assets/logo-icon.png')}
        />
      </View>
      <View style={[styles.background]}>
        <WelcomeMessage onload={scrollToTop} />
        <View style={styles.buttonsContainer}>
          <Button
            title="Start using the app"
            onPress={() => {
              if (onClose) {
                onClose()
              }
            }}
            style={{ paddingHorizontal: 100 }}
          />
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  background: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  buttonsContainer: {},
  logo: {
    width: 50,
    height: 50,
  },
  logoContainer: {
    // position: "absolute",
    // top: 70,
    alignItems: 'center',
    marginTop: 20,
  },
  mainContainer: { padding: 20, textAlign: 'center', fontFamily: 'Open Sans' },
  tagline: {
    fontSize: 25,
    fontWeight: '600',
    paddingVertical: 20,
  },
})

export default WelcomeScreen
