import React, { useState } from 'react'
import { GestureResponderEvent, View } from 'react-native'
import { useFormikContext } from 'formik'

import colors from '../../config/colors'
import { defaultStyles } from '../../config/styles'
import AppText from '../Text'
import ButtonIcon from '../ButtonIcon'
import RolePicker from './RolePicker'
import ContactPicker from '../forms/FormContactPicker'
import UserShareList from '../picker/UserShareList'
import { ICio } from 'types/cio'

const Contributors = () => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext<ICio>()
  const [contributing, setContributing] = useState({
    name: '',
    teamId: '',
    userId: '',
    role: '',
    roleString: '',
  })

  return (
    <>
      <ContactPicker
        name={`contributor`}
        placeholder="Who is contributing?"
        width="100%"
        group={true}
        teams={true}
        onChange={({ id, name, type }) => {
          let userId = ''
          let teamId = ''

          if (type === 'team') {
            teamId = id
          } else {
            userId = id
          }

          setContributing({
            ...contributing,
            name: name,
            userId: userId,
            teamId: teamId,
          })
        }}
      />
      <AppText style={defaultStyles.questionWithPadding}>Their role:</AppText>

      <RolePicker
        onChange={({ role, roleString }) => {
          setContributing({
            ...contributing,
            role: role,
            roleString: roleString,
          })
        }}
      />

      <View style={{ flexDirection: 'row' }}>
        <View style={{ flex: 1 }}>
          <ButtonIcon
            text="Add contributor"
            icon="plus-circle"
            color={colors.primary}
            backgroundColor={colors.white}
            borderColor={colors.primary}
            onPress={(event: GestureResponderEvent) => {
              if (
                (!contributing.userId && !contributing.teamId) ||
                !contributing.role
              ) {
                return
              }

              const contributors = values['contributors']

              if (contributing.userId) {
                contributors.push({
                  name: contributing.name,
                  user: '/users/' + contributing.userId,
                  team: null,
                  role: contributing.role,
                  roleString: contributing.roleString,
                })
              } else if (contributing.teamId) {
                contributors.push({
                  name: contributing.name,
                  team: '/teams/' + contributing.teamId,
                  user: null,
                  role: contributing.role,
                  roleString: contributing.roleString,
                })
              }

              setFieldValue('contributors', contributors)
              setFieldTouched('contributors')
            }}
          />
        </View>
      </View>

      <View style={[defaultStyles.lightBox]}>
        <UserShareList
          field={'contributors'}
          title="Contributors"
          noItemMessage="No contributors"
        />
      </View>
    </>
  )
}

export default Contributors
