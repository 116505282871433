import React from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'

import { defaultConfig } from '../../config/styles'
import AppText from '../Text'
import { Sections } from '../../utility/cioSection'

const borderRadius = 15

interface IPanel {
  enabled: boolean
  text: string
  section: Sections
  onPress: (section: Sections) => {}
}

function Panel({ enabled, section, text, onPress, extraStyles = {} }) {
  return (
    <TouchableOpacity
      style={[
        styles.panel,
        extraStyles,
        {
          borderColor: higlightColour(enabled),
          backgroundColor: backgroundColour(enabled),
        },
      ]}
      onPress={() => {
        onPress(section)
      }}
    >
      <AppText style={[styles.panelText, { color: higlightColour(enabled) }]}>
        {text}
      </AppText>
    </TouchableOpacity>
  )
}

function backgroundColour(enabled: boolean) {
  return enabled ? defaultConfig.colors.lightPrimary : ''
}

function higlightColour(enabled: boolean) {
  return enabled ? defaultConfig.colors.primary : defaultConfig.colors.medium
}

const styles = StyleSheet.create({
  panel: {
    marginBottom: 10,
    borderRadius: borderRadius,
    flex: 1,
    borderWidth: 3,
    textAlign: 'center',
  },
  panelText: {
    fontWeight: '600',
    fontSize: 25,
    height: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

export default Panel
