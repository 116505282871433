import { useQuery } from 'react-query'

import { get, post, patch, remove } from '../../utility/api'
import { IRapidReview } from 'types/rapidReview'

export const endpoint = '/rapid_reviews'

const getRapidReview = (rapidReviewId: string) => {
  let query = useQuery<IRapidReview>(
    ['rapidReview', { rapidReviewId: rapidReviewId }],
    ({ signal }) =>
      get(endpoint + '/' + rapidReviewId, {
        signal,
      }),
    {
      refetchOnWindowFocus: false,
    }
  )

  return query
}

const getRapidReviews = (userId) => {
  return useQuery<IRapidReview[]>('rapidReviews', ({ signal }) =>
    get('/users/' + userId + endpoint + '?order[date]=desc', {
      signal,
    })
  )
}

export const addRapidReview = ({ rapidReview }) => {
  return post(endpoint, rapidReview)
}

export const updateRapidReview = ({ rapidReview }) => {
  return patch(endpoint + '/' + rapidReview.id, rapidReview, {
    headers: { 'content-type': 'application/merge-patch+json' },
  })
}

const deleteRapidReview = (rapidReviewId: string) => {
  return remove(endpoint + '/' + rapidReviewId)
}

export default {
  getRapidReviews,
  getRapidReview,
  addRapidReview,
  updateRapidReview,
  deleteRapidReview,
}
