import React, { useState } from 'react'
import { useFormikContext } from 'formik'
import { View } from 'react-native'
import { sub } from 'date-fns'

import WhenPicker, { IWhenOption } from '../picker/WhenPicker'
import AppText from '../Text'
import ButtonIcon from '../ButtonIcon'
import { defaultConfig } from '../../config/styles'
import { INudgeOption } from 'types/nudgeOption'
import IsNotificationDisabled from '../IsNotificationDisabled'

interface IFormWhenFormat {
  unit: string
  num: number
  sub: boolean
}

export interface IFormWhenOption {
  label: string
  format: IFormWhenFormat
}

interface IFormWhenPicker {
  name: string
  onChange?(item: string | undefined): void
  placeholder?: string
  width: any
  formatSource: string
  options: IFormWhenOption[]
  autoSubmit?: boolean
  nudgeDetails?: { title: string; description: string } | null
}

function FormWhenPicker({
  name,
  onChange,
  placeholder,
  width,
  formatSource,
  options,
  autoSubmit = true,
  nudgeDetails = null,
}: IFormWhenPicker) {
  const { handleSubmit, setFieldValue, values } = useFormikContext()
  const [item, setItem] = useState<string | undefined>(undefined)

  const now: Date = new Date()
  const fromDate: Date | null = values[formatSource] ?? null

  const formattedOptions: IWhenOption[] = []

  if (fromDate) {
    // console.log(values[name].when, typeof values[name].when)
    formattedOptions.push({
      label: 'Select when to nudge',
      value: undefined,
    })

    options.map((option) => {
      const value: Date = sub(fromDate, {
        weeks: option.format.unit == 'weeks' ? option.format.num : 0,
        days: option.format.unit == 'days' ? option.format.num : 0,
      })

      if (now > value) {
        return
      }

      formattedOptions.push({
        label: option.label,
        value: value,
      })
    })
  }

  if (formattedOptions.length < 2) {
    return (
      <View style={{ marginTop: 20 }}>
        <AppText>
          No nudges available (Date is too soon, or in the past)
        </AppText>
      </View>
    )
  }

  return (
    <View>
      <WhenPicker
        name={name}
        placeholder={placeholder}
        width={width}
        options={formattedOptions}
        onValueChange={(item: string | undefined) => {
          setItem(item)

          if (onChange) {
            onChange(item)
          }

          if (!autoSubmit) {
            setFieldValue(name, {
              when: item,
              title: nudgeDetails?.title,
              description: nudgeDetails?.description,
            } as INudgeOption)
          }
        }}
      />

      {autoSubmit && (
        <View style={{ marginTop: 20 }}>
          <ButtonIcon
            text="Add nudge"
            icon={'bell'}
            color={defaultConfig.colors.white}
            backgroundColor={defaultConfig.colors.primary}
            onPress={() => {
              setFieldValue(name, {
                when: item,
              })

              handleSubmit()
            }}
          />
        </View>
      )}

      <IsNotificationDisabled />
    </View>
  )
}

export default FormWhenPicker
