import React, { useEffect } from 'react'

import { dashboardStyles } from '../../../../../config/styles'
import { IWidget } from './IWidget'
import SharedButtons from '../../../SharedWithMe/components/SharedButtons'
import TitleGraphic from '../../../../../components/TileGraphic'

export const SharedWithMeWidgetName = 'sharedWithMe'

export const SharedWithMeWidget: React.FC<IWidget> = ({
  editing,
  onLoaded,
}) => {
  useEffect(() => {
    onLoaded(SharedWithMeWidgetName)
  }, [onLoaded])

  return (
    <div style={dashboardStyles.block}>
      <TitleGraphic
        title="Shared With Me"
        graphic={require('/assets/lead-cio.jpg')}
        offset={false}
      />
      <SharedButtons editing={editing} padding={true} />
    </div>
  )
}
