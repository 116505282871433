import { useQuery } from 'react-query'

import { get } from '../../utility/api'

const ShedTips = (section: string) => {
  return useQuery(
    'shedTips',
    ({ signal }) =>
      get('/sheds/' + section + '/tips', {
        signal,
      }),
    {
      retry: (failureCount, error) => {
        if (error.response.status === 404) {
          return false
        }

        return failureCount <= 2
      },
    }
  )
}

export default {
  ShedTips,
}
