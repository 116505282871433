import React from 'react'
import { StyleSheet, View } from 'react-native'
import { useNavigation } from '@react-navigation/native'

import { defaultStyles } from '../../../../config/styles'
import AppText from '../../../../components/Text'
import TickButton from '../../../../components/TickButton'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { AppStackParamList } from '../../../../navigation/AppNavigator'

export function RapidReviewing({ cioId }) {
  const navigation = useNavigation<DrawerNavigationProp<AppStackParamList>>()

  return (
    <>
      <AppText style={defaultStyles.questionWithPadding}>
        Are you ready to Rapid Review your recent CIO?
      </AppText>

      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <View style={{ width: '49%' }}>
          <TickButton
            title="Yes"
            enabled={true}
            onPress={() => {
              navigation.navigate('RAPID_REVIEW', {
                screen: 'RapidReview_Form_With_CIO',
                params: {
                  criticalImpactOpportunityId: cioId,
                },
              })
            }}
          />
        </View>
        <View style={{ width: '49%' }}>
          <TickButton
            title="No"
            onPress={() => {
              navigation.navigate('CRITICAL_IMPACT_OPPORTUNITY', {
                screen: 'CriticalImpactOpportunity_Form',
                params: {
                  criticalImpactOpportunityId: cioId,
                },
              })
            }}
          />
        </View>
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  bold: {
    fontWeight: '600',
  },
})
