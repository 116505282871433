import AsyncStorage from '@react-native-async-storage/async-storage'

const storeToken = (key: string, authToken): void => {
  try {
    AsyncStorage.setItem(key, authToken)
  } catch (error) {
    console.log('Error storing the auth token', error)
  }

  return
}

const getToken = async (key: string) => {
  try {
    return await AsyncStorage.getItem(key)
  } catch (error) {
    console.log('Error getting the auth token', error)
  }

  return null
}

const removeToken = async (key: string) => {
  try {
    await AsyncStorage.removeItem(key)
  } catch (error) {
    console.log('Error removing the auth token', error)
  }
}

export default { getToken, removeToken, storeToken }
