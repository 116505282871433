import React from 'react'
import { View } from 'react-native'
import AppText from '../Text'
import Anchor from '../Anchor'

const GravatarText = () => {
  return (
    <View>
      <AppText>
        We understand that setting up an image (avatar) of yourself for your
        profile may well be important to you. In this current version of the
        Habitbuilder app, actually doing it may seem like a bit of a faff.
        <br />
        <b>OR</b> you can see it as a test to discover just how important your
        image is to you!
      </AppText>
      <br />

      <AppText>
        To assign your avatar you need to go to Gravatar (see what they did
        there?).
        <br />
        The link is below. Randomly, they then ask you to sign in/register with
        Wordpress. What’s good about that is that, subsequently, every time you
        register with your email into another site that uses Wordpress, your
        avatar is automatically assigned to you!
      </AppText>
      <br />

      <AppText>
        So, we would ask you to give yourself 5 minutes and persist – as
        follows:
      </AppText>
      <br />

      <AppText>
        Go to Gravatar{' '}
        <Anchor
          href="https://en.gravatar.com"
          newWindow={true}
          style={{ color: '#0000ff' }}
        >
          https://en.gravatar.com
        </Anchor>
      </AppText>
      <br />

      <AppText>
        Click the blue Create button and follow the instructions until you can
        upload your favourite photo!
        <br />
        Then when you sign in to our app again, voilà!
      </AppText>
      <br />

      <AppText>
        Thanks
        <br />
        The CI Team
      </AppText>
    </View>
  )
}

export default GravatarText
