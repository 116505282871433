import React, { useState } from 'react'
import WelcomeMessageModal from './components/WelcomeMessageModal'
import RitualModal from './components/RitualModal'
import NudgeMessageModal from './components/NudgeMessageModal'
import CiWelcomeMessageModal from './components/CiWelcomeMessageModal'

function StartUpScreen() {
  const [companyWaitWelcome, setCompanyWaitWelcome] = useState(true)
  const [ciWaitWelcome, setCiWaitWelcome] = useState(true)

  const companyWelcome = (
    <WelcomeMessageModal
      onFinish={() => {
        setCompanyWaitWelcome(false)
      }}
    />
  )

  const ciWelcome = (
    <CiWelcomeMessageModal
      onFinish={() => {
        setCiWaitWelcome(false)
      }}
    />
  )

  if (ciWaitWelcome) {
    return ciWelcome
  }

  if (companyWaitWelcome) {
    return companyWelcome
  }

  return (
    <>
      <NudgeMessageModal />
      <RitualModal />
    </>
  )
}

export default StartUpScreen
