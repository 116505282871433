import React from 'react'
import { FormField } from '.'

function PickerEmail({ name }) {
  return (
    <FormField
      name={name}
      autoCapitalize="none"
      autoCorrect={false}
      icon="email"
      keyboardType="email-address"
      placeholder="Email"
      textContentType="emailAddress"
    />
  )
}

export default PickerEmail
