import React from 'react'
import { Text, TouchableOpacity, View } from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'

import { defaultConfig } from '../config/styles'
import IconTextButton from './IconTextButton'

interface IWarningButton {
  text: string
  size?: number
  showIcon?: boolean
  icon?
  onPress: any
  style?: object
}

function WarningButton({
  text,
  size = 15,
  showIcon = true,
  icon = 'alert',
  onPress = {},
  style = {},
}: IWarningButton) {
  return (
    <IconTextButton
      text={text}
      size={size}
      showIcon={showIcon}
      icon={icon}
      color={defaultConfig.colors.red}
      onPress={onPress}
      style={style}
    />
  )
}

export default WarningButton
