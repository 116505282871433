/*
 * https://github.com/jaydenseric/apollo-upload-client/issues/216#issuecomment-991982646
 */

import { Platform } from 'react-native'

/** web platform: blob. */
const convertBase64ToBlob = async (base64) => {
  if (null === base64) {
    return null
  }

  const response = await fetch(base64)
  return await response.blob()
}

/** android/ios platform: ReactNativeFile.*/
const createReactNativeFile = (uri) => {
  return uri
  // const file = new ReactNativeFile({
  //   uri,
  //   type: mime.lookup(uri) || 'image',
  //   name: `file-${Date.now()}`,
  // });
  // return file;
}

const convertUploadedFile = async (uri) => {
  return Platform.OS === 'web'
    ? await convertBase64ToBlob(uri)
    : createReactNativeFile(uri)
}

export { convertBase64ToBlob, createReactNativeFile, convertUploadedFile }
