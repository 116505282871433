import { useQuery } from 'react-query'

import { get, post, patch } from '../../utility/api'
import { IUserItem } from 'types/UserItem'
export const endpoint = '/users'

// const register = (userInfo) => {
//   return post('/users', userInfo)
// }

const getUser = (userId: string) => {
  return useQuery<IUserItem>(['user', { userId: userId }], ({ signal }) =>
    get(`/users/${userId}`, {
      signal,
    })
  )
}

const getCompanyUsers = () => {
  return useQuery(['company_user'], ({ signal }) =>
    get(`${endpoint}?order[name]=asc`, {
      signal,
      config: {
        transformResponse: [
          function (data) {
            const users: Array<object> = []
            const colleges = JSON.parse(data)

            if (!colleges) {
              return []
            }

            colleges.map((collegue) => {
              users.push({
                id: collegue['id'],
                name: collegue['name'],
                group: {
                  id: collegue['group'] ? collegue['group']['id'] : 0,
                  name: collegue['group']
                    ? collegue['group']['name']
                    : 'Unassigned',
                },
              })
            })

            return users
          },
        ],
      },
    })
  )
}

const updateProfile = ({ userId, profileDetails }) =>
  patch(`/users/${userId}`, profileDetails, {
    headers: { 'content-type': 'application/merge-patch+json' },
  })

const updatePreRitual = ({ userId, preRitual }) =>
  patch(
    `/users/${userId}`,
    { preRitual: preRitual },
    {
      headers: { 'content-type': 'application/merge-patch+json' },
    }
  )

const updateRituals = ({ userId, rituals }) =>
  patch(
    `/users/${userId}`,
    { rituals: rituals },
    {
      headers: { 'content-type': 'application/merge-patch+json' },
    }
  )

export default {
  // register,
  getUser,
  getCompanyUsers,
  updateProfile,
  updatePreRitual,
  updateRituals,
}
