import React from 'react'

import { useFormikContext } from 'formik'

interface Props {
  field: string
}

export function DisplayFieldValue({ field }: Props) {
  const { values } = useFormikContext()

  return values[field] ?? ''
}
