import React from 'react'
import { Platform } from 'react-native'
import { string, func } from 'prop-types'
import WebView from 'react-native-autoheight-webview'

const VimeoPlayer = ({ videoUrl, onError }) => {
  return (
    <>
      {Platform.OS === 'web' ? (
        <div
          dangerouslySetInnerHTML={{
            __html: `
            <html>
              <body>
                <iframe src="${videoUrl}" width="100%" height="400" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                <script src="https://player.vimeo.com/api/player.js"></script>
              </body>
            </html>
          `,
          }}
        />
      ) : (
        <WebView
          style={style}
          onError={onError}
          allowsFullscreenVideo
          scrollEnabled={false}
          automaticallyAdjustContentInsets
          source={{
            html: `
            <html>
              <body>
                <iframe src="${videoUrl}" width="100%" height="400" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                <script src="https://player.vimeo.com/api/player.js"></script>
              </body>
            </html>
          `,
          }}
        />
      )}
    </>
  )
}

const style = {
  height: '400',
  maxWidth: '100%',
}

VimeoPlayer.propTypes = {
  videoUrl: string,
  onError: func,
}

export default VimeoPlayer
