import React from 'react'
import { FlatList, StyleSheet } from 'react-native'
import { format, parseISO } from 'date-fns'

import AppText from '../Text'
import CriticalImpactOpportunityButton from '../screen-button/CriticalImpactOpportunityButton'

function List({ title, navigation, data, displayWhenEmpty = false }) {
  if ((!data || !data.length) && !displayWhenEmpty) {
    return null
  }

  return (
    <>
      <AppText style={styles.text}>{title}</AppText>
      <FlatList
        data={data}
        keyExtractor={(criticalImpactOpportunity) => {
          return criticalImpactOpportunity.id.toString()
        }}
        renderItem={({ item }) => (
          <CriticalImpactOpportunityButton
            date={format(parseISO(item.date), 'do MMMM yyyy')}
            meetingName={item.what}
            id={item.id}
            onPress={() => {
              navigation.navigate(
                item.statusString === 'Draft'
                  ? 'CriticalImpactOpportunity_Form'
                  : 'CriticalImpactOpportunity_Menu',
                {
                  criticalImpactOpportunityId: item.id,
                }
              )
            }}
          />
        )}
        ListEmptyComponent={<AppText>There are currently no CIOs</AppText>}
      />
    </>
  )
}

const styles = StyleSheet.create({
  text: {
    fontSize: 25,
    fontWeight: '600',
    marginVertical: 20,
  },
})

export default List
