import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'

import header from '../components/part/Header'
import IntroScreen from '../screens/app/Boom/IntroScreen'
import BoomFormScreen from '../screens/app/Boom/BoomFormScreen'
import BoomShareScreen from '../screens/app/Boom/BoomShareScreen'
import BoomScreen from '../screens/app/Boom/BoomScreen'
import { BoomParamList } from './BoomParamList'

const Stack = createStackNavigator<BoomParamList>()

const BoomNavigator = () => (
  <Stack.Navigator initialRouteName="Boom_Intro">
    <Stack.Screen
      name="Boom_Intro"
      component={IntroScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showHamburger: false,
            showBackButton: true,
            showNudgifications: true,
          }),
        }
      }}
    />
    <Stack.Screen
      name="Boom"
      component={BoomScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showHamburger: false,
            showBackButton: true,
            showNudgifications: true,
          }),
        }
      }}
    />
    <Stack.Screen
      name="Boom_Edit"
      component={BoomFormScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showHamburger: false,
            showBackButton: true,
            showNudgifications: true,
          }),
        }
      }}
    />
    <Stack.Screen
      name="Boom_Share"
      component={BoomShareScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showHamburger: false,
            showBackButton: true,
            showNudgifications: true,
          }),
        }
      }}
    />
  </Stack.Navigator>
)

export default BoomNavigator
