import React, { useState } from 'react'
import { View } from 'react-native'
import * as Yup from 'yup'
import { useMutation, useQueryClient } from 'react-query'

import { Form, SubmitButton } from '../../../../components/forms'

import Screen from '../../../../components/Screen'
import AppText from '../../../../components/Text'
import AppButton from '../../../../components/Button'
import { defaultStyles } from '../../../../config/styles'
import meetingQuery from '../../../../api/query/meeting'

import FormSuggestionChoice from '../../../../components/forms/FormSuggestionChoice'
import { toIRI } from '../../../../utility/resource'
import TitleGraphic from '../../../../components/TitleGraphic'
import ActivityIndicator from '../../../../components/ActivityIndicator'
import FormContactPicker from '../../../../components/forms/FormContactPicker'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { AppStackParamList } from '../../../../navigation/AppNavigator'
import { useNavigation } from '@react-navigation/native'

export default function SuggestionScreen({ route }) {
  const navigation = useNavigation<DrawerNavigationProp<AppStackParamList>>()
  const queryClient = useQueryClient()

  const meetingId = route.params.meetingId
  const getMeetingApi = meetingQuery.getMeeting(meetingId)
  const getMeetingSuggestionApi = meetingQuery.getMeetingSuggestions(meetingId)

  const endMutation = useMutation(meetingQuery.endMeeting, {
    onSuccess: (data) => {
      queryClient.invalidateQueries('rapidReviews')
      queryClient.invalidateQueries(['rapidReview', { rapidReviewId: data.id }])

      navigation.navigate('RAPID_REVIEW', {
        screen: 'Meeting_Saved',
      })
    },
    onError: (error) => {
      alert('Failed to update the meeting')
      console.log(error)
    },
  })

  const handleSubmit = async (meetingDetails) => {
    const meetingUpdate = { ...meetingDetails }

    if (
      getMeetingSuggestionApi.isSuccess &&
      getMeetingSuggestionApi.data.length > 0
    ) {
      meetingUpdate.suggestionOwner = toIRI(
        meetingDetails.suggestionOwner.id,
        'users'
      )
    } else {
      delete meetingUpdate.suggestion
      delete meetingUpdate.suggestionOwner
    }

    endMutation.mutate(meetingUpdate)
  }

  const validationSchema = Yup.object().shape({
    suggestion: Yup.string()
      .min(1)
      .typeError('Which suggestion is being taken forward?')
      .label('Improvement'),
    suggestionOwner: Yup.object().shape({
      id: Yup.string()
        .required()
        .min(1)
        .typeError('Who is taking the improvement forward?'),
      name: Yup.string(),
    }),
  })

  return (
    <Screen style={defaultStyles.screen}>
      <TitleGraphic
        section="Meetings"
        title="Suggestions"
        graphic={require('../../../../assets/lead-rapid-review.jpg')}
      />
      <View style={defaultStyles.mainContainer}>
        <ActivityIndicator visible={getMeetingApi.isLoading} />
        {getMeetingApi.isError && (
          <>
            <AppText>Couldn't retrieve the Meeting.</AppText>
            <AppButton title="Retry" onPress={() => getMeetingApi.refetch()} />
          </>
        )}
        {getMeetingApi.isSuccess && (
          <>
            <Form
              initialValues={{
                id: meetingId,
                suggestion: null,
                suggestionOwner: {
                  value: '',
                  label: '',
                },
              }}
              onSubmit={handleSubmit}
              validationSchema={
                getMeetingSuggestionApi.isSuccess &&
                getMeetingSuggestionApi.data.length > 0
                  ? validationSchema
                  : null
              }
            >
              <AppText style={defaultStyles.questionWithoutPadding}>
                Suggestions for: {getMeetingApi.data.name}
              </AppText>

              <View>
                <>
                  <ActivityIndicator
                    visible={getMeetingSuggestionApi.isLoading}
                  />
                  {getMeetingSuggestionApi.isError && (
                    <>
                      <AppText>
                        Couldn't retrieve the Meeting suggestions.
                      </AppText>
                      <AppButton
                        title="Retry"
                        onPress={() => getMeetingSuggestionApi.refetch()}
                      />
                    </>
                  )}
                  {getMeetingSuggestionApi.isSuccess &&
                    getMeetingSuggestionApi.data?.length > 0 && (
                      <>
                        <FormSuggestionChoice
                          name="suggestion"
                          suggestions={getMeetingSuggestionApi.data}
                        />

                        <AppText
                          style={[
                            defaultStyles.questionWithoutPadding,
                            { marginTop: 20 },
                          ]}
                        >
                          Who is taking the suggestion forward?
                        </AppText>
                        <FormContactPicker
                          name="suggestionOwner"
                          group={false}
                          teams={false}
                          placeholder="Who is taking the suggestion forward?"
                          width="100%"
                          changeUpdateForm={true}
                          showOther={true}
                        />
                      </>
                    )}

                  {getMeetingSuggestionApi.isSuccess &&
                    getMeetingSuggestionApi.data?.length === 0 && (
                      <AppText>No Rapid Reviews found.</AppText>
                    )}
                </>
              </View>

              <SubmitButton title="Finish meeting" />
            </Form>
          </>
        )}
      </View>
    </Screen>
  )
}
