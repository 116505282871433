import React from 'react'
import { FlatList, StyleSheet, View } from 'react-native'
import { useFocusEffect } from '@react-navigation/native'
import Constants from 'expo-constants'

import ActivityIndicator from '../../../components/ActivityIndicator'
import Title from '../../../components/Title'
import AppText from '../../../components/Text'
import colors from '../../../config/colors'
import { defaultStyles } from '../../../config/styles'
import Screen from '../../../components/Screen'
import Button from '../../../components/ButtonIcon'
import FaqButton from '../../../components/FaqButton'
import faqQuery from '../../../api/query/faq'
import AppButton from '../../../components/Button'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { AppStackParamList } from '../../../navigation/AppNavigator'
import { useNavigation } from '@react-navigation/native'

function FaqScreen() {
  const navigation = useNavigation<DrawerNavigationProp<AppStackParamList>>()
  const { isLoading, isSuccess, isError, data, refetch } = faqQuery.getFaqs()

  return (
    <Screen style={defaultStyles.screen}>
      <Title style={{ fontSize: 25, color: 'black' }}>Help</Title>

      <View style={[defaultStyles.mainContainer, styles.mainContainer]}>
        <ActivityIndicator visible={isLoading} />
        {isError && (
          <>
            <AppText>Couldn't retrieve the FAQs.</AppText>
            <AppButton title="Retry" onPress={() => refetch()} />
          </>
        )}
        {isSuccess && (
          <>
            <AppText style={styles.text}>FAQs</AppText>
            <FlatList
              data={data}
              keyExtractor={(faq) => {
                return faq.id.toString()
              }}
              renderItem={({ item }) => (
                <FaqButton
                  question={item.question}
                  id={item.id}
                  onPress={() => {
                    navigation.navigate('HELP', {
                      screen: 'FAQ_View',
                      params: {
                        faqId: item.id,
                      },
                    })
                  }}
                />
              )}
            />
          </>
        )}
      </View>

      <View style={styles.bottomButtons}>
        <AppText style={styles.text}>Question not answered?</AppText>
        <Button
          text="Get in touch >"
          color={colors.secondary}
          backgroundColor={colors.white}
          onPress={() => {
            navigation.navigate('HELP', {
              screen: 'Message_Screen',
            })
          }}
        />
      </View>
      <View>
        <AppText>Build: {Constants.manifest?.extra.buildDate}</AppText>
      </View>
    </Screen>
  )
}

const styles = StyleSheet.create({
  mainContainer: {
    alignItems: 'center',
  },
  bottomButtons: {
    alignItems: 'center',
  },
  screen: {
    padding: 20,
  },
  text: {
    fontSize: 25,
    fontWeight: '600',
    marginVertical: 20,
  },
})

export default FaqScreen
