import React, { ReactNode } from 'react'
import { Pressable, StyleSheet, Text, TextStyle, ViewStyle } from 'react-native'

import { defaultConfig, defaultStyles } from '../config/styles'

interface IAppButton {
  title: string | null
  children?: ReactNode | null
  onPress: () => void
  disabled?: boolean
  backgroundColor?: string
  borderColor?: string
  textColor?: string
  emphasis?: boolean
  style?:
    | {
        borderRadius?: number
        height?: string
        width?: string
      }
    | TextStyle
    | ViewStyle
}

const AppButton: React.FC<IAppButton> = ({
  title,
  children = null,
  onPress,
  backgroundColor = 'primary',
  borderColor = 'primary',
  textColor = 'white',
  emphasis = true,
  disabled = false,
  style = {},
}) => {
  const extraStyles: TextStyle = {
    color: defaultConfig.colors[textColor],
    fontWeight: emphasis === true ? 'bold' : 'normal',
  }

  return (
    <Pressable
      style={({ pressed }) => [
        defaultStyles.box,
        styles.button,
        {
          backgroundColor: defaultConfig.colors[backgroundColor],
          borderColor: defaultConfig.colors[borderColor],
          borderRadius: style.borderRadius ?? 50,
          height: style.height ?? '60',
          width: style.width ?? '100%',
        },
        disabled && styles.disabledButton,
        pressed && !disabled && styles.pressedButton,
        style,
      ]}
      onPress={() => {
        console.log('Button clicked: ' + (title ?? 'No title'))

        if (!disabled && onPress) {
          console.log('Button onPress: ' + (title ?? 'No title'))
          onPress()
        }
      }}
    >
      {children}
      {title !== null && (
        <Text style={[styles.text, defaultStyles.openSans, extraStyles]}>
          {title}
        </Text>
      )}
    </Pressable>
  )
}

const styles = StyleSheet.create({
  button: {
    backgroundColor: defaultConfig.colors.primary,
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  text: {
    fontSize: 18,
    fontWeight: '700',
    fontFamily: 'Open Sans',
    textAlign: 'center',
  },
  disabledButton: {
    opacity: 0.5,
    backgroundColor: defaultConfig.colors.secondary,
  },
  pressedButton: {
    backgroundColor: defaultConfig.colors.secondary,
  },
})

export default AppButton
