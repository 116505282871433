import React from 'react'
import { StyleSheet } from 'react-native'

import { ICioView } from '../../../../@types/cio'
import { format, parseISO } from 'date-fns'

import '../../../../config/css/cio-view.css'

interface Props {
  cio: ICioView
}

const dateFormat = 'EEE, dd MMM yyyy HH:mm:ss'

export function ViewCio({ cio }: Props) {
  return (
    <>
      <div
        style={{
          textAlign: 'center',
          width: '100%',
          backgroundColor: '#303D50',
        }}
      >
        <br />
        <h3>CRITICAL IMPACT OPPORTUNITIES</h3>
        <h1>{cio.what}</h1>
        <br />
      </div>
      <div style={{ marginLeft: '2em', marginRight: '2em' }}>
        <h4>Name</h4>
        <div className="box">{cio.user.name}</div>

        <hr />

        <h2>PICK</h2>
        <h4>What is your CIO?</h4>
        <div className="box">{cio.what}</div>

        <h4>When is it?</h4>
        <div className="box">
          {format(
            typeof cio.date === 'string' ? parseISO(cio.date) : cio.date,
            dateFormat
          )}
        </div>

        <h4>Where is it?</h4>
        <div className="box">{cio.location}</div>

        <h4>Why is it worth the extra effort?</h4>
        <div className="box">{cio.why}</div>

        <hr />

        <h2>PREP</h2>
        <h4>Who is your audience?</h4>
        <div className="box">{cio.whoAudience}</div>

        <h4>
          What do you know about them to help you connect on a human level?
        </h4>
        <div className="box">{cio.humanConnection}</div>

        <h4>What’s the Purpose/Intent?</h4>
        <div className="box">{cio.humanIntent}</div>

        <h4>What needs planning/doing to create the impact you want?</h4>
        <div className="box">
          <ul>
            {cio.tasks.map((task) => (
              <li key={task}>{task}</li>
            ))}
          </ul>
        </div>

        <h4>Time required to prepare for this plan</h4>
        <div className="box">{cio.prepareTime}</div>

        <h4>What you want them to DO</h4>
        <div className="box">{cio.audienceDo}</div>

        <h4>What you want them to FEEL</h4>
        <div className="box">{cio.audienceFeel}</div>

        <h4>What you want them to KNOW</h4>
        <div className="box">{cio.audienceKnow}</div>

        <h4>Contributors to your CIO</h4>
        <div className="box">
          <ul>
            {cio.contributors.map((contributor) => (
              <li key={contributor.name}>
                {contributor.roleString}: {contributor.name}
              </li>
            ))}
          </ul>
        </div>

        <h2>
          To have the best possible connection to your audience, it is useful to
          connect with them before, during and after your CIO - and to know how
          you will do that in advance.
        </h2>
        <h4>Before</h4>
        <div className="box">{cio.prepBefore}</div>

        <h4>During</h4>
        <div className="box">{cio.prepDuring}</div>

        <h4>After</h4>
        <div className="box">{cio.prepAfter}</div>

        <h4>You time</h4>
        <div className="box">
          {format(
            typeof cio.youTime === 'string'
              ? parseISO(cio.youTime)
              : cio.youTime,
            dateFormat
          )}
        </div>

        <h4>Rehearsal time</h4>
        <div className="box">
          {format(
            typeof cio.rehearsalDate === 'string'
              ? parseISO(cio.rehearsalDate)
              : cio.rehearsalDate,
            dateFormat
          )}
        </div>

        <h4>Review date</h4>
        <div className="box">
          {format(
            typeof cio.reviewDate === 'string'
              ? parseISO(cio.reviewDate)
              : cio.reviewDate,
            dateFormat
          )}
        </div>

        <h4>To bring the Best Out Of Me for this impact moment.</h4>
        <div className="box">
          <ul>
            {cio.user.rituals.map((ritual) => (
              <li key={ritual}>{ritual}</li>
            ))}
          </ul>
        </div>

        <h4>Pre-CIO ritual</h4>
        <div className="box">{cio.user.preRitual}</div>

        <h2>PERFORM</h2>

        <h4>My BOSS Practice</h4>
        <div className="box">
          <ul className="boss">
            <li>B: {cio.boss1}</li>
            <li>O: {cio.boss2}</li>
            <li>S: {cio.boss3}</li>
            <li>S: {cio.boss4}</li>
          </ul>
        </div>
      </div>
    </>
  )
}

const styles = StyleSheet.create({
  bold: {
    fontWeight: '600',
  },
})
