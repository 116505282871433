import client from './client'

const endpoint = '/meetings'

const getMeetings = (userId) =>
  client.get('/users/' + userId + '/attending_meetings')

const getMeeting = (meetingId) => client.get(endpoint + `/${meetingId}`)

const getPastMeeting = (meetingId) =>
  client.get(endpoint + `/${meetingId}/past`)

const joinMeeting = (userId, meetingCode) =>
  client.post(endpoint + '/join/' + meetingCode, [])

const startMeeting = (meetingId) =>
  client.post(endpoint + `/${meetingId}/start`, [])

const getMeetingSuggestions = (meetingId) =>
  client.get(endpoint + `/${meetingId}/suggestions`)

const endMeeting = (meetingId, meeting) =>
  client.post(endpoint + `/${meetingId}/end`, meeting)

const addMeeting = (userId, meeting) => {
  return client.post(endpoint, meeting, {
    // onUploadProgress: (progress) =>
    //   onUploadProgress(progress.loaded / progress.total),
  })
}

const getMeetingReviews = (meetingId) =>
  client.get(endpoint + `/${meetingId}/rapid_reviews`)

export default {
  getMeetings,
  getMeeting,
  getPastMeeting,
  joinMeeting,
  addMeeting,
  startMeeting,
  getMeetingSuggestions,
  endMeeting,
  getMeetingReviews,
}
