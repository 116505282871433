import React from 'react'
import { GestureResponderEvent, View } from 'react-native'
import { useDispatch } from 'react-redux'
import '../../../../config/css/react-draggable.css'

import ButtonIcon from '../../../../components/ButtonIcon'
import colors from '../../../../config/colors'
import {
  IWidgetItem,
  removeWidget,
} from '../../../../redux/slices/dashboardSlice'

interface IRemoveWidgetButton {
  readonly widget: IWidgetItem
}

export const RemoveWidgetButton: React.FC<IRemoveWidgetButton> = ({
  widget,
}) => {
  const dispatch = useDispatch()

  return (
    <View
      style={{
        position: 'absolute',
        top: -20,
        right: -75,
        zIndex: 1000,
        backgroundColor: 'transparent',
      }}
    >
      <ButtonIcon
        icon="close-circle"
        color={colors.primary}
        backgroundColor="transparent"
        buttonBackgroundColor={colors.white}
        size={32}
        onPress={(event: GestureResponderEvent) => {
          event.stopPropagation()
          dispatch(removeWidget(widget.i))
        }}
      />
    </View>
  )
}
