import { useQuery } from 'react-query'

import { get, post, patch, remove } from '../../utility/api'
import { QUERY_KEYS } from '../../config/queryKeys'
export const endpoint = '/bosses'

const getBosses = (userId) => {
  return useQuery(QUERY_KEYS.boss.all, ({ signal }) =>
    get('/users/' + userId + endpoint + '?order[name]=asc', {
      signal,
    })
  )
}

const getDashboardBosses = (userId) => {
  return useQuery(QUERY_KEYS.boss.widget(userId), ({ signal }) =>
    get('/users/' + userId + endpoint + '?order[updated]=desc&pageSize=3', {
      signal,
    })
  )
}

const getBoss = (bossId?: string) => {
  return useQuery(
    QUERY_KEYS.boss.item(bossId),
    ({ signal }) => get(`${endpoint}/${bossId}`, { signal }),
    {
      enabled: undefined !== bossId,
    }
  )
}

const deleteBoss = (id) => {
  return remove(endpoint + '/' + id)
}

export const addBoss = (boss) => {
  return post(endpoint, boss)
}

export const updateBoss = (boss) => {
  return patch(endpoint + '/' + boss.id, boss, {
    headers: { 'content-type': 'application/merge-patch+json' },
  })
}

export default {
  addBoss,
  updateBoss,
  deleteBoss,
  getBosses,
  getBoss,
  getDashboardBosses,
}
