import React from 'react'
import { Text } from 'react-native'

import { defaultConfig, defaultStyles } from '../config/styles'

function TextHighlight({ children, style = {}, ...otherProps }) {
  return (
    <Text
      style={[
        defaultStyles.text,
        { color: defaultConfig.colors.primary },
        style,
      ]}
      {...otherProps}
    >
      {children}
    </Text>
  )
}

export default TextHighlight
