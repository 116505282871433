import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'

import header from '../components/part/Header'
import DashboardScreen from '../screens/app/Dashboard/DashboardScreen'
import NudgificationsScreen from '../screens/app/Dashboard/NudgificationsScreen'
import RateYourShedScreen from '../screens/app/Dashboard/RateYourShedScreen'
import ShedProgressScreen from '../screens/app/Dashboard/ShedProgressScreen'
import ShedTipsScreen from '../screens/app/Dashboard/ShedTipsScreen'

const Stack = createStackNavigator()

const DashboardNavigator = () => (
  <Stack.Navigator initialRouteName="Dashboard_Screen">
    <Stack.Screen
      name="Dashboard_Screen"
      component={DashboardScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showHamburger: true,
            showNudgifications: true,
          }),
        }
      }}
    />
    <Stack.Screen
      name="Nudgification_Screen"
      component={NudgificationsScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showBackButton: true,
            showNudgifications: true,
          }),
        }
      }}
    />
    <Stack.Screen
      name="ShedRatingScreen"
      component={RateYourShedScreen}
      initialParams={{ previous: false }}
      options={() => {
        return {
          ...header.defaultHeader({
            showBackButton: true,
            showNudgifications: false,
          }),
        }
      }}
    />
    <Stack.Screen
      name="ShedProgressScreen"
      component={ShedProgressScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showBackButton: true,
            showNudgifications: false,
          }),
        }
      }}
    />
    <Stack.Screen
      name="ShedTipsScreen"
      component={ShedTipsScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showBackButton: true,
            showNudgifications: false,
          }),
        }
      }}
    />
  </Stack.Navigator>
)

export default DashboardNavigator
