import authStorage from '../auth/storage'
import {
  initialiseSessionSuccess,
  selectIsAuthenticated,
  selectIsInitialized,
  selectUser,
  selectUserFeatures,
  selectUserRituals,
  selectUserRoles,
  signOutSuccess,
} from '../redux/slices/sessionSlice'
import useAppDispatch from './useAppDispatch'
import useAppSelector from './useAppSelector'

export const useSession = () => {
  const isInitialized = useAppSelector(selectIsInitialized)
  const isAuthenticated = useAppSelector(selectIsAuthenticated)
  const userRoles = useAppSelector(selectUserRoles)
  const userFeatures = useAppSelector(selectUserFeatures)
  const userRituals = useAppSelector(selectUserRituals)

  const user = useAppSelector(selectUser)
  const dispatch = useAppDispatch()

  const logIn = async (authToken) => {
    authStorage.storeToken(authToken)

    const userPayload = await authStorage.getUser()

    if (userPayload) {
      dispatch(initialiseSessionSuccess(userPayload))
    } else {
      console.log('Payload was empty')
    }
  }

  const restoreUser = async () => {
    const userPayload = await authStorage.getUser()
    if (userPayload) {
      dispatch(initialiseSessionSuccess(userPayload))
    }
  }

  const logOut = () => {
    authStorage.removeToken()
    dispatch(signOutSuccess())
  }

  const renew = async () => {
    const token = await authStorage.renewToken()
    logIn(token)
  }

  return {
    user,
    logIn,
    logOut,
    renew,
    restoreUser,
    isInitialized,
    isAuthenticated,
    userFeatures,
    userRituals,
    userRoles,
  }
}
