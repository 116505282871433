import { Component, ReactNode } from 'react'

interface IProps {
  delay: number
  action: () => ReactNode
  enabled: boolean
}

interface IState {
  delay: number
  renderAction?: () => ReactNode
  enabled: boolean
}

export default class IntervalRedraw extends Component<IProps, IState> {
  interval: ReturnType<typeof setTimeout> | null = null

  constructor(props) {
    super(props)
    const { action, enabled, delay = 1 } = props
    this.state = {
      delay: delay * 1000,
      renderAction: action,
      enabled: enabled,
    }
  }

  componentDidMount() {
    this.interval = setInterval(() => this.redraw(), this.state.delay)
  }

  componentWillUnmount() {
    if (null === this.interval) {
      return
    }
    clearInterval(this.interval)
  }

  redraw() {
    if (this.state.enabled && this.forceUpdate) {
      this.forceUpdate()
    }
  }

  render() {
    return this.state.renderAction && this.state.enabled
      ? this.state.renderAction()
      : null
  }
}
