import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'

import header from '../components/part/Header'
import ListScreen from '../screens/app/SharedWithMe/ListScreen'
import CioScreen from '../screens/app/SharedWithMe/CioScreen'
import CioViewScreen from '../screens/app/CriticalImpactOpportunity/ViewScreen'
import CioMenuScreen from '../screens/app/CriticalImpactOpportunity/MenuScreen'
import RrScreen from '../screens/app/SharedWithMe/RrScreen'
import RapidReviewViewScreen from '../screens/app/RapidReview/Personal/ViewScreen'
import BoomScreen from '../screens/app/SharedWithMe/BoomScreen'
import BoomViewScreen from '../screens/app/Boom/BoomViewScreen'

const Stack = createStackNavigator()

const SharedWithMeNavigator = () => (
  <Stack.Navigator initialRouteName="SharedWithMe_List">
    <Stack.Screen
      name="SharedWithMe_List"
      component={ListScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showHamburger: false,
            showBackButton: true,
            showNudgifications: false,
          }),
        }
      }}
    />
    <Stack.Screen
      name="SharedWithMe_CriticalImpactOpportunity_List"
      component={CioScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showHamburger: false,
            showBackButton: true,
            showNudgifications: false,
          }),
        }
      }}
    />
    <Stack.Screen
      name="SharedWithMe_CriticalImpactOpportunity_Overview"
      component={CioMenuScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showHamburger: false,
            showBackButton: true,
            showNudgifications: false,
          }),
        }
      }}
    />
    <Stack.Screen
      name="SharedWithMe_CriticalImpactOpportunity_View"
      component={CioViewScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showHamburger: false,
            showBackButton: true,
            showNudgifications: false,
          }),
        }
      }}
    />
    <Stack.Screen
      name="SharedWithMe_RapidReview_List"
      component={RrScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showHamburger: false,
            showBackButton: true,
            showNudgifications: false,
          }),
        }
      }}
    />
    <Stack.Screen
      name="SharedWithMe_RapidReview_View"
      component={RapidReviewViewScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showHamburger: false,
            showBackButton: true,
            showNudgifications: false,
          }),
        }
      }}
    />
    <Stack.Screen
      name="SharedWithMe_Boom_List"
      component={BoomScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showHamburger: false,
            showBackButton: true,
            showNudgifications: false,
          }),
        }
      }}
    />
    <Stack.Screen
      name="SharedWithMe_Boom_View"
      component={BoomViewScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showHamburger: false,
            showBackButton: true,
            showNudgifications: false,
          }),
        }
      }}
    />
  </Stack.Navigator>
)

export default SharedWithMeNavigator
