import React from 'react'
import { Image, View, StyleSheet, TouchableWithoutFeedback } from 'react-native'

import Text from '../Text'
import colors from '../../config/colors'
import { IResourceCollectionItem } from 'types/ResourceCollectionItem'

interface IResourceButton {
  resource: IResourceCollectionItem
  onPress: () => void
}

function ResourceButton({ resource, onPress }: IResourceButton) {
  let placeholderImage

  if (resource.thumbs['640'] || resource.thumbs['base']) {
    placeholderImage = resource.thumbs['640'] ?? resource.thumbs['base']
  } else {
    placeholderImage = require('../../assets/resource-placeholder.jpg')
  }

  return (
    <TouchableWithoutFeedback onPress={onPress}>
      <View style={styles.card}>
        <Image style={styles.placeholder} source={placeholderImage} />
        <View style={styles.detailsContainer}>
          <Text style={styles.title} numberOfLines={1}>
            {resource.title}
          </Text>
          <Text style={styles.subTitle} numberOfLines={1}>
            {resource.subtitle}
          </Text>
        </View>
      </View>
    </TouchableWithoutFeedback>
  )
}

const styles = StyleSheet.create({
  card: {
    borderRadius: 10,
    backgroundColor: colors.light,
    marginBottom: 20,
    overflow: 'hidden',
    flexDirection: 'row',
  },
  detailsContainer: {
    padding: 20,
  },
  placeholder: {
    width: '20%',
  },
  subTitle: {},
  title: {
    marginBottom: 7,
    color: colors.secondary,
    fontWeight: 'bold',
    flex: 1,
  },
})

export default ResourceButton
