import React from 'react'
import { View, StyleSheet, TouchableWithoutFeedback } from 'react-native'

import Text from '../Text'
import colors from '../../config/colors'

function TeamButton({ teamName, onPress, highlighted = false }) {
  let buttonBackground = colors.light
  let buttonText = colors.primary

  if (highlighted) {
    buttonBackground = colors.primary
    buttonText = colors.white
  }

  return (
    <TouchableWithoutFeedback onPress={onPress}>
      <View style={[styles.card, { backgroundColor: buttonBackground }]}>
        <View style={styles.detailsContainer}>
          <Text
            style={[styles.subTitle, { color: buttonText }]}
            numberOfLines={2}
          >
            {teamName}
          </Text>
        </View>
      </View>
    </TouchableWithoutFeedback>
  )
}

const styles = StyleSheet.create({
  card: {
    borderRadius: 10,
    marginBottom: 20,
    cursor: 'pointer',
    overflow: 'hidden',
  },
  detailsContainer: {
    padding: 20,
  },
  subTitle: {
    fontWeight: 'bold',
  },
})

export default TeamButton
