import jsrsasign from 'jsrsasign'
import tokenStorage from './storageToken'
import authApi, { renew, IRenewToken } from '../api/auth'
import { ApiResponse } from 'apisauce'

const tokenKey = 'authToken'

const responseToken = 'token'
const responseRefreshToken = 'refreshToken'

// cache public key
let key: ApiResponse<string, null>
let onTokenChange

interface IJwtHeader {
  typ: string
  alg: string
}

interface IJwtPayload {
  company: string
  company_id: string
  exp: number
  features: string[]
  group: string
  group_id: string
  iat: number
  id: string
  ip: string
  job_title: string
  name: string
  rituals: string[]
  roles: string[]
  username: string
}

const getUser = async (): Promise<IJwtPayload | null> => {
  const token = await getToken()

  if (token === undefined || !token) {
    return null
  }

  const payload = await decodeJwt(token[responseToken])

  if (payload) {
    return payload
  }

  try {
    const newToken = await renewToken()

    if (newToken) {
      storeToken(newToken)

      const payload = await decodeJwt(newToken[responseToken])

      if (payload) {
        return payload
      }
    }
  } catch (renewError) {
    console.log('Error renewing the auth token', token, renewError)
  }

  return null
}

const checkAndGetToken = async () => {
  const token = await getToken()

  if (token === undefined || !token) {
    console.log('storage: ', 'undefined or not set')
    return null
  }

  if (await jwtValid(token[responseToken], true)) {
    // console.log("storage: ", "token is valid", token[responseToken]);
    return token
  }

  try {
    const newToken = await renewToken()

    if (newToken) {
      storeToken(newToken)

      console.log('storage: ', 'token refreshed')
      return newToken
    }
  } catch (renewError) {
    console.log('Error renewing the auth token', renewError)
  }

  console.log('storage: ', 'end of function')
  return null
}

const decodeJwt = async (token) => {
  const headerObj = jwtHeader(token) as IJwtHeader
  const payloadObj = jwtPayload(token) as IJwtPayload

  const isValid = await jwtValid(token)

  if (!isValid) {
    if (payloadObj && payloadObj.exp) {
      const newToken = await renewToken()

      if (null === newToken) {
        console.log('failed to renew')
        return null
      }

      const isRenewValid = await jwtValid(newToken.token)

      if (isRenewValid) {
        const newRewPayload = jwtPayload(newToken.token)
        storeToken(newToken)

        return newRewPayload
      }
    }

    return null
  }

  return payloadObj
}

const jwtValid = async (token, padTime = false): Promise<boolean> => {
  key = await authApi.publicKey<string>()
  if (!key) {
    key = await authApi.publicKey<string>()
  }

  const pubkey = jsrsasign.KEYUTIL.getKey(key.data)
  const options = {
    alg: ['RS256'],
    verifyAt: jsrsasign.KJUR.jws.IntDate.getNow(),
  }

  if (padTime) {
    // console.log("padding", jsrsasign.KJUR.jws.IntDate.get("now + 1hour"));
    // options.verifyAt = jsrsasign.KJUR.jws.IntDate.get("now + 1hour");
    options.verifyAt = options.verifyAt + 60
  }

  return jsrsasign.KJUR.jws.JWS.verifyJWT(token, pubkey, options)
}

const jwtPayload = (token: string) => {
  return jsrsasign.KJUR.jws.JWS.readSafeJSONString(
    jsrsasign.b64utoutf8(token.split('.')[1])
  )
}

const jwtHeader = (token: string) => {
  return jsrsasign.KJUR.jws.JWS.readSafeJSONString(
    jsrsasign.b64utoutf8(token.split('.')[0])
  )
}

const renewToken = async (): Promise<IRenewToken | null> => {
  const token = await getToken()
  console.log('Trying to renew auth token', token)

  if (null === token) {
    console.log('renew failed, null')
    return null
  }

  const response = await authApi.renew<IRenewToken>(token[responseRefreshToken])

  if (!response.ok || response.data === undefined) {
    console.log('renew failed', token, response)
    return null
  }

  return response.data
}

const storeToken = async (authToken: IRenewToken) => {
  tokenStorage.storeToken(tokenKey, JSON.stringify(authToken))
}

const getToken = async (): Promise<IRenewToken | null> => {
  let value: IRenewToken | null = null

  const token = await tokenStorage.getToken(tokenKey)

  try {
    if (token) {
      value = JSON.parse(token)
    }
  } catch (error) {
    console.log('Error parsing the auth token', token, error)
  }

  return value
}

const removeToken = async () => {
  tokenStorage.removeToken(tokenKey)
}

const watchToken = async (callback) => {
  onTokenChange = callback
}

export default {
  getUser,
  storeToken,
  getToken,
  checkAndGetToken,
  watchToken,
  renewToken,
  removeToken,
  responseToken,
  responseRefreshToken,
}

export type { IJwtPayload as IUser }
