import React from 'react'
import { View, StyleSheet, TouchableWithoutFeedback } from 'react-native'
import { format, parseISO } from 'date-fns'

import Text from '../Text'
import colors from '../../config/colors'

function MeetingButton({ meeting, onPress }) {
  return (
    <TouchableWithoutFeedback onPress={onPress}>
      <View style={styles.card}>
        <View style={styles.detailsContainer}>
          <Text style={styles.title} numberOfLines={1}>
            {format(parseISO(meeting.date), 'do MMMM yyyy')}
          </Text>
          <Text style={styles.subTitle} numberOfLines={2}>
            {meeting.name}
          </Text>
        </View>
      </View>
    </TouchableWithoutFeedback>
  )
}

const styles = StyleSheet.create({
  card: {
    borderRadius: 10,
    backgroundColor: colors.light,
    marginBottom: 20,
    overflow: 'hidden',
  },
  detailsContainer: {
    padding: 20,
  },
  subTitle: {},
  title: {
    marginBottom: 7,
    color: colors.secondary,
    fontWeight: 'bold',
    flex: 1,
  },
})

export { MeetingButton }
