import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'

import header from '../components/part/Header'
import FormScreen from '../screens/app/TrophyCabinet/FormScreen'
import ListScreen from '../screens/app/TrophyCabinet/ListScreen'
import ViewScreen from '../screens/app/TrophyCabinet/ViewScreen'

const Stack = createStackNavigator()

const TrophyCabinetNavigator = () => (
  <Stack.Navigator initialRouteName="Trophy_List">
    <Stack.Screen
      name="Trophy_Form"
      component={FormScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showHamburger: false,
            showBackButton: true,
            showNudgifications: false,
          }),
        }
      }}
    />
    <Stack.Screen
      name="Trophy_View"
      component={ViewScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showHamburger: false,
            showBackButton: true,
            showNudgifications: false,
          }),
        }
      }}
    />
    <Stack.Screen
      name="Trophy_List"
      component={ListScreen}
      options={() => {
        return {
          ...header.defaultHeader({
            showHamburger: true,
            showBackButton: false,
            showNudgifications: false,
          }),
        }
      }}
    />
  </Stack.Navigator>
)

export default TrophyCabinetNavigator
