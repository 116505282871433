import React from 'react'
import { StyleSheet, View } from 'react-native'

import { defaultStyles } from '../../../config/styles'
import Screen from '../../../components/Screen'
import NudgeView from '../../../components/profile/NudgeView'
import Title from '../../../components/Title'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { AppStackParamList } from '../../../navigation/AppNavigator'
import { useNavigation } from '@react-navigation/native'

function NudgeScreen() {
  const navigation = useNavigation<DrawerNavigationProp<AppStackParamList>>()

  return (
    <Screen style={defaultStyles.screen}>
      <View style={[defaultStyles.mainContainer, styles.mainContainer]}>
        <Title style={{ fontSize: 25, color: 'black' }}>Nudges</Title>
        <NudgeView
          onClose={() => {
            navigation.navigate('PROFILE', {
              screen: 'Profile',
            })
          }}
        />
      </View>
    </Screen>
  )
}

const styles = StyleSheet.create({
  mainContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
})

export default NudgeScreen
