import parseISO from 'date-fns/parseISO'
import { ICio } from 'types/cio'
import { Sections } from './cioSection'
import { INudgeOption } from 'types/nudgeOption'

export function defaultCio(criticalImpactOpportunity?: ICio): ICio {
  if (typeof criticalImpactOpportunity === 'undefined') {
    criticalImpactOpportunity = {} as ICio
  }

  if (
    typeof criticalImpactOpportunity.contributors === 'object' &&
    !(criticalImpactOpportunity.contributors instanceof Array)
  ) {
    criticalImpactOpportunity.contributors = [
      criticalImpactOpportunity.contributors,
    ]
  }

  if (
    criticalImpactOpportunity.date &&
    typeof criticalImpactOpportunity.date === 'string'
  ) {
    criticalImpactOpportunity.date = parseISO(criticalImpactOpportunity.date)
  }
  if (
    criticalImpactOpportunity.prepareDate &&
    typeof criticalImpactOpportunity.prepareDate === 'string'
  ) {
    criticalImpactOpportunity.prepareDate = parseISO(
      criticalImpactOpportunity.prepareDate
    )
  }
  if (
    criticalImpactOpportunity.youTime &&
    typeof criticalImpactOpportunity.youTime === 'string'
  ) {
    criticalImpactOpportunity.youTime = parseISO(
      criticalImpactOpportunity.youTime
    )
  }
  if (
    criticalImpactOpportunity.rehearsalDate &&
    typeof criticalImpactOpportunity.rehearsalDate === 'string'
  ) {
    criticalImpactOpportunity.rehearsalDate = parseISO(
      criticalImpactOpportunity.rehearsalDate
    )
  }
  if (
    criticalImpactOpportunity.reviewDate &&
    typeof criticalImpactOpportunity.reviewDate === 'string'
  ) {
    criticalImpactOpportunity.reviewDate = parseISO(
      criticalImpactOpportunity.reviewDate
    )
  }

  return {
    id: criticalImpactOpportunity.id ?? null,
    status: criticalImpactOpportunity.status ?? 1,
    statusString: criticalImpactOpportunity.statusString ?? 'Draft',
    section: criticalImpactOpportunity.section ?? Sections.intro,

    // P1
    what: criticalImpactOpportunity.what ?? '',
    date: criticalImpactOpportunity.date ?? new Date(),
    location: criticalImpactOpportunity.location ?? '',
    why: criticalImpactOpportunity.why ?? '',
    cioNudge: criticalImpactOpportunity.cioNudge ?? null,
    cioNudgeOptions:
      criticalImpactOpportunity.cioNudgeOptions ?? ({} as INudgeOption),

    // P2
    whoAudience: criticalImpactOpportunity.whoAudience ?? '',
    whoHuman: criticalImpactOpportunity.whoHuman ?? '',
    humanConnection: criticalImpactOpportunity.humanConnection ?? '',
    humanIntent: criticalImpactOpportunity.humanIntent ?? '',
    tasks: criticalImpactOpportunity.tasks ?? [],
    prepareTime: criticalImpactOpportunity.prepareTime ?? '',
    prepareDate: criticalImpactOpportunity.prepareDate ?? new Date(),
    audienceDo: criticalImpactOpportunity.audienceDo ?? '',
    audienceFeel: criticalImpactOpportunity.audienceFeel ?? '',
    audienceKnow: criticalImpactOpportunity.audienceKnow ?? '',
    contributors: criticalImpactOpportunity.contributors ?? [],
    prepBefore: criticalImpactOpportunity.prepBefore ?? '',
    prepDuring: criticalImpactOpportunity.prepDuring ?? '',
    prepAfter: criticalImpactOpportunity.prepAfter ?? '',
    youTime: criticalImpactOpportunity.youTime ?? new Date(),
    youTimeNudge: criticalImpactOpportunity.youTimeNudge ?? null,
    youTimeNudgeOptions:
      criticalImpactOpportunity.youTimeNudgeOptions ?? ({} as INudgeOption),
    rehearsalDate: criticalImpactOpportunity.rehearsalDate ?? new Date(),
    rehearsalDateNudge: criticalImpactOpportunity.rehearsalDateNudge ?? null,
    rehearsalDateNudgeOptions:
      criticalImpactOpportunity.rehearsalDateNudgeOptions ??
      ({} as INudgeOption),
    reviewDate: criticalImpactOpportunity.reviewDate ?? new Date(),
    reviewDateNudge: criticalImpactOpportunity.reviewDateNudge ?? null,
    reviewDateNudgeOptions:
      criticalImpactOpportunity.reviewDateNudgeOptions ?? ({} as INudgeOption),
    ritual: criticalImpactOpportunity.ritual ?? '',

    // P3
    prePerformanceRitual: criticalImpactOpportunity.prePerformanceRitual ?? '',
    bossId: null,
    boss1: criticalImpactOpportunity.boss1 ?? '',
    boss2: criticalImpactOpportunity.boss2 ?? '',
    boss3: criticalImpactOpportunity.boss3 ?? '',
    boss4: criticalImpactOpportunity.boss4 ?? '',
    controlEnvironment: criticalImpactOpportunity.controlEnvironment ?? '',

    // P4
    anythingElse: criticalImpactOpportunity.anythingElse ?? [],
    rapidReview: criticalImpactOpportunity.rapidReview ?? null,
  }
}
