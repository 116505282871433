import React, { useState } from 'react'
import { StyleSheet, View } from 'react-native'

import { defaultStyles } from '../../../config/styles'
import Screen from '../../../components/Screen'
import Button from '../../../components/Button'
import Title from '../../../components/Title'
import Profile from '../../../components/profile/Profile'
import ActivityIndicator from '../../../components/ActivityIndicator'
import RitualDoneScreen from './RitualDoneScreen'
import { useSession } from '../../../hooks/useSession'
import userQuery from '../../../api/query/user'
import AppText from '../../../components/Text'
import { useMutation, useQueryClient } from 'react-query'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { AppStackParamList } from '../../../navigation/AppNavigator'
import { useNavigation } from '@react-navigation/native'

interface IRitualScreen {
  onClose?: null | Function
  rituals?: string[] | null
}

function RitualScreen({
  rituals = null,
  onClose = null,
}: Readonly<IRitualScreen>) {
  const navigation = useNavigation<DrawerNavigationProp<AppStackParamList>>()
  const { user, renew } = useSession()
  const queryClient = useQueryClient()
  const { isSuccess, isError, isLoading, data, refetch } = userQuery.getUser(
    user.id
  )
  const [changeMade, setChangeMade] = useState<boolean>(false)
  const [newRituals, setNewRituals] = useState<string[]>(data?.rituals ?? [])

  const ritualMutation = useMutation(userQuery.updateRituals, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['user', { userId: data.id }])
      setDone(true)
    },
    onError: (error) => {
      if (newRituals.length === 0) {
        alert('Please select at least 1 ritual')
      } else {
        alert('Failed to update the ritual')
      }
      console.log(rituals, error)
    },
  })

  const [isDone, setDone] = useState<boolean>(false)
  const firstTime = rituals === null

  if (isDone) {
    return (
      <RitualDoneScreen
        rituals={newRituals}
        onPress={() => {
          renew()

          if (onClose) {
            return onClose()
          }

          navigation.navigate('PROFILE', {
            screen: 'Profile',
          })
        }}
      />
    )
  }

  return (
    <Screen style={defaultStyles.screen}>
      <View style={[defaultStyles.mainContainer, styles.mainContainer]}>
        <ActivityIndicator visible={isLoading} />
        <Title style={{ fontSize: 25, color: 'black' }}>
          Let's build your HPP Profile
        </Title>

        {isError && (
          <>
            <AppText>Couldn't retrieve your HPP Profile.</AppText>
            <Button title="Retry" onPress={refetch()} />
          </>
        )}

        <Profile
          onChange={(rituals) => {
            console.log(rituals)
            if (!changeMade) {
              setChangeMade(true)
            }

            setNewRituals(rituals)
          }}
        />

        {isSuccess && (
          <>
            <Button
              title={firstTime ? 'Continue' : 'Save'}
              disabled={!changeMade}
              onPress={() => {
                ritualMutation.mutate({
                  userId: user.id,
                  rituals: newRituals,
                })
              }}
            />
          </>
        )}
      </View>
    </Screen>
  )
}

const styles = StyleSheet.create({
  mainContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  meetingCode: {
    justifyContent: 'flex-start',
    marginVertical: 100,
    width: '100%',
  },
  text: {
    fontSize: 25,
    fontWeight: '600',
    marginVertical: 20,
  },
})

export default RitualScreen
