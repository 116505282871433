import React, { useState } from 'react'
import { NavigationContainer } from '@react-navigation/native'
import AppLoading from './src/components/startup/AppLoading'
import { QueryClient, QueryClientProvider } from 'react-query'

import navigationTheme from './src/navigation/navigationTheme'
import AppNavigator from './src/navigation/AppNavigator'
import AuthNavigator from './src/navigation/AuthNavigator'
import * as rootNavigation from './src/navigation/rootNavigation'
import { linking } from './src/config/linking'
import StartUpScreen from './src/components/startup/StartUpScreen'
import useWhiteLabel from './src/hooks/useWhiteLabel'
import { useSession } from './src/hooks/useSession'

export default function AppNavigation() {
  const { isAuthenticated, restoreUser, user } = useSession()
  const [isReady, setIsReady] = useState(false)
  const { whiteLabel } = useWhiteLabel()

  const handleError = (error) => {
    console.warn(error)
  }

  if (!isReady) {
    return (
      <AppLoading
        startAsync={restoreUser}
        onFinish={() => setIsReady(true)}
        onError={handleError}
      />
    )
  }

  // https://reactnavigation.org/docs/navigation-container/#documenttitleenabled

  const queryClient = new QueryClient()

  return (
    <NavigationContainer
      theme={navigationTheme}
      linking={linking}
      ref={rootNavigation.navigationRef}
      documentTitle={{
        formatter: (options, route) => whiteLabel?.name ?? 'Loading ...',
      }}
      // onReady={() => {
      //   rootNavigation.isReadyRef.current = true;
      // }}
    >
      {isAuthenticated ? (
        <QueryClientProvider client={queryClient}>
          <AppNavigator />
          <StartUpScreen />
        </QueryClientProvider>
      ) : (
        <AuthNavigator />
      )}
    </NavigationContainer>
  )
}
