import React from 'react'
import { View } from 'react-native'

import Screen from '../../../components/Screen'
import colors from '../../../config/colors'
import { defaultStyles } from '../../../config/styles'
import TitleGraphic from '../../../components/TitleGraphic'
import AppText from '../../../components/Text'
import boomQuery from '../../../api/query/boom'
import ButtonIcon from '../../../components/ButtonIcon'
import { ViewBoom } from './components/ViewBoom'
import ActivityIndicator from '../../../components/ActivityIndicator'
import { useNavigation } from '@react-navigation/native'
import { AppStackParamList } from '../../../navigation/AppNavigator'
import { DrawerNavigationProp } from '@react-navigation/drawer'

function BoomScreen() {
  const navigation = useNavigation<DrawerNavigationProp<AppStackParamList>>()
  const { isLoading, isSuccess, isError, data, refetch } =
    boomQuery.getBoom('me')

  return (
    <Screen style={defaultStyles.screen}>
      <TitleGraphic
        section="B.O.O.M"
        title="Best Out Of Me"
        graphic={require('../../../assets/lead-boom.jpg')}
      />
      <View style={defaultStyles.mainContainer}>
        <AppText>
          <p>
            B.O.O.M is a high-performance practice used by great teams to
            fast-form, build real connection and inclusion, and to stay tight.
          </p>
          <p>
            <strong>Your BOOM will be unique to you</strong>
          </p>
          <p>
            Sharing your BOOM with your team enables them to get the best out of
            you when it matters.
          </p>
        </AppText>
        <hr />
        {isLoading && <ActivityIndicator visible={isLoading} />}
        {isSuccess && <ViewBoom boom={data} />}

        <View style={{ flexDirection: 'row' }}>
          <View style={{ flex: 1, marginRight: 5 }}>
            <ButtonIcon
              text="Edit"
              icon="text-box-plus-outline"
              color={colors.white}
              backgroundColor={colors.primary}
              onPress={() => {
                navigation.navigate('BOOM', {
                  screen: 'Boom_Edit',
                  params: {
                    boomId: 'me',
                  },
                })
              }}
            />
          </View>
          {!isLoading && isSuccess && (
            <View style={{ flex: 1, marginLeft: 5 }}>
              <ButtonIcon
                text="Share"
                icon="share"
                color={colors.primary}
                backgroundColor={colors.white}
                borderColor={colors.primary}
                onPress={() => {
                  navigation.navigate('BOOM', {
                    screen: 'Boom_Share',
                    params: {
                      boomId: 'me',
                    },
                  })
                }}
              />
            </View>
          )}
        </View>
      </View>
    </Screen>
  )
}

export default BoomScreen
