import React, { CSSProperties, RefObject, forwardRef } from 'react'
import { withSize } from 'react-sizeme'
import { CioWidget, CioWidgetName } from './Widgets/CioWidget'
import {
  RapidReviewWidget,
  RapidReviewWidgetName,
} from './Widgets/RapidReviewWidget'
import {
  SharedWithMeWidget,
  SharedWithMeWidgetName,
} from './Widgets/SharedWithMeWidget'
import { IWidget } from './Widgets/IWidget'
import {
  BossPracticeWidget,
  BossPracticeWidgetName,
} from './Widgets/BossPracticeWidget'
import {
  TrophyCabinentWidget,
  TrophyCabinentWidgetName,
} from './Widgets/TrophyCabinentWidget'
import { WidgetArrayItem } from './utils'
import { RemoveWidgetButton } from './RemoveWidgetButton'
import { View } from 'react-native'
import { dashboardStyles } from '../../../../config/styles'

interface IDisplayWidgetProps {
  item: WidgetArrayItem
  editing: boolean
  widgetType: string
  widgetParams: IWidget
  style: CSSProperties
  className: string
  key: string
}

type DisplayWidgetRef = RefObject<HTMLDivElement>

type DisplayWidgetPropsWithRef = IDisplayWidgetProps & {
  ref?: DisplayWidgetRef
}

const widgetMap = {
  [CioWidgetName]: CioWidget,
  [RapidReviewWidgetName]: RapidReviewWidget,
  [SharedWithMeWidgetName]: SharedWithMeWidget,
  [BossPracticeWidgetName]: BossPracticeWidget,
  [TrophyCabinentWidgetName]: TrophyCabinentWidget,
}

const DisplayWidget = forwardRef<HTMLDivElement, DisplayWidgetPropsWithRef>(
  ({ item, editing, widgetType, widgetParams, style, className, key }, ref) => {
    const WidgetComponent = widgetMap[widgetType]
    let widgetContent: React.JSX.Element

    if (WidgetComponent) {
      widgetContent = <WidgetComponent {...widgetParams} />
    } else {
      console.warn('Unknown widget type: ' + widgetType)
      widgetContent = (
        <View style={dashboardStyles.block}>
          {'Unknown widget type: ' + widgetType}
        </View>
      )
    }

    return (
      <div
        id={item.i}
        ref={ref}
        key={item.i}
        className="grid-item"
        style={{
          ...itemStyle,
          ...(editing ? editingWidgetStyles : {}),
        }}
        data-grid={item}
      >
        <div className="widgetContents">
          {editing && <RemoveWidgetButton widget={item} />}
          {widgetContent}
        </div>
      </div>
    )
  }
)

const DisplayWidgetWithSize = withSize({
  monitorHeight: true,
  monitorWidth: false,
})(DisplayWidget)

const editingWidgetStyles: CSSProperties = {
  borderWidth: '2px',
  borderColor: '#cccccc',
  borderStyle: 'dashed',
  marginBottom: 20,
  padding: 15,
  cursor: 'grab',
  boxSizing: 'border-box',
}

const itemStyle: CSSProperties = {
  width: '100%',
  // backgroundColor: 'blue',
}

export default DisplayWidgetWithSize
