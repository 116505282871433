import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { IUser } from '../../auth/storage'

export interface SessionState {
  isInitialized: boolean
  isAuthenticated: boolean
  metadata?: IUser
  isUpdatingContext: boolean
}

const INITIAL_STATE: SessionState = {
  isInitialized: false,
  isAuthenticated: false,
  isUpdatingContext: false,
}

const sessionSlice = createSlice({
  name: 'session',
  initialState: INITIAL_STATE,
  reducers: {
    initialiseSessionSuccess: (state, action: PayloadAction<IUser>) => {
      return {
        ...state,
        isAuthenticated: true,
        isInitialized: true,
        metadata: action.payload,
      }
    },

    initialiseSessionFail: () => {
      return {
        ...INITIAL_STATE,
        isInitialized: true,
      }
    },

    initialiseSessionError: () => {
      return {
        ...INITIAL_STATE,
        isInitialized: true,
      }
    },

    signOutSuccess: () => {
      return {
        ...INITIAL_STATE,
      }
    },
    setIsUpdatingContext(state, action: PayloadAction<boolean>) {
      state.isUpdatingContext = action.payload
    },
  },
})

export const selectSession = (state: RootState) => state.session

export const selectIsInitialized = createSelector(
  selectSession,
  (session) => session.isInitialized
)

export const selectIsAuthenticated = createSelector(
  selectSession,
  (session) => session.isAuthenticated
)

export const selectIsUnauthenticated = createSelector(
  selectSession,
  (session) => !session.isAuthenticated
)

export const selectUser = createSelector(
  selectSession,
  (session) => session.metadata
)

export const selectUserRoles = createSelector(selectUser, (user) => user?.roles)

export const selectUserRituals = createSelector(
  selectUser,
  (user) => user?.features
)

export const selectUserFeatures = createSelector(
  selectUser,
  (user) => user?.features
)

export const sessionReducer = sessionSlice.reducer

export const {
  initialiseSessionSuccess,
  initialiseSessionFail,
  initialiseSessionError,
  signOutSuccess,
  setIsUpdatingContext,
} = sessionSlice.actions
