import React from 'react'
import { View } from 'react-native'

import { defaultStyles } from '../../../config/styles'
import Screen from '../../../components/Screen'
import appDate from '../../../utility/date'
import itemStorage from '../../../auth/storageToken'
import { WidgetLayout } from './components/WidgetLayout'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { AppStackParamList } from '../../../navigation/AppNavigator'
import { useNavigation } from '@react-navigation/native'
import ShedActions from '../../../components/shed/ShedActions'

function DashboardScreen() {
  const navigation = useNavigation<DrawerNavigationProp<AppStackParamList>>()

  React.useEffect(() => {
    return () => {
      showShedRatings()
    }
  }, [appDate.today()])

  showShedRatings()

  function showShedRatings() {
    itemStorage.getToken('LAST_LOGIN').then((lastLoginDate) => {
      itemStorage.storeToken('LAST_LOGIN', appDate.today())

      if (
        lastLoginDate === '' ||
        lastLoginDate === null ||
        lastLoginDate !== appDate.today()
      ) {
        navigation.navigate('DASHBOARD', {
          screen: 'ShedRatingScreen',
          params: {
            previous: false,
          },
        })
      }
    })
  }

  return (
    <Screen style={defaultStyles.screen}>
      <View style={defaultStyles.mainContainer}>
        <ShedActions />
        <WidgetLayout />
      </View>
    </Screen>
  )
}

export default DashboardScreen
