import React from 'react'
import { MaterialCommunityIcons } from '@expo/vector-icons'

import { defaultConfig } from '../config/styles'
import IconTextButton from './IconTextButton'

interface IInfoButton {
  text: string
  size?: number
  showIcon?: boolean
  icon?: keyof typeof MaterialCommunityIcons.glyphMap
  onPress: any
  style?: object
}

const InfoButton: React.FC<IInfoButton> = ({
  text,
  size = 15,
  showIcon = true,
  icon = 'information',
  onPress = {},
  style = {},
}) => {
  return (
    <IconTextButton
      text={text}
      size={size}
      showIcon={showIcon}
      icon={icon}
      color={defaultConfig.colors.primary}
      onPress={onPress}
      style={style}
    />
  )
}

export default InfoButton
