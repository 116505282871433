export type ComponentType =
  | 'WELCOME_MESSAGE'
  | 'CIO'
  | 'RAPID_REVIEW'
  | 'BOSS'
  | 'BOOM'
  | 'TROPHY'
  | 'PROFILE'
  | 'REMINDER'
  | 'RESOURCE'
  | 'TEAM'

export const COMPONENT = {
  WELCOME_MESSAGE: 'WELCOME_MESSAGE',
  CIO: 'CIO',
  RAPID_REVIEW: 'RAPID_REVIEW',
  BOSS: 'BOSS',
  BOOM: 'BOOM',
  TROPHY: 'TROPHY',
  PROFILE: 'PROFILE',
  REMINDER: 'REMINDER',
  RESOURCE: 'RESOURCE',
  TEAM: 'TEAM',
} satisfies Record<string, ComponentType>

export type FeatureType = `FEATURE_${ComponentType}`

export const FEATURE = {
  WELCOME_MESSAGE: 'FEATURE_WELCOME_MESSAGE',
  CIO: 'FEATURE_CIO',
  RAPID_REVIEW: 'FEATURE_RAPID_REVIEW',
  BOSS: 'FEATURE_BOSS',
  BOOM: 'FEATURE_BOOM',
  TROPHY: 'FEATURE_TROPHY',
  PROFILE: 'FEATURE_PROFILE',
  REMINDER: 'FEATURE_REMINDER',
  RESOURCE: 'FEATURE_RESOURCE',
  TEAM: 'FEATURE_TEAM',
} satisfies Record<string, FeatureType>
