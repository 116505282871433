import React from 'react'
import {
  Image,
  TouchableOpacity,
  useWindowDimensions,
  View,
} from 'react-native'
import {
  HeaderBackButton,
  HeaderBackButtonProps,
  HeaderTitleProps,
} from '@react-navigation/elements'
import { useNavigation } from '@react-navigation/native'
import ButtonIcon from '../ButtonIcon'
import Notification from '../Notification'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { AppStackParamList } from '../../navigation/AppNavigator'
import { StackNavigationOptions } from '@react-navigation/stack'
import { HeaderProps } from 'react-native-elements'
import { isScreenNarrow } from './helper'
import useWhiteLabel from '../../hooks/useWhiteLabel'

const left = () => {
  const navigation = useNavigation<DrawerNavigationProp<AppStackParamList>>()
  const dimensions = useWindowDimensions()

  return isScreenNarrow(dimensions) ? (
    <ButtonIcon
      color="#303D50"
      icon="menu"
      onPress={() => navigation.toggleDrawer()}
    />
  ) : (
    <ButtonIcon color="#303D50" icon="menu" visible={false} />
  )
}

const right = () => {
  return <Notification />
}

const rightHidden = () => {
  return <ButtonIcon visible={false} icon={'bell-outline'} />
}

function LogoTitle() {
  const navigation = useNavigation<DrawerNavigationProp<AppStackParamList>>()
  const { whiteLabel } = useWhiteLabel()

  return (
    <View style={{ width: '100%', alignItems: 'center' }}>
      <TouchableOpacity
        onPress={() => {
          navigation.navigate('DASHBOARD', {
            screen: 'Dashboard_Screen',
          })
        }}
      >
        <Image
          key="logo"
          source={{ uri: whiteLabel?.logo }}
          style={{ width: 50, height: 50 }}
          resizeMode="cover"
        />
      </TouchableOpacity>
    </View>
  )
}

interface CustomHeaderProps {
  showNudgifications: boolean
  showBackButton: boolean
}

const defaultHeader = (headerProps: HeaderProps): StackNavigationOptions => {
  const props: StackNavigationOptions = {
    headerTintColor: '#fff',
    headerStyle: {
      backgroundColor: '#FFFFFF',
    },
    headerTitle: (headerProps: HeaderTitleProps) => (
      <LogoTitle {...headerProps} style={{ width: '100%' }} />
    ),
    headerTitleAlign: 'center',
  }

  if (headerProps.showBackButton) {
    props.headerLeft = (props: HeaderBackButtonProps) => {
      return props.canGoBack ? (
        <HeaderBackButton
          onPress={props.onPress}
          canGoBack={props.canGoBack}
          onLabelLayout={props.onLabelLayout}
        />
      ) : (
        left()
      )
    }
  } else {
    props.headerLeft = () => {
      return left()
    }
  }

  if (headerProps.showNudgifications) {
    props.headerRight = () => {
      return right()
    }
  } else {
    props.headerRight = () => {
      return rightHidden()
    }
  }

  return props
}

export default {
  defaultHeader,
}
