import React from 'react'
import { View, StyleSheet } from 'react-native'
import { useFormikContext } from 'formik'
import DatePicker from 'react-datepicker'
import { MaterialCommunityIcons } from '@expo/vector-icons'

import ErrorMessage from './ErrorMessage'
import { defaultStyles, defaultConfig } from '../../config/styles'

import 'react-datepicker/dist/react-datepicker.css'

// trying to remove the border on the date input
import '../../config/css/react-datepicker.css'

interface AppFormDatePickerProps {
  name: string
  icon: keyof typeof MaterialCommunityIcons.glyphMap
  placeholder: string
  enableTime?: boolean
  dateFormat?: string
  minDate?: Date
  maxDate?: Date
  width?: string
  readOnly?: boolean
}

const AppFormDatePicker: React.FC<AppFormDatePickerProps> = ({
  name,
  icon,
  placeholder,
  enableTime = false,
  dateFormat = 'd MMM yyyy',
  minDate = null,
  maxDate = null,
  width = '100%',
  readOnly = false,
}) => {
  const { errors, setFieldValue, touched, values } = useFormikContext()

  return (
    <View style={[styles.container, { width: width }]}>
      {icon && (
        <MaterialCommunityIcons
          name={icon}
          size={20}
          color={defaultConfig.colors.medium}
          style={styles.icon}
        />
      )}
      <DatePicker
        selected={values[name] ? new Date(values[name]) : null}
        onChange={(date) => {
          setFieldValue(name, date)
        }}
        style={[defaultStyles.text, styles.input]}
        dateFormat={dateFormat}
        placeholderText={placeholder ?? ''}
        popperPlacement="top"
        maxDate={maxDate}
        minDate={minDate}
        readOnly={readOnly}
        timeInputLabel="Time:"
        showTimeInput={enableTime}
      />
      <ErrorMessage error={errors[name]} visible={touched[name]} />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: defaultConfig.colors.white,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: defaultConfig.colors.medium,
    flexDirection: 'row',
    padding: 15,
    marginVertical: 10,
    zIndex: 300,
  },
  icon: {
    marginRight: 10,
  },
  input: {
    flex: 1,
    fontWeight: '500',
    fontSize: 16,
    borderWidth: 0,
    outlineStyle: 'none',
  },
})

export default AppFormDatePicker
