const blue = '#3EACBE'
const red = '#ED4E5F'
const green = '#ADC83C'

export default {
  primary: blue,
  secondary: '#3E4E63',
  black: '#000',
  white: '#fff',
  medium: '#aaa',
  grey: '#a3a3a3',
  lightGrey: '#f0f0f0',
  light: '#eee',
  lightPrimary: '#DCF0F2',
  dark: '#0c0c0c',
  danger: '#ff5252',
  blue: blue,
  red: red,
  green: green,
}
