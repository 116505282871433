import React from 'react'
import { View, StyleSheet, TouchableWithoutFeedback } from 'react-native'

import Text from '../Text'
import colors from '../../config/colors'
import Icon from '../Icon'

interface Props {
  bossName: string
  onPress: () => void
  highlighted?: boolean
  buttonBackground?: string
  textColour?: string
  highlightedTextColour?: string
}

function BossButton({
  bossName,
  onPress,
  highlighted = false,
  buttonBackground = colors.light,
  textColour = colors.primary,
  highlightedTextColour = colors.white,
}: Props) {
  let buttonText = highlighted ? highlightedTextColour : textColour

  return (
    <TouchableWithoutFeedback onPress={onPress}>
      <View style={[styles.card, { backgroundColor: buttonBackground }]}>
        <View style={[styles.detailsContainer, { flexDirection: 'row' }]}>
          {highlighted && (
            <View style={{ marginRight: 10 }}>
              <Icon
                name="check-bold"
                size={25}
                iconColor={colors.primary}
                backgroundColor={colors.white}
              />
            </View>
          )}
          <Text
            style={[styles.subTitle, { color: buttonText }]}
            numberOfLines={2}
          >
            {bossName}
          </Text>
        </View>
      </View>
    </TouchableWithoutFeedback>
  )
}

const styles = StyleSheet.create({
  card: {
    borderRadius: 10,
    marginBottom: 20,
    cursor: 'pointer',
    overflow: 'hidden',
  },
  detailsContainer: {
    padding: 20,
  },
  subTitle: {
    fontWeight: 'bold',
  },
})

export default BossButton
